import { auth, database, app, functions } from './initFirebase';
import { onAuthStateChanged, getIdToken, signInWithEmailAndPassword, createUserWithEmailAndPassword,signInWithPopup, GoogleAuthProvider, signOut } from "firebase/auth";
import { ref, get, set, onValue, query, orderByChild, equalTo, update, remove, child, onChildAdded, onChildChanged, onChildRemoved } from "firebase/database";
import { count, doc } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { list } from 'firebase/storage';
import { jobs } from 'googleapis/build/src/apis/jobs';

const VERSION = process.env.VERSION;
console.log('App version:', VERSION);

function showUpdateNotification() {
  const notification = document.createElement('div');
  notification.textContent = `A new version is available. Click here to update.`;
  notification.style.cssText = `
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: #4CAF50;
    color: white;
    text-align: center;
    line-height: 2.5;
    overflow: hidden;
    z-index: 9999;
    cursor: pointer;
  `;
  notification.addEventListener('click', () => {
    notification.style.display = 'none';
    window.location.reload();
  });
  document.body.appendChild(notification);
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    console.log('Service worker is registered.');
    const swUrl = `/service-worker-${VERSION}.js`;
    navigator.serviceWorker.register(swUrl)
      .then(registration => {
        console.log('SW registered:', registration);
        
        registration.addEventListener('updatefound', () => {
          const newWorker = registration.installing;
          console.log('New SW found. Installing...');
          
          newWorker.addEventListener('statechange', () => {
            console.log('New SW state:', newWorker.state);
            if (newWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                setTimeout(() => {
                showUpdateNotification();
                },5000);
              } else {
                console.log('Content is cached for offline use.');
              }
            }
          });
        });

        // Check for updates immediately and periodically
        registration.update();
        setInterval(() => {
          registration.update();
        }, 60 * 60 * 1000); // Check every hour
      })
      .catch(error => {
        console.error('SW registration failed:', error);
      });
  });
}

let indexedVersion = 36;
let currentIndexedVersion;

const clerkIdInfo = {
  "Chelsea": "cm",
  "Karen": "klc",
  "Kyle": "kb",
  "Mac": "mjh",
  "Sophie": "sll"
};

let globalUserData;
let globalTechList;

const handleTechNames = (techList) => {
  const techItems = Object.values(techList)
    .filter(tech => tech.role.toLowerCase().includes('tech'))
    .sort((a, b) => a.displayName.localeCompare(b.displayName));

  const firstNameMap = new Map();

  techItems.forEach(tech => {
    const firstName = tech.displayName.split(' ')[0];
    firstNameMap.set(firstName, (firstNameMap.get(firstName) || 0) + 1);
  });

  const processedNames = techItems.map(tech => {
    const nameParts = tech.displayName.split(' ');
    const firstName = nameParts[0];
    const lastName = nameParts.length > 1 ? nameParts[nameParts.length - 1] : '';

    if (firstNameMap.get(firstName) > 1 && lastName) {
      return `${firstName} ${lastName[0]}.`;
    } else {
      return firstName;
    }
  });

  const finalNames = processedNames.reduce((acc, name, index) => {
    if (acc.includes(name)) {
      const nameParts = techItems[index].displayName.split(' ');
      if (nameParts.length > 1) {
        acc.push(`${nameParts[0]} ${nameParts[nameParts.length - 1]}`);
      } else {
        acc.push(techItems[index].displayName);
      }
    } else {
      acc.push(name);
    }
    return acc;
  }, []);

  return finalNames;
};

async function collectTechInformation() {
  console.log('Collecting tech information...');
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('techData')) {
          db.createObjectStore('techData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('users');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'techDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const idToken = await fetchIdToken();
        const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/users.json?auth=${idToken}`;

        const options = {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        };

        const response = await fetch(firebaseUrl, options);
        if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
        const result = await response.json();

        globalUserData = result;
        
        const techUsers = Object.entries(result).reduce((acc, [id, userData]) => {
          if (id === 'lastUpdated') return acc;
          if (userData.role.toLowerCase().includes('tech')) {
            acc[id] = { id, ...userData };
          }
          return acc;
        }, {});

        const transaction = db.transaction(['techData', 'metadata'], 'readwrite');
        const techStore = transaction.objectStore('techData');
        const metadataStore = transaction.objectStore('metadata');

        const indexedDBTechIDs = await new Promise((resolve, reject) => {
          const request = techStore.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });

        const firebaseTechIDs = Object.keys(techUsers);

        Object.values(techUsers).forEach(tech => {
          techStore.put(tech);
        });

        const deletedTechIDs = indexedDBTechIDs.filter(id => !firebaseTechIDs.includes(id));
        deletedTechIDs.forEach(id => techStore.delete(id));

        metadataStore.put({ key: 'techDataLastUpdated', timestamp: firebaseTimestamp });

        await new Promise((resolve, reject) => {
          transaction.oncomplete = () => {
            console.log('All tech data have been updated in IndexedDB.');
            resolve();
          };
          transaction.onerror = (event) => {
            console.error('Transaction error:', event.target.errorCode);
            reject(event.target.error);
          };
        });

        globalTechList = handleTechNames(techUsers);
      } else {
        // If data is up to date, fetch from IndexedDB
        const transaction = db.transaction(['techData'], 'readonly');
        const techStore = transaction.objectStore('techData');
        const techUsers = await new Promise((resolve, reject) => {
          const request = techStore.getAll();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
        globalUserData = techUsers.reduce((acc, user) => {
          acc[user.id] = user;
          return acc;
        }, {});
        globalTechList = handleTechNames(techUsers);
      }
    } else {
      // Offline: fetch from IndexedDB
      const transaction = db.transaction(['techData'], 'readonly');
      const techStore = transaction.objectStore('techData');
      const techUsers = await new Promise((resolve, reject) => {
        const request = techStore.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });
      globalUserData = techUsers.reduce((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      globalTechList = handleTechNames(techUsers);
    }

    console.log('Global user data:', globalUserData);
    console.log('Processed tech list:', globalTechList);

    // Call any function that needs to use the updated tech list
    // For example: updateTechList();

  } catch (error) {
    console.error("Error collecting tech information:", error);
  }
}

// Listeners
const jobsRef = ref(database, 'data');

onChildChanged(jobsRef, async (snapshot) => {
  console.log(snapshot.val());
  const updatedJob = snapshot.val();
  const jobId = snapshot.key;
  console.log('Job updated:', jobId, updatedJob);
  
  if(jobId !== 'lastUpdated'){
    try {
      await updateEntireJobDB(updatedJob);
      console.log('Job updated in IndexedDB');
    } catch (error) {
      console.error('Failed to update job in IndexedDB', error);
    }
  }
});

/* onChildAdded(jobsRef, async (snapshot) => {
  const newJob = snapshot.val();
  const jobId = snapshot.key;
  console.log('New job added:', jobId, newJob);
  
  try {
    await updateEntireJobDB(newJob);
    console.log('New job added to IndexedDB');
  } catch (error) {
    console.error('Failed to add new job to IndexedDB', error);
  }
}); */

onChildRemoved(jobsRef, async (snapshot) => {
  const jobId = snapshot.key;
  console.log('Job removed:', jobId);
  
  // If you want to remove the job from IndexedDB as well, you can add that logic here
  // For example:
  // await removeJobFromIndexedDB(jobId);
});

async function updateEntireJobDB(jobData) {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" });
      }
    };

    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");

      jobData.id = jobData.Job_ID;
      console.log(jobData);
      const updateRequest = objectStore.put(jobData);

      updateRequest.onsuccess = function() {
        console.log(`Data updated for Job_ID ${jobData.Job_ID}`);
        resolve();
      };

      updateRequest.onerror = function(error) {
        console.error("Error updating data", error);
        reject(error);
      };

      transaction.oncomplete = function() {
        db.close();
      };
    };

    openRequest.onerror = function(error) {
      console.error("Error opening database", error);
      reject(error);
    };
  });
}

//End of Listeners

var globalCustomerData = {};
var closeWorkOrders = {};
let jobHours = {};
let techInfo = {};
let customerDB = {};
let boatDB = {};
let activeTableRow;
let selectedJobRow;
var globalWorkOrder;
var globalJobId;
let activeRow = null;
let currentOpCodes = [];
var jobIdGlobal;
let globalKit;
let dataRows;
var globalShrink;
let countObject = {};

// Events array populated by the populateJobList() function and used for the scheduler
let events = [];

document.addEventListener('DOMContentLoaded', async () => {
  try {
    await checkAndInitializeGoogleAPIs();
  } catch (error) {
    console.error('Error initializing app:', error);
  }
});

async function startApp(){
  try{

    await openDatabase();
    await loadIframes();
    await getCurrentUserData();
    document.getElementById('currentUser').value = user.displayName;

  }catch(error){
    console.error('Error starting app', error);
  }
}

async function formatDate(date) {
try{
  if(date){
    date = new Date(date);
  }else{
    date = new Date();
  };
  
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth() returns 0-11
  let day = date.getDate().toString().padStart(2, '0');
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}catch(error){
  console.error('Error formatting date', error);
}
}

async function openDatabase() {
  await closeExistingConnections(); // Close any existing connections
  await deleteDatabaseIfVersionChanged(); // Delete if version has changed

  return new Promise((resolve, reject) => {

    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      console.log('onupgradeneeded event triggered for version', currentIndexedVersion);

      // Create object stores if they don't already exist
      const objectStores = [
        { name: 'customerData', keyPath: 'id' },
        { name: 'jobData', keyPath: 'id' },
        { name: 'opCodeData', keyPath: 'id' },
        { name: 'mapDataBoats', keyPath: 'id' },
        { name: 'mapDataMoorings', keyPath: 'id' },
        { name: 'inventoryData', keyPath: 'id' },
        { name: 'receivingData', keyPath: 'id' },
        { name: 'mooringDatabase', keyPath: 'id' },
        { name: 'rentalDatabase', keyPath: 'id' },
        { name: 'vendorData', keyPath: 'id' },
        { name: 'scheduleData', keyPath: 'id' },
        { name: 'techData', keyPath: 'id' },
        { name: 'metadata', keyPath: 'key' },
      ];

      objectStores.forEach(store => {
        if (!db.objectStoreNames.contains(store.name)) {
          db.createObjectStore(store.name, { keyPath: store.keyPath });
          console.log(`Object store ${store.name} created`);
        } else {
          console.log(`Object store ${store.name} already exists`);
        }
      });

      const transaction = event.target.transaction;
      const metadataStore = transaction.objectStore('metadata');
      const itemsToAdd = [
        { key: 'customerDataLastUpdated', timestamp: 0 },
        { key: 'jobDataLastUpdated', timestamp: 0 },
        { key: 'opCodeDataLastUpdated', timestamp: 0 },
        { key: 'boatDataLastUpdated', timestamp: 0 },
        { key: 'mooringDataLastUpdated', timestamp: 0 },
        { key: 'inventoryDataLastUpdated', timestamp: 0 },
        { key: 'receivingDataLastUpdated', timestamp: 0},
        { key: 'mooringDatabaseLastUpdated', timestamp: 0 },
        { key: 'settingsLastUpdated', timestamp: 0},
        { key: 'vendorDataLastUpdated', timestamp: 0 },
        { key: 'scheduleDataLastUpdated', timestamp: 0 },
        { key: 'techDataLastUpdated', timestamp: 0 },
      ];

      itemsToAdd.forEach(item => {
        metadataStore.put(item);
        console.log(`Metadata item ${item.key} added`);
      });

      transaction.oncomplete = () => {
        console.log('Initial metadata items added.');
      };

      transaction.onerror = (event) => {
        console.error('Error adding initial metadata items:', event.target.error);
        reject(event.target.error);
      };
    };

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject(event.target.errorCode);
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };
  });
}

/* gapi.load('client', () => {
  initializeGoogleAPIs();
}); */

function retrieveAndLogAuthToken() {

  if (!navigator.onLine) {
    return; // Exit the function early if offline
  }

  const functions = getFunctions(app);
  const getAuthToken = httpsCallable(functions, 'getGoogleOAuthToken');

  getAuthToken()
    .then((result) => {
      localStorage.setItem('userAccessToken', result.data.accessToken);
    })
    .catch((error) => {
      console.error('Error retrieving OAuth token:', error);
    });
}

function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/calendar');
  provider.addScope('https://www.googleapis.com/auth/spreadsheets');

  signInWithPopup(auth, provider)
    .then((result) => {
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;
      const idToken = credential.idToken;
      const user = result.user;

      // Decode the ID token to get the expiration time
      const decodedToken = JSON.parse(atob(idToken.split('.')[1]));
      const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds

      // Save the access token, refresh token, and expiration time to local storage
      localStorage.setItem('accessToken', token);
      localStorage.setItem('refreshToken', user.refreshToken);
      localStorage.setItem('expirationTime', expirationTime);

      // Check if the user already exists in the Realtime Database
      const userRef = ref(database, `users/${user.uid}`);
      get(userRef)
        .then(async (snapshot) => {
          if (snapshot.exists()) {
            // User already exists, check if the user has an assigned role
            const userData = snapshot.val();
            if (userData.role) {
              // User has a role, proceed with loading the page
              console.log("User already exists and has a role");
            } else {
              // User doesn't have a role, display a message and prevent page loading
              console.log("User already exists but doesn't have a role");
              displayMessage("Access Denied", "Please contact the administrator to assign a role.");
            }
          } else {
            // User doesn't exist, create the user in the Realtime Database without a role
            set(userRef, {
              email: user.email,
              displayName: user.displayName,
            })
              .then(() => {
                console.log("New user created without a role");
                displayMessage("Access Denied", "Please contact the administrator to assign a role.");
              })
              .catch((error) => {
                console.error("Error creating new user: ", error);
              });
          }
        })
        .catch((error) => {
          console.error("Error checking user existence: ", error);
        });
    })
    .catch((error) => {
      console.error("Error signing in with Google: ", error);
    });
}

async function fetchIdToken() {
  const user = auth.currentUser;

  if (!user) {
    throw new Error('User not authenticated');
  }

  try {
    const idToken = await user.getIdToken(true);
    return idToken;
  } catch (error) {
    console.error('Error fetching ID token:', error);
    throw error;
  }
}

/* function initializeGoogleAPIs() {

  if(!navigator.onLine){
    return;
  }

  // Initialize the Google Identity Services library
  google.accounts.id.initialize({
    client_id: '66934899393-kevqmctq7l3fpra0ib0nesimpr3ff9jk.apps.googleusercontent.com',
    callback: handleCredentialResponse,
    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets',
  });

  // Prompt the user to select an account
  google.accounts.id.prompt();

  // Initialize the Google API client library
  gapi.client.init({
    apiKey: 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U',
    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest', 'https://sheets.googleapis.com/$discovery/rest?version=v4']
  }).then(() => {
    console.log('Google API client library is loaded and initialized');
    gapi.client.load('sheets', 'v4');

  }).catch((error) => {
    console.error('Error initializing Google API client library:', error);
  });
} */


  function checkAndInitializeGoogleAPIs() {
    return new Promise((resolve, reject) => {
      function check() {
        if (typeof gapi !== 'undefined' && typeof google !== 'undefined') {
          initializeGoogleAPIs().then(resolve).catch(reject);
        } else {
          setTimeout(check, 100);
        }
      }
      check();
    });
  }

  function initializeGoogleAPIs() {
    if (!navigator.onLine) {
      return Promise.reject('No internet connection');
    }
  
    return new Promise((resolve, reject) => {
      // Initialize the Google Identity Services library
      if (typeof google !== 'undefined' && google.accounts && google.accounts.id) {
        google.accounts.id.initialize({
          client_id: '66934899393-kevqmctq7l3fpra0ib0nesimpr3ff9jk.apps.googleusercontent.com',
          callback: handleCredentialResponse,
          scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/spreadsheets',
        });
      } else {
        console.warn('Google Identity Services not yet loaded');
      }
  
      // Initialize the Google API client library
      if (typeof gapi !== 'undefined') {
        gapi.load('client', () => {
          gapi.client.init({
            apiKey: 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U',
            discoveryDocs: [
              'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest', 
              'https://sheets.googleapis.com/$discovery/rest?version=v4',
              'https://www.googleapis.com/discovery/v1/apis/translate/v2/rest'
            ]
          }).then(() => {
            // Check if translate API is loaded
            if (gapi.client.translate) {
              console.log('Translate API loaded successfully');
              setupSpeechRecognition();
            } else {
              console.warn('Translate API not loaded');
            }
            resolve();
          }).catch((error) => {
            console.error('Error initializing Google API client library:', error);
            reject(error);
          });
        });
      } else {
        reject('Google API client library not yet loaded');
      }
    });
  }

function handleCredentialResponse(response) {

  if(!navigator.onLine){
    return;
  }

  // Handle the credential response and obtain the access token
  const accessToken = response.credential;
  console.log(accessToken);

  // Decode the credential (JWT) to get the expiration time
  const decodedToken = JSON.parse(atob(accessToken.split('.')[1]));
  const expirationTime = decodedToken.exp * 1000; // Convert expiration time to milliseconds

  localStorage.setItem('userAccessToken', accessToken);
  localStorage.setItem('expirationTime', expirationTime);

}

async function getUserRole(uid) {
  if(!navigator.onLine){
    console.log('User is offline. Skipping function.');
    return;
  }
  try {
    const customersRef = ref(database, 'users/' + uid + '/role');
    const snapshot = await get(customersRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error fetching user role:", error);
    return null;
  }
}

async function closeExistingConnections() {
  const databases = await indexedDB.databases();
  for (const database of databases) {
    if (database.name === 'openDatabase') {
      const request = indexedDB.open(database.name, database.version);
      request.onsuccess = () => {
        const db = request.result;
        db.close();
      };
      request.onerror = (event) => {
        console.error(`Failed to close connection to database: ${database.name}`, event.target.error);
      };
    }
  }
}

async function getCurrentDatabaseVersion() {
  const dbName = 'openDatabase';
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName);

    request.onsuccess = (event) => {
      const db = event.target.result;
      const version = db.version;
      db.close();
      resolve(version);
    };

    request.onerror = (event) => {
      console.error('Error checking database version:', event.target.errorCode);
      resolve(null); // In case of error, resolve with null
    };
  });
}

async function deleteDatabaseIfVersionChanged() {
  const dbName = 'openDatabase';
  const currentVersion = await getCurrentDatabaseVersion();
  if (currentVersion !== null && currentVersion < indexedVersion) {
    console.log('New version detected. Deleting old database...');
    currentIndexedVersion = indexedVersion;
    await new Promise((resolve, reject) => {
      const deleteRequest = indexedDB.deleteDatabase(dbName);

      deleteRequest.onerror = (event) => {
        console.error('Error deleting database:', event.target.errorCode);
        reject(event.target.errorCode);
      };

      deleteRequest.onsuccess = (event) => {
        console.log('Database deleted successfully');
        resolve();
      };
    });
  }else{
    currentIndexedVersion = currentVersion;
  }
  
}

function handleAuthStateChange(user) {
  if (!user) {
    document.getElementById('login-modal').style.display = 'flex';
    return;
  }

  if (navigator.onLine) {
    onlineFlow(user);
  } else {
    offlineFlow(user);
  }
}

async function onlineFlow(user) {
  try {
    await checkAuthState();
    const hasAccess = await getUserRole(user.uid);
    if (!hasAccess) {
      console.log('No Role!');
      document.getElementById('no-role').style.display = "flex";
      return;
    }
    await openDatabase();
    await initializeDatabase();
    await loadIframes();
    await getCurrentUserData();
    await populateVendorLists();
    document.getElementById('currentUser').value = user.displayName;
  } catch (error) {
    console.error("Error during online flow:", error);
  }
}

async function offlineFlow(user) {
  try {
    // Load data from IndexedDB cache
    loadDataFromIndexedDB();
    await loadIframes();
    document.getElementById('currentUser').value = user.displayName;
    // Get user info from localStorage
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      document.getElementById('currentUser').value = userInfo.displayName;
    }
    
    // Additional offline operations...
  } catch (error) {
    console.error("Error during offline flow:", error);
  }
}

async function loadDataFromIndexedDB() {
  try{
    await Promise.all([
      getJobsOpen(),
      getCustomerOpen(),
      getOpCodes(),
      getMapData(),
      getInventory(),
      getReceiving(),
      getVendors(),
      getMooringDatabaseOpen(),
      getRentalDatabaseOpen(),
      populateVendorLists(),
      getScheduleOpen()
    ])
      console.log("Loading data from IndexedDB...");
  }catch(error){
    console.error('Error loading data from IndexedDB', error);
  }
}

onAuthStateChanged(auth, handleAuthStateChange);

// Listen for online/offline events
window.addEventListener('online', () => {
  console.log('App is online');
  const user = auth.currentUser;
  if (user) onlineFlow(user);
});

window.addEventListener('offline', () => {
  console.log('App is offline');
  const user = auth.currentUser;
  if (user) offlineFlow(user);
});

async function initializeDatabase() {
  
  try {
    // Perform data synchronization
    await collectTechInformation();
    await Promise.all([
      getCustomerOpen(),
      getJobsOpen(),
      getOpCodes(),
      getMapData(),
      getInventory(),
      getReceiving(),
      getVendors(),
      getMooringDatabaseOpen(),
      getRentalDatabaseOpen(),
      getScheduleOpen()
    ]);

  } catch (error) {
    console.error('Failed to initialize the database:', error);
    throw error;
  } 
}

async function loadIframes() {
  // Check if the database is initialized and ready to use
  try{
      // Load the iframes
      document.getElementById('maps').innerHTML = '<iframe src="/maps" class="page-frame"></iframe>';
      document.getElementById('spring').innerHTML = '<iframe src="/spring" class="page-frame"></iframe>';
      document.getElementById('hauling').innerHTML = '<iframe src="/hauling" class="page-frame"></iframe>';
      document.getElementById('customers').innerHTML = '<iframe src="/customers" class="page-frame"></iframe>';
      document.getElementById('mooringDatabase').innerHTML = '<iframe src="/mooringDatabase" class="page-frame"></iframe>';
      document.getElementById('rentalDatabase').innerHTML = '<iframe src="/rentalDatabase" class="page-frame"></iframe>';
      document.getElementById('inventoryDatabase').innerHTML = '<iframe src="/inventory" class="page-frame"></iframe>';
      document.getElementById('receivingDatabase').innerHTML = '<iframe src="/receiving" class="page-frame"></iframe>';
      /* document.getElementById('schedule').innerHTML = '<iframe src="/schedule" class="page-frame"></iframe>'; */

  }catch(error){
    console.error('Error loading iFrames', error);
  };
}

function checkAuthState() {
  return new Promise((resolve, reject) => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const accessToken = localStorage.getItem('userAccessToken');
        localStorage.setItem('userInfo', JSON.stringify(user));
        document.getElementById('currentUserName').innerHTML = user.displayName;
        if (accessToken) {
          resolve(accessToken);
        } else {
          reject(new Error('Access token not available'));
        }
      } else {
        resolve(null);
      }
    });
  });
}

async function getCurrentUserData() {
  try{
    const user = auth.currentUser;
    if (user) {
      const email = user.email;
      return user;
    }
  }catch(error){
    console.error('Cannot get current user info' + error);
  };
}

async function fetchFirebaseLastUpdated(firebasePath) {
  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/${firebasePath}/lastUpdated.json?auth=${idToken}`;

    const response = await fetch(firebaseUrl);
    if (!response.ok) throw new Error(`Firebase fetch failed with status ${response.status}`);
    
    const timestamp = await response.json();
    return timestamp;
  } catch (error) {
    console.error(`Error fetching Firebase last updated timestamp for ${firebasePath}:`, error);
    throw error; // Re-throw the error to be handled by the caller
  }
}

async function fetchIndexedDBLastUpdated(db, metadataKeyName) {
  return new Promise((resolve, reject) => {
    const transaction = db.transaction(['metadata'], 'readonly');
    const store = transaction.objectStore('metadata');
    const request = store.get(metadataKeyName);

    request.onsuccess = () => {
      resolve(request.result ? request.result.timestamp : 0);
    };

    request.onerror = (event) => {
      console.error(`Error fetching IndexedDB last updated timestamp for ${metadataKeyName}:`, event.target.error);
      reject(event.target.error);
    };
  });
}

async function updateTimestamps(dataStoreNameIDB, dataStoreNameFirebase) {
  // Timestamp to use for both IndexedDB and Firebase updates
  const currentTimestamp = new Date().getTime();

  try {
    // Update timestamp in IndexedDB
    const db = await openIndexedDB(); // Assuming this function abstracts the IndexedDB open request
    const tx = db.transaction(['metadata'], 'readwrite');
    const store = tx.objectStore('metadata');
    const key = `${dataStoreNameIDB}LastUpdated`;
    store.put({ key: key, timestamp: currentTimestamp });

    // Update timestamp in Firebase
    const firebasePath = `${dataStoreNameFirebase}/lastUpdated`;
    await updateFirebaseTimestamp(firebasePath, currentTimestamp);
  } catch (error) {
    console.error(`Error updating timestamps for ${dataStoreNameIDB}:`, error);
  }
}

async function updateFirebaseTimestamp(path, timestamp) {
  try {
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/${path}.json?auth=${idToken}`;

    const response = await fetch(firebaseUrl, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(timestamp),
    });
    
    if (!response.ok) throw new Error('Firebase update failed');
  } catch (error) {
    console.error('Error updating Firebase timestamp:', error);
    throw error; // It's generally a good practice to throw the error to be handled by the caller
  }
}

  async function getCustomerOpen() {
    try {
      const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
      const db = await new Promise((resolve, reject) => {
        dbPromise.onupgradeneeded = event => {
          const db = event.target.result;
          if (!db.objectStoreNames.contains('customerData')) {
            db.createObjectStore('customerData', { keyPath: 'id' });
          }
          if (!db.objectStoreNames.contains('metadata')) {
            db.createObjectStore('metadata', { keyPath: 'key' });
          }
        };
        
        dbPromise.onerror = event => reject(event.target.error);
        dbPromise.onsuccess = event => resolve(event.target.result);
      });
  
      let firebaseTimestamp, indexedDBTimestamp;
  
      if (navigator.onLine) {
        firebaseTimestamp = await fetchFirebaseLastUpdated('customers');
        indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'customerDataLastUpdated');
      }
  
      if (navigator.onLine && (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp)) {
        // Online and data needs updating
        const customersRef = ref(database, '/customers');
        const snapshot = await get(customersRef);
  
        const transaction = db.transaction(['customerData', 'metadata'], 'readwrite');
        const store = transaction.objectStore('customerData');
        const metadataStore = transaction.objectStore('metadata');
  
        // Get all customer IDs from IndexedDB
        const indexedDBCustomerIDs = await new Promise((resolve, reject) => {
          const request = store.getAllKeys();
          request.onsuccess = () => resolve(request.result);
          request.onerror = () => reject(request.error);
        });
  
        const firebaseCustomerIDs = [];
  
        snapshot.forEach((childSnapshot) => {
          const childKey = childSnapshot.key;
          const childData = childSnapshot.val();
  
          firebaseCustomerIDs.push(childKey);
  
          // Add or update customer data in IndexedDB
          store.put({ id: childKey, ...childData });
        });
  
        // Identify and remove deleted customers from IndexedDB
        const deletedCustomerIDs = indexedDBCustomerIDs.filter(id => !firebaseCustomerIDs.includes(id));
        deletedCustomerIDs.forEach(id => store.delete(id));
  
        // Update the timestamp in metadata store after successful updates
        metadataStore.put({ key: 'customerDataLastUpdated', timestamp: firebaseTimestamp });
  
        await new Promise((resolve, reject) => {
          transaction.oncomplete = () => {
            console.log('All customers have been updated in IndexedDB.');
            resolve();
          };
          transaction.onerror = (event) => {
            console.error('Transaction error:', event.target.errorCode);
            reject(event.target.error);
          };
        });
      }
  
      // Whether online or offline, fetch customer data from IndexedDB
      fetchCustomerDataAndGenerateTable();
  
    } catch (error) {
      console.error('Error in getCustomerOpen:', error);
    }
  }

async function getMooringDatabaseOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mooringDatabase')) {
          db.createObjectStore('mooringDatabase', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine) {
    const firebaseTimestamp = await fetchFirebaseLastUpdated('mooringDatabase');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'mooringDatabaseLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const mooringRef = ref(database, '/mooringDatabase/tags');
      const snapshot = await get(mooringRef);

      const transaction = db.transaction(['mooringDatabase', 'metadata'], 'readwrite');
      const store = transaction.objectStore('mooringDatabase');
      const metadataStore = transaction.objectStore('metadata');

      // Get all mooring IDs from IndexedDB
      const indexedDBMooringIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseMooringIDs = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        firebaseMooringIDs.push(childKey);

        // Add or update mooring data in IndexedDB
        store.put({ id: childKey, ...childData });
      });

      // Identify and remove deleted mooring from IndexedDB
      const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
      deletedMooringIDs.forEach(id => store.delete(id));

      // Update the timestamp in metadata store after successful updates
      metadataStore.put({ key: 'mooringDatabaseLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('Mooring database has been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error in getMooringDatabaseOpen:', error);
  }
}

async function getRentalDatabaseOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mooringDatabase')) {
          db.createObjectStore('mooringDatabase', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine) {
    const firebaseTimestamp = await fetchFirebaseLastUpdated('mooringDatabase');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'mooringDatabaseLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const mooringRef = ref(database, '/mooringDatabase/rentals');
      const snapshot = await get(mooringRef);

      const transaction = db.transaction(['rentalDatabase', 'metadata'], 'readwrite');
      const store = transaction.objectStore('rentalDatabase');
      const metadataStore = transaction.objectStore('metadata');

      // Get all mooring IDs from IndexedDB
      const indexedDBMooringIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseMooringIDs = [];

      snapshot.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        firebaseMooringIDs.push(childKey);

        // Add or update mooring data in IndexedDB
        store.put({ id: childKey, ...childData });
      });

      // Identify and remove deleted mooring from IndexedDB
      const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
      deletedMooringIDs.forEach(id => store.delete(id));

      // Update the timestamp in metadata store after successful updates
      metadataStore.put({ key: 'mooringDatabaseLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('Rental database has been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error in getRentalDatabaseOpen:', error);
  }
}

async function getJobsOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('jobData')) {
          db.createObjectStore('jobData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('data');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'jobDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const dbRef = ref(database, '/data');
    
        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const dataArray = Object.entries(data || {}).map(([id, details]) => ({
              id,
              ...details,
            }));

            const transaction = db.transaction(['jobData', 'metadata'], 'readwrite');
            const jobStore = transaction.objectStore('jobData');
            const metadataStore = transaction.objectStore('metadata');

            const indexedDBJobIDs = await new Promise((resolve, reject) => {
              const request = jobStore.getAllKeys();
              request.onsuccess = () => resolve(request.result);
              request.onerror = () => reject(request.error);
            });

            const firebaseJobIDs = dataArray.map(job => job.id);

            dataArray.forEach(job => {
              jobStore.put(job);
            });

            const deletedJobIDs = indexedDBJobIDs.filter(id => !firebaseJobIDs.includes(id));
            deletedJobIDs.forEach(id => jobStore.delete(id));

            metadataStore.put({ key: 'jobDataLastUpdated', timestamp: firebaseTimestamp });

            await new Promise((resolve, reject) => {
              transaction.oncomplete = () => {
                console.log('All job data have been updated in IndexedDB.');
                resolve();
              };
              transaction.onerror = (event) => {
                console.error('Transaction error:', event.target.errorCode);
                reject(event.target.error);
              };
            });
          }
        } catch (error) {
          console.error('Error fetching data from Firebase:', error);
        }
      }
    }

    // Always call mainTable(), whether online or offline
    mainTable();

  } catch (error) {
    console.error('Error in getJobsOpen:', error);
  }
}

async function getMapData() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('mapDataBoats')) {
          db.createObjectStore('mapDataBoats', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('mapDataMoorings')) {
          db.createObjectStore('mapDataMoorings', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine){
    // Fetch timestamps
    const firebaseTimestampBoats = await fetchFirebaseLastUpdated('maps/boats');
    const indexedDBTimestampBoats = await fetchIndexedDBLastUpdated(db, 'boatDataLastUpdated');

    const firebaseTimestampMoorings = await fetchFirebaseLastUpdated('maps/moorings');
    const indexedDBTimestampMoorings = await fetchIndexedDBLastUpdated(db, 'mooringDataLastUpdated');

    // Update boats data if necessary
    if (!indexedDBTimestampBoats || indexedDBTimestampBoats < firebaseTimestampBoats) {
      const boatsRef = ref(database, '/maps/boats');
      const snapshotBoats = await get(boatsRef);

      const transactionBoats = db.transaction(['mapDataBoats', 'metadata'], 'readwrite');
      const boatStore = transactionBoats.objectStore('mapDataBoats');
      const metadataStore = transactionBoats.objectStore('metadata');

      const indexedDBBoatIDs = await new Promise((resolve, reject) => {
        const request = boatStore.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseBoatIDs = [];

      snapshotBoats.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        firebaseBoatIDs.push(childKey);

        boatStore.put({ id: childKey, ...childData });
      });

      const deletedBoatIDs = indexedDBBoatIDs.filter(id => !firebaseBoatIDs.includes(id));
      deletedBoatIDs.forEach(id => boatStore.delete(id));

      metadataStore.put({ key: 'boatDataLastUpdated', timestamp: firebaseTimestampBoats });

      transactionBoats.oncomplete = () => {
        console.log('All boats have been updated in IndexedDB.');
      };

      transactionBoats.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    } 
    // Update moorings data if necessary
    if (!indexedDBTimestampMoorings || indexedDBTimestampMoorings < firebaseTimestampMoorings) {
      const mooringsRef = ref(database, '/maps/moorings');
      const snapshotMoorings = await get(mooringsRef);

      const transactionMoorings = db.transaction(['mapDataMoorings', 'metadata'], 'readwrite');
      const mooringStore = transactionMoorings.objectStore('mapDataMoorings');
      const metadataStore = transactionMoorings.objectStore('metadata');

      const indexedDBMooringIDs = await new Promise((resolve, reject) => {
        const request = mooringStore.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseMooringIDs = [];

      snapshotMoorings.forEach((childSnapshot) => {
        const childKey = childSnapshot.key;
        const childData = childSnapshot.val();

        firebaseMooringIDs.push(childKey);

        mooringStore.put({ id: childKey, ...childData });
      });

      const deletedMooringIDs = indexedDBMooringIDs.filter(id => !firebaseMooringIDs.includes(id));
      deletedMooringIDs.forEach(id => mooringStore.delete(id));

      metadataStore.put({ key: 'mooringDataLastUpdated', timestamp: firebaseTimestampMoorings });

      transactionMoorings.oncomplete = () => {
        console.log('All moorings have been updated in IndexedDB.');
      };

      transactionMoorings.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error in getMapData:', error);
  }
}

async function getOpCodes() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('opCodeData')) {
          db.createObjectStore('opCodeData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine){
    const firebaseTimestamp = await fetchFirebaseLastUpdated('opcodes');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'opCodeDataLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const opCodesRef = ref(database, '/opcodes');
      const snapshot = await get(opCodesRef);
      const opCodes = snapshot.val();

      const transaction = db.transaction(['opCodeData', 'metadata'], 'readwrite');
      const store = transaction.objectStore('opCodeData');
      const metadataStore = transaction.objectStore('metadata');

      const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseOpCodeIDs = Object.keys(opCodes);

      Object.entries(opCodes).forEach(([key, value]) => {
        store.put({ id: key, ...value });
      });

      const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
      deletedOpCodeIDs.forEach(id => store.delete(id));

      metadataStore.put({ key: 'opCodeDataLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('All op codes have been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error synchronizing op codes:', error);
  }
}

async function getInventory() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('inventoryData')) {
          db.createObjectStore('inventoryData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine){
    const firebaseTimestamp = await fetchFirebaseLastUpdated('inventory');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'inventoryDataLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const opCodesRef = ref(database, '/inventory');
      const snapshot = await get(opCodesRef);
      const opCodes = snapshot.val();

      const transaction = db.transaction(['inventoryData', 'metadata'], 'readwrite');
      const store = transaction.objectStore('inventoryData');
      const metadataStore = transaction.objectStore('metadata');

      const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseOpCodeIDs = Object.keys(opCodes);

      Object.entries(opCodes).forEach(([key, value]) => {
        store.put({ id: key, ...value });
      });

      const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
      deletedOpCodeIDs.forEach(id => store.delete(id));

      metadataStore.put({ key: 'inventoryDataLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('All inventory has been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error synchronizing inventory:', error);
  }
}

async function getVendors() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('vendorData')) {
          db.createObjectStore('vendorData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine){
    const firebaseTimestamp = await fetchFirebaseLastUpdated('settings');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'settingsLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const opCodesRef = ref(database, '/settings/vendorList');
      const snapshot = await get(opCodesRef);
      const opCodes = snapshot.val();

      const transaction = db.transaction(['vendorData', 'metadata'], 'readwrite');
      const store = transaction.objectStore('vendorData');
      const metadataStore = transaction.objectStore('metadata');

      const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseOpCodeIDs = Object.keys(opCodes);
      console.log(opCodes)

      Object.entries(opCodes).forEach(([key, value]) => {
        store.put({ id: key, ...value });
      });

      const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
      deletedOpCodeIDs.forEach(id => store.delete(id));

      metadataStore.put({ key: 'settingsLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('All settings has been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error synchronizing settings:', error);
  }
}

async function getReceiving() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('receivingData')) {
          db.createObjectStore('receivingData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if(navigator.onLine){
    const firebaseTimestamp = await fetchFirebaseLastUpdated('receiving');
    const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'receivingDataLastUpdated');

    if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
      const opCodesRef = ref(database, '/receiving');
      const snapshot = await get(opCodesRef);
      const opCodes = snapshot.val();

      const transaction = db.transaction(['receivingData', 'metadata'], 'readwrite');
      const store = transaction.objectStore('receivingData');
      const metadataStore = transaction.objectStore('metadata');

      const indexedDBOpCodeIDs = await new Promise((resolve, reject) => {
        const request = store.getAllKeys();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject(request.error);
      });

      const firebaseOpCodeIDs = Object.keys(opCodes);

      Object.entries(opCodes).forEach(([key, value]) => {
        store.put({ id: key, ...value });
      });

      const deletedOpCodeIDs = indexedDBOpCodeIDs.filter(id => !firebaseOpCodeIDs.includes(id));
      deletedOpCodeIDs.forEach(id => store.delete(id));

      metadataStore.put({ key: 'receivingDataLastUpdated', timestamp: firebaseTimestamp });

      transaction.oncomplete = () => {
        console.log('All receiving has been updated in IndexedDB.');
      };

      transaction.onerror = (event) => {
        console.error('Transaction error:', event.target.errorCode);
      };
    }
  }
  } catch (error) {
    console.error('Error synchronizing inventory:', error);
  }
}

async function getScheduleOpen() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('scheduleData')) {
          db.createObjectStore('scheduleData', { keyPath: 'id' });
        }
        if (!db.objectStoreNames.contains('metadata')) {
          db.createObjectStore('metadata', { keyPath: 'key' });
        }
      };

      dbPromise.onerror = event => reject(event.target.error);
      dbPromise.onsuccess = event => resolve(event.target.result);
    });

    if (navigator.onLine) {
      const firebaseTimestamp = await fetchFirebaseLastUpdated('scheduleDatabase');
      const indexedDBTimestamp = await fetchIndexedDBLastUpdated(db, 'scheduleDataLastUpdated');

      if (!indexedDBTimestamp || indexedDBTimestamp < firebaseTimestamp) {
        const dbRef = ref(database, '/scheduleDatabase');
    
        try {
          const snapshot = await get(dbRef);
          if (snapshot.exists()) {
            const data = snapshot.val();
            const dataArray = Object.entries(data || {}).map(([id, details]) => ({
              id,
              ...details,
            }));

            const transaction = db.transaction(['scheduleData', 'metadata'], 'readwrite');
            const jobStore = transaction.objectStore('scheduleData');
            const metadataStore = transaction.objectStore('metadata');

            const indexedDBJobIDs = await new Promise((resolve, reject) => {
              const request = jobStore.getAllKeys();
              request.onsuccess = () => resolve(request.result);
              request.onerror = () => reject(request.error);
            });

            const firebaseJobIDs = dataArray.map(job => job.id);

            dataArray.forEach(job => {
              jobStore.put(job);
            });

            const deletedJobIDs = indexedDBJobIDs.filter(id => !firebaseJobIDs.includes(id));
            deletedJobIDs.forEach(id => jobStore.delete(id));

            metadataStore.put({ key: 'scheduleDataLastUpdated', timestamp: firebaseTimestamp });

            await new Promise((resolve, reject) => {
              transaction.oncomplete = () => {
                console.log('All schedule data has been updated in IndexedDB.');
                resolve();
              };
              transaction.onerror = (event) => {
                console.error('Transaction error:', event.target.errorCode);
                reject(event.target.error);
              };
            });
          }
        } catch (error) {
          console.error('Error fetching schedule from Firebase:', error);
        }
      }
    }

  } catch (error) {
    console.error('Error at getScheduleData:', error);
  }
}

function updateSignInStatus(isSignedIn) {
  if (isSignedIn) {
    // User is signed in. You can now make API calls.
  } else {
    // User is not signed in. Start sign-in process.
    gapi.auth2.getAuthInstance().signIn();
  }
}

function refreshAccessToken() {
  return new Promise((resolve, reject) => {
    const refreshToken = localStorage.getItem('refreshToken');
    if (refreshToken) {
      const auth2 = gapi.auth2.getAuthInstance();
      auth2.signIn({ prompt: 'none' }).then(() => {
        const currentUser = auth2.currentUser.get();
        currentUser.reloadAuthResponse().then((authResponse) => {
          const newAccessToken = authResponse.access_token;
          localStorage.setItem('accessToken', newAccessToken);
          resolve(newAccessToken);
        }).catch((error) => {
          reject(error);
        });
      }).catch((error) => {
        reject(error);
      });
    } else {
      reject(new Error('Refresh token not available'));
    }
  });
}



async function listUpcomingEvents() {
  try {
    
    if (!gapi || !gapi.client) {
      throw new Error('gapi.client is not loaded');
    }

    const accessToken = await checkAuthState();

    gapi.client.setToken(accessToken);

    const response = await gapi.client.request({
      'path': 'https://www.googleapis.com/calendar/v3/calendars/c_8b3bf39137e47f5a8b783bbb1a392348e66b57064559d6b4e7c0f8c0be3d41b3@group.calendar.google.com/events',
      'method': 'GET',
      'params': {
        'timeMin': (new Date()).toISOString(),
        'showDeleted': false,
        'singleEvents': true,
        'maxResults': 20,
        'orderBy': 'startTime'
      },
      'headers': {
        'Authorization': 'Bearer ' + accessToken
      }
    });

    var events = response.result.items;
    displayEvents(events);
  } catch (error) {
    console.error('Error fetching upcoming events:', error);
  }
}

async function addUserToDatabase() {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently authenticated.');
      return;
    }
    
    const uid = currentUser.uid;
    const email = currentUser.email; // Use the email of the authenticated user
    const role = document.getElementById('user-role').value;
    const displayName = document.getElementById('display-name').value;
    const userData = {
      "role": role,
      "displayName": displayName,
      "email": email
    };

    // Create a reference to the 'users' collection and set the user data
    await set(ref(database, `users/${uid}`), userData);
    console.log('User added to database successfully.');
  } catch (error) {
    console.error('Error adding user to database:', error);
  }
}
/* document.getElementById('addUserButton').addEventListener('click',addUserToDatabase) */

function loginUser(email, password) {
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Show an error message to your user or log it
      console.error("Error signing in: ", errorCode, errorMessage);
    });
}

function logoutUser() {
  signOut(auth)
    .then(() => {
      // Sign-out successful
      console.log("User signed out");
      document.getElementById('login-modal').style.display = 'flex';
    })
    .catch((error) => {
      // An error happened
      console.error("Error signing out: ", error);
    });
}

function createUser(email, password) {
  createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      // Signed up 
      const user = userCredential.user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      // Show an error message to your user or log it
      console.error("Error signing up: ", errorCode, errorMessage);
    });
}

function displayMessage(title, message) {
  // Display the message to the user (you can customize this function based on your UI)
  alert(`${title}\n${message}`);
}
     
      document.getElementById('log-in-button').addEventListener('click', function(event) {
        event.preventDefault();
        const email = document.getElementById('user-email').value;
        const password = document.getElementById('user-password').value;
        loginUser(email, password)
        document.getElementById('login-modal').style.display = 'none';
    });

    document.getElementById('log-in-google').addEventListener('click', function(event) {
      event.preventDefault();
      signInWithGoogle()
      document.getElementById('login-modal').style.display = 'none';
     });
    
/*     document.getElementById('signup-form').addEventListener('submit', function(event) {
        event.preventDefault();
        const email = document.getElementById('signup-email').value;
        const password = document.getElementById('signup-password').value;
        createUser(email, password);
    }); */  


async function fetchJobsWithLaborAndPartsByWorkOrder(workOrder) {
  try {
    // Reference to your Firebase database
    const jobsRef = ref(database, 'data'); // Assuming 'data' contains your jobs

    const snapshot = await get(query(jobsRef, orderByChild('Work_Order'), equalTo(workOrder.toString())));

    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const job = childSnapshot.val();

        // Check and process labor data if exists
        if (job.Labor) {
          console.log("Labor Data for Work Order " + workOrder + ":");
          Object.values(job.Labor).forEach(labor => {
            console.log(labor); // Process each labor entry as needed
          });
        } else {
          console.log("No Labor Data found for Work Order " + workOrder);
        }

        // Check and process parts data if exists
        if (job.Parts) {
          console.log("Parts Data for Work Order " + workOrder + ":");
          Object.values(job.Parts).forEach(part => {
            console.log(part); // Process each parts entry as needed
          });
        } else {
          console.log("No Parts Data found for Work Order " + workOrder);
        }
      });
    } else {
      console.log("No matching jobs found for Work Order " + workOrder);
    }
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
}

function emailTemplates(){

}

// Helper function to open IndexedDB and return a promise that resolves with the database instance
function openIndexedDB() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase', currentIndexedVersion);
    request.onerror = (event) => reject(event.target.error);
    request.onsuccess = (event) => resolve(event.target.result);
  });
}

function addJobToIndexedDB(db, job, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.put({ id: jobId, ...job });
}

function updateJobInIndexedDB(db, job, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.put({ id: jobId, ...job });
}

function deleteJobFromIndexedDB(db, jobId) {
  const transaction = db.transaction(['jobData'], 'readwrite');
  const jobStore = transaction.objectStore('jobData');
  jobStore.delete(jobId);
}

function hideJob(jobId) {
  const jobItem = document.querySelector(`.job-item[data-id="${jobId}"]`);
  if (jobItem) {
    jobItem.style.display = 'none';
  }
}

function showJob(jobId) {
  console.log(jobId);
  const jobItem = document.querySelector(`.job-item[data-id="${jobId}"]`);
  if (jobItem) {
    jobItem.style.display = 'flex';
  }
}

async function populateJobList() {
  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('openDatabase', currentIndexedVersion);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });

    const transaction = db.transaction(['jobData'], 'readonly');
    const objectStore = transaction.objectStore('jobData');
    const jobListDiv = document.getElementById('jobList');
    const jobsArray = [];

    await new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const job = cursor.value;
          if (!job.Status || !job.Description || 
              ['Complete', 'Closed', 'Declined', 'Hold'].includes(job.Status) || 
              job.Description.toLowerCase().includes('launch')) {
            cursor.continue();
            return;
          }
          jobsArray.push(job);
          cursor.continue();
        } else {
          resolve();
        }
      };
    });

    // Sort all jobs at once
    jobsArray.sort((a, b) =>{
      let aDate, bDate;
      if(a.Start_Date){
        aDate = new Date(a.Start_Date);
      }else{
        aDate = new Date(a.Request_Date);
      }
      if(b.Start_Date){
        bDate = new Date(b.Start_Date);
      }else{
        bDate = new Date(b.Request_Date);
      }
      return aDate - bDate;
    });

    // Clear existing job list
    jobListDiv.innerHTML = '';

    // Populate job list with sorted jobs
    jobsArray.forEach((job) => {
      const jobDiv = document.createElement('div');
      jobDiv.className = 'job-item';
      jobDiv.setAttribute('data-id', job.Job_ID);
      jobDiv.setAttribute('data-tech', job.Techs);
      jobDiv.setAttribute('data-techId', job.Techs != '' ? (getTechId(job.Techs) || '') : '');
      jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));
      jobDiv.addEventListener('click', (e) => {opCheck(e)});

      if (job.Schedule_Date) {
        events.push({
          id: job.Job_ID,
          customer: job.Customer_Name,
          boat: job.Boat_Name,
          date: job.Schedule_Date,
          status: job.Status,
          tech: job.Techs,
          description: job.Description,
        });
      }

      const customerBoatDiv = document.createElement('div');
      customerBoatDiv.className = 'customer-boat-info';

      const customerNameP = document.createElement('p');
      customerNameP.innerHTML = job.Customer_Name;
      customerNameP.className = 'customer-name-jobs';
      customerBoatDiv.appendChild(customerNameP);

      const boatNameP = document.createElement('p');
      boatNameP.innerHTML = job.Boat_Name;
      boatNameP.className = 'boat-name-jobs';
      customerBoatDiv.appendChild(boatNameP);

      const categoryWorkOrderDiv = document.createElement('div');
      categoryWorkOrderDiv.className = 'category-work-order-info';
      categoryWorkOrderDiv.classList.add('hide-mobile');

      const categoryP = document.createElement('p');
      categoryP.innerHTML += job.Category;
      categoryP.className = 'category-info';
      categoryWorkOrderDiv.appendChild(categoryP);

      const workOrderP = document.createElement('p');
      const workOrderLabel = document.createElement('strong');
      workOrderLabel.textContent = 'WO: ';
      workOrderP.appendChild(workOrderLabel);
      workOrderP.innerHTML += job.Work_Order;
      workOrderP.className = 'work-order-jobs';
      categoryWorkOrderDiv.appendChild(workOrderP);

      const descriptionTechDiv = document.createElement('div');
      descriptionTechDiv.className = 'description-info';

      const jobDescriptionP = document.createElement('p');
      let jobDescription = job.Description.includes(':') ? job.Description.split(':')[0] : job.Short_Description;

      jobDescriptionP.innerHTML = jobDescription;
      descriptionTechDiv.appendChild(jobDescriptionP);

      const techP = document.createElement('p');
      const techLabel = document.createElement('strong');
      techLabel.textContent = 'Tech: ';
      techP.appendChild(techLabel);
      techP.innerHTML += job.Techs;
      techP.className = 'tech-info';

      techP.addEventListener('click', (e) => {
        e.stopPropagation();
        const updateSection = e.target;
      
        const techNames = globalTechList;

        const jobId = e.target.closest('.job-item').getAttribute('data-id');
        const jobInfo = e.target.closest('.job-item').getAttribute('data-jobInfo');

        const popup = jobListPopup(techNames, jobId, jobInfo, e.target);
        popup.style.left = e.pageX + 'px';
        popup.style.top = e.pageY + 'px';
        popup.style.display = 'block';
      });

      descriptionTechDiv.appendChild(techP);

      const dateDiv = document.createElement('div');
      dateDiv.className = 'date-info';
      dateDiv.classList.add('hide-mobile');
      const requestDateP = document.createElement('p');
      const requestDateLabel = document.createElement('strong');
      requestDateLabel.textContent = 'Requested: ';
      requestDateP.appendChild(requestDateLabel);
      requestDateP.className = 'request-date-info';
      let jobDate;

      if (job.Start_Date) {
        if (job.Start_Date.split('-')[0] === new Date().getFullYear().toString()) {
          jobDate = job.Start_Date.split('-')[1] + '-' + job.Start_Date.split('-')[2];
        } else {
          jobDate = job.Start_Date;
        }
      } else {
        if (job.Request_Date.split('-')[0] === new Date().getFullYear().toString()) {
          jobDate = job.Request_Date.split('-')[1] + '-' + job.Request_Date.split('-')[2];
        } else {
          jobDate = job.Request_Date;
        }
      }

      requestDateP.innerHTML += jobDate;
      dateDiv.appendChild(requestDateP);

          // Create the label for the schedule date input

      const scheduledDateDiv = document.createElement('div');
      scheduledDateDiv.className = 'scheduled-date-info';
      const scheduleDateLabel = document.createElement('strong');
      scheduleDateLabel.textContent = 'Scheduled: ';

      const scheduleDateP = document.createElement('input');
      scheduleDateP.type = 'date'; 
      scheduleDateP.className = 'schedule-date-info';
      scheduleDateP.value = job.Schedule_Date ? job.Schedule_Date : '';
      if (!job.Schedule_Date || job.Schedule_Date === '') {
        scheduleDateP.style.color = 'red';
      }

      scheduleDateP.addEventListener('change', async (e) => {
        const jobId = e.target.closest('.job-item').getAttribute('data-id');
        const date = e.target.value;
        updateJobInfo(jobId, 'Schedule_Date', e.target.value);
      });

      // Append the label and input to the dateDiv
      scheduledDateDiv.appendChild(scheduleDateLabel);
      scheduledDateDiv.appendChild(scheduleDateP);
      dateDiv.appendChild(scheduledDateDiv);

      const statusPriorityDiv = document.createElement('div');
      statusPriorityDiv.className = 'status-priority-info';
      statusPriorityDiv.classList.add('hide-mobile');
      const statusP = document.createElement('p');
      const statusLabel = document.createElement('strong');
      statusLabel.textContent = 'Status: ';
      statusP.appendChild(statusLabel);
      statusP.innerHTML += job.Status;
      statusPriorityDiv.appendChild(statusP);

      const priorityP = document.createElement('p');
      const priorityLabel = document.createElement('strong');
      priorityLabel.textContent = 'Priority: ';
      priorityP.appendChild(priorityLabel);
      priorityP.innerHTML += job.Priority ? job.Priority : 'Normal';
      statusPriorityDiv.appendChild(priorityP);

      const noteDiv = document.createElement('div');
      noteDiv.className = 'notes-info';
      const jobNotesP = document.createElement('button');

      jobNotesP.addEventListener('click', async () => {
        
        const jobDiv = jobNotesP.closest('[data-id]');
        const jobId = jobDiv.getAttribute('data-id');
        const jobNotesData = await getJobData(jobId);
        
        
        document.getElementById('office-notes').classList.add('active');
        document.getElementById('note-job-id').value = jobId;
    
        const noteArea = document.getElementById('note-area');
        noteArea.innerHTML = '';
    
        if (jobNotesData.Notes) {
            Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
              const noteContainer = document.createElement('div');
              noteContainer.className = 'note-pair';
              noteContainer.id = key;
            
              const noteInfo = document.createElement('div');
              noteInfo.className = 'note-info';
                         
              const noteText = document.createElement('p');
              noteText.textContent = note.Note;

              const techInfo = document.createElement('div');
              techInfo.className = 'note-info-tech';
            
              techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;
            
              const deleteButton = document.createElement('button');
              deleteButton.textContent = 'Delete';
              deleteButton.className = 'delete-note';
              deleteButton.addEventListener('click', async () => {
                await deleteNotes(jobId, key);
                noteContainer.remove();
              });
            
              noteInfo.appendChild(noteText);
              noteInfo.appendChild(techInfo); 
              noteInfo.appendChild(deleteButton);
              noteContainer.appendChild(noteInfo);
              
              noteArea.appendChild(noteContainer);
            });
        }
    });

      jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;
      
      if (job.Notes) {
        // If job.Notes is not blank, apply a red filter
        jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
      }

      noteDiv.appendChild(jobNotesP);

      const statusDiv = document.createElement('div');
      statusDiv.className = 'status-info';
      statusDiv.style.backgroundColor = job.Status === 'In Progress' ? 'yellow' : 'green';
      statusDiv.innerHTML = ' ';

      jobDiv.appendChild(statusDiv);
      jobDiv.appendChild(customerBoatDiv);
      jobDiv.appendChild(categoryWorkOrderDiv);
      jobDiv.appendChild(descriptionTechDiv);
      jobDiv.appendChild(dateDiv);
      jobDiv.appendChild(statusPriorityDiv);
      jobDiv.appendChild(noteDiv);

      jobListDiv.appendChild(jobDiv);
    });

    // Close the IndexedDB connection
    db.close();
    populateJobsForCurrentTech();
  } catch (error) {
    console.error("Error fetching job data from IndexedDB:", error);
  }
}

async function populateJobsForCurrentTech() {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'));
  if (!userInfo || !userInfo.displayName) {
    console.error("User info is not available.");
    return;
  }

  const currentTech = userInfo.displayName.split(' ')[0];
  console.log("Current Tech:", currentTech);

  try {
    const db = await new Promise((resolve, reject) => {
      const request = window.indexedDB.open('openDatabase', currentIndexedVersion);
      request.onerror = () => reject(request.error);
      request.onsuccess = () => resolve(request.result);
    });

    const transaction = db.transaction(['jobData'], 'readonly');
    const objectStore = transaction.objectStore('jobData');
    const jobListDiv = document.getElementById('schedule-list');
    const jobsArray = [];

    await new Promise((resolve, reject) => {
      objectStore.openCursor().onsuccess = (event) => {
        const cursor = event.target.result;
        if (cursor) {
          const job = cursor.value;
          if (job.Techs && job.Techs.includes(currentTech)) {
            jobsArray.push(job);
          }
          cursor.continue();
        } else {
          resolve();
        }
      };
    });

    jobsArray.sort((a, b) => {
      const aDate = a.Start_Date ? new Date(a.Start_Date) : new Date(a.Request_Date);
      const bDate = b.Start_Date ? new Date(b.Start_Date) : new Date(b.Request_Date);
      return aDate - bDate;
    });

    jobListDiv.innerHTML = '';
    jobsArray.forEach((job) => {
      const jobDiv = document.createElement('div');
      jobDiv.className = 'job-item';
      jobDiv.setAttribute('data-id', job.Job_ID);
      jobDiv.setAttribute('data-tech', job.Techs);
      jobDiv.setAttribute('data-techId', job.Techs ? (getTechId(job.Techs) || '') : '');
      jobDiv.setAttribute('data-jobInfo', JSON.stringify(job));
      jobDiv.addEventListener('click', (e) => {opCheck(e)});

      const customerBoatDiv = document.createElement('div');
      customerBoatDiv.className = 'customer-boat-info';
      customerBoatDiv.innerHTML = `<p class='customer-name-jobs'>${job.Customer_Name}</p><p class='boat-name-jobs'>${job.Boat_Name}</p>`;
      
      const descriptionDiv = document.createElement('div');
      descriptionDiv.className = 'description-info';
      let jobDescription = job.Description.split(':')[0];
      descriptionDiv.innerHTML = `<p>${jobDescription}</p>`;
      
      const statusDiv = document.createElement('div');
      statusDiv.className = 'status-info';
      statusDiv.style.backgroundColor = job.Status === 'In Progress' ? 'yellow' : 'green';
      statusDiv.innerHTML = ' ';
      
      const requestDate = document.createElement('div');
      requestDate.className = 'request-date-info hide-mobile';
      requestDate.innerHTML = job.Start_Date ? job.Start_Date : job.Request_Date;

      const noteDiv = document.createElement('div');
      noteDiv.className = 'notes-info';
      const jobNotesP = document.createElement('button');

      jobNotesP.addEventListener('click', async () => {
        
        const jobDiv = jobNotesP.closest('[data-id]');
        const jobId = jobDiv.getAttribute('data-id');
        const jobNotesData = await getJobData(jobId);
        
        
        document.getElementById('office-notes').classList.add('active');
        document.getElementById('note-job-id').value = jobId;
    
        const noteArea = document.getElementById('note-area');
        noteArea.innerHTML = '';
    
        if (jobNotesData.Notes) {
            Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
              const noteContainer = document.createElement('div');
              noteContainer.className = 'note-pair';
              noteContainer.id = key;
            
              const noteInfo = document.createElement('div');
              noteInfo.className = 'note-info';
                         
              const noteText = document.createElement('p');
              noteText.textContent = note.Note;

              const techInfo = document.createElement('div');
              techInfo.className = 'note-info-tech';
            
              techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;
            
              const deleteButton = document.createElement('button');
              deleteButton.textContent = 'Delete';
              deleteButton.className = 'delete-note';
              deleteButton.addEventListener('click', async () => {
                await deleteNotes(jobId, key);
                noteContainer.remove();
              });
            
              noteInfo.appendChild(noteText);
              noteInfo.appendChild(techInfo); 
              noteInfo.appendChild(deleteButton);
              noteContainer.appendChild(noteInfo);
              
              noteArea.appendChild(noteContainer);
            });
        }
    });

      jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;
      
      if (job.Notes) {
        // If job.Notes is not blank, apply a red filter
        jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
      }

      noteDiv.appendChild(jobNotesP);
      
      jobDiv.appendChild(statusDiv);
      jobDiv.appendChild(customerBoatDiv);
      jobDiv.appendChild(descriptionDiv);
      jobDiv.appendChild(requestDate);
      jobDiv.appendChild(noteDiv);
      jobListDiv.appendChild(jobDiv);
    });

    db.close();
  } catch (error) {
    console.error("Error fetching job data from IndexedDB:", error);
  }
}

async function updateJobRow(jobId, key, value) {

  try{
// Find the job row with the matching data-id
const jobRow = document.querySelector(`.job-item[data-id="${jobId}"]`);
  
if (!jobRow) {
  console.error(`Job with ID ${jobId} not found`);
  return;
}

// Parse the current data-jobInfo
let currentJobInfo = JSON.parse(jobRow.getAttribute('data-jobInfo'));

currentJobInfo[key] = value;
jobRow.setAttribute('data-jobInfo', JSON.stringify(currentJobInfo));

// Update HTML elements
if (key === 'Customer_Name') {
  jobRow.querySelector('.customer-name-jobs').textContent = value;
}
if (key === 'Boat_Name') {
  jobRow.querySelector('.boat-name-jobs').textContent = value;
}
if (key === 'Category') {
  jobRow.querySelector('.category-info').textContent = value;
}
if (key === 'Work_Order') {
  jobRow.querySelector('.work-order-jobs').innerHTML = `<strong>WO: </strong>${value}`;
}
if (key === 'Description') {
  const jobDescription = value.includes(':') ? value.split(':')[0] : currentJobInfo.Short_Description;
  jobRow.querySelector('.description-info p:first-child').textContent = jobDescription;
}
if (key === 'Techs') {
  jobRow.querySelector('.tech-info').innerHTML = `<strong>Tech: </strong>${value}`;
}
if (key === 'Start_Date' || key === 'Request_Date') {
  const dateElement = jobRow.querySelector('.request-date-info');
  let jobDate;
  if (key === 'Start_Date') {
    jobDate = new Date(value).getFullYear() === new Date().getFullYear() ?
      value.split('-').slice(1).join('-') : value;
  } else {
    jobDate = new Date(value).getFullYear() === new Date().getFullYear() ?
      value.split('-').slice(1).join('-') : value;
  }
  dateElement.innerHTML = `<strong>Requested: </strong>${jobDate}`;
}
if (key === 'Schedule_Date') {
  const scheduleDateInput = jobRow.querySelector('.schedule-date-info');
  scheduleDateInput.value = value;
  scheduleDateInput.style.color = value ? '' : 'red';
}
if (key === 'Status') {
  jobRow.querySelector('.status-priority-info p:first-child').innerHTML = `<strong>Status: </strong>${value}`;
  const statusIndicator = jobRow.querySelector('.status-info');
  statusIndicator.style.backgroundColor = value === 'In Progress' ? 'yellow' : 'green';
}
if (key === 'Priority') {
  jobRow.querySelector('.status-priority-info p:last-child').innerHTML = `<strong>Priority: </strong>${value || 'Normal'}`;
}
  }catch(error){
    console.error("Error updating job row:", error);
  }
  
}

async function mainTable() {
  try {
    document.getElementById('table-content').innerHTML = '';
    document.getElementById("table-loading-icon").style.display = "block";

    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const store = transaction.objectStore('jobData');

      const getAllRequest = store.getAll();

      getAllRequest.onerror = (event) => {
        console.error('Error fetching job data:', event.target.errorCode);
      };

      getAllRequest.onsuccess = (event) => {
        const dataArray = event.target.result;

        let operations = [];
        let techInfo = {};

        dataArray.forEach(data => {
          if (data['Transfer_to_Billing'] === "Yes") {
            operations.push(data);
          }
          if (data['Techs'] && (data['Status'] === 'On Work Schedule' || data['Status'] === 'In Progress')) {
            if (!techInfo[data['Techs']]) {
              techInfo[data['Techs']] = [];
            }
            techInfo[data['Techs']].push([data['Job_ID'], data['Customer_Name'], data['Boat_Name'], data['Work_Order'], data['Description'], data['Status'], data['Notes'], data['Start_Date'], data['Transfer_to_Billing']]);
          }
        });

        var wo = [];
        var blanks = [];
        var kits = [];
        var awaiting = [];
        var order = [];
        var closed = [];

        for (let data of dataArray) {
          if (data['Parts_Kit_Status'] === "Needs Kit") {
            kits.push(data['Work_Order']);
          }
        }

        var readyToBillSome = [];
        var readyToBillAll = [];
        var operationsByWorkOrder = {};

        dataArray.forEach(item => {
          const workOrderNumber = item['Work_Order'];
          const status = item['Status'];

          if (!operationsByWorkOrder[workOrderNumber]) {
            operationsByWorkOrder[workOrderNumber] = [];
          }

          operationsByWorkOrder[workOrderNumber].push(status);
        });

        countObject = dataArray.reduce((accumulator, item) => {
          const workOrder = item.Work_Order;
          const dependency = item.Operation_Dependency;
        
          if (!accumulator[workOrder]) {
            accumulator[workOrder] = {
              Count: 0,
              Dependency: 0
            };
          }
          
          if(item.Status && item.Description){
            if(item.Status != 'Complete'){
              accumulator[workOrder].Count += 1;
            }
            accumulator[workOrder].Dependency += Number(dependency);
            accumulator[workOrder].Customer_Name = item.Customer_Name;
            accumulator[workOrder].Customer_ID = item.Customer_ID;
          }
          
          return accumulator;
        }, {});

        window.workOrderData = countObject;

        Object.keys(operationsByWorkOrder).forEach(workOrderNumber => {
          const operations = operationsByWorkOrder[workOrderNumber];

          if (operations.some(status => status === "Complete")) {
            if (!readyToBillSome.includes(workOrderNumber)) {
              readyToBillSome.push(workOrderNumber);
            }
          } else if (operations.every(status => status === "Closed")) {
            if (!closed.includes(workOrderNumber)) {
              closed.push(workOrderNumber);
            }
          }
          if (operations.every(status => status === "Complete")) {
            if (!readyToBillAll.includes(workOrderNumber)) {
              readyToBillAll.push(workOrderNumber);
            }
          }
        });

        for (let data of dataArray) {
          if (data['Status'] === "Awaiting Approval") {
            awaiting.push(data['Work_Order']);
          }
        }

        for (let data of dataArray) {
          if (data['Parts Ordered'] === "Part Ordered") {
            order.push(data['Work_Order']);
          }
        }

        for (let data of dataArray) {
          wo.push(data['Work_Order']);
        }

        var unique = [...new Set(wo)];
        var woList = [];

        for (let k = 0; k < unique.length; k++) {
          for (let l = 0; l < dataArray.length; l++) {
            if (dataArray[l]['Work_Order'] === unique[k]) {
              woList.push(dataArray[l]);
              break;
            }
          }
        }

        var finalList = woList.sort(function(a, b) {
          if (a['Start_Date'] && b['Start_Date']) {
            return new Date(a['Start_Date']) - new Date(b['Start_Date']);
          } else if (a['Start_Date']) {
            return -1;
          } else if (b['Start_Date']) {
            return 1;
          } else {
            return 0;
          }
        });

        finalList = finalList.filter(workOrder => !closed.includes(workOrder['Work_Order']));

        renderTable(finalList);

        document.getElementById("table-loading-icon").style.display = "none";

        // Set up filter and sort listeners after the table is rendered
        setupFilterListeners();
        setupSortListeners();
      };
    };

    await populateJobList();
    await load();
    document.querySelectorAll('.operation-container-row').forEach((element) => {
      element.remove();
    });

  } catch (error) {
    console.error('Error fetching job data:', error);
  }
}

function renderTable(dataList) {
  const dataSection = document.getElementById('table-content');
  dataSection.innerHTML = ''; // Clear existing content

/*   if (dataList.length === 0) {
    const emptyMessage = document.createElement('div');
    emptyMessage.textContent = 'No matching work orders found.';
    emptyMessage.className = 'empty-table-message';
    dataSection.appendChild(emptyMessage);
    return;
  } */

  dataList.forEach(function(data) {
    if (!data['Status'] || data['Status'].toLowerCase() === 'hold' || 
        (countObject[data['Work_Order']] && countObject[data['Work_Order']].Count == 0) || 
        data['Status'] === 'Declined' || data['Status'] === 'Closed') {
      return;
    }

    const row = createTableRow(data);
    dataSection.appendChild(row);
  });

  // After rendering, apply any existing filters and sorting
  updateTable();
}

function createTableRow(data) {
  const row = document.createElement("div");
  row.setAttribute('data-jobinfo', JSON.stringify(data));
  row.setAttribute('data-id', data['Job_ID']);
  row.setAttribute('data-customerId', data['Customer_ID']);
  row.setAttribute('data-boatId', data['Boat_ID']);
  row.setAttribute('data-workOrder', data['Work_Order']);
  row.addEventListener('click', (e) => { selectWorkOrder(e) });
  row.className = 'table-item';

  // Create status visual
  const statusVisual = document.createElement('div');
  statusVisual.className = 'status-info';
  statusVisual.style.backgroundColor = data['Status'] === 'In Progress' ? 'yellow' : 'green';
  statusVisual.innerHTML = '&nbsp;'; // Add a non-breaking space to ensure the div has content

  // Create info div
  const infoDiv = document.createElement('div');
  infoDiv.className = 'info-div';

  // Customer and Boat info
  const customerDiv = document.createElement('div');
  customerDiv.className = 'job-group';
  customerDiv.innerHTML = `
    <span class="bold-font">${data['Customer_Name']}</span>
    <span class="boat-name-jobs">${data['Boat_Name']}</span>
  `;

  // Work Order and Description info
  const descDiv = document.createElement('div');
  descDiv.className = 'job-group';
  descDiv.innerHTML = `
    <span class="bold-font">${data['Short_Description']}</span>
    <span class="group-row">
      <span class="bold-font">WO: </span>
      <span>${data['Work_Order']}</span>
    </span>
  `;

  // Operations and Date info
  const operationsDiv = document.createElement('div');
  operationsDiv.className = 'job-group hide-mobile';
  operationsDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Operations: </span>
      <span>${countObject[data['Work_Order']] ? countObject[data['Work_Order']].Count : 0}</span>
    </span>
    <span class="group-row">
      <span class="bold-font">Date: </span>
      <span>${data['Start_Date'] ? data['Start_Date'] : data['Request_Date']}</span>
    </span>
  `;

  // Status and Priority info
  const statusDiv = document.createElement('div');
  statusDiv.className = 'job-group hide-mobile';
  statusDiv.innerHTML = `
    <span class="group-row">
      <span class="bold-font">Status: </span>
      <span>${data['Status']}</span>
    </span>
    <span class="group-row">
      <span class="bold-font">Priority: </span>
      <span>${data['Priority']}</span>
    </span>
  `;

  // Append all elements to the row
  infoDiv.appendChild(customerDiv);
  infoDiv.appendChild(descDiv);
  infoDiv.appendChild(operationsDiv);
  infoDiv.appendChild(statusDiv);

  row.appendChild(statusVisual);
  row.appendChild(infoDiv);

  return row;
}

document.getElementById('log-out-button').addEventListener('click', logoutUser);

/* document.getElementById('open-filter').addEventListener('click', () => {
  
  const value = document.getElementById('filter-box').style.display;

  if(value == 'none' || !value){
    document.getElementById('filter-box').style.display = 'flex';
  }else{
    document.getElementById('filter-box').style.display = 'none';
  };
}); */

document.getElementById('codeSearch').addEventListener('input',()=>{
  codeSearch()
  })

document.getElementById('search').addEventListener('input', searchCall)

document.getElementById('customerDatabaseButton').addEventListener('click', async () => {

  try{
    await fetchCustomerDataAndGenerateTable();
    document.getElementById('Add').style.visibility = "hidden"
    document.getElementById('customerData').style.display = "flex"
    document.getElementById("customer-search").focus()
  }catch(error){
    console.error('Error fetching customer data:', error);
  };
});

document.getElementById('customerDatabaseEmail').addEventListener('click', customerEmail)

document.getElementById('open-add-labor').addEventListener('click', () => {
  console.log(new Date());
  console.log(new Date().toISOString());
  console.log(new Date().toISOString().split('T')[0]);
  document.getElementById('labor-entry-date').value = new Date().toISOString().split('T')[0];
  document.getElementById('labor-section').style.display = 'flex';
  document.getElementById('labor-entry-description').innerHTML = '';
});

document.getElementById('open-add-parts').addEventListener('click', () => {
  document.getElementById('part-entry-date').value = new Date().toISOString().split('T')[0];
  document.getElementById('parts-section').style.display = 'flex';
  document.getElementById('parts-table-body').innerHTML = '';
  initializePartsTable();
});


document.getElementById('add-labor').addEventListener('click', submitLabor);

document.getElementById('add-part').addEventListener('click', addParts);

document.addEventListener('DOMContentLoaded', function() {

    retrieveAndLogAuthToken();
  
    // Add button placeholder
    document.querySelector('.add-button-placeholder').addEventListener('click', addOpen);
  
    // Schedule button
    document.getElementById('scheduleButton').addEventListener('click',() => {
      document.getElementById('tech-data').style.display='flex';
    });
  
    // Add open button
    document.getElementById('myBtn').addEventListener('click', addOpen);
  
    // Calculator button
    document.getElementById('calc-button').addEventListener('click', function() {
        document.getElementById('calcBody').style.display = 'flex';
    });
  
    // Close button for pop-out
    document.getElementById('close-btn').addEventListener('click', function() {
        // You need to define what should happen when this button is clicked
    });
  
    // Calendar close button
    document.getElementById('calendar-close-btn').addEventListener('click', function() {
        // You need to define what should happen when this button is clicked
    });
  
    // General work form button
    document.getElementById('general-work-form').addEventListener('click', generalWorkForm);
  
    // Storage work form button
    document.getElementById('storage-work-form').addEventListener('click', storageWorkForm);
  
    // Generate general work order button
    document.getElementById('general-work-order-button').addEventListener('click', function() {
        getWorkOrderNumber('general');
    });
  
    // Generate winter work order button
    document.getElementById('winter-work-order-button').addEventListener('click', function() {
        getWorkOrderNumber('winter');
    });
  
    // Generate spring work order button
    document.getElementById('spring-work-order-button').addEventListener('click', function() {
        getWorkOrderNumber('spring');
    });
  
    document.getElementById('add-btn').addEventListener('click', () => {
      document.getElementById('activeJobs').innerHTML = '';
      AddRow();
    });
  
    // Complete and close add button
    document.getElementById('add-ops-btn').addEventListener('click', closeAdd);
  
    // Add job button
    document.getElementById('addJobButton').addEventListener('click', function() {
        addJob();
    });
  
    // Delete job button
    document.getElementById('deleteJobButton').addEventListener('click', function() {
        deleteJob(this);
    });
  
    document.getElementById('mobile-menu-button').addEventListener('click',() => {
      document.querySelector('.page-select').classList.toggle('hide-mobile');
    })
  
    // Add part order button
    document.getElementById('add-part-order').addEventListener('click', e => {

      addPartOrder()
    });
  
    // Clear part order button
    document.getElementById('clear-part-btn').addEventListener('click', clearPartOrder);
  
    // Add new customer button
    document.getElementById('add-customer-btn').addEventListener('click', addNewCustomer);
  
    // Job complete button
    document.querySelector('.job-complete-button').addEventListener('click', submitOpen);
  
    document.getElementById('entryButton').addEventListener('click', showStatus);
  
    document.getElementById('holdButton').addEventListener('click', holdOperation);

    document.getElementById('removeHoldButton').addEventListener('click', removeHoldOperation);
  
    document.getElementById('deleteButton').addEventListener('click', deleteOperation)
  
    document.getElementById('entryButtonMobile').addEventListener('click', showStatus);
  
    document.getElementById('holdButtonMobile').addEventListener('click', holdOperation);
  
    document.getElementById('deleteButtonMobile').addEventListener('click', deleteOperation)
  
    document.getElementById('partBoxButtonMobile').addEventListener('click', partBox)
  
    document.getElementById('collectKitsButtonMobile').addEventListener('click', collectKits)

    document.getElementById('change-view-button').addEventListener('click', function(event) {
      event.stopPropagation();
      toggleView('change-view', this, event);
    });
    
    document.getElementById('filter-view-button').addEventListener('click', function(event) {
      event.stopPropagation();
      toggleView('filter-view', this, event);
    });
    
    document.getElementById('sort-view-button').addEventListener('click', function(event) {
      event.stopPropagation();
      toggleView('sort-view', this, event);
    });
    
    // Add click event listener to the document
    document.addEventListener('click', function(event) {
      closeOpenViews(event.target);
    });
    
    function toggleView(viewId, button,) {
      var view = document.getElementById(viewId);
    
      // Toggle classes using toggle method
      view.classList.toggle('hideBox');
      view.classList.toggle('showBox');
    
      // Update position and size if shown
      if (view.classList.contains('showBox')) {
        var buttonRect = button.getBoundingClientRect();
        
        // Get the computed style of the view
        var viewStyle = window.getComputedStyle(view);
        var viewWidth = viewStyle.width;
        
        // Add transition to button
        button.style.transition = 'width 0.3s ease-in-out';
        button.style.backgroundColor = 'var(--nav-background-color)';
        button.style.color = 'white';
        
        // Set the button width to match the view
        setTimeout(() => {
          button.style.width = viewWidth;
        }, 0);
        
        // Make the view visible
        view.style.visibility = 'visible';
      } else {
        // Animate button back to original width
        button.style.transition = 'width 0.3s ease-in-out';
        button.style.width = '';
        button.style.backgroundColor = '';
        button.style.color = 'black';
        view.style.visibility = 'hidden';
      }
    }
  
    document.querySelector('.page-buttons').addEventListener('click',() => {
      const menuButton = document.getElementById('mobile-menu-button');
      if(!menuButton.classList.contains('hide-mobile')){
        menuButton.click();
        menuButton.querySelector('svg').classList.toggle('toggle');
      }
    });
  
    document.getElementById('map-open-button').addEventListener('click', () => {
    const elements = document.querySelectorAll('.page-view')
    elements.forEach( page => {
      page.style.display = 'none';
      page.style.visibility = 'hidden';
    });
     document.getElementById('maps').style.display = 'flex';
     document.getElementById('maps').style.visibility = 'visible';
     document.getElementById('current-page').innerHTML = "Maps";
    });
  
    document.getElementById('customers-open-button').addEventListener('click', () => {
      const elements = document.querySelectorAll('.page-view')
      elements.forEach( page => {
        page.style.display = 'none';
        page.style.visibility = 'hidden';
      });
       document.getElementById('customers').style.display = 'flex';
       document.getElementById('customers').style.visibility = 'visible';
       document.getElementById('current-page').innerHTML = "Customer Database";
      });
  
    document.getElementById('open-work-button').addEventListener('click', () => {
      const elements = document.querySelectorAll('.page-view')
      elements.forEach( page => {
        page.style.display = 'none';
        page.style.visibility = 'hidden';
      });
       document.getElementById('open-work').style.display = 'flex';
       document.getElementById('open-work').style.visibility = 'visible';
       document.getElementById('work-order-view').style.display = 'flex';
       document.getElementById('current-page').innerHTML = "Work Orders";
      });
  
      document.getElementById('spring-open-button').addEventListener('click', () => {
        const elements = document.querySelectorAll('.page-view')
        const viewBox = document.getElementById('change-view')
        closeOpenViews();
        viewBox.classList.remove('showBox');

        elements.forEach( page => {
          page.style.display = 'none';
          page.style.visibility = 'hidden';
        });
         document.getElementById('spring').style.display = 'flex';
         document.getElementById('spring').style.visibility = 'visible';
         document.getElementById('current-page').innerHTML = "Spring Commissioning";
        });

        document.getElementById('hauling-open-button').addEventListener('click', () => {
          const elements = document.querySelectorAll('.page-view')
          const viewBox = document.getElementById('change-view')
          closeOpenViews();
          viewBox.classList.remove('showBox');
  
          elements.forEach( page => {
            page.style.display = 'none';
            page.style.visibility = 'hidden';
          });
           document.getElementById('hauling').style.display = 'flex';
           document.getElementById('hauling').style.visibility = 'visible';
           document.getElementById('current-page').innerHTML = "Hauling and Winterizing";
          });
  
        document.getElementById('mooring-menu-open-button').addEventListener('click', () => {

          const elements = document.querySelectorAll('.page-view')

          elements.forEach( page => {
            page.style.display = 'none';
            page.style.visibility = 'hidden';
          });

           document.getElementById('rentalDatabase').style.display = 'flex';
           document.getElementById('rentalDatabase').style.visibility = 'visible';
           document.getElementById('current-page').innerHTML = "Rental Database";
          });

          document.getElementById('inventory-menu-open-button').addEventListener('click', () => {

            const elements = document.querySelectorAll('.page-view')
  
            elements.forEach( page => {
              page.style.display = 'none';
              page.style.visibility = 'hidden';
            });
  
             document.getElementById('inventoryDatabase').style.display = 'flex';
             document.getElementById('inventoryDatabase').style.visibility = 'visible';
             document.getElementById('current-page').innerHTML = "Inventory Database";
            });

            /* document.getElementById('schedule-open-button').addEventListener('click', () => {

              const elements = document.querySelectorAll('.page-view')
    
              elements.forEach( page => {
                page.style.display = 'none';
                page.style.visibility = 'hidden';
              });
    
               document.getElementById('schedule').style.display = 'flex';
               document.getElementById('schedule').style.visibility = 'visible';
               document.getElementById('current-page').innerHTML = "Work List";
              }); */
  
});

document.getElementById('switch-job-view').addEventListener('click', () => {
  const workOrderView = document.getElementById('work-order-view');
  const workOrderSearch = document.getElementById('search');
  const jobView = document.getElementById('job-view');
  const jobSearch = document.getElementById('job-search');
  const scheduleView = document.getElementById('schedule-view');
  const scheduleSearch = document.getElementById('schedule-search');
  const viewBox = document.getElementById('change-view')
  closeOpenViews();

  viewBox.classList.remove('showBox');


  const elements = document.querySelectorAll('.page-view')
      elements.forEach( page => {
        page.style.display = 'none';
        page.style.visibility = 'hidden';
      });
       document.getElementById('open-work').style.display = 'flex';
       document.getElementById('open-work').style.visibility = 'visible';
       document.getElementById('current-page').innerHTML = "Operations";

    workOrderView.style.display = 'none';
    workOrderSearch.classList.add('hidden');

    scheduleView.style.display = 'none';
    scheduleSearch.classList.add('hidden');

    jobView.style.display = 'flex';
    jobSearch.classList.remove('hidden');
    jobSearch.focus();

});

document.getElementById('switch-work-order-view').addEventListener('click', () => {
  const workOrderView = document.getElementById('work-order-view');
  const workOrderSearch = document.getElementById('search');
  const jobView = document.getElementById('job-view');
  const jobSearch = document.getElementById('job-search');
  const scheduleView = document.getElementById('schedule-view');
  const scheduleSearch = document.getElementById('schedule-search');
  const viewBox = document.getElementById('change-view')
  closeOpenViews();
  
  viewBox.classList.remove('showBox');


  const elements = document.querySelectorAll('.page-view')
  elements.forEach( page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });
   document.getElementById('open-work').style.display = 'flex';
   document.getElementById('open-work').style.visibility = 'visible';
   document.getElementById('current-page').innerHTML = "Work Orders";

    workOrderView.style.display = 'flex';
    workOrderSearch.classList.remove('hidden');
    workOrderSearch.focus();

    jobView.style.display = 'none';
    jobSearch.classList.add('hidden');

    scheduleView.style.display = 'none';
    scheduleSearch.classList.add('hidden');
  
});

document.getElementById('switch-schedule-view').addEventListener('click', () => {
  const workOrderView = document.getElementById('work-order-view');
  const workOrderSearch = document.getElementById('search');
  const jobView = document.getElementById('job-view');
  const jobSearch = document.getElementById('job-search');
  const scheduleView = document.getElementById('schedule-view');
  const scheduleSearch = document.getElementById('schedule-search');
  const viewBox = document.getElementById('change-view')
  closeOpenViews();
  
  viewBox.classList.remove('showBox');

  const elements = document.querySelectorAll('.page-view')
      elements.forEach( page => {
        page.style.display = 'none';
        page.style.visibility = 'hidden';
      });
       document.getElementById('open-work').style.display = 'flex';
       document.getElementById('open-work').style.visibility = 'visible';
       document.getElementById('current-page').innerHTML = "Operations";

    workOrderView.style.display = 'none';
    workOrderSearch.classList.add('hidden');

    jobView.style.display = 'none';
    jobSearch.classList.add('hidden');

    scheduleView.style.display = 'flex';
    scheduleSearch.classList.remove('hidden');
    scheduleSearch.focus();

});

document.addEventListener('DOMContentLoaded', function() {
  const haulLaunchButton = document.getElementById('haul-launch-open-button');
  const haulLaunchModal = document.getElementById('haul-launch-selection');

  haulLaunchButton.addEventListener('click', function(e) {
    e.stopPropagation(); // Prevent this click from immediately closing the modal
    haulLaunchModal.style.display = haulLaunchModal.style.display === 'block' ? 'none' : 'block';
  });

  // Close the modal when clicking outside of it
  document.addEventListener('click', function(e) {
    if (!haulLaunchModal.contains(e.target) && e.target !== haulLaunchButton) {
      haulLaunchModal.style.display = 'none';
    }
  });
});

function closeOpenViews(exceptElement) {

  if(exceptElement && exceptElement.closest('div').classList.contains('filter-box')){
    return;
  }

  var openViews = document.querySelectorAll('.showBox');
  openViews.forEach(function(view) {
    if (view !== exceptElement && !view.contains(exceptElement)) {
      view.classList.remove('showBox');
      view.classList.add('hideBox');
      view.style.visibility = 'hidden';
      
      // Reset the associated button's width
      var buttonId = view.id.replace('-view', '-view-button');
      var button = document.getElementById(buttonId);
      if (button) {
        button.style.transition = 'width 0.3s ease-in-out';
        button.style.width = '';
        button.style.backgroundColor = '';
        button.style.color = 'black';
      }
    }
  });
}
  
document.addEventListener('DOMContentLoaded',()=>{
var element = document.getElementById("pop-out-box");
var style = window.getComputedStyle(element);
var width = style.getPropertyValue("width");

document.getElementById('pop-out-box').style.left= "-600px";
})

document.addEventListener('DOMContentLoaded', async () => {
  
  if(!navigator.onLine){
    console.log('User is offline. Skipping function.');
    return;
  }

  try {
    
    const hourlyRateRef = ref(database, '/pricing/Hourly Rate');
    const snapshot = await get(hourlyRateRef);
    const hourlyRate = snapshot.val();
    document.getElementById('currentLaborRate').value = hourlyRate;
  } catch (error) {
    console.error("Error fetching hourly rate: ", error);
  }
});

document.addEventListener('DOMContentLoaded', () => {
  const today = new Date();
  const formattedDate = today.toISOString().split('T')[0]; // Format today's date as "yyyy-mm-dd"
  fetchTideInfo(formattedDate); // Fetch tide info for today
  
  const dateInput = document.getElementById('tideDateSelect');
  dateInput.value = formattedDate;

  // Listen for changes in the date input to fetch new tide info
  dateInput.addEventListener('change', (event) => {
    fetchTideInfo(event.target.value);
  });
});

document.addEventListener('DOMContentLoaded', ()=>{
  var elements = document.querySelectorAll('.engineW')
  elements.forEach( (element) => {
  element.addEventListener('click', ()=>{
    document.getElementById('bayTestWinter').checked = element.checked
    })
  })
})
  
document.addEventListener('DOMContentLoaded', ()=>{
  var elements = document.querySelectorAll('.engineS')
  elements.forEach( (element) => {
  element.addEventListener('click', ()=>{
    document.getElementById('bayTestSpring').checked = element.checked
    })
  })
})

document.addEventListener('DOMContentLoaded', async function() {
  var date = new Date().toLocaleDateString('en-us', { year:"numeric", month:"2-digit", day:"2-digit", timeZone: 'UTC' });
  var year = date.split('/')[2];
  var nextYear = (Number(year) + 1);
  document.getElementById('launch').value = nextYear + "-04-01";
});

document.addEventListener('DOMContentLoaded', async function() {
  await fetchParts();
  Promise.all([
    renderLaborData(),
    renderPartsData()
  ])
})


const openBtn = document.getElementById("open-btn");
const calendarOpenBtn = document.getElementById('calendar-open-btn')
const popOutBox = document.getElementById("pop-out-box");
const calendarPopOutBox = document.getElementById('calendar-pop-out-box')
const closeBtn = document.getElementById("close-btn");
const calendarCloseBtn = document.getElementById("calendar-close-btn");

openBtn.addEventListener("click", () => {
document.getElementById('pop-out-box').style.left='0px'
popOutBox.classList.add("open");
});

calendarOpenBtn.addEventListener("click", async () => {
await listUpcomingEvents();
document.getElementById('calendar-pop-out-box').style.left='0px'
popOutBox.classList.add("open");
});

closeBtn.addEventListener("click", () => {
var element = document.getElementById("pop-out-box");
var style = window.getComputedStyle(element);
var width = style.getPropertyValue("width");

document.getElementById('pop-out-box').style.left= "-600px"
popOutBox.classList.remove("open");
});

calendarCloseBtn.addEventListener("click", () => {
var element = document.getElementById("calendar-pop-out-box");
var style = window.getComputedStyle(element);
var width = style.getPropertyValue("width");

document.getElementById('calendar-pop-out-box').style.left= "-600px"
calendarPopOutBox.classList.remove("open");
});


// Client-side JavaScript function to fetch tide information
async function fetchTideInfo(dateSelect) {

  
  if (!navigator.onLine) {
    console.log('User is offline. Skipping tide info fetch.');
    return null;
  }

  const tideLocation = '8516945';
  const tideEndpoint = 'https://api.tidesandcurrents.noaa.gov/api/prod/datagetter';
  let tideDate;

  if (!dateSelect) {
    const today = new Date();
    const year = today.getFullYear().toString();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const date = today.getDate().toString().padStart(2, '0');
    tideDate = `${year}-${month}-${date}`;
  } else {
    const [year, month, date] = dateSelect.split('-');
    tideDate = `${year}-${month}-${date}`;
  }

  const early = new Date(`${tideDate} 04:30:00 AM`);
  const late = new Date(`${tideDate} 05:30:00 PM`);
  const urlDate = tideDate.replace(/-/g, '');
  const locationParams = `&station=${tideLocation}`;
  const product = 'predictions';
  const params = `?begin_date=${urlDate}&end_date=${urlDate}&time_zone=lst&interval=hilo&datum=MLLW&format=json&units=english`;

  try {
    const response = await fetch(tideEndpoint + params + locationParams + '&product=' + product + '&application=client-side-js', {
      method: 'GET'
    });
    const data = await response.json();
    const tides = data.predictions;
    let highTide, lowTide;

    if(tides){
      tides.forEach(tide => {
        const tideTime = new Date(tide.t);
        if (tideTime > early && tideTime < late) {
          if (tide.type === "H") {
            highTide = formatTime(tideTime, 'America/New_York');
          } else if (tide.type === "L") {
            lowTide = formatTime(tideTime, 'America/New_York');
          }
        }
      });
    }
  
    setTides([highTide, lowTide]);
    return { highTide, lowTide, tideDate };
  } catch (error) {
    console.error('Failed to fetch tide data:', error);
    return null;
  }
}

// Utility function to format time in "h:mm a" format
function formatTime(date, timeZone) {
  // This function will format the time based on the client's locale.
  // Adjust as necessary for your specific requirements.
  return date.toLocaleTimeString('en-US', {hour: 'numeric', minute: '2-digit', hour12: true, timeZone: timeZone});
}

function setTides(tides){
document.getElementById('highTide').innerHTML=tides[0]
document.getElementById('lowTide').innerHTML=tides[1]
}

function displayEvents(events) {

  const container = document.getElementById("eventsContainer");
  container.innerHTML = ""; // Clear previous events

  const currentDate = new Date().toISOString().split('T')[0];

  let groupedEvents = {};
  events.forEach(event => {
    let key;
    if (event.start.date) {
      key = event.start.date;
    } else if (event.start.dateTime) {
      key = event.start.dateTime.split('T')[0];
    }

    if (key && key >= currentDate) {
      if (!groupedEvents[key]) {
        groupedEvents[key] = [];
      }
      groupedEvents[key].push(event);
    }
  });

  for (let date in groupedEvents) {
      let dateDiv = document.createElement("div");
      dateDiv.className = "date-section";

      let dateHeader = document.createElement("h2");
      let eventDate = new Date(date + "T00:00:00");
      dateHeader.textContent = eventDate.toLocaleDateString('default', {
          weekday: 'long', 
          month: 'long', 
          day: 'numeric'
      });

      dateDiv.appendChild(dateHeader);

      groupedEvents[date].forEach(event => {

        let eventDiv = document.createElement("div");
        eventDiv.className = "event-item";
        let title = document.createElement("span");
        
        if (event.start.date) {
          title.textContent = event.summary;
        } else if (event.start.dateTime) {
          const startDateTime = new Date(event.start.dateTime);
          const endDateTime = new Date(event.end.dateTime);
          
          const startTime = startDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
          
          const endTime = endDateTime.toLocaleString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
          });
          
          const time = startTime === endTime ? startTime : `${startTime} - ${endTime}`;
          title.textContent = `${event.summary}\n(${time})`;
        }
        
        eventDiv.appendChild(title);
        dateDiv.appendChild(eventDiv);
      });

      container.appendChild(dateDiv);
  }
}

async function holdOperation(){
const jobID = document.getElementById('currentJobId').value;
  try{
    const dbRef = ref(database, `/data/${jobID}`);
    await update(dbRef, {"Status":"Hold"});
    await updateJobDB(jobID,'Status',"Hold");
    mainTable();
  }catch(error){
    console.error('Failed to put job on hold', error);
  };
};

async function removeHoldOperation(){
  const jobID = document.getElementById('currentJobId').value;
    try{
      const dbRef = ref(database, `/data/${jobID}`);
      await update(dbRef, {"Status":"On Work Schedule"});
      await updateJobDB(jobID,'Status',"On Work Schedule");
      mainTable();
    }catch(error){
      console.error('Failed to put job on hold', error);
    };
  };

async function fetchParts() {
  try {
    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('inventoryData')) {
          db.createObjectStore('inventoryData', { keyPath: 'Part_Number' });
        }
      };

      dbPromise.onsuccess = event => resolve(event.target.result);
      dbPromise.onerror = event => reject(event.target.error);
    });

    const transaction = db.transaction('inventoryData', 'readonly');
    const store = transaction.objectStore('inventoryData');

    const data = await new Promise((resolve, reject) => {
      const request = store.getAll();
      request.onsuccess = event => resolve(request.result);
      request.onerror = event => reject(request.error);
    });

    partsList(data);

  } catch (error) {
    console.error('Failed to fetch data from IndexedDB:', error);
  }
}

function searchCall() {
  var searchQuery = document.getElementById('search').value.trim().toLowerCase();
  var searchTerms = searchQuery.split(/\s+/).filter(term => term.length > 0);
  var allJobs = document.querySelectorAll('#table-content .table-item');

  allJobs.forEach(function(job) {
    if (job.classList.contains('filtered')) {
      // Skip already filtered items
      return;
    }

    var jobData = JSON.parse(job.getAttribute('data-jobinfo'));
    var jobMatchFound = false;

    if (searchTerms.length === 0) {
      jobMatchFound = true;
    } else {
      const jobData = JSON.parse(job.getAttribute('data-jobinfo'));
      delete jobData.Notes;
      jobMatchFound = searchTerms.every(term => 
        JSON.stringify(jobData).toLowerCase().includes(term)
      );
    }

    job.style.display = jobMatchFound ? '' : 'none';
  });

  /* handleEmptyTable(); */
}

function handleEmptyTable() {
  const tableContent = document.getElementById('table-content');
  const visibleJobs = tableContent.querySelectorAll('.table-item[style="display: flex;"]');
  const emptyMessage = tableContent.querySelector('.empty-table-message');

  if (visibleJobs.length === 0) {
    if (!emptyMessage) {
      const newEmptyMessage = document.createElement('div');
      newEmptyMessage.textContent = 'No matching work orders found.';
      newEmptyMessage.className = 'empty-table-message';
      tableContent.appendChild(newEmptyMessage);
    }
  } else if (emptyMessage) {
    emptyMessage.remove();
  }
}

function handleEmptyJobList() {
  const jobSection = document.getElementById('jobList');
  const visibleJobs = jobSection.querySelectorAll('.job-item:not(.filtered)');
  const emptyMessage = jobSection.querySelector('.empty-message') || document.createElement('div');
  
  emptyMessage.classList.add('empty-message');
  emptyMessage.textContent = 'No jobs match the current filters.';

  if (visibleJobs.length === 0) {
    if (!jobSection.contains(emptyMessage)) {
      jobSection.appendChild(emptyMessage);
    }
  } else {
    if (jobSection.contains(emptyMessage)) {
      jobSection.removeChild(emptyMessage);
    }
  }
}

function codeSearch() {
const searchQuery = document.getElementById('codeSearch').value


var table = 'op-code-table'
var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

for (let row of tableRows) {
  const rowCells = row.querySelectorAll('td');
  
  let matchFound = false;

  for (const cell of rowCells) {
    const cellText = cell.textContent.toLowerCase();

    if (cellText.includes(searchQuery)) {
      matchFound = true;
      break;
    }
  }

  if (!matchFound) {
    row.classList.add("searchHide")
  } else {
    row.classList.remove('searchHide')
  }
}
}

function customerEmail(){
  document.getElementById('customerEmailModal').style.display = 'flex';
}

function customerDataSpring(){
document.getElementById('Spring').style.visibility = "hidden"
document.getElementById('customerData').style.display = "flex"
document.getElementById("customer-search").focus()
}

function formatCurrency(value) {
  // Convert string to a number if it's not already
  const numberValue = typeof value === "string" ? parseFloat(value) : value;

  // Use Intl.NumberFormat to format the number as currency
  const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      // You can adjust the options as needed
  });

  return formatter.format(numberValue);
}

function parseDate(dateString) {
return new Date(dateString);
}

function holdTable(){
const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

openRequest.onupgradeneeded = function(event) {
  const db = event.target.result;
  db.createObjectStore("openData", { keyPath: "id" });
};

openRequest.onsuccess = function(event) {
  const db = event.target.result;

  const tableTx = db.transaction("openData", "readonly");
  const tableStore = tableTx.objectStore("openData");
  const tableRequest = tableStore.getAll();

  tableRequest.onsuccess = function(event) {
    const data = event.target.result;
const dataArray = data
.map((wo) => {
  if (typeof wo === "object") {
    return Object.values(wo); // Convert object to array
  }
  return wo;
})

var Parent = document.getElementById('hold-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}


var holdCount = 0
dataArray.forEach(function(r){
if(r[12].toLowerCase() !== 'hold'){
  return
}
 holdCount + 1
  var tbody = document.getElementById("hold-body")
      var row = document.createElement("tr")
      var jobId = document.createElement("td")
      jobId.className = "main-data"
      jobId.textContent = r[0]
      var name = document.createElement("td")
      name.className = "main-data"
      name.textContent = r[1]
      var customerId = document.createElement("td")
      customerId.className = "main-data"
      customerId.textContent = r[2]
      var boat = document.createElement("td")
      boat.className = "main-data"      
      boat.textContent = r[3]
      var boatId = document.createElement("td")
      boatId.className = "main-data"
      boatId.textContent = r[4]
      var email = document.createElement("td")
      email.className = "main-data"
      email.textContent = r[5]
      var workOrder = document.createElement("td")
      workOrder.className = "main-data hide-mobile"
      workOrder.textContent = r[6]
      var category = document.createElement("td")
      category.className = "main-data"
      category.textContent=r[7]
      var opType = document.createElement("td")
      opType.className = "main-data"
      opType.textContent=r[8]
      var description = document.createElement('td')
      description.className = 'main-data'
      description.textContent=r[9]
      var date = document.createElement('td')
      date.className = 'main-data hide-mobile'
      date.textContent=r[10].split('-')[1] + '/' + r[10].split('-')[2] + '/' + r[10].split('-')[0]
      var tech = document.createElement('td')
      tech.className = 'main-data'
      tech.textContent=r[11]
      var status = document.createElement('td')
      status.className = 'main-data'
      status.textContent=r[12]
      var hours = document.createElement('td')
      hours.className = 'main-data'
      hours.textContent=r[13]
      var charge = document.createElement('td')
      charge.className = 'main-data'
      charge.textContent=r[14]
      var notes = document.createElement('td')
      notes.className = 'main-data'
      notes.textContent=r[15]
      var begin = document.createElement('td')
      begin.className = 'main-data'
      begin.textContent=r[16]
      var shortDesc = document.createElement('td')
      shortDesc.className = 'main-data'
      shortDesc.textContent=r[17]
      var launch = document.createElement('td')
      launch.className = 'main-data hide-mobile'
      if(r[19] == null || r[19] == '' || r[19] == 'undefined'){
        launch.textContent = ''
      }else{launch.textContent=r[19].split('-')[1] + '/' + r[19].split('-')[2] + '/' + r[19].split('-')[0]}
      var add = document.createElement("td")
      add.innerHTML = "<button class='returnOpButton' onclick='returnOperation()'><i class='material-icons'>undo</i></button>"
      
     
      row.appendChild(jobId).style.display='none'
      row.appendChild(name)
      row.appendChild(customerId).style.display='none'
      row.appendChild(boat)
      row.appendChild(boatId).style.display='none'
      row.appendChild(email).style.display='none'
      row.appendChild(workOrder)
      row.appendChild(category).style.display='none'
      row.appendChild(opType).style.display='none'
      row.appendChild(shortDesc).style.display='none'
      row.appendChild(description)
      row.appendChild(date).style.display='none'
      row.appendChild(tech).style.display='none'
      row.appendChild(status).style.display='none'
      row.appendChild(hours).style.display='none'
      row.appendChild(charge).style.display='none'
      row.appendChild(notes).style.display='none'
      row.appendChild(begin).style.display='none'
      row.appendChild(launch)
      row.appendChild(add)
      tbody.appendChild(row)
      }) 
  }
}
showGeneral()
}

function returnOperation() {
  var tbody = document.getElementById("hold-body");
  tbody.onclick = function (e) {
      e = e || window.event;
      var data = [];
      var target = e.srcElement || e.target;
      if (target.innerHTML == "undo") {
          while (target && target.nodeName !== "TR") {
              target = target.parentNode;
          }
          if (target) {
              var cells = target.getElementsByTagName("td");
              for (var i = 0; i < cells.length; i++) {
                  data.push(cells[i].innerHTML);
              }
          }

          var jobId = data[0];
          var dbRef = ref(database, 'data/' + jobId);
          dbRef.update({
              Status: "On Work Schedule"
          }).then(function() {
              console.log("Status updated successfully.");
          }).catch(function(error) {
              console.error("Error updating status: ", error);
          });

          document.getElementById('selectOption').style.display = 'none';
      }
  };
}



function showBoxes() {

var checks = document.querySelectorAll('.customCheck');

checks.forEach( check => {
  // If the element is currently being displayed as a table cell...
  if (check.style.display === 'table-cell') {
      // ...change it to be hidden
      check.style.display = 'none';
      document.querySelector('.customCheckHeader').style.display="none"
      document.getElementById('showOps').innerHTML = "Select Operations"
      document.getElementById('moveOps').style.display="none"
      document.getElementById('showOps').classList.remove("hideOp")
      document.getElementById('showOps').classList.add("showOp")
      check.children[0].checked = false
  } else {
      // ...otherwise, change it to be displayed as a table cell
      check.style.display = 'table-cell';
      document.querySelector('.customCheckHeader').style.display="table-cell"
      document.getElementById('showOps').innerHTML = "Hide Checkboxes"
      document.getElementById('showOps').classList.add("hideOp")
      document.getElementById('showOps').classList.remove("showOp")
      document.getElementById('moveOps').style.display="block"
      check.children[0].checked = false
  }
})

}

function moveOperations(){
const boatId = document.getElementById('op-body').rows[0].children[4].innerHTML
const workOrder = document.getElementById('op-body').rows[0].children[6].innerHTML
getOpenWorkOrders(boatId,workOrder)
}

function moveCheckedOpsToCurrent(e){

const previousWorkOrder = document.getElementById('activeWorkOrder').value
const currentWorkOrder = e.target.parentElement.children[5].innerHTML
const currentCategory = e.target.parentElement.children[6].innerHTML
const currentShortDesc = e.target.parentElement.children[7].innerHTML


const jobs = []

var ops = document.querySelectorAll('.customCheck')
ops.forEach(op => {

  const checkbox = op.children[0]
  if(checkbox.checked === true){
    const jobId = op.parentElement.children[0].innerHTML
    const customerId = document.getElementById('customerIdMove').value
    const boatId = document.getElementById('boatIdMove').value
    const workOrder = currentWorkOrder
    const oldWorkOrder = previousWorkOrder
    const category = currentCategory
    const shortDesc = currentShortDesc
    const description = op.parentElement.children[9].innerHTML
    var opCodeCheck = op.parentElement.children[19].innerHTML
    if(opCodeCheck === ''){
     var opCode = prompt('No OpCode for "' +  op.parentElement.children[9].innerHTML +  '". Please enter one.' )
    }else{var opCode = op.parentElement.children[19].innerHTML}

     jobs.push([jobId,customerId,boatId,workOrder,category,shortDesc,description,opCode,oldWorkOrder])
  }
})

jobs.forEach(job => {

const serviceLog = {}

    serviceLog.id = job[0]
    serviceLog.customerId = job[1]
    serviceLog.boatId = job[2]
    serviceLog.workOrder = job[3]
    serviceLog.category = job[4]
    serviceLog.shortDesc = job[5]
    serviceLog.description = job[6]
    serviceLog.opCode = job[7]
    serviceLog.oldWorkOrder = job[8]
    if(job[4].toLowerCase() === 'internal'){
        serviceLog.type = "Internal"
    }else{serviceLog.type = "Retail"}

    transferRecord(serviceLog)

})

document.getElementById('work-order-button-move').style.display = "inline-block"
document.getElementById('open-wo-table').style.display = "table"
}

function transferRecord(serviceLog) {
  var jobId = serviceLog.id;
  var workOrder = serviceLog.workOrder;
  var category = serviceLog.category;
  var shortDesc = serviceLog.shortDesc;
  var opCode = serviceLog.opCode;

  try {
      // Update work order info in Firebase Realtime Database
      ref(database,'data/' + jobId).update({
          WorkOrder: workOrder,
          Category: category,
          ShortDescription: shortDesc,
          OpCode: opCode
      }).then(function() {
          // If successful, delete operation code
          deleteOpCode(serviceLog.oldWorkOrder, opCode);
          console.log("Work order info updated successfully.");
      }).catch(function(error) {
          console.error("Error updating work order info:", error);
      });

      // Format data or perform any other actions
      // (Note: You'll need to implement any additional functionality here)

      toastMessage("Transfer Complete. Please Delete Old Work Order In Dockmaster.", 'green');
  } catch (e) {
      console.error("Error transferring record:", e);
      toastMessage("Error transferring record. Please check the console for details.", 'red');
  }
}

function moveCheckedOpsNew(){

const jobs = []

var ops = document.querySelectorAll('.customCheck')
ops.forEach(op => {

  const checkbox = op.children[0]
  if(checkbox.checked === true){

    const jobId = op.parentElement.children[0].innerHTML
    const customerId = document.getElementById('customerIdMove').value
    const boatId = document.getElementById('boatIdMove').value
    const workOrder = document.getElementById('workOrderMove').value
    const oldWorkOrder = document.getElementById('activeWorkOrder').value
    const category = document.getElementById('categoryMove').value
    const shortDesc = document.getElementById('shortDescMove').value
    const description = op.parentElement.children[9].innerHTML
    const opCodeCheck = op.parentElement.children[19].innerHTML
    
    if(opCodeCheck === ''){
     var opCode = prompt('No OpCode for "' +  op.parentElement.children[9].innerHTML +  '". Please enter one.' )
    }else{var opCode = op.parentElement.children[19].innerHTML}

    jobs.push([jobId,customerId,boatId,workOrder,category,shortDesc,description,opCode,oldWorkOrder])
  }
})

jobs.forEach(job => {

const serviceLog = {}

    serviceLog.id = job[0]
    serviceLog.customerId = job[1]
    serviceLog.boatId = job[2]
    serviceLog.workOrder = job[3]
    serviceLog.category = job[4]
    serviceLog.shortDesc = job[5]
    serviceLog.description = job[6]
    serviceLog.opCode = job[7]
    serviceLog.oldWorkOrder = job[8]

    transferRecord(serviceLog)

})

document.getElementById('work-order-button-move').style.display = "inline-block"
document.getElementById('open-wo-table').style.display = "table"
}

function noteHighlight(tbody){

 var rows = tbody.getElementsByTagName("tr");

  for (i = 0; i < rows.length; i++) {
    var cells = rows[i].getElementsByTagName('td');
 
          if(cells[12].innerHTML.includes("Complete")){
            rows[i].style = "background-color: #ffcdd2;"
          }
          if(!cells[12].innerHTML.includes("Complete") && !cells[12].innerHTML.includes("On Work Schedule")){
            rows[i].style = "background-color: #ecedaf;"
          }
    }
}

function addLabor(quick) {

  const tbody = document.getElementById("labor-table-body");
  const activeDescCells = tbody.querySelectorAll("td#active-labor-cell");
  activeDescCells.forEach(cell => cell.removeAttribute("id"));
  const row = document.createElement("tr");
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;


  const id = document.createElement('td');
  id.textContent = generateId();

      // Create the tech cell with an input for new names and a datalist for predefined options
const tech = document.createElement('td');
tech.classList.add('hide-mobile');
tech.className = "tech-selector";
const techDropdown = document.createElement('select');

const techNames = globalTechList;
      
      techNames.forEach(name => {
          const optionElement = document.createElement('option');
          optionElement.value = name;
          optionElement.textContent = name;
          techDropdown.appendChild(optionElement);
      });

if(localStorage.getItem('currentTech') !== ""){
techDropdown.setAttribute('data-selected-tech',localStorage.getItem('currentTech'))
techDropdown.value = localStorage.getItem('currentTech')
}

techDropdown.addEventListener("change", function() {
  this.setAttribute('data-selected-tech', this.value);
  localStorage.setItem('currentTech',this.value)
});

tech.appendChild(techDropdown);


      const customer = document.createElement('td');
      customer.textContent = document.getElementById('currentName').value;
      const boat = document.createElement('td');
      boat.textContent = document.getElementById('currentBoat').value;
      const workOrder = document.createElement('td');
      workOrder.textContent = document.getElementById('currentWorkOrder').value;
      const desc = document.createElement('td');
      if(typeof quick === 'string'){
        desc.textContent = quick
      }else{desc.textContent = ''}
      desc.contentEditable = 'true';
      desc.setAttribute('id','active-labor-cell')
      const hour = document.createElement('td');
      hour.textContent = '';
      hour.contentEditable = 'true';
      const date = document.createElement('td');
      date.textContent = formattedDate.toString();
      date.classList.add('hide-mobile');
      const del = document.createElement('td');
      del.innerHTML = "<button class='labor-data-delete-button table-button'><i class='material-icons'>clear</i></button>"
      del.addEventListener('click', deleteLabor)

      row.appendChild(id).style.display = "none";
      row.appendChild(tech);
      row.appendChild(customer).style.display = "none";
      row.appendChild(boat).style.display = "none";
      row.appendChild(workOrder).style.display = "none";
      row.appendChild(desc);
      row.appendChild(hour);
      row.appendChild(date);
      row.appendChild(del)

      let holdTimer
      let holdDelayType = 3000;
    
    row.addEventListener('input', (e) => {
        clearTimeout(holdTimer); // Clear any existing timer
    });
    
    row.addEventListener('keyup', (e) => {

      clearTimeout(holdTimer)
      holdTimer = setTimeout(() => {

        const originalText = e.target.closest('tr').children[5].innerHTML;
        const labor = originalText.replace(/&nbsp;/g, '');
        const techSelector = e.target.closest('tr').querySelector('.tech-selector select');
        const tech = techSelector.getAttribute('data-selected-tech');
        const jobId = document.getElementById('currentJobId').value;
        const laborData = {
          'Description': labor,
          'Hours': e.target.closest('tr').children[6].innerHTML,
          'Category': document.getElementById('currentCategory').value,
          'Op_Type': document.getElementById('currentOpType').value,
          'Job_ID': jobId,
          'ID': e.target.closest('tr').children[0].innerHTML,
          'Boat_Name': document.getElementById('currentBoat').value,
          'Customer_Name': document.getElementById('currentName').value,
          'Time_Submitted': getTodaysDate(),
          'Work_Order': document.getElementById('currentWorkOrder').value,
          'Tech': tech
        }

        addLaborFirebase(laborData);
        updateLaborIndex(jobId, laborData);
        updateTimestamps('jobData','data');

        const rowData = JSON.parse(selectedJobRow.getAttribute('data-info'));
        
        if( rowData.Status == 'On Work Schedule' ){
          if( rowData.Techs != '' ){
            updateJobStatusTech(jobId, rowData.Techs,'In Progress')
          }
          updateJobStatus(jobId, 'In Progress')
          selectedJobRow.style.backgroundColor = 'rgb(236, 237, 175)';
          activeTableRow.querySelector('.status-info').style.backgroundColor = 'yellow';
        };
    }, holdDelayType);
    });

    tbody.appendChild(row);

      desc.focus();

}

async function addLaborFirebase(laborData) {

  try{
    const laborRef = ref(database, 'data/' + laborData.Job_ID + '/Labor/' + laborData.ID);
  
    set(laborRef, laborData, (error) => {
      if (error) {
        console.error("Error adding labor record:", error);
      } else {
        console.log("Labor record added successfully!");
      }
    });
  }catch(error){
    console.error('Failed to add labor to Firebase', error);
  }
  
}

async function submitLabor(){

  try{
    const originalText = document.getElementById('labor-entry-description').value;
    const labor = originalText.replace(/&nbsp;/g, '');

    let techSelector;

    if(document.getElementById('labor-entry-tech').value === '' || document.getElementById('labor-entry-tech').value === 'default'){
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const currentTech = userInfo.displayName.split(' ')[0];
      techSelector = currentTech;
    }else{
      techSelector = document.getElementById('labor-entry-tech').value;
    }

    const jobId = document.getElementById('currentJobId').value;
    const entryId = await generateId();
    const entryDate = document.getElementById('labor-entry-date').value;
    const date = await formatDate(entryDate);
    const category = document.getElementById('currentCategory').value;
    const opType = document.getElementById('currentOpType').value;
    const status = document.getElementById('currentStatus').value;
    const assignedTech = document.getElementById('currentTech').value;


    const laborData = {
      'Description': labor,
      'Hours': document.getElementById('labor-entry-hours').value,
      'Category': category,
      'Op_Type': opType,
      'Job_ID': jobId,
      'ID': entryId,
      'Boat_Name': document.getElementById('currentBoat').value,
      'Customer_Name': document.getElementById('currentName').value,
      'Time_Submitted': date,
      'Work_Order': document.getElementById('currentWorkOrder').value,
      'Tech': techSelector
    }

    await addLaborFirebase(laborData);
    await updateLaborIndex(jobId, laborData);
    updateTimestamps('jobData','data');
    
    if( status == 'On Work Schedule' ){
      if( assignedTech != '' ){
        updateJobStatusTech(jobId, assignedTech,'In Progress')
      }
      updateJobStatus(jobId, 'In Progress')
      selectedJobRow.style.backgroundColor = 'rgb(236, 237, 175)';
      activeTableRow.querySelector('.status-info').style.backgroundColor = 'yellow';
    };
    toastMessage('Labor Submitted', 'green');
    document.getElementById('labor-entry-description').value = '';
    document.getElementById('labor-entry-hours').value = '';
    generateLaborTable(jobId);
  }catch(error){
    console.error('Failed to submit labor', error);
    toastMessage('Failed to submit labor', 'red');
  }
  
}


function getTodaysDate(){
  const today = new Date();
  const year = today.getFullYear().toString();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const date = today.getDate().toString().padStart(2, '0');
  return year + '-' + month + '-' + date;
  }



/*   function addParts(quick) {
    document.getElementById('parts-loading-icon').style.display = "flex";

    const jobId = document.getElementById('currentJobId').value;
  
    const tbody = document.getElementById("parts-table-body");
    const activePartCells = tbody.querySelectorAll("td#active-part-cell");
    activePartCells.forEach(cell => cell.removeAttribute("id"));
    const activeQtyCells = tbody.querySelectorAll("td#active-qty-cell");
    activeQtyCells.forEach(cell => cell.removeAttribute("id"));
    const row = document.createElement("div"); 
    row.className = "parts-data-row";
   
    const id = document.createElement('p');
    id.textContent = generateId();
    id.className = "id-selector";
    id.style.display = "none";

    const part = document.createElement('input');
    if (typeof quick === 'string') {
      part.textContent = quick;
    } else {
      part.textContent = '';
    }
    part.contentEditable = 'true';
    part.className = "parts-selector";
    part.id = "active-part-cell";

    const qtyContainer = document.createElement('div');
    qtyContainer.className = 'qty-container';
    
    const qty = document.createElement('input');
    qty.textContent = '';
    qty.contentEditable = 'true';
    qty.className = "qty-selector";
    qty.id = "active-qty-cell";

    qtyContainer.appendChild(qty);


    const del = document.createElement('button');
    del.className = 'parts-data-delete-button table-button';
    del.innerHTML = "<i class='material-icons'>clear</i>";
    del.addEventListener('click', deleteParts);
  
    row.appendChild(id);
    row.appendChild(part);
    row.appendChild(qtyContainer);
    /* row.appendChild(del);
  
    let holdTimer;
    let holdDelayType = 3000;
  
    qty.addEventListener('keyup', (e) => {
      clearTimeout(holdTimer);

      const rowData = e.target.closest('.parts-data-row');
      
      if (!rowData) {
        console.error('Could not find parent .parts-data-row');
        return;
      }
    
      const partElement = rowData.querySelector('.parts-selector');
      const qtyElement = rowData.querySelector('.qty-selector');
    
      if (!partElement || !qtyElement) {
        console.error('Could not find part or quantity element');
        return;
      }
    
      const partSelector = partElement.value.trim();
      const qtySelector = qtyElement.value.trim();

      if (partSelector !== '' && qtySelector !== '') {
        holdTimer = setTimeout(() => {

          let techSelector;

          if(document.getElementById('part-entry-tech').value === '' || document.getElementById('part-entry-tech').value === 'default'){
            const userInfo = JSON.parse(localStorage.getItem('userInfo'));
            const currentTech = userInfo.displayName.split(' ')[0];
            techSelector = currentTech;
          }else{
            techSelector = document.getElementById('part-entry-tech').value;
          }
          
          const partData = {
            'Part_Number': partSelector,
            'Quantity': qtySelector,
            'Category': document.getElementById('currentCategory').value,
            'Op_Type': document.getElementById('currentOpType').value,
            'Job_ID': jobId,
            'ID': rowData.querySelector('.id-selector').textContent,
            'Boat_Name': document.getElementById('currentBoat').value,
            'Customer_Name': document.getElementById('currentName').value,
            'Time_Submitted': getTodaysDate(),
            'Work_Order': document.getElementById('currentWorkOrder').value,
            'Tech': techSelector
          };
  
          addPartsFirebase(partData);
          updatePartsIndex(jobId, partData);
          updateTimestamps('jobData', 'data');
  
        }, holdDelayType);
      }
    });
  
    tbody.appendChild(row);
    document.getElementById('parts-loading-icon').style.display = "none";
  
    // Create suggestion container and append it to the body
    const suggestionContainer = document.createElement('div');
    suggestionContainer.classList.add('suggestion-list');
    suggestionContainer.style.display = 'none';
    document.body.appendChild(suggestionContainer);
  
    part.addEventListener('input',async function() {
      try{
      const query = part.value.toLowerCase().trim();
      suggestionContainer.innerHTML = ''; // Clear previous suggestions
    
      // Position the suggestion container
      const rect = part.getBoundingClientRect();
      suggestionContainer.style.top = `${rect.bottom + window.scrollY}px`;
      suggestionContainer.style.left = `${rect.left + window.scrollX}px`;
  
      let inventoryData = await fetchDataFromStore('inventoryData');
      const filteredData = inventoryData.filter(item => {
        const itemValues = Object.values(item).map(value => value.toString().toLowerCase());
        return query.split(' ').every(term => itemValues.some(value => value.includes(term)));
      });  
      // Display suggestions
      filteredData.forEach(item => {
        const suggestionItem = document.createElement('div');
        suggestionItem.classList.add('suggestion-item');
        suggestionItem.textContent = `${item.Part_Number} (${item.Description})`;
        suggestionItem.addEventListener('click', () => {
          part.value = `${item.Part_Number} (${item.Description})`;
          suggestionContainer.style.display = 'none';
        });
        suggestionContainer.appendChild(suggestionItem);
      });
  
      // Show or hide the suggestion container based on the filtered results
      suggestionContainer.style.display = filteredData.length ? 'block' : 'none';
    }catch(error){
      console.error('Failed to fetch data from IndexedDB:', error);
    }
    });
  } */
  
  
    function initializePartsTable() {
      const tbody = document.getElementById("parts-table-body");
      tbody.innerHTML = ''; // Clear existing content
    
      // Create header row
      const headerRow = document.createElement("div");
      headerRow.className = "parts-data-row header-row";
    
      const partHeader = document.createElement('div');
      partHeader.textContent = "Part Number";
      partHeader.className = "parts-selector header";
    
      const qtyHeader = document.createElement('div');
      qtyHeader.textContent = "Qty";
      qtyHeader.className = "qty-selector header";

      const deleteHeader = document.createElement('div');
      deleteHeader.textContent = "Delete";
      deleteHeader.className = "delete-selector header";
    
      headerRow.appendChild(partHeader);
      headerRow.appendChild(qtyHeader);
      headerRow.appendChild(deleteHeader);
    
      tbody.appendChild(headerRow);
    }

    function addParts(quick) {
      const jobId = document.getElementById('currentJobId').value;
  
      // Create and show the part number input popup
      const partPopup = createPopup('Enter Part Number');
      const partInput = document.createElement('input');
      partInput.type = 'text';
      partInput.placeholder = 'Part Number';
      partInput.className = 'new-part-input';
      if(typeof quick === 'string'){
        partInput.value = quick;
      }
      partPopup.appendChild(partInput);
  
      const suggestionContainer = document.createElement('div');
      suggestionContainer.classList.add('suggestion-list');
      suggestionContainer.style.display = 'none';
      partPopup.appendChild(suggestionContainer);
  
      partInput.addEventListener('input', async function() {
          try {
              const query = partInput.value.toLowerCase().trim();
              suggestionContainer.innerHTML = '';
  
              let inventoryData = await fetchDataFromStore('inventoryData');
              const filteredData = inventoryData.filter(item => {
                  const itemValues = Object.values(item).map(value => value.toString().toLowerCase());
                  return query.split(' ').every(term => itemValues.some(value => value.includes(term)));
              });
  
              filteredData.forEach(item => {
                  const suggestionItem = document.createElement('div');
                  suggestionItem.classList.add('suggestion-item');
                  suggestionItem.textContent = `${item.Part_Number} (${item.Description})`;
                  suggestionItem.addEventListener('click', () => {
                    console.log(item);
                      partInput.value = `${item.Part_Number} (${item.Description})`;
                      suggestionContainer.style.display = 'none';
                      closePopup(partPopup);
                      showQuantityPopup(item.Part_Number, item.Description);
                  });
                  suggestionContainer.appendChild(suggestionItem);
              });
  
              suggestionContainer.style.display = filteredData.length ? 'block' : 'none';
          } catch(error) {
              console.error('Failed to fetch data from IndexedDB:', error);
          }
      });
  
      document.body.appendChild(partPopup);
      partInput.focus();
  }
  
  function showQuantityPopup(partNumber, description) {

      const qtyPopup = createPopup('Enter Quantity');
      const qtyInput = document.createElement('input');
      
      qtyInput.type = 'number';
      qtyInput.placeholder = 'Quantity';
      qtyInput.className = 'new-qty-input';
      qtyPopup.appendChild(qtyInput);
  
      const confirmButton = document.createElement('button');
      confirmButton.textContent = 'Submit';
      confirmButton.addEventListener('click', () => {
          const quantity = qtyInput.value.trim();
          if (quantity !== '') {
              addPartToTable(partNumber, description, quantity);
              closePopup(qtyPopup);
          }
      });
      qtyPopup.appendChild(confirmButton);
  
      document.body.appendChild(qtyPopup);
      qtyInput.focus();
  }
  
  async function addPartToTable(partNumber, description, quantity) {
    console.log(partNumber, description, quantity);
    try{
      const tbody = document.getElementById("parts-table-body");
      const row = document.createElement("div");
      row.className = "parts-data-row";
  
      const id = document.createElement('p');
      id.textContent = generateId();
      id.className = "id-selector";
      id.style.display = "none";
  
      const part = document.createElement('div');
      part.textContent = `${partNumber} (${description})`;
      part.className = "parts-selector";
  
      const qtyContainer = document.createElement('div');
      qtyContainer.className = 'qty-container';
      
      const qty = document.createElement('div');
      qty.textContent = quantity;
      qty.className = "qty-selector";
  
      qtyContainer.appendChild(qty);
  
      const del = document.createElement('button');
      del.className = 'parts-data-delete-button table-button';
      del.innerHTML = "<i class='material-icons'>clear</i>";
      del.addEventListener('click', deleteParts);
  
      row.appendChild(id);
      row.appendChild(part);
      row.appendChild(qtyContainer);
      row.appendChild(del);
  
      tbody.appendChild(row);
  
      // Add part to Firebase and update timestamps
      const partData = {
          'Part_Number': partNumber,
          'Quantity': quantity,
          'Category': document.getElementById('currentCategory').value,
          'Op_Type': document.getElementById('currentOpType').value,
          'Job_ID': document.getElementById('currentJobId').value,
          'ID': id.textContent,
          'Boat_Name': document.getElementById('currentBoat').value,
          'Customer_Name': document.getElementById('currentName').value,
          'Time_Submitted': getTodaysDate(),
          'Work_Order': document.getElementById('currentWorkOrder').value,
          'Tech': getTechSelector(),
          'Description': description
      };
  
     await addPartsFirebase(partData);
     await updatePartsIndex(document.getElementById('currentJobId').value, partData);
      updateTimestamps('jobData', 'data');
      generatePartsTable(document.getElementById('currentJobId').value);
    }catch(error){
      console.error('Failed to add part to table:', error);
    }

  }
  
  function createPopup(title) {
      const popup = document.createElement('div');
      popup.className = 'part-popup';
      popup.style.position = 'fixed';
      popup.style.left = '50%';
      popup.style.top = '15%';
      popup.style.transform = 'translate(-50%, -50%)';
      popup.style.backgroundColor = 'white';
      popup.style.padding = '1rem';
      popup.style.boxShadow = 'rgba(0, 0, 0, 0.5) 0px 0px 1000px 1000px';
      popup.style.zIndex = '1010';
      popup.style.borderRadius = '10px';
  
      const titleElement = document.createElement('h3');
      titleElement.textContent = title;
      popup.appendChild(titleElement);
  
      return popup;
  }
  
  function closePopup(popup) {
      document.body.removeChild(popup);
  }
  
  function getTechSelector() {
      const techSelect = document.getElementById('part-entry-tech');
      if (techSelect.value === '' || techSelect.value === 'default') {
          const userInfo = JSON.parse(localStorage.getItem('userInfo'));
          return userInfo.displayName.split(' ')[0];
      }
      return techSelect.value;
  }

async function addPartsFirebase(partData) {

  try{
  const partRef = ref(database, 'data/' + partData.Job_ID + '/Parts/' + partData.ID);
  
  set(partRef, partData, (error) => {
    if (error) {
      console.error("Error adding labor record:", error);
    } else {
      console.log("Labor record added successfully!");
    }
  });
}catch(error){
  console.error('Failed to add part to Firebase:', error);
}

}

function getJobData(jobId) {
  return new Promise((resolve, reject) => {
    const openRequest = indexedDB.open('openDatabase', currentIndexedVersion);

    openRequest.onerror = (event) => {
      reject(new Error(`Error opening database: ${event.target.errorCode}`));
    };

    openRequest.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');
      const getRequest = objectStore.get(jobId);

      getRequest.onerror = (event) => {
        reject(new Error(`Error getting job data: ${event.target.errorCode}`));
      };

      getRequest.onsuccess = (event) => {
        if (getRequest.result) {
          console.log('Job data:', getRequest.result);
          resolve(getRequest.result);
        } else {
          console.log('No job data found for ID:', jobId);
          resolve(null);
        }
      };
    };
  });
}


function updateParts(e) {
  if (
    e.target.getAttribute('data-check') === 'save' ||
    e.target.textContent === 'save' ||
    e.target.getAttribute('data-check') === 'update' ||
    e.target.textContent === 'update'
  ) {
    toastMessage('Applying Changes', 'blue');
    document.getElementById('currentStatus').value = 'In Progress';
    const targetRow = e.target.closest('tr');
    if (targetRow) {
      const cells = targetRow.querySelectorAll('td');
      const description = cells[5].textContent.replace(/ {2,}/g, ' ').trim();
      const jobId = document.getElementById('currentJobId').value;
      getJobData(jobId);

      let selectedTech
      if(cells[1].querySelector('select').getAttribute('data-selected-tech')){
        selectedTech = cells[1].querySelector('select').getAttribute('data-selected-tech')
        }else{ selectedTech = cells[1].textContent}

      const dataLog = {
        ID: cells[0].textContent.trim(),
        Tech: selectedTech, // Use the selected tech value
        CustomerName: cells[2].textContent.trim(),
        BoatName: cells[3].textContent.trim(),
        PartNumber: cells[5].textContent.trim(),
        Quantity: cells[6].textContent.trim(),
        Category: document.getElementById('currentCategory').value,
        OpType: document.getElementById('currentOpType').value,
        WorkOrder: cells[4].textContent.trim(),
        Timestamp: cells[7].textContent.trim()
      };

      const jobsRef = ref(database, 'data/' + jobId + '/Parts/' + dataLog.ID);
      set(jobsRef, dataLog)
        .then(() => {
          toastMessage("Firebase update succeeded", 'green');
          updatePartsIndex(jobId, dataLog);
          
          if(e.target.classList.contains('parts-data-button')){
            const button = targetRow.querySelector('.parts-data-button');
            button.innerHTML = "<button class='parts-update-button table-button'><i class='material-icons'>update</i></button>";
            button.setAttribute('data-check', 'update');
          }
          
        })
        .catch((error) => {
          toastMessage("Firebase update failed", 'red');
        });
    }
  }
}

async function updateLaborIndex(jobId, laborEntry) {
  
  try{
    const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version

    openRequest.onerror = function(event) {
        console.error('Error opening database:', event.target.errorCode);
    };
  
    openRequest.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction(['jobData'], 'readwrite');
        const objectStore = transaction.objectStore('jobData');
        
        const jobRequest = objectStore.get(jobId);
  
        jobRequest.onerror = function(event) {
            console.error('Error fetching job data:', event.target.errorCode);
        };
  
        jobRequest.onsuccess = function(event) {
            const jobData = jobRequest.result;
  
            if (jobData) {
                // Assuming laborEntry is an object with a unique ID itself
                // If it doesn't have an ID, you should assign it one
                const laborId = laborEntry.ID; // Ensure this ID is properly assigned or generated
                
                if (!jobData.Labor) {
                    jobData.Labor = {}; // Initialize if it doesn't exist
                }
  
                // Update the specific labor entry within the job
                jobData.Labor[laborId] = laborEntry;
  
                // Save the updated jobData back to the database
                const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
                
                updateRequest.onerror = function(event) {
                    console.error('Error updating job data:', event.target.errorCode);
                };
  
                updateRequest.onsuccess = function(event) {
                    console.log('Job data updated successfully');
                    // Handle successful update, e.g., updating the UI or notifying the user
                };
            } else {
                console.error('Job not found:', jobId);
            }
        };
    };
  }catch(error){
    console.error('Failed to update labor index:', error);
  }
  
}

function removeLaborFromIndex(jobId, laborId) {
  const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version
  openRequest.onerror = function(event) {
    console.error('Error opening database:', event.target.errorCode);
  };
  openRequest.onsuccess = function(event) {
    const db = event.target.result;
    const transaction = db.transaction(['jobData'], 'readwrite');
    const objectStore = transaction.objectStore('jobData');
    const jobRequest = objectStore.get(jobId);
    jobRequest.onerror = function(event) {
      console.error('Error fetching job data:', event.target.errorCode);
    };
    jobRequest.onsuccess = function(event) {
      const jobData = jobRequest.result;
      if (jobData && jobData.Labor && jobData.Labor[laborId]) {
        delete jobData.Labor[laborId]; // Remove the specific labor entry from the job
        const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
        updateRequest.onerror = function(event) {
          console.error('Error updating job data:', event.target.errorCode);
        };
        updateRequest.onsuccess = function(event) {
          console.log('Labor entry removed successfully');
          // Handle successful removal, e.g., updating the UI or notifying the user
        };
      } else {
        console.error('Labor entry not found:', laborId);
      }
    };
  };
}

async function updatePartsIndex(jobId, partEntry) {

    try{
    console.log(jobId);
    console.log(partEntry);
    const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version

    openRequest.onerror = function(event) {
      console.error('Error opening database:', event.target.errorCode);
    };

    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readwrite');
      const objectStore = transaction.objectStore('jobData');
      
      const jobRequest = objectStore.get(jobId);

      jobRequest.onerror = function(event) {
          console.error('Error fetching job data:', event.target.errorCode);
      };

      jobRequest.onsuccess = function(event) {
          const jobData = jobRequest.result;

          if (jobData) {
            
              const partId = partEntry.ID; // Ensure this ID is properly assigned or generated
              
              if (!jobData.Parts) {
                  jobData.Parts = {}; // Initialize if it doesn't exist
              }

              // Update the specific labor entry within the job
              jobData.Parts[partId] = partEntry;

              // Save the updated jobData back to the database
              const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
              
              updateRequest.onerror = function(event) {
                  console.error('Error updating job data:', event.target.errorCode);
              };

              updateRequest.onsuccess = function(event) {
                  console.log('Job data updated successfully');
                  // Handle successful update, e.g., updating the UI or notifying the user
              };
          } else {
              console.error('Job not found:', jobId);
          }
      };
    };
    }catch(error){
    console.error('Failed to update parts index:', error);
    }     
}

function removePartsFromIndex(jobId, partId) {
  const openRequest = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your actual database version
  openRequest.onerror = function(event) {
    console.error('Error opening database:', event.target.errorCode);
  };
  openRequest.onsuccess = function(event) {
    const db = event.target.result;
    const transaction = db.transaction(['jobData'], 'readwrite');
    const objectStore = transaction.objectStore('jobData');
    const jobRequest = objectStore.get(jobId);
    jobRequest.onerror = function(event) {
      console.error('Error fetching job data:', event.target.errorCode);
    };
    jobRequest.onsuccess = function(event) {
      const jobData = jobRequest.result;
      if (jobData && jobData.Parts && jobData.Parts[partId]) {
        delete jobData.Parts[partId]; // Remove the specific labor entry from the job
        const updateRequest = objectStore.put(jobData); // Use 'put' to update the existing entry
        updateRequest.onerror = function(event) {
          console.error('Error updating job data:', event.target.errorCode);
        };
        updateRequest.onsuccess = function(event) {
          console.log('Labor entry removed successfully');
          // Handle successful removal, e.g., updating the UI or notifying the user
        };
      } else {
        console.error('Labor entry not found:', partId);
      }
    };
  };
}


function updateLabor(e) {
  if (
    e.target.getAttribute('data-check') === 'save' ||
    e.target.textContent === 'save' ||
    e.target.getAttribute('data-check') === 'update' ||
    e.target.textContent === 'update'
  ) {
    toastMessage('Applying Changes', 'blue');
    document.getElementById('currentStatus').value = 'In Progress';
    const targetRow = e.target.closest('tr');
    if (targetRow) {
      const cells = targetRow.querySelectorAll('td');
      const description = cells[5].textContent.replace(/ {2,}/g, ' ').trim();
      const jobId = document.getElementById('currentJobId').value;
      let selectedTech
      if(cells[1].querySelector('select').getAttribute('data-selected-tech')){
      selectedTech = cells[1].querySelector('select').getAttribute('data-selected-tech')
      }else{ selectedTech = cells[1].textContent}
      
      getJobData(jobId);
      const dataLog = {
        ID: cells[0].textContent.trim(),
        Tech: selectedTech,
        CustomerName: cells[2].textContent.trim(),
        BoatName: cells[3].textContent.trim(),
        Description: cells[5].textContent.trim(),
        Hours: cells[6].textContent.trim(),
        Category: document.getElementById('currentCategory').value,
        OpType: document.getElementById('currentOpType').value,
        WorkOrder: cells[4].textContent.trim(),
        TimeSubmitted: cells[7].textContent.trim()
      };
      const jobsRef = ref(database, 'data/' + jobId + '/Labor/' + dataLog.ID);
      set(jobsRef, dataLog)
        .then(() => {
          toastMessage("Firebase update succeeded", 'green');
          updateLaborIndex(jobId, dataLog);
          const button = e.target.closest('button');
          console.log(button)
          if (button.classList.contains('labor-data-button')) {
            button.innerHTML = "<i class='material-icons'>update</i>";
            button.classList.remove('labor-data-button');
            button.classList.add('labor-update-button');
            button.setAttribute('data-check', 'update');
          }
        })
        .catch((error) => {
          toastMessage("Firebase update failed", 'red');
        });
    }
  }
}

function deleteLabor(e) {
  const button = e.target.closest('button');
  if (button && button.classList.contains('labor-data-delete-button')) {
    const laborEntry = button.closest('.labor-entry');
    if (laborEntry) {
      const jobId = document.getElementById('currentJobId').value;
      const laborId = laborEntry.getAttribute('data-id');

      const confirmDelete = confirm('Are you sure you want to delete this labor record?');
      if (confirmDelete) {
        toastMessage('Deleting Labor Record', 'blue');
        const laborRef = ref(database, 'data/' + jobId + '/Labor/' + laborId);
        remove(laborRef)
          .then(() => {
            toastMessage("Labor record deleted successfully", 'green');
            laborEntry.remove();
            removeLaborFromIndex(jobId, laborId);
            updateLaborCount();
          })
          .catch((error) => {
            toastMessage("Failed to delete labor record", 'red');
          });
      }
    }
  }
}

function deleteParts(e) {
  const button = e.target.closest('button');
  if (button && button.classList.contains('parts-data-delete-button')) {
    const partEntry = button.closest('.part-entry');
    if (partEntry) {
      const jobId = document.getElementById('currentJobId').value;
      const partId = partEntry.getAttribute('data-id');

      const confirmDelete = confirm('Are you sure you want to delete this part record?');
      if (confirmDelete) {
        toastMessage('Deleting Parts Record', 'blue');
        const partsRef = ref(database, 'data/' + jobId + '/Parts/' + partId);
        remove(partsRef)
          .then(() => {
            toastMessage("Part record deleted successfully", 'green');
            partEntry.remove();
            removePartsFromIndex(jobId, partId);
            updatePartsCount();
          })
          .catch((error) => {
            toastMessage("Failed to delete part record", 'red');
          });
      }
    }
  }
}

function updateLaborCount() {
  const laborEntries = document.querySelectorAll('#labor-table-body-current .labor-entry:not(.header)');
  document.getElementById('labor-header').innerHTML = 'Labor (' + laborEntries.length + ')';
}

function updatePartsCount() {
  const partEntries = document.querySelectorAll('#parts-table-body-current .part-entry:not(.header)');
  document.getElementById('parts-header').innerHTML = 'Parts (' + partEntries.length + ')';
}


async function showStatus(){

  try{

      if(document.getElementById('updateDescription').value.toLowerCase().includes("shrinkwrap boat")){
            openShrinkMenu(document.getElementById('currentStatus').value)
            }else{

            var id = document.getElementById('currentJobId').value

            await generateLaborTable(id)
            await generatePartsTable(id)

            fillInCustomerData();

            document.getElementById('statusTable').style.display="flex"

      }

  }catch(error){
    console.error('Failed to fetch op data:', error);
  }
}

function partBox(){

document.getElementById('partsModal').style.display='flex'
document.getElementById('partId').value = '';
document.getElementById('partVendorList').value = '';
document.getElementById('vendor').value='';
document.getElementById('manufacturer').value='';

var tbody = document.getElementById("op-body")

tbody.onclick = function (e) {

  e = e || window.event;
  var data = [];
  var target = e.srcElement || e.target;
  while (target && target.nodeName !== "TR") {
      target = target.parentNode;
  }
  if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);
     }
  }

document.getElementById('partWorkOrder').value = data[6]
document.getElementById('partJobId').value = data[0]

}
}


function workList(){
var dataList = JSON.parse(localStorage.getItem('openData'))
var Parent = document.getElementById('work-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

var wo = []

for(i=0;i<dataList.length;i++){
wo.push(dataList[i][6])
}
var unique = [...new Set(wo)]
var woList = []
for(k=0;k<unique.length;k++){
for(l=0;l<dataList.length;l++){
    if(dataList[l][6] === unique[k]){
    woList.push(dataList[l])
    break
  }
}
}

var final = woList.filter(function(w){return w[6].includes('TEMP') && w[0] != ''})

final.forEach(function(r){
  var tbody = document.getElementById("work-body")
      var row = document.createElement("tr")
      var jobId = document.createElement("td")
      jobId.className = "main-data"
      jobId.textContent = r[0]
      var name = document.createElement("td")
      name.className = "main-data"
      name.textContent = r[1]
      var customerId = document.createElement("td")
      customerId.className = "main-data"
      customerId.textContent = r[2]
      var boat = document.createElement("td")
      boat.className = "main-data"      
      boat.textContent = r[3]
      var boatId = document.createElement("td")
      boatId.className = "main-data"
      boatId.textContent = r[4]
      var email = document.createElement("td")
      email.className = "main-data"
      email.textContent = r[5]
      var workOrder = document.createElement("td")
      workOrder.className = "main-data hide-mobile"
      workOrder.textContent = r[6]
      workOrder.setAttribute("contenteditable", "true")
      var category = document.createElement("td")
      category.className = "main-data"
      category.textContent=r[7]
      var opType = document.createElement("td")
      opType.className = "main-data"
      opType.textContent=r[8]
      var description = document.createElement('td')
      description.className = 'main-data'
      description.textContent=r[9]
      description.setAttribute("contenteditable", "true")
      var date = document.createElement('td')
      date.className = 'main-data'
      date.textContent=r[10].split('-')[1] + '/' + r[10].split('-')[2] + '/' + r[10].split('-')[0]
      var tech = document.createElement('td')
      tech.className = 'main-data'
      tech.textContent=r[11]
      tech.setAttribute("contenteditable", "true")
      var status = document.createElement('td')
      status.className = 'main-data'
      status.textContent=r[12]
      var hours = document.createElement('td')
      hours.className = 'main-data'
      hours.textContent=r[13]
      var charge = document.createElement('td')
      charge.className = 'main-data'
      charge.textContent=r[14]
      var notes = document.createElement('td')
      notes.className = 'main-data'
      notes.textContent=r[15]
      var begin = document.createElement('td')
      begin.className = 'main-data hide-mobile'
      begin.textContent=r[16]
      var shortDesc = document.createElement('td')
      shortDesc.className = 'main-data'
      shortDesc.textContent=r[17]
      var woId = document.createElement('td')
      woId.className = 'main-data'
      woId.textContent=r[18]
      var del = document.createElement("td")
      del.innerHTML = "<button class='changeWorkOrderButton' onclick='saveWorkOrder(this)'><i class='fa-regular fa-file-word'></i></button>"
      
     
      row.appendChild(jobId).style.display='none'
      row.appendChild(name)
      row.appendChild(customerId).style.display='none'
      row.appendChild(boat)
      row.appendChild(boatId).style.display='none'
      row.appendChild(email).style.display='none'
      row.appendChild(workOrder)
      row.appendChild(category).style.display='none'
      row.appendChild(opType).style.display='none'
      row.appendChild(shortDesc)
      row.appendChild(description).style.display='none'
      row.appendChild(date).style.display='none'
      row.appendChild(tech)
      row.appendChild(status)
      row.appendChild(hours).style.display='none'
      row.appendChild(charge).style.display='none'
      row.appendChild(notes).style.display='none'
      row.appendChild(begin)
      row.appendChild(woId).style.display='none'
      row.appendChild(del)
      tbody.appendChild(row)


      })
}

function saveWorkOrder(e){
var tbody = document.getElementById(e.parentElement.parentElement.parentElement.id)
var tr = tbody.getElementsByTagName("tr")[0];
tbody.onclick = function (e) {
var newNumber = prompt('Enter Work Order Number')
if(newNumber ===null){
  return
}


  e = e || window.event
  var data = [];
  var target = e.srcElement || e.target;
  
  while (target && target.nodeName !== "TR") {
      target = target.parentNode;
  }

  if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);

      }

      var workOrder = data[6]


google.script.run.withSuccessHandler(()=>{
  toastMessage('Changes Saved!','green')

  if(tbody.id === "work-body"){
  target.style.display="none"
  }else{target.getElementsByTagName('td')[6].innerHTML = newNumber}
}).updateOpen(workOrder,newNumber)
}
}

}

function techTable(){
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

openRequest.onupgradeneeded = function(event) {
  const db = event.target.result;
  db.createObjectStore("openData", { keyPath: "id" });
};

openRequest.onsuccess = function(event) {
  const db = event.target.result;

  const tableTx = db.transaction("openData", "readonly");
  const tableStore = tableTx.objectStore("openData");
  const tableRequest = tableStore.getAll();

  tableRequest.onsuccess = function(event) {
    const data = event.target.result;
const dataArray = data
.map((wo) => {
  if (typeof wo === "object") {
    return Object.values(wo); // Convert object to array
  }
  return wo;
})
var Parent = document.getElementById('tech-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

var curr = new Date; 
var first = curr.getDate() - curr.getDay()
var last = first + 13; 
var firstday = new Date(curr.setDate(first)).toUTCString();
var lastday = new Date(curr.setDate(last)).getTime();

var woList = dataArray.filter(function(w){return w[21] === 'Needs Kit' && w[0] != ''})

woList.sort(function(a,b){
if(a[16] != '' || b[16] != ''){
return new Date(a[16]) - new Date(b[16])
}
})

woList.forEach(function(r){
 if(new Date(r[16]).getTime() <= lastday && r[16] != ''){
  var tbody = document.getElementById("tech-body")
      var row = document.createElement("tr")
      var jobId = document.createElement("td")
      jobId.className = "main-data"
      jobId.textContent = r[0]
      var name = document.createElement("td")
      name.className = "main-data"
      name.textContent = r[1]
      var customerId = document.createElement("td")
      customerId.className = "main-data"
      customerId.textContent = r[2]
      var boat = document.createElement("td")
      boat.className = "main-data"      
      boat.textContent = r[3]
      var boatId = document.createElement("td")
      boatId.className = "main-data"
      boatId.textContent = r[4]
      var email = document.createElement("td")
      email.className = "main-data"
      email.textContent = r[5]
      var workOrder = document.createElement("td")
      workOrder.className = "main-data hide-mobile"
      workOrder.textContent = r[6]
      workOrder.setAttribute("contenteditable", "true")
      var category = document.createElement("td")
      category.className = "main-data"
      category.textContent=r[7]
      var opType = document.createElement("td")
      opType.className = "main-data"
      opType.textContent=r[8]
      var description = document.createElement('td')
      description.className = 'main-data'
      description.textContent=r[9].split(':')[0]
      description.setAttribute("contenteditable", "true")
      var date = document.createElement('td')
      date.className = 'main-data'
      date.textContent=r[10].split('-')[1] + '/' + r[10].split('-')[2] + '/' + r[10].split('-')[0]
      var tech = document.createElement('td')
      tech.className = 'main-data'
      tech.textContent=r[11]
      tech.setAttribute("contenteditable", "true")
      var status = document.createElement('td')
      status.className = 'main-data'
      status.textContent=r[12]
      var hours = document.createElement('td')
      hours.className = 'main-data'
      hours.textContent=r[13]
      var charge = document.createElement('td')
      charge.className = 'main-data'
      charge.textContent=r[14]
      var notes = document.createElement('td')
      notes.className = 'main-data'
      notes.textContent=r[15]
      var begin = document.createElement('td')
      begin.className = 'main-data hide-mobile'
      begin.textContent=r[16]
      var shortDesc = document.createElement('td')
      shortDesc.className = 'main-data'
      shortDesc.textContent=r[17]
      var woId = document.createElement('td')
      woId.className = 'main-data'
      woId.textContent=r[18]
      var save = document.createElement("td")
      save.innerHTML = "<button class='saveButton' onclick='collectKits(this)'><i class='material-icons'>build_circle</i></button>"
      var del = document.createElement("td")
      del.innerHTML = "<button class='removeButton' onclick='removeKit(this)'><i class='material-icons'>close</i></button>"
      
     
      row.appendChild(jobId).style.display='none'
      row.appendChild(name)
      row.appendChild(customerId).style.display='none'
      row.appendChild(boat)
      row.appendChild(boatId).style.display='none'
      row.appendChild(email).style.display='none'
      row.appendChild(workOrder)
      row.appendChild(category).style.display='none'
      row.appendChild(opType).style.display='none'
      row.appendChild(description)
      row.appendChild(date).style.display='none'
      row.appendChild(tech)
      row.appendChild(status)
      row.appendChild(hours).style.display='none'
      row.appendChild(charge).style.display='none'
      row.appendChild(notes).style.display='none'
      row.appendChild(begin)
      row.appendChild(shortDesc).style.display='none'
      row.appendChild(woId).style.display='none'
      row.appendChild(save)
      row.appendChild(del)
      tbody.appendChild(row)
 }
      })

document.getElementById('techTable').style.display="flex"
  }
}
}

function removeKit(e){
var id = e.parentElement.parentElement.firstChild.innerHTML
google.script.run.withSuccessHandler( ()=>{
  e.parentElement.parentElement.style.display="none"
}).kitRemove(id)
}

function saveTech(){


var tbody = document.getElementById("tech-body");
var tr = tbody.getElementsByTagName("tr")[0];
tbody.onclick = function (e) {

if(e.target.innerHTML == "save"){
  e = e || window.event
  var data = [];
  var target = e.srcElement || e.target;
  while (target && target.nodeName !== "TR") {
      target = target.parentNode;
  }
  
  if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);
          
      }

      var jobId = data[0]
      var name = data[1]
      var customerId = data[2]
      var boatName = data[3]
      var boatId = data[4]
      var email = data[5]
      var workOrder = data[6]
      var category = data[7]
      var opType = data[8]
      var desc = data[9]
      var date = data[10]
      var tech = data[11]
      var status = data[12]
      var hours = data[13]
      var charge = data[14]
      var note = data[15]
      var begin = data[16]
      var shortDesc = data[17]

google.script.run.withSuccessHandler(toastMessage('Changes Saved!','green')).updateOpen(jobId,name,customerId,boatName,boatId,email,workOrder,category,opType,desc,date,tech,status,hours,charge,note,begin,shortDesc)
}
}
}
}

function getCustomerDataById(customerId, boatId) {
  return new Promise((resolve, reject) => {
    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your DB name and version

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject('Database error: ' + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      // Get the customer data by customerId
      const customerRequest = store.get(customerId);

      customerRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
        reject('Error fetching customer data: ' + event.target.errorCode);
      };

      customerRequest.onsuccess = (event) => {
        const customerData = event.target.result;
        // Ensure customerData and Boats[boatId] exists
        if (customerData && customerData.Boats && customerData.Boats[boatId]) {
          resolve(customerData.Boats[boatId]);
        } else {
          reject('Boat data not found for customerId: ' + customerId + ', boatId: ' + boatId);
        }
      };
    };

    request.onupgradeneeded = (event) => {
      // Placeholder for database upgrade logic, if necessary
      // This is only called if you're connecting to a higher version of the database
      // than what exists in the browser, allowing you to create object stores, etc.
    };
  });
}

async function getCustomerBalance(customer) {
  console.log(customer);
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Customers/RetrieveCustomer/${customer}`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };

  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    if (result.Invoices) {
      const rows = [];

      for (const inv of result.Invoices) {
        const tbody = document.getElementById('invoice-body');
        tbody.innerHTML = '';

        if (inv.Description.split(' ')[0] !== '') {
          const workOrderCheck = inv.Description.split(' ')[0];
          const workOrderData = await getWorkOrderInfoDM(workOrderCheck, inv.DueDate, inv.Id);

          for (const r of workOrderData) {
            const row = document.createElement('tr');
            const invoiceNumber = document.createElement('td');
            invoiceNumber.textContent = inv.Id;
            const workOrder = document.createElement('td');
            workOrder.innerHTML = r.Work_Order;
            const description = document.createElement('td');
            description.innerHTML = r.Description.split(':')[0];
            description.style.fontSize = '.75rem';
            const date = document.createElement('td');
            date.innerHTML = r.Bill_Date;

            const startDate = new Date(r.Bill_Date);
            const endDate = new Date();
            const differenceInMilliseconds = endDate - startDate;
            const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            const pastDue = document.createElement('td');
            pastDue.innerHTML = differenceInDays;
            const amount = document.createElement('td');
            amount.innerHTML = r.Charge;

            row.appendChild(invoiceNumber);
            row.appendChild(workOrder);
            row.appendChild(description);
            row.appendChild(date);
            row.appendChild(pastDue);
            row.appendChild(amount);

            rows.push(row);
          }
        } else {
          const row = document.createElement('tr');
          const invoiceNumber = document.createElement('td');
          invoiceNumber.textContent = inv.Id;
          const workOrder = document.createElement('td');
          workOrder.innerHTML = "";
          const description = document.createElement('td');
          description.innerHTML = 'Point of Sale';
          const date = document.createElement('td');
          date.innerHTML = inv.DueDate;

          const startDate = new Date(inv.DueDate);
          const endDate = new Date();
          const differenceInMilliseconds = endDate - startDate;
          const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

          const pastDue = document.createElement('td');
          pastDue.innerHTML = differenceInDays;
          const amount = document.createElement('td');
          amount.innerHTML = `$${Number(inv.InvoiceAmount).toFixed(2)}`;

          row.appendChild(invoiceNumber);
          row.appendChild(workOrder);
          row.appendChild(description);
          row.appendChild(date);
          row.appendChild(pastDue);
          row.appendChild(amount);

          rows.push(row);
        }
      }

      // Sort rows by date
      rows.sort((a, b) => {
        const dateA = new Date(a.querySelector('td:nth-child(4)').innerText);
        const dateB = new Date(b.querySelector('td:nth-child(4)').innerText);
        return dateA - dateB;
      });

      // Append sorted rows to the table
      const tbody = document.getElementById('invoice-body');
      rows.forEach(row => tbody.appendChild(row));
    }

    let balance = result.Balance;
    let numStr = balance.toString();

    if (numStr.length < 3) {
      while (numStr.length < 3) {
        numStr = '0' + numStr;
      }
    }

    let formattedNumber = numStr.slice(0, -2) + '.' + numStr.slice(-2);
    return formattedNumber;
  } catch (error) {
    console.error('Error fetching customer balance:', error);
    return null;
  }
}

async function getWorkOrderInfoDM(workOrder, billDate, invoiceNumber){
  console.log(workOrder, billDate, invoiceNumber)
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();
  const DM_URL = 'https://api.dockmaster.com:4134/';

  const url = `${DM_URL}api/v2/Service/WorkOrders/Retrieve?Id=${workOrder}&Detail=true`;
  const options = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };
const data = [];
  try {
    const response = await fetch(url, options);
    const result = await response.json();
    result.Operations.forEach(op => {
      const info = {
        "Charge": `$${Number(op.TotalCharges).toFixed(2).toLocaleString()}`,
        "Description": op.LongDesc,
        "Work_Order": workOrder,
        "Bill_Date": billDate,
        "Invoice": invoiceNumber
      };
      data.push(info);
    });

    return data;
}catch(error){
  console.log(error);
};

}

async function updateWorkOrderDM(serviceLog) {


  // Assuming `serviceLog` is an object that contains all necessary data
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();

  // Define the API endpoint for updating work order information
  const DM_URL = 'https://api.dockmaster.com:4134/';
  const url = `${DM_URL}api/v2/Service/WorkOrders/Update?OverwriteComments=false`;

  // Prepare the payload based on `serviceLog`
  const payload = {
    CustId: serviceLog.DMCustomer,
    WOId: serviceLog.Work_Order,
    Type: serviceLog.type,
    LocationCode: "1",
    Title: serviceLog.Short_Description,
    ClerkId: serviceLog.clerkId, 
    OperationCodes: [{
      ShortDesc: serviceLog.Short_Description_DM,
      Opcode: serviceLog.OpCode,
      LongDesc: serviceLog.Description,
      TechDesc: serviceLog.Description,
      Desc: serviceLog.Short_Description_DM
    }]
  };

  // Conditionally add optional fields
  if (serviceLog.flatLabor || serviceLog.flatPerFoot || serviceLog.flatMethod) {
    if (serviceLog.flatLabor !== '') {
      payload.OperationCodes[0].FlatRateAmount = serviceLog.flatLabor.toString();
    }
    if (serviceLog.flatPerFoot !== '') {
      payload.OperationCodes[0].FlatRatePerFootRate = serviceLog.flatPerFoot.toString();
    }
    if (serviceLog.flatMethod !== '') {
      payload.OperationCodes[0].FlatRatePerFootMethod = serviceLog.flatMethod.toString();
    }
  }

  // Prepare the request options
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload) // Ensure the payload is stringified
  };

  // Execute the API call
  try {
    const response = await fetch(url, options);
    console.log(response);
    const result = await response.json();
    

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return result; // You might want to return something more specific or handle it differently
  } catch (error) {
    console.error('Error in updating work order:', error);
    return null; // Or handle the error as you see fit
  }
}

async function deleteOperationDM(WOId, Operation) {
  // Assuming `WOId` and `Operation` are provided as parameters
  const apiKeyRef = child(ref(database), 'API/DockmasterDMToken');
  const snapshot = await get(apiKeyRef);
  const DOCKMASTER_API_KEY = snapshot.val();

  // Define the API endpoint for deleting an operation
  const DM_URL = 'https://api.dockmaster.com:4134/';
  const url = `${DM_URL}api/v2/Service/WorkOrders/DeleteOperation?WOId=${WOId}&Operation=${Operation}`;

  // Prepare the request options
  const options = {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${DOCKMASTER_API_KEY}`,
      'Content-Type': 'application/json'
    }
  };

  // Execute the API call
  try {
    const response = await fetch(url, options);
    const result = await response.json();

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    console.log('Op Deleted in Dockmaster', result);
    return result; // You might want to return something more specific or handle it differently
  } catch (error) {
    console.error('Error in deleting work order operation:', error);
    return null; // Or handle the error as you see fit
  }
}


function showBalance(data){

google.script.run.withSuccessHandler( (info)=>{
  var invoices = info;
  invoices.forEach( inv => {

    let tbody = document.getElementById('invoice-body');
    let row = document.createElement('tr');

    let invoice = document.createElement('td');
    let wo = document.createElement('td');
    let shortDesc = document.createElement('td')
    let date = document.createElement('td');
    let days = document.createElement('td');
    let amount = document.createElement('td');
    
    invoice.textContent = inv.Id;
    wo.textContent = inv.Description.split(' : ')[0];
    shortDesc.textContent = inv.ShortDesc
    date.textContent = inv.DueDate;
    days.textContent = inv.DaysFromDue.toString().split('-')[1];
    amount.textContent = formatCurrency(inv.Amount);

    row.appendChild(invoice);
    row.appendChild(wo);
    row.appendChild(shortDesc)
    row.appendChild(date);
    row.appendChild(days);
    row.appendChild(amount);

    if(inv.DaysFromDue.toString().split('-')[1] > 30){
      row.style.backgroundColor = '#fffcbf'
    }

    if(inv.DaysFromDue.toString().split('-')[1] > 60){
      row.style.backgroundColor = '#ffbfc0'
    }

    // Append the row directly to the tbody
    tbody.appendChild(row);
  });
}).getCustomerInvoiceData(data[22]);
}

async function selectWorkOrder(e){
  
const data = e.target.closest('.table-item');
activeTableRow = data;
const info = JSON.parse(data.getAttribute('data-jobinfo'));
const jobId = data.getAttribute('data-id');
const customerId = data.getAttribute('data-customerId');
const boatId = data.getAttribute('data-boatId');
const workOrder = data.getAttribute('data-workOrder');
  
  if(boatId){
    getCustomerDataById(customerId, boatId)
    .then(boatInfo => {
      document.getElementById('generalLength').value = boatInfo.Boat_Length;
      document.getElementById('generalBeam').value = boatInfo.Boat_Beam;
      document.getElementById('generalMooring').value = boatInfo.Mooring_Number;
      document.getElementById('generalTag').value = boatInfo.Tag_Number;
      document.getElementById('generalBottomPaint').value = boatInfo.Bottom_Paint_Type;
      document.getElementById('generalBottomPaintColor').value = boatInfo.Bottom_Paint_Color;
      document.getElementById('generalAnodeList').value = boatInfo.Anode_List;
      document.getElementById('generalMastLength').value = boatInfo.Mast_Length;
      document.getElementById('generalEngineCount').value = boatInfo.Number_of_Engines;
      document.getElementById('generalBatteryCount').value = boatInfo.Number_of_Batteries;
    })
    .catch(error => {
      console.error("Error fetching customer data:", error);
    });
  }
  
  globalWorkOrder = workOrder
  document.getElementById('activeWorkOrder').value = workOrder;
  const tableContent = document.getElementById('table-content');
  tableContent.style.padding = '0rem';
  
  var clickedRow = e.target.closest('.table-item');
  
  if (clickedRow === activeRow && e.target.tagName !== 'BUTTON' || e.target.id === 'search') {
    // If the clicked row is the same as the active row, remove the operation details container
    const existingContainerRow = clickedRow.parentNode.querySelector('.operation-container-row');
    if (existingContainerRow) {
        existingContainerRow.remove();
        activeRow.style.position = '';
        activeRow.style.top = '';
        tableContent.style.padding = '1rem 0rem';
    }
    activeRow = null; // Reset activeRow as there's no active operation details shown
    activeTableRow = null;
    selectedJobRow = null;
  } else if(e.target.tagName !== 'BUTTON') {
    // If the clicked row is different from the active row, first remove any existing operation details container
    const existingContainerRow = document.querySelector('.operation-container-row');
    if (existingContainerRow) {
        existingContainerRow.remove();
        activeRow.style.position = '';
        activeRow.style.top = '';
        tableContent.style.padding = '1rem 0rem';
    }
  
   
    activeRow = clickedRow;
    activeTableRow = activeRow;
    activeRow.style.position = 'sticky';
    activeRow.style.top = '0';
   
    displayOperations(globalWorkOrder, clickedRow);
    }
}

async function getCustomerDMID(customerId) {
  return new Promise((resolve, reject) => {
    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', currentIndexedVersion); // Ensure this matches your DB name and version

    request.onerror = (event) => {
      console.error('Database error:', event.target.errorCode);
      reject('Database error: ' + event.target.errorCode);
    };

    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['customerData'], 'readonly');
      const store = transaction.objectStore('customerData');

      // Get the customer data by customerId
      const customerRequest = store.get(customerId);

      customerRequest.onerror = (event) => {
        console.error('Error fetching customer data:', event.target.errorCode);
        reject('Error fetching customer data: ' + event.target.errorCode);
      };

      customerRequest.onsuccess = (event) => {
        const customerData = event.target.result;
        // Ensure customerData and Boats[boatId] exists
        if (customerData) {
          resolve(customerData.DM_Customer);
        } else {
          reject('Boat data not found for customerId: ' + customerId + ', boatId: ' + boatId);
        }
      };
    };

    request.onupgradeneeded = (event) => {
      // Placeholder for database upgrade logic, if necessary
      // This is only called if you're connecting to a higher version of the database
      // than what exists in the browser, allowing you to create object stores, etc.
    };
  });
}



async function displayOperations(workOrderNumber, afterRow) {

const info = JSON.parse(afterRow.getAttribute('data-jobinfo'));

  document.getElementById('invoice-body').innerHTML='';
  const currentUser = auth.currentUser;
  const hasAccess = await getUserRole(currentUser.uid);

  const balanceButton = document.createElement('button')
  balanceButton.classList.add('hide-mobile');

        
  fetchOperationsForWorkOrder(workOrderNumber)
      .then(async operations => {

          // Check if operations are available
          if (!operations || operations.length === 0) {
              console.log('No operations found for this work order.');
              return; // Exit if no operations
          }

          // Remove existing operation containers if any
          const existingContainer = afterRow.parentNode.querySelector('.operation-container');
          if (existingContainer) {
              existingContainer.remove();
          }

          if (afterRow.nextElementSibling && afterRow.nextElementSibling.classList.contains('operation-container-row')) {
              afterRow.parentNode.removeChild(afterRow.nextElementSibling);
              return; // Exit the function to toggle off the display of operations
          }

          // Create a new table row to host the operations container
          let containerRow = document.createElement('div');
          containerRow.className = 'operation-container-row'; // For potential styling or identification

          let buttonRow = document.createElement('div');
          buttonRow.className = 'operation-button-row';

          // Create a table cell that spans all columns
          let containerCell = document.createElement('div');
          containerCell.colSpan = afterRow.children.length; // Set colspan to match the number of columns in the parent table
          containerCell.className = 'operation-div'

          // Create a container div for the operations table
          let containerDiv = document.createElement('div');
          containerDiv.style.width = '100%'; // Ensure it spans the full width
          containerDiv.className = 'operation-container'; // For styling

          // Create the operations table within the div
          const operationsTable = document.createElement('div');
          operationsTable.id = 'operations-table'; // Add styling as needed

          opTableList2(operations, operationsTable);

          if(hasAccess.includes('admin') && activeRow != null){

            balanceButton.id = 'balance-amount';
            document.getElementById('invoice-customer').innerHTML = info.Customer_Name;
        
            balanceButton.addEventListener('click',(e)=>{
              console.log('Customer Balance Clicked')
              e.stopPropagation();
              document.getElementById('invoiceModal').style.display = 'flex';
              })
        
              balanceButton.style.display = "flex";
              balanceButton.innerHTML = 'Customer Balance: $--.--';
          }
              const addOpButton = document.createElement('button');
              addOpButton.addEventListener('click', addOperation);
              addOpButton.className = 'add-op-button';
              addOpButton.innerHTML = 'Add Operation';
        
              const customerRecordButton = document.createElement('button');
              customerRecordButton.addEventListener('click',(e) => {
                e.stopPropagation();
                callOpenCustomerData(info.Customer_ID);
              });
              customerRecordButton.className = 'customer-record-button';
              customerRecordButton.classList.add('hide-mobile');
              customerRecordButton.innerHTML = 'Customer Record';

          buttonRow.appendChild(balanceButton);
          buttonRow.appendChild(addOpButton);
          buttonRow.appendChild(customerRecordButton);
          containerRow.appendChild(buttonRow);

          containerCell.appendChild(containerDiv);
          containerDiv.appendChild(operationsTable);
          containerRow.appendChild(containerCell);

        afterRow.parentNode.insertBefore(containerRow, afterRow.nextSibling);

        await collectBalance(info.Customer_ID);
      })
      .catch(error => {
          console.error("Failed to fetch operations:", error);
      });

}

document.getElementById('customerRecordMobile').addEventListener('click',(e)=>{
  const customerId = document.getElementById('updateCustomerId').value;
    e.stopPropagation();
    callOpenCustomerData(customerId);
    document.getElementById('Update').style.display = 'none';
});

async function collectBalance(customerId){
  const customerDM =  await getCustomerDMID(customerId);
  const balance = await getCustomerBalance(customerDM)
  console.log(balance);
  document.getElementById('balance-amount').innerHTML = `Current Balance: $${Number(balance).toFixed(2).toLocaleString()}` 
}


function fetchOperationsForWorkOrder(workOrderNumber) {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('openDatabase');
    
    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');

      const getRequest = objectStore.getAll();

      getRequest.onsuccess = () => {
        const allJobs = getRequest.result;
        const filteredJobs = allJobs.filter(job => {
          return (
            job.Work_Order === workOrderNumber &&
            job.Category.toString() !== 'Declined'
          );
        });
        console.log(filteredJobs);
        resolve(filteredJobs);
      };

      getRequest.onerror = (event) => {
        reject(new Error(`Error fetching operations from IndexedDB: ${event.target.error}`));
      };
    };

    request.onerror = (event) => {
      reject(new Error(`Error opening IndexedDB: ${event.target.error}`));
    };
  });
}


  function opTableList2(ops, operationsTable){

    ops.sort((a, b) => {
      const dateA = new Date(a.Request_Date);
      const dateB = new Date(b.Request_Date);
      return dateA - dateB;
    })
  
    ops.forEach(function(r){
  
      var row = document.createElement("div")
      row.className = 'op-data-row'
      row.addEventListener('click',(e) => {opCheck(e)})
      row.setAttribute('data-info',JSON.stringify(r))
      
      var name = document.createElement("p")
      name.className = "op-data"
      name.textContent = r.Customer_Name
      
      var boat = document.createElement("p")
      boat.className = "op-data"      
      boat.textContent = r.Boat_Name
     
      var workOrder = document.createElement("p")
      workOrder.className = "op-data hide-mobile"
      workOrder.textContent = r.Work_Order
      workOrder.setAttribute("contenteditable", "true")
      
      var description = document.createElement("p");
      description.className = 'op-data';
      description.textContent = r.Description.split(':')[0];
      description.style.fontWeight = 'bold';

      var opCode = document.createElement("p")
      opCode.className = 'op-data'
      opCode.textContent = `OpCode: ${r.OpCode}`
  
      const dateDiv = document.createElement('div');
      dateDiv.className = 'op-date-info';
      dateDiv.classList.add('hide-mobile');
      const requestDateP = document.createElement('p');
      const requestDateLabel = document.createElement('strong');
      requestDateLabel.textContent = 'Requested: ';
      requestDateP.appendChild(requestDateLabel);
      requestDateP.className = 'op-request-date-info';
      let jobDate;

      if (r.Start_Date) {
        if (r.Start_Date.split('-')[0] === new Date().getFullYear().toString()) {
          jobDate = r.Start_Date.split('-')[1] + '-' + r.Start_Date.split('-')[2];
        } else {
          jobDate = r.Start_Date;
        }
      } else {
        if (r.Request_Date.split('-')[0] === new Date().getFullYear().toString()) {
          jobDate = r.Request_Date.split('-')[1] + '-' + r.Request_Date.split('-')[2];
        } else {
          jobDate = r.Request_Date;
        }
      }

      requestDateP.innerHTML += jobDate;
      dateDiv.appendChild(requestDateP);

          // Create the label for the schedule date input

      const scheduledDateDiv = document.createElement('div');
      scheduledDateDiv.className = 'op-scheduled-date-info';
      const scheduleDateLabel = document.createElement('strong');
      scheduleDateLabel.textContent = 'Scheduled: ';

      const scheduleDateP = document.createElement('input');
      scheduleDateP.type = 'date'; 
      scheduleDateP.className = 'schedule-date-info';
      scheduleDateP.value = r.Schedule_Date ? r.Schedule_Date : '';
      if (!r.Schedule_Date || r.Schedule_Date === '') {
        scheduleDateP.style.color = 'red';
      }

      scheduleDateP.addEventListener('change', async (e) => {
        const jobId = JSON.parse(e.target.closest('.op-data-row').getAttribute('data-info')).Job_ID;
        const date = e.target.value;
        updateJobInfo(jobId, 'Schedule_Date', date);
        updateJobRow(jobId, 'Schedule_Date', date);
      });

      // Append the label and input to the dateDiv
      scheduledDateDiv.appendChild(scheduleDateLabel);
      scheduledDateDiv.appendChild(scheduleDateP);
      dateDiv.appendChild(scheduledDateDiv);
     
      const tech = document.createElement("p");
      tech.className = "tech-select noPrint";
      const techDropdown = document.createElement('select');

      const deleteOption = document.createElement('option');
      deleteOption.value = '';
      deleteOption.textContent = '';
      techDropdown.appendChild(deleteOption);
  
      const techNames = globalTechList;

      techNames.forEach(name => {
          const optionElement = document.createElement('option');
          optionElement.value = name;
          optionElement.textContent = name;
          techDropdown.appendChild(optionElement);
      });
  
      if (r.Techs) {
          techDropdown.value = r.Techs;
          techDropdown.setAttribute('data-selected-tech',r.Techs)
        
      }
  
          techDropdown.addEventListener("change",async function() {
          try{
            const oldTech = this.getAttribute('data-selected-tech');
            const newTech = this.value;
            this.setAttribute('data-selected-tech', newTech); 
    
            const data = JSON.parse(this.closest('.op-data-row').getAttribute('data-info'));
            const id = data.Job_ID;
            
            if (oldTech) {
               /*  removeJobFromTech(id, oldTech,'change'); */
               console.log('Old Tech:', oldTech);
            }
    
          await addJobToTech(data, newTech);
          await updateJobDB(id, 'Techs', newTech);
          await updateJobRow(id, 'Techs', newTech);
          populateJobsForCurrentTech();
          filterJobs();
          updateWeekView();
          }catch(error){
            console.log(error);
          }
        
        });
  
      tech.appendChild(techDropdown)
  
      if(r.Status.toString() === "Complete"){
        row.style.backgroundColor='#ffcdd2'
      }else if(r.Status.toString() === 'Hold'){
        row.style.backgroundColor = "#b0b0b0"
        row.style.fontStyle = "italic"
      }else if(r.Status.toString() === 'Awaiting Approval'){
        row.style.backgroundColor = "#3e0054"
        row.style.color = "white"
        row.style.fontStyle = "italic"
      }else if(r.Status.toString() === 'In Progress'){
        row.style.backgroundColor = "#ecedaf"
        row.style.color = "black"
      }
  

      const noteDiv = document.createElement('div');
      noteDiv.className = 'notes-info';
      const jobNotesP = document.createElement('button');

      jobNotesP.addEventListener('click', async () => {
        
        const jobDiv = jobNotesP.closest('[data-info]');
        const jobId = JSON.parse(jobDiv.getAttribute('data-info')).Job_ID;
        const jobNotesData = await getJobData(jobId);
        
        
        document.getElementById('office-notes').classList.add('active');
        document.getElementById('note-job-id').value = jobId;
    
        const noteArea = document.getElementById('note-area');
        noteArea.innerHTML = '';
    
        if (jobNotesData.Notes) {
            Object.entries(jobNotesData.Notes).forEach(([key, note]) => {
              const noteContainer = document.createElement('div');
              noteContainer.className = 'note-pair';
              noteContainer.id = key;
            
              const noteInfo = document.createElement('div');
              noteInfo.className = 'note-info';
                         
              const noteText = document.createElement('p');
              noteText.textContent = note.Note;

              const techInfo = document.createElement('div');
              techInfo.className = 'note-info-tech';
            
              techInfo.innerHTML = note.Tech + ' | ' + note.Date + ', ' + note.Time;
            
              const deleteButton = document.createElement('button');
              deleteButton.textContent = 'Delete';
              deleteButton.className = 'delete-note';
              deleteButton.addEventListener('click', async () => {
                await deleteNotes(jobId, key);
                noteContainer.remove();
              });
            
              noteInfo.appendChild(noteText);
              noteInfo.appendChild(techInfo); 
              noteInfo.appendChild(deleteButton);
              noteContainer.appendChild(noteInfo);
              
              noteArea.appendChild(noteContainer);
            });
        }
    });

      jobNotesP.innerHTML = `<img src="/assets/note-icon.png" alt="Notes" width="24" height="24">`;
      
      if (job.Notes) {
        // If job.Notes is not blank, apply a red filter
        jobNotesP.querySelector('img').style.filter = 'brightness(0) saturate(100%) invert(15%) sepia(95%) saturate(6932%) hue-rotate(359deg) brightness(94%) contrast(113%)';
      }
  
      const descDiv = document.createElement('div');
      descDiv.className = 'op-row-div';
      descDiv.appendChild(description);
      descDiv.appendChild(opCode);
  
      row.appendChild(descDiv);
      row.appendChild(dateDiv);
      row.appendChild(tech);
      row.appendChild(jobNotesP);
  
      if(r.Parts_Ordered == "Part Ordered"){
        row.classList.add('flash-animation');
      }
  
    if(r.Parts_Kit_Status.includes('Needs Kit')){
      row.style.backgroundColor = "#3838B4"
      row.style.color = "white"
    }
  
      operationsTable.appendChild(row);
      })  
   
  };

function addOperation(e){

e.stopPropagation()
document.getElementById('general-work-order-button').style.display='none';
document.getElementById('customerDatabaseButton').style.display='none';
document.getElementById('workOrderType').style.display='none';


const rowData = JSON.parse(document.getElementById('operations-table').querySelector('.op-data-row').getAttribute('data-info'));
const workOrderNumber = rowData.Work_Order;

const allRows = document.getElementById('operations-table').querySelectorAll('.op-data-row');

currentOpCodes = []
for(let row of allRows){
  const info = JSON.parse(row.getAttribute('data-info'));

  if(info.OpCode){
    currentOpCodes.push(info.OpCode);
  }
}

document.getElementById('overallCategory').value = rowData.Category;

let codeTable = document.getElementById('op-code-body').rows

for(let code of codeTable){
  code.classList.remove('hidden')
  }

document.getElementById('name').value = rowData.Customer_Name;

if(rowData.Customer_Name.includes(',')){
document.getElementById('firstName').value = rowData.Customer_Name.split(', ')[1];
document.getElementById('lastName').value = rowData.Customer_Name.split(', ')[0];
}else{
document.getElementById('firstName').value = '';
document.getElementById('lastName').value = rowData.Customer_Name.split(', ')[0];
}

document.getElementById('customerId').value = rowData.Customer_ID;

if(rowData.Boat_Name != null){
  document.getElementById('boat').value = rowData.Boat_Name;
  document.getElementById('boatId').value = rowData.Boat_ID;
};

document.getElementById('email').value = rowData.Email;
document.getElementById('winterWorkOrder').value = rowData.Work_Order;
document.getElementById('springWorkOrder').value = rowData.Work_Order;
document.getElementById('generalWorkOrder').value = rowData.Work_Order;
document.getElementById('shortDesc').value = rowData.Short_Description;

let field = document.createElement('fieldset');
field.id = workOrderNumber.toString();
let legend = document.createElement('legend');
legend.innerHTML = workOrderNumber.toString();
field.appendChild(legend);
document.getElementById('activeJobs').appendChild(field);


document.getElementById('launch').value = rowData.Launch_Date;
document.getElementById('haul').value = rowData.Haul_Date;

addOpen("add")
} 

function addOpen(newOp){

var add = document.getElementById("Add")
add.style.display="flex"
setTimeout(()=>{
  document.querySelector('.modal-inside').scrollTop = 0
},100)


add.addEventListener('click',(e)=>{
  if(e.target.id == 'exit' || e.target.id == 'exitIcon'){
  add.style.display="none"

document.getElementById('general-work-order-button').style.display='block'
document.getElementById('customerDatabaseButton').style.display='block'
document.getElementById('workOrderType').style.display='flex'
document.getElementById('search').focus()
document.getElementById("name").value=""  
document.getElementById("customerId").value=""
document.getElementById("boat").value="" 
document.getElementById("boatId").value="" 
document.getElementById("generalWorkOrder").value=""
document.getElementById("winterWorkOrder").value=""
document.getElementById("springWorkOrder").value=""
document.getElementById('haul').value=""
document.getElementById('launch').value=""
document.getElementById('haul').value=""
document.getElementById('phone').value="";
document.getElementById('email').value="";
document.getElementById('generalLength').value=""
document.getElementById('generalBeam').value=""
document.getElementById('generalCombo').value=""
document.getElementById('generalMooring').value=""
document.getElementById('storageCheck').checked = false
document.getElementById("generalTag").value = "";
document.getElementById("generalBottomPaint").value = "";
document.getElementById("generalBottomPaintColor").value = "";
document.getElementById("generalAnodeList").value = "";
document.getElementById("generalMastLength").value = "";
document.getElementById("generalEngineCount").value = "";
document.getElementById("generalBatteryCount").value = "";
generalWorkForm()
document.getElementById('shortDesc').value=""

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

document.getElementById('add-ops-btn').classList.add('hidden')
document.getElementById('add-btn').classList.remove('hidden')

let codeTable = document.getElementById('op-code-body').rows

const activeJobs = document.getElementsByClassName('active')

for(let i = 0;i<activeJobs.length;i++){

const number = activeJobs[0].id.at(-1)

document.getElementById("category" + number).value=""
document.getElementById("opType" + number).value=""
document.getElementById('opCode' + number).value=""
document.getElementById("description" + number).value=""
document.getElementById("date" + number).value=""
document.getElementById("tech" + number).value=""
document.getElementById("status" + number).value=""
document.getElementById("job" + number).classList.remove('active')
document.getElementById("job" + number).classList.add('inactive')
document.getElementById('addJobButton' + number).classList.remove('hidden')
document.getElementById('deleteJobButton' + number).classList.add('hidden')
document.getElementById('jobExit' + number).classList.remove('hidden')
document.getElementById('jobMinimize' + number).classList.add('hidden')

}

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

document.getElementById('generalWorkOrder').scrollIntoView()
  }
})

if(newOp){
return
}else{document.getElementById('customerDatabaseButton').click()}
}

document.getElementById('exit-invoice').addEventListener('click', () => {
document.getElementById('invoiceModal').style.display = 'none';
})

document.getElementById('receivePart').addEventListener('click',()=>{
 let div = document.getElementById('receive-div');
 div.classList.toggle('hidden')
})


function closedWorkOrderForm(){
    add.style.display="none"
    console.log("I Closed It")
  
  document.getElementById('general-work-order-button').style.display='block'
  document.getElementById('customerDatabaseButton').style.display='block'
  document.getElementById('workOrderType').style.display='flex'
  document.getElementById('search').focus()
  document.getElementById("name").value=""  
  document.getElementById("customerId").value=""
  document.getElementById("boat").value="" 
  document.getElementById("boatId").value="" 
  document.getElementById("generalWorkOrder").value=""
  document.getElementById("winterWorkOrder").value=""
  document.getElementById("springWorkOrder").value=""
  document.getElementById('haul').value=""
  document.getElementById('launch').value=""
  document.getElementById('haul').value=""
  document.getElementById('phone').value="";
  document.getElementById('email').value="";
  document.getElementById('generalLength').value=""
  document.getElementById('generalBeam').value=""
  document.getElementById('generalCombo').value=""
  document.getElementById('generalMooring').value=""
  document.getElementById('storageCheck').checked = false
  document.getElementById("generalTag").value = "";
  document.getElementById("generalBottomPaint").value = "";
  document.getElementById("generalBottomPaintColor").value = "";
  document.getElementById("generalAnodeList").value = "";
  document.getElementById("generalMastLength").value = "";
  document.getElementById("generalEngineCount").value = "";
  document.getElementById("generalBatteryCount").value = "";
  generalWorkForm()
  document.getElementById('shortDesc').value=""
  
  var Parent = document.getElementById('activeJobs')
  while(Parent.hasChildNodes()){
  Parent.removeChild(Parent.firstChild)}
  
  document.getElementById('add-ops-btn').classList.add('hidden')
  document.getElementById('add-btn').classList.remove('hidden')
  
  let codeTable = document.getElementById('op-code-body').rows
  
  const activeJobs = document.getElementsByClassName('active')
  
  for(let i = 0;i<activeJobs.length;i++){
  
  const number = activeJobs[0].id.at(-1)
  
  document.getElementById("category" + number).value=""
  document.getElementById("opType" + number).value=""
  document.getElementById('opCode' + number).value=""
  document.getElementById("description" + number).value=""
  document.getElementById("date" + number).value=""
  document.getElementById("tech" + number).value=""
  document.getElementById("status" + number).value=""
  document.getElementById("job" + number).classList.remove('active')
  document.getElementById("job" + number).classList.add('inactive')
  document.getElementById('addJobButton' + number).classList.remove('hidden')
  document.getElementById('deleteJobButton' + number).classList.add('hidden')
  document.getElementById('jobExit' + number).classList.remove('hidden')
  document.getElementById('jobMinimize' + number).classList.add('hidden')
  
  }
  
  var Parent = document.getElementById('activeJobs')
  while(Parent.hasChildNodes()){
  Parent.removeChild(Parent.firstChild)}
  
  document.getElementById('generalWorkOrder').scrollIntoView()
}


function opCheck(e, jobData){

let data;
let row;

if(e){
  e.stopPropagation();
  if(e.target.tagName === 'INPUT' || e.target.tagName === 'BUTTON' || e.target.parentElement.tagName === 'BUTTON' || e.target.className === "customCheck" || e.target.tagName === 'SELECT'){
    return
    }
  if(e.target.closest('.op-data-row')){
    e.target.closest('.op-data-row').classList.add('selectedRow')
    row = e.target.closest('.op-data-row');
    selectedJobRow = row;
  }
  
  if(e.target.closest('.job-item')){
    row = e.target.closest('.job-item');
  }
  
    if (row && row.getAttribute('data-info')) {
  data = JSON.parse(row.getAttribute('data-info'));
    }
    
    if(row && row.getAttribute('data-jobinfo')){
  data = JSON.parse(row.getAttribute('data-jobinfo'));
    };
}else{
  data = jobData
}

console.log("Job ID: " + data.Job_ID);
globalJobId = data.Job_ID
globalShrink = data
document.getElementById('currentJobId').value = data.Job_ID
document.getElementById('jobIdPartKits').value = data.Job_ID
document.getElementById('jobId').value=data.Job_ID
document.getElementById('updateName').value=data.Customer_Name
document.getElementById('currentName').value = data.Customer_Name
document.getElementById('currentCustomerName').innerHTML = data.Customer_Name;
document.getElementById('currentBoatName').innerHTML = data.Boat_Name;
document.getElementById('currentOpenOp').innerHTML = "Op: " + data.Description.split(":")[0]
document.getElementById('currentShortDesc').value = data.Short_Description
document.getElementById('customerPartKits').value=data.Customer_Name
document.getElementById('updateFirstName').value=data.Customer_Name.split(', ')[1]
document.getElementById('updateLastName').value=data.Customer_Name.split(', ')[0]
document.getElementById('updateCustomerId').value=data.Customer_ID
document.getElementById('customerIdPartKits').value=data.Customer_ID
document.getElementById('updateBoat').value=data.Boat_Name.replace(/&amp;/g, '&');
document.getElementById('currentBoat').value=data.Boat_Name.replace(/&amp;/g, '&');
document.getElementById('boatPartKits').value=data.Boat_Name
document.getElementById('updateBoatId').value=data.Boat_ID
document.getElementById('boatIdPartKits').value = data.Boat_ID
document.getElementById('updateEmail').value=data.Email
document.getElementById('updateWorkOrder').value=data.Work_Order
document.getElementById('woPartKits').value=data.Work_Order
document.getElementById('updateCategory').value=data.Category
document.getElementById('categoryPartKits').value=data.Category
document.getElementById('updateOpType').value=data.Op_Type
document.getElementById('opTypePartKits').value=data.Op_Type
document.getElementById('updateDescription').value=data.Description.replace(/&amp;/g, '&');
document.getElementById('currentJobDesc').value=data.Description.replace(/&amp;/g, '&');
document.getElementById('descriptionPartKits').value=data.Description
document.getElementById('currentTech').value=data.Techs
document.getElementById('updateStatus').value=data.Status
document.getElementById('currentStatus').value=data.Status
document.getElementById('updateHours').value=data.Estimated_Hours
document.getElementById('updateAmount').value=data.Charge

document.getElementById('updateShortDesc').value=data.Short_Description.replace(/&amp;/g, '&');
document.getElementById('shortDescPartKits').value=data.Short_Description.replace(/&amp;/g, '&');
document.getElementById('updateOpCode').value = data.OpCode.toString()
document.getElementById('currentOpenOpCode').innerHTML = data.OpCode.toString();
document.getElementById('currentOpCode').value = data.OpCode
document.getElementById("currentWorkOrder").value = data.Work_Order
document.getElementById("launchDateAdd").value = data.Launch_Date
document.getElementById("haulDateAdd").value = data.Haul_Date
document.getElementById("launchDateSpring").value = data.Launch_Date
document.getElementById("haulDateSpring").value = data.Haul_Date
document.getElementById("launch").value = data.Launch_Date
document.getElementById("haul").value = data.Haul_Date
document.getElementById('partWorkOrder').value = data.Work_Order
document.getElementById('currentCategory').value = data.Category
document.getElementById('currentOpType').value = data.Op_Type
document.getElementById('currentCustomerId').value = data.Customer_ID
document.getElementById('currentBoatId').value = data.Boat_ID
document.getElementById('partJobId').value = data.Job_ID

if(data.Status == 'Hold'){
document.getElementById('holdButton').style.display = 'none'
document.getElementById('removeHoldButton').style.display = 'block'
}else{
document.getElementById('holdButton').style.display = 'block'
}


if( (data.Description.toLowerCase().includes('remove batter') || data.Description.toLowerCase().includes('store batter')) && document.getElementById('generalBatteryCount').value === ''){
const batteryCount = prompt('Enter Number of Batteries')
document.getElementById('generalBatteryCount').value = batteryCount
}else{document.getElementById('Update').classList.add('active')}

let oldButtons = document.querySelectorAll('.map-button');
if(oldButtons){
  oldButtons.forEach(button => button.remove());
};

let mapButton = document.createElement('button')
mapButton.id='showMap'
mapButton.className = 'map-button'
mapButton.innerHTML = 'Show on Map'
mapButton.addEventListener('click', ()=>{
document.getElementById('mapView').style.display='flex'
})
mapButton.setAttribute('data-boatId',data.Boat_ID)
/* document.getElementById('operations-field').appendChild(mapButton) */

}

function opCheckSchedule(data){
globalJobId = data.Job_ID
globalShrink = data
document.getElementById('currentJobId').value = data.Job_ID
document.getElementById('jobIdPartKits').value = data.Job_ID
document.getElementById('jobId').value=data.Job_ID
document.getElementById('updateName').value=data.Customer_Name
document.getElementById('currentName').value = data.Customer_Name
document.getElementById('currentBoatName').value = data.Boat_Name;
document.getElementById('currentOpenOp').innerHTML = "Op: " + data.Description.split(":")[0]
document.getElementById('currentShortDesc').value = data.Short_Description
document.getElementById('customerPartKits').value=data.Customer_Name
document.getElementById('updateFirstName').value=data.Customer_Name.split(', ')[1]
document.getElementById('updateLastName').value=data.Customer_Name.split(', ')[0]
document.getElementById('updateCustomerId').value=data.Customer_ID
document.getElementById('customerIdPartKits').value=data.Customer_ID
document.getElementById('updateBoat').value=data.Boat_Name.replace(/&amp;/g, '&');
document.getElementById('currentBoat').value=data.Boat_Name.replace(/&amp;/g, '&');
document.getElementById('boatPartKits').value=data.Boat_Name
document.getElementById('updateBoatId').value=data.Boat_ID
document.getElementById('boatIdPartKits').value = data.Boat_ID
document.getElementById('updateEmail').value=data.Email
document.getElementById('updateWorkOrder').value=data.Work_Order
document.getElementById('woPartKits').value=data.Work_Order
document.getElementById('updateCategory').value=data.Category
document.getElementById('categoryPartKits').value=data.Category
document.getElementById('updateOpType').value=data.Op_Type
document.getElementById('opTypePartKits').value=data.Op_Type
document.getElementById('updateDescription').value=data.Description.replace(/&amp;/g, '&');
document.getElementById('currentJobDesc').value=data.Description.replace(/&amp;/g, '&');
document.getElementById('descriptionPartKits').value=data.Description
if(data.Tech){
  document.getElementById('updateTech').value=data.Tech;
}else if(data.Techs){
  document.getElementById('updateTech').value=data.Techs;
};
document.getElementById('updateStatus').value=data.Status
document.getElementById('currentStatus').value=data.Status
document.getElementById('updateHours').value=data.Estimated_Hours
document.getElementById('updateAmount').value=data.Charge
document.getElementById('updateShortDesc').value=data.Short_Description.replace(/&amp;/g, '&');
document.getElementById('shortDescPartKits').value=data.Short_Description.replace(/&amp;/g, '&');
document.getElementById('updateOpCode').value = data.OpCode.toString()
document.getElementById('currentOpCode').value = data.OpCode
document.getElementById("currentWorkOrder").value = data.Work_Order
document.getElementById("launchDateAdd").value = data.Launch_Date
document.getElementById("haulDateAdd").value = data.Haul_Date
document.getElementById("launchDateSpring").value = data.Launch_Date
document.getElementById("haulDateSpring").value = data.Haul_Date
document.getElementById("launch").value = data.Launch_Date
document.getElementById("haul").value = data.Haul_Date
document.getElementById('partWorkOrder').value = data.Work_Order
document.getElementById('currentCategory').value = data.Category
document.getElementById('currentOpType').value = data.Op_Type
document.getElementById('currentCustomerId').value = data.Customer_ID
document.getElementById('currentBoatId').value = data.Boat_ID
document.getElementById('partJobId').value = data.Job_ID


if( (data.Description.toLowerCase().includes('remove batter') || data.Description.toLowerCase().includes('store batter')) && document.getElementById('generalBatteryCount').value === ''){
const batteryCount = prompt('Enter Number of Batteries')
document.getElementById('generalBatteryCount').value = batteryCount
}else{document.getElementById('selectOption').style.display="flex"}

let mapButton = document.createElement('button')
mapButton.id='showMap'
mapButton.className = 'map-button'
mapButton.innerHTML = 'Show on Map'
mapButton.addEventListener('click', ()=>{
document.getElementById('mapView').style.display='flex'
})
mapButton.setAttribute('data-boatId',data.Boat_ID)
/* document.getElementById('operations-field').appendChild(mapButton) */

}

function showUpdate(){
document.getElementById('Update').style.display = "flex";
}

var modalSelect = document.getElementById("addSelectModal")
var modalAdd = document.getElementById("Add");
var modalCustomer = document.getElementById("customerData");
var modalUpdate = document.getElementById("Update");
var modalNewCustomer = document.getElementById('addNewCustomer')

// Get the <span> element that closes the modal
var closeBtnAdd = document.getElementById("exit");
var closeBtnCustomer = document.getElementById("exitCustomer");
var closeBtnUpdate = document.getElementById("exitUpdate");

var closeBtnNewCustomer = document.getElementById("exitNewCustomer");
var closeBtnOp = document.getElementById('exitOp')

document.getElementById("exitTech").addEventListener('click',()=>{
document.getElementById("techTable").style.display="none"
tableMaker()
})

document.getElementById("exitWorkOrder").addEventListener('click',()=>{
document.getElementById("woTable").style.display="none"
tableMaker()
})

function closeAdd(){
document.getElementById('Add').style.display="none"
  document.getElementById('search').focus()
  document.getElementById("name").value=""  
document.getElementById("customerId").value=""
document.getElementById("boat").value="" 
document.getElementById("boatId").value="" 
document.getElementById("generalWorkOrder").value=""
document.getElementById("winterWorkOrder").value=""
document.getElementById("springWorkOrder").value=""
document.getElementById('haul').value=""
document.getElementById('launch').value=""
document.getElementById('haul').value=""
document.getElementById('generalLength').value=""
document.getElementById('generalBeam').value=""
document.getElementById('generalCombo').value=""
document.getElementById('generalMooring').value=""
document.getElementById('storageCheck').checked = false
document.getElementById("generalTag").value = "";
document.getElementById("generalBottomPaint").value = "";
document.getElementById("generalBottomPaintColor").value = "";
document.getElementById("generalAnodeList").value = "";
document.getElementById("generalMastLength").value = "";
document.getElementById("generalEngineCount").value = "";
document.getElementById("generalBatteryCount").value = "";
generalWorkForm()
document.getElementById('shortDesc').value=""

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

document.getElementById('add-ops-btn').classList.add('hidden')
document.getElementById('add-btn').classList.remove('hidden')

let codeTable = document.getElementById('op-code-body').rows

for(let code of codeTable){
code.classList.remove('hidden')
}

const activeJobs = document.getElementsByClassName('active')

for(let i = 0;i<activeJobs.length;i++){

const number = activeJobs[0].id.at(-1)

document.getElementById("category" + number).value=""
document.getElementById("opType" + number).value=""
document.getElementById('opCode' + number).value=""
document.getElementById("description" + number).value=""
document.getElementById("date" + number).value=""
document.getElementById("tech" + number).value=""
document.getElementById("status" + number).value=""
document.getElementById("job" + number).classList.remove('active')
document.getElementById("job" + number).classList.add('inactive')
document.getElementById('addJobButton' + number).classList.remove('hidden')
document.getElementById('deleteJobButton' + number).classList.add('hidden')
document.getElementById('jobExit' + number).classList.remove('hidden')
document.getElementById('jobMinimize' + number).classList.add('hidden')
}

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}
}


function springOpen(){
var checks = document.querySelectorAll('.checks')
checks.forEach( check => check.checked = false)
var spring = document.getElementById("Spring")
spring.style.display="flex"
modalSelect.style.display="none"
document.getElementById('addSelectModal').style.display="none"
spring.addEventListener('click',(e)=>{
  if(e.target.id == 'springExit' || e.target.id == 'springExitIcon'){
  spring.style.display="none"
  document.getElementById('nameSpring').value=""
document.getElementById("customerIdSpring").value=''
document.getElementById('boatSpring').value=''
document.getElementById('boatIdSpring').value=''
document.getElementById('phoneSpring').value=''
document.getElementById('boatTypeSpring').value=''
document.getElementById('emailSpring').value=''
document.getElementById('woSpring').value=''
document.getElementById('woWinter').value=''
document.getElementById('comboSpring').value=''
document.getElementById('bottomPaintTypeSpring').value=''
document.getElementById('bottomPaintColorSpring').value=''
document.getElementById('launch').value=""
document.getElementById('haul').value=""

document.getElementById('addSelectModal').style.display="none"
document.getElementById('springExit').scrollIntoView()
document.getElementById("opTable").style.display="flex"
  }
})


}

function selectOpen(){
var select = document.getElementById("addSelectModal")
select.style.display="flex"
select.addEventListener('click',(e)=>{

  if(e.target.id == "addSelectModal"){
  select.style.display="none"
  }
  document.getElementById("search").value=""
})
}

closeBtnCustomer.onclick = function() {
modalCustomer.style.display = "none";
if(modalAdd.style.visibility="hidden"){
modalAdd.style.visibility="visible"
}
}

function generateId(){
  var dt = new Date().getTime();
  var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = (dt + Math.random()*16)%16 | 0;
      dt = Math.floor(dt/16);
      return (c=='x' ? r :(r&0x3|0x8)).toString(16);
  });
  return uuid;
}

closeBtnNewCustomer.onclick = function() {
modalNewCustomer.style.display = "none";
}

closeBtnOp.onclick = ()=>{
document.getElementById('opTable').style.display="none"
document.querySelector('.opButtonColumn').style.display="none"
document.getElementById('loading-icon-op').style.display="flex"
document.getElementById('workOrderMove').value = ''
document.getElementById('shortDescMove').value = ''

var selectedRows = document.querySelectorAll('.selectedWorkOrder')
selectedRows.forEach( row => {row.classList.remove('selectedWorkOrder')})

var checks = document.querySelectorAll('.customCheck');

checks.forEach( check => {
      check.style.display = 'none';
      document.querySelector('.customCheckHeader').style.display="none"
      document.getElementById('showOps').innerHTML = "Select Operations"
      document.getElementById('moveOps').style.display="none"
      document.getElementById('showOps').classList.remove("hideOp")
      document.getElementById('showOps').classList.add("showOp")
      check.children[0].checked = false
  }
)
}

document.getElementById('exitOpWo').addEventListener('click',() => {
document.getElementById('openWOTable').style.display="none"
document.getElementById('newWorkGroup').style.display = "none"
document.getElementById('work-order-button-move').style.display = "inline-block"
document.getElementById('open-wo-table').style.display = "table"
})

async function saveToIndexedDB(item) {
const db = await openIndexedDB();
return new Promise((resolve, reject) => {
  const transaction = db.transaction("openData", "readwrite");
  const objectStore = transaction.objectStore("openData");
  const request = objectStore.add(item);

  request.onsuccess = (event) => {
    resolve(event.target.result);
  };

  request.onerror = (event) => {
    reject(event.target.error);
  };
});
}

function AddRow(){

/* var storageCheck = document.getElementById('storageCheck').checked
var storagePrice = document.getElementById('storagePrice').innerHTML
var price = parseFloat(storagePrice.replace(/[$,]/g, ''));
var length = document.getElementById('generalLength').value
var beam = document.getElementById('generalBeam').value
var mooring = document.getElementById('generalMooring').value
var combo = document.getElementById('generalCombo').value
var customerId = document.getElementById('customerId').value;
var boat = document.getElementById('boat').value;
var boatId = document.getElementById('boatId').value;
var email = document.getElementById('email').value;
var name = document.getElementById('name').value;
var haul = document.getElementById('haul').value
var launch = document.getElementById('launch').value

if(name.includes(',')){
var firstName = name.split(', ')[1]
var lastName = name.split(', ')[0]
}else{
var firstName = ''
var lastName = name
}

var dataLog = {}
dataLog.customerId = customerId
dataLog.boatId = boatId
dataLog.lastName = lastName
dataLog.firstName = firstName
dataLog.email = email
dataLog.boat = boat
dataLog.name = name
dataLog.length = length
dataLog.beam = beam
dataLog.launch = launch
dataLog.haul = haul
dataLog.mooring = mooring
dataLog.combo = combo
dataLog.price = price



google.script.run.updateCustomer(dataLog)

if(storageCheck === true){
google.script.run.winterStorage(dataLog)
} */

document.getElementById('Add').style.display="none"
document.getElementById('general-work-order-button').style.display='inline-block'
document.getElementById('workOrderType').style.display='flex'
document.getElementById('customerDatabaseButton').style.display='inline-block'
document.getElementById("customerId").value=""
document.getElementById("boatId").value=""
document.getElementById("name").value=""
document.getElementById("boat").value=""
document.getElementById("generalWorkOrder").value=""
document.getElementById('phone').value="";
document.getElementById("email").value=""
document.getElementById('shortDesc').value=""
document.getElementById('winterWorkOrder').value=''
document.getElementById('springWorkOrder').value=''
document.getElementById('haul').value=''
document.getElementById('launch').value=''
document.getElementById('storageCheck').checked = false
document.getElementById('generalMooring').value=''
document.getElementById('generalLength').value=''
document.getElementById('generalBeam').value=''
document.getElementById('generalCombo').value=''
document.getElementById('generalTag').value=''
document.getElementById('generalBottomPaintColor').value=''
document.getElementById('generalBottomPaint').value=''
document.getElementById('generalAnodeList').value=''
document.getElementById('generalMastLength').value=''
document.getElementById('generalEngineCount').value=''
document.getElementById('generalBatteryCount').value=''
document.getElementById('storagePrice').innerHTML = ''

document.getElementById('exit').scrollIntoView()

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}
    
/* getDataOpen()
clearActiveOps()
generalWorkForm() */

}

function clearActiveOps(){

var Parent = document.getElementById('activeJobs')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

document.getElementById('add-ops-btn').classList.add('hidden')
document.getElementById('add-btn').classList.remove('hidden')

let codeTable = document.getElementById('op-code-body').rows

for(let code of codeTable){
code.classList.remove('hidden')
}
}

async function showConfirmationPopup(message) {
  return new Promise((resolve) => {
    const popup = document.createElement('div');
    popup.className = 'confirmation-popup';
    popup.innerHTML = `
      <div class="popup-content">
        <p>${message}</p>
        <button class="yes-btn">Yes</button>
        <button class="no-btn">No</button>
      </div>
    `;

    document.body.appendChild(popup);

    const yesBtn = popup.querySelector('.yes-btn');
    const noBtn = popup.querySelector('.no-btn');

    yesBtn.addEventListener('click', () => {
      document.body.removeChild(popup);
      resolve(true);
    });

    noBtn.addEventListener('click', () => {
      document.body.removeChild(popup);
      resolve(false);
    });
  });
}


async function deleteOperation(){

  var jobId = document.getElementById('currentJobId').value;

  const jobInfo =  await getJobDataDB(jobId);

  const WOId = jobInfo.Work_Order;
  const operation = jobInfo.OpCode; 

  const confirmDelete = await showConfirmationPopup('Are you sure you want to delete this job?');

  if(!confirmDelete){return};

  document.getElementById('selectOption').style.display='none';
  document.getElementById('search').value = "";

  if (confirmDelete) {
    const laborRef = ref(database, 'data/' + jobId);
    remove(laborRef)
      .then(() => {
        deleteDataFromOpenDatabase(jobId);
        deleteOperationDM(WOId, operation);
        toastMessage("Job deleted successfully", 'green');
        var deleteRow = document.querySelectorAll('.selectedRow')
        var opTable = document.getElementById('operations-table')
        deleteRow.forEach(row => opTable.removeChild(row))
        deleteRow.forEach(row => row.classList.remove('selectedRow'))
      })
      .catch((error) => {
        toastMessage("Failed to delete labor record", 'red');
      });

const idToken = await fetchIdToken();
const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/";
const timestampPath = `data/lastUpdated.json?auth=${idToken}`;

try {
  const timestampResponse = await fetch(firebaseUrl + timestampPath, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({".sv": "timestamp"})
  });
  const timestampResult = await timestampResponse.json();
  console.log("Timestamp updated successfully", timestampResult);
} catch (e) {
  console.error("Error updating timestamp: " + e.toString());
}

  }

  if(document.getElementById('operations-table').children.length === 0){
    console.log(document.getElementById('operations-table').children.length)
   activeTableRow.remove();
   document.querySelector('.operation-container-row').remove();
   activeTableRow = null;
   selectedJobRow = null;
    }
} 


function deleteDataFromOpenDatabase(jobId) {
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const store = transaction.objectStore("jobData");

      const request = store.delete(jobId);
  }

}

function tableDelete(jobId){

var tbody = document.getElementById("op-body")
var tr = tbody.getElementsByTagName("tr")[0];

for(i=0;i<tbody.rows.length;i++){
if(tbody.rows[i].cells[0].innerHTML == jobId)
tbody.rows[i].style.display="none"
}
}

document.getElementById('generalWorkOrder').addEventListener('input',()=>{

})

async function fetchCustomerDataAndGenerateTable() {
try{
  const request = indexedDB.open('openDatabase', currentIndexedVersion);

  request.onerror = (event) => {
    console.error('Database error:', event.target.errorCode);
  };

  request.onsuccess = (event) => {
    const db = event.target.result;
    const transaction = db.transaction(['customerData'], 'readonly');
    const store = transaction.objectStore('customerData');

    const getAllRequest = store.getAll();

    getAllRequest.onerror = (event) => {
      console.error('Error fetching customer data:', event.target.errorCode);
    };

    getAllRequest.onsuccess = (event) => {
      const dataArray = event.target.result;
      generateCustomerTable(dataArray);
    };
  };
}catch(error){
  console.log(error);
}
}

function generateCustomerTable(dataArray) {

  const customerTables = ['tbody','customer-table-body','customer-email-body']

  for(let table of customerTables){
    var parent = document.getElementById(table);
    while (parent.hasChildNodes()) {
      parent.removeChild(parent.firstChild);
    }
  }

  dataArray = dataArray.sort((a, b) => {
    if (a.Last_Name && b.Last_Name) {
      return a.Last_Name.localeCompare(b.Last_Name);
    } else if (a.Last_Name) {
      return -1; 
    } else if (b.Last_Name) {
      return 1; 
    } else {
      return 0; 
    }
  });

  dataArray.forEach(function(customer) {

    var tbody = document.getElementById("tbody");
    var emailBody = document.getElementById('customer-email-body')
    var row = document.createElement("tr");
    var emailRow = document.createElement('tr');
    var nameTd = document.createElement("td");
    nameTd.className = 'form-data';
    nameTd.textContent = customer['Last_Name'] + ", " + customer['First_Name'];
    nameTd.setAttribute('data-customerinfo',JSON.stringify(customer))
    // Click event listener for the name cell
    nameTd.addEventListener('click', function() {
      if(Object.keys(customer['Boats']).length > 1){
      showBoatNamesPopup(customer['Boats']);
      }else{
        const boatKey = Object.keys(customer['Boats'])[0];
        const boat = customer['Boats'][boatKey];
      document.getElementById("boat").value= boat.Boat_Name
      document.getElementById("boatId").value= boat.Boat_ID
      document.getElementById("generalLength").value= boat.Boat_Length
      document.getElementById("generalBeam").value= boat.Boat_Beam
      document.getElementById("generalBottomPaint").value= boat.Bottom_Paint_Type
      document.getElementById("generalBottomPaintColor").value= boat.Bottom_Paint_Color
      document.getElementById('generalMastLength').value = boat.Mast_Length
      document.getElementById('generalEngineCount').value = boat.Number_of_Engines
      document.getElementById('generalBatteryCount').value = boat.Number_of_Batteries
      document.getElementById('generalMooring').value = boat.Mooring_Number
      document.getElementById('generalCombo').value = boat.Combo_Key
      document.getElementById('generalTag').value = boat.Tag_Number
      document.getElementById('generalAnodeList').value = boat.Anode_List
      document.getElementById('generalMooringWeight').value = boat.Mooring_Weight
      document.getElementById('launchDateAdd').value = boat.Launch_Date
      document.getElementById('haulDateAdd').value = boat.Haul_Date
      document.getElementById('DMBoatId').value = boat.DM_Boat
      if(boat.Launch_Date !== ""){
      document.getElementById('launch').value = boat.Launch_Date
      }
      if(boat.Haul_Date !== ""){
      document.getElementById('haul').value = boat.Haul_Date
      }
      document.getElementById('launchDateSpring').value = boat.Launch_Date
      document.getElementById('haulDateSpring').value = boat.Haul_Date
    storagePrice()
    document.getElementById('customer-search').value=""
    getOpCodes()
    document.getElementById("customerData").style.display = "none";
      }
      document.getElementById('Add').style.visibility = 'visible';
     
        document.getElementById("customerId").value= customer.Customer_ID
        document.getElementById("name").value= customer.Last_Name + ', ' + customer.First_Name
        document.getElementById('DMCustomerId').value = customer.DM_Customer

        if(customer.First_Name.includes(',')){
        document.getElementById('firstName').value=customer.Last_Name.split(', ')[1]
        document.getElementById('lastName').value=customer.Last_Name.split(', ')[0]
        }else{
        document.getElementById('firstName').value=''
        document.getElementById('lastName').value= customer.Last_Name
        }
        document.getElementById('phone').value= customer.Phone;
        document.getElementById("email").value= customer.Email;
        resetSearch('myTable')
    });

    const emailTd = document.createElement('td');
    emailTd.className = 'form-data';
    emailTd.textContent = customer['Last_Name'] + ', ' + customer['First_Name'];
    emailTd.value = customer['Email'];
    emailTd.addEventListener('click', (e) => {
      document.getElementById('email-customer').value = e.target.value;
      document.getElementById('customerEmailModal').style.display = "none";
    })

    row.appendChild(nameTd);
    emailRow.appendChild(emailTd);
    tbody.appendChild(row);
    emailBody.appendChild(emailRow);
  });
  populateTable(dataArray)
}


function showBoatNamesPopup(boats) {

  var popup = document.getElementById('boat-popup-list')

  while(popup.hasChildNodes()){
    popup.removeChild(popup.firstChild)};

  var boatList = document.createElement('ul');
  Object.values(boats).forEach(function(boat) {
    console.log(boat);
    var listItem = document.createElement('li');
    listItem.textContent = boat['Boat_Name'];
    listItem.addEventListener("click",() =>{

      document.getElementById("boat").value= boat.Boat_Name
      document.getElementById("boatId").value= boat.Boat_ID
      document.getElementById("generalLength").value= boat.Boat_Length
      document.getElementById("generalBeam").value= boat.Boat_Beam
      document.getElementById("generalBottomPaint").value= boat.Bottom_Paint_Type
      document.getElementById("generalBottomPaintColor").value= boat.Bottom_Paint_Color
      document.getElementById('generalMastLength').value = boat.Mast_Length
      document.getElementById('generalEngineCount').value = boat.Number_of_Engines
      document.getElementById('generalBatteryCount').value = boat.Number_of_Batteries
      document.getElementById('generalMooring').value = boat.Mooring_Number
      document.getElementById('generalCombo').value = boat.Combo_Key
      document.getElementById('generalTag').value = boat.Tag_Number
      document.getElementById('generalAnodeList').value = boat.Anode_List
      document.getElementById('generalMooringWeight').value = boat.Mooring_Weight
      document.getElementById('launchDateAdd').value = boat.Launch_Date
      document.getElementById('haulDateAdd').value = boat.Haul_Date
      document.getElementById('DMCustomerId').value = boat.DM_Customer
      document.getElementById('DMBoatId').value = boat.DM_Boat
      if(boat.Launch_Date !== ""){
      document.getElementById('launch').value = boat.Launch_Date
      }
      if(boat.Haul_Date !== ""){
      document.getElementById('haul').value = boat.Haul_Date
      }
      document.getElementById('launchDateSpring').value = boat.Launch_Date
      document.getElementById('haulDateSpring').value = boat.Haul_Date
    storagePrice()
    document.getElementById('customer-search').value=""
    getOpCodes()
    document.getElementById("customerData").style.display = "none";
  /*   popup.style.display = "none" */
    document.getElementById('boat-popup-div').style.display = 'none';
    })
    boatList.appendChild(listItem);

  });

  popup.appendChild(boatList);
  document.getElementById('boat-popup-div').style.display = 'flex';
}

function updateRecord(){


toastMessage('Updating Operation','blue')

var row = document.querySelector('.selectedRow')

var jobId = document.getElementById('currentJobId').value
var updateName = document.getElementById('updateName').value
if(updateName.includes(',')){
var updateFirstName = updateName.split(', ')[1]
var updateLastName = updateName.split(', ')[0]
}else{
var updateFirstName = ''
var updateLastName = updateName
}
var updateCustomerId = document.getElementById('updateCustomerId').value
var updateBoat = document.getElementById('updateBoat').value
var updateBoatId = document.getElementById('updateBoatId').value
var updateEmail = document.getElementById('updateEmail').value
var updateWorkOrder = document.getElementById('updateWorkOrder').value
var updateCategory = document.getElementById('updateCategory').value
var updateOpType = document.getElementById('updateOpType').value
var updateDescription = document.getElementById('updateDescription').value
var updateTech = document.getElementById('updateTech').value
var updateStatus = document.getElementById('updateStatus').value
var updateHours = document.getElementById('updateHours').value
var updateAmount = document.getElementById('updateAmount').value
var updateOpCode = document.getElementById('updateOpCode').value
var updateShortDesc = document.getElementById('updateShortDesc').value

var techID = getTechId(updateTech)
modifyTechJobs(techID, jobId, updateDescription,updateTech)

var serviceLog = {}
serviceLog.Customer_ID = updateCustomerId
serviceLog.Boat_ID = updateBoatId
serviceLog.Customer_Name = updateName
serviceLog.Email = updateEmail
serviceLog.Boat_Name = updateBoat
serviceLog.Work_Order = updateWorkOrder
serviceLog.OpCode = updateOpCode;
serviceLog.Short_Description = updateShortDesc;
serviceLog.Description = updateDescription;

document.getElementById('processing').style.display="block"
modalUpdate.style.display = "none";

updateJobInfo(jobId,serviceLog);

let request = indexedDB.open('openDatabase', currentIndexedVersion);

request.onerror = (event) => console.error('Database error:', event.target.errorCode);

request.onsuccess = (event) => {
const db = event.target.result;

const transaction = db.transaction('jobData', 'readwrite');
const store = transaction.objectStore('jobData');

const key = jobId.toString()

const getRequest = store.get(key);

getRequest.onerror = (event) => console.error('Error getting record:', event.target.errorCode);
getRequest.onsuccess = (event) => {
  
  const record = event.target.result;

  record.Email = updateEmail
  record.Category = updateCategory
  record.Op_Type = updateOpType
  record.Description = updateDescription
  if(record.Tech){
    record.Tech = updateTech
  }else{record.Techs = updateTech}
  record.Status = updateStatus
  record.Estimated_Hours = updateHours
  record.Charge = updateAmount
  record.Short_Description = updateShortDesc
  record.OpCode = updateOpCode
  const updateRequest = store.put(record);

  updateRequest.onerror = (event) => console.error('Error updating record:', event.target.errorCode);
  updateRequest.onsuccess = () => console.log('Record updated successfully');
};

transaction.oncomplete = () => db.close();
};


document.getElementById('processing').style.display='none'
}

document.getElementById('newCustomer').addEventListener("click",function(){
document.getElementById('customerPopUp').style.display="flex"
})

function addNewCustomer(){

var customer = {}

if(document.getElementById('customerFormId').value !== ''){
customer.Customer_ID = document.getElementById('customerFormId').value
}else{customer.Customer_ID = generateId()};

customer.Salutation = document.getElementById('customerSalutation').value
customer.Last_Name = document.getElementById('customerLastName').value
customer.First_Name = document.getElementById('customerFirstName').value
customer.Street = document.getElementById('customerStreet').value
customer.City = document.getElementById('customerCity').value
customer.State = document.getElementById('customerState').value
customer.Zip = document.getElementById('customerZip').value
customer.Phone = document.getElementById('customerPhone').value
customer.Email = document.getElementById('customerEmail').value
customer.DM_Customer = document.getElementById('customerDM').value
customer.id = customer.Customer_ID;


if(document.getElementById('boatDM')){

var boat = {}

boat.Boat_ID = generateId();
boat.Boat_Name = document.getElementById('customerBoatName').value
boat.Boat_Length = document.getElementById('customerBoatLength').value
boat.Boat_Beam = document.getElementById('customerBoatBeam').value
boat.Combo_Key = document.getElementById('customerComboKey').value
boat.Mooring_Number = document.getElementById('customerMooringNumber').value
boat.Mooring_Weight = document.getElementById('customerMooringWeight').value
boat.Bottom_Paint_Type = document.getElementById('bottomPaintTypeSpring').value
boat.Bottom_Paint_Color = document.getElementById('bottomPaintColorSpring').value
boat.DM_Boat = document.getElementById('boatDM').value

}


var Parent = document.getElementById('tbody')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

document.getElementById('addNewCustomer').style.display="none"
toastMessage('Information Updated in OBMC App and Dockmaster', 'green')
    
document.getElementById('customerFormId').value = "";
document.getElementById('customerLastName').value = "";
document.getElementById('customerFirstName').value = "";
document.getElementById('customerStreet').value = "";
document.getElementById('customerCity').value = "";
document.getElementById('customerState').value = "";
document.getElementById('customerZip').value = "";
document.getElementById('customerPhone').value = "";
document.getElementById('customerEmail').value = "";
document.getElementById('customerBoatName').value = "";
document.getElementById('customerBoatLength').value = "";
document.getElementById('customerBoatBeam').value = "";
document.getElementById('customerComboKey').value = "";
document.getElementById('customerMooringNumber').value = "";
document.getElementById('customerMooringWeight').value = ""
document.getElementById('customerDM').value = ''
document.getElementById('boatDM').value = ''
document.getElementById('bottomPaintTypeSpring').value = ""
document.getElementById('bottomPaintColorSpring').value = ""
addCustomerToIndexedDB(customer, boat);
addCustomerToFirebase(customer, boat);

console.log(customer.Customer_ID);
}

async function addCustomerToFirebase(customer, boat) {
  try {
    const customerRef = ref(database, `/customers/${customer.Customer_ID}`);
    await set(customerRef, customer);

    if (boat) {
      const boatRef = ref(database, `/customers/${customer.Customer_ID}/Boats/${boat.Boat_ID}`);
      await set(boatRef, boat);
    }

    console.log('Customer added to Firebase:', customer);
    console.log('Boat added to Firebase:', boat);
  } catch (error) {
    console.error('Error adding customer/boat to Firebase:', error);
  }
}

async function addCustomerToIndexedDB(customer, boat) {
  try {
    // Check if the customer object has a valid Customer_ID
    if (!customer.Customer_ID) {
      console.error('Invalid customer object. Missing Customer_ID.');
      return;
    }

    const dbPromise = indexedDB.open('openDatabase', currentIndexedVersion);
    const db = await new Promise((resolve, reject) => {
      dbPromise.onupgradeneeded = event => {
        const db = event.target.result;
        if (!db.objectStoreNames.contains('customerData')) {
          db.createObjectStore('customerData', { keyPath: 'Customer_ID' });
        }
      };
      dbPromise.onsuccess = event => resolve(event.target.result);
      dbPromise.onerror = event => reject(event.target.error);
    });

    const transaction = db.transaction(['customerData'], 'readwrite');
    const store = transaction.objectStore('customerData');

    // Check if the customer already exists
    const getRequest = store.get(customer.Customer_ID);
    await new Promise((resolve, reject) => {
      getRequest.onsuccess = event => {
        const existingCustomer = event.target.result;
        const updatedCustomer = { ...existingCustomer, ...customer };
        
        if (boat) {
          if (!updatedCustomer.Boats) {
            updatedCustomer.Boats = {};
          }
          updatedCustomer.Boats[boat.Boat_ID] = boat;
        }

        const request = existingCustomer ? store.put(updatedCustomer) : store.add(updatedCustomer);
        request.onsuccess = () => resolve();
        request.onerror = event => reject(event.target.error);
      };
      getRequest.onerror = event => reject(event.target.error);
    });

    console.log('Customer added/updated in IndexedDB:', customer);
    console.log('Boat added/updated in IndexedDB:', boat);
  } catch (error) {
    console.error('Error adding/updating customer/boat in IndexedDB:', error);
  }
}

document.getElementById('updateStatus').addEventListener('change',function(){
if(updateStatus.value=="Declined"){
  var jobId=document.getElementById('jobId').value
if(confirm("Would you like to remove this work request?")===true){
UpdateRecord()
  }
}
})

function openEstimate(){
document.getElementById('openEstimateLink').click()
}

function toastMessage(message, status, time) {
var x = document.getElementById("snackbar");

// Set background color if status is provided
if (status) {
  x.style.backgroundColor = status.toString();
}

// Set default timeout duration if not provided
var timeOut = time || 3000;

// Convert time from milliseconds to seconds for CSS
var timeInSeconds = timeOut / 1000;

// Set the message
x.innerHTML = message;

// Set animation with dynamic timing
x.style.animation = `fadein 0.5s, fadeout 0.5s ${timeInSeconds}s, flash 1s infinite`;
x.style.animationFillMode = "forwards";

// Show the snackbar
x.className = "show";

// Hide the snackbar after it is shown for 'timeOut' duration
setTimeout(function() {
  x.className = x.className.replace("show", "");
  x.style.animation = ""; // Reset the animation
}, timeOut + 500); // Add 500ms to account for the fadein duration
}

document.getElementById('email-open-button').addEventListener('click',() => {
  document.getElementById('emailTable').style.display = 'flex';
})
document.getElementById('email-send').addEventListener('click', sendEmail);


document.getElementById('chat-send').addEventListener('click', sendChat)

function sendChat(e) {
  e.preventDefault();
  // get values to be submitted
  const timestamp = Date.now();
  const userId = auth.currentUser.uid;
  const messageInput = document.getElementById("message-input");
  const message = messageInput.value;
  // clear the input box
  messageInput.value = "";
  // auto scroll to bottom
  document.getElementById("messages").scrollIntoView({
    behavior: "smooth",
    block: "end",
    inline: "nearest",
  });
  const username = 'Mac';
  set(ref(database, "users/" + userId + "/messages/" + timestamp), {
    username,
    message,
  });
}

function sendEmail() {
  const to = document.getElementById('email-customer').value;
  const subject = document.getElementById('email-subject').value;
  const message = document.getElementById('email-message').value;

  // Check if the message contains HTML tags
  const isHTML = /<\/?[a-z][\s\S]*>/i.test(message);

  const data = {
    to: to,
    subject: subject,
    text: isHTML ? undefined : message, // If message is not HTML, set as text
    html: isHTML ? message : undefined  // If message is HTML, set as html
  };

  fetch('https://us-central1-marine-center-database.cloudfunctions.net/sendEmail', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(response => {
      if (response.ok) {
        toastMessage('Email Sent', 'green');
        // Clear the form fields
        document.getElementById('email-customer').value = '';
        document.getElementById('email-subject').value = '';
        document.getElementById('email-message').value = '';
      } else {
        console.error('Error sending email');
        // Show an error message to the user
        toastMessage('Error sending email', 'red');
      }
    })
    .catch(error => {
      console.error('Error sending email:', error);
      // Show an error message to the user
      alert('Error sending email');
    });
}



function partSearch() {
var input, filter, table, tr, td, i, txtValue;
input = document.getElementById("myPartsInput");
filter = input.value.toUpperCase();
table = document.getElementById("partsTable");
tr = table.getElementsByTagName("tr");
for (i = 0; i < tr.length; i++) {
  td = tr[i].getElementsByTagName("td")[1];
  if (td) {
    txtValue = td.textContent || td.innerText;
    if (txtValue.toUpperCase().indexOf(filter) > -1) {
      tr[i].style.display = "";
    } else {
      tr[i].style.display = "none";
    }
  }       
}
}

function partSelect() {

var partTbody = document.getElementById("parts-list-body")

partTbody.onclick = function (e) {
  e = e || window.event;
  var data = [];
  var target = e.srcElement || e.target;
  while (target && target.nodeName !== "TR") {
      target = target.parentNode;
  }
  if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);
     }
  }

  var qtyPrompt = window.prompt("Enter Quantity");
  var activeQtyCell = document.getElementById('active-qty-cell');
  var activePartCell = document.getElementById('active-part-cell');

  if (activeQtyCell) {
      activeQtyCell.innerHTML = qtyPrompt;
      activeQtyCell.removeAttribute('id');  // Remove id
  }

  if (activePartCell) {
      activePartCell.innerHTML = data[1];
      activePartCell.removeAttribute('id');  // Remove id
  }

  document.getElementById("partsData").style.display = "none";
}
}

function partOrderSelect(part){

let data = JSON.parse(part);

let qtyPrompt = window.prompt("Enter Quantity")

document.getElementById('partId').value = data.id
document.getElementById('partData').setAttribute('data-part', JSON.stringify(data))
document.getElementById('orderQty').value= qtyPrompt
document.getElementById("orderPart").value=data.Part_Number
document.getElementById("partDescription").value=data.Description
document.getElementById("manufacturer").value=data.Manufacturer
document.getElementById('myPartsInput').value=""
document.getElementById('partsOrderData').style.display="none"
document.getElementById('partVendorList').value = data.Vendor_List;


}

function partSelectData(e){

var partTbody = document.getElementById("parts-select-body")
const row = e.target.closest('tr').querySelectorAll('td')
const data = [];
row.forEach( cell => data.push(cell.innerHTML))

if(document.getElementById('#active-part-cell')){
document.querySelector("#active-part-cell").innerHTML=data[2]
}else{addParts(data[2])}
document.getElementById('myPartsSelectInput').value=""
document.getElementById('partsSelectData').style.display="none"


}




document.getElementById('exitPartsData').addEventListener('click',()=>{
document.getElementById('partsData').style.display=""
document.getElementById('myPartsInput').value=""
})

document.getElementById('exitPartsOrderData').addEventListener('click',()=>{
document.getElementById('partsOrderData').style.display=""
document.getElementById('myPartsOrderInput').value=""
})

document.getElementById('exitPartsSelectData').addEventListener('click',()=>{
document.getElementById('partsSelectData').style.display=""
document.getElementById('myPartsSelectInput').value=""
})

document.getElementById('partButton').addEventListener('click',()=>{
document.getElementById('partsOrderData').style.display="flex"
console.log('Checked')
})

function calculateSuggestedSalePrice(listId, costId, saleId) {
  const msrp = parseFloat(document.getElementById(listId).value);
  const cost = parseFloat(document.getElementById(costId).value);
  const salePriceInput = document.getElementById(saleId);
console.log(msrp, cost);
  if (isNaN(msrp) || isNaN(cost)) {
    salePriceInput.placeholder = '';
    return;
  }

  const percent = Math.round(((msrp - cost) / msrp) * 100);
  let suggestedSalePrice;

  if (percent <= 15) {
    suggestedSalePrice = msrp;
  } else if (percent <= 35) {
    suggestedSalePrice = msrp * 0.9; // 10% discount
  } else {
    suggestedSalePrice = msrp * 0.85; // 15% discount
  }

  salePriceInput.placeholder = `Suggested: $${suggestedSalePrice.toFixed(2)}`;
}

// Add event listeners to MSRP and Cost inputs
document.getElementById('listReceived').addEventListener('input',() => {
 let partMSRP = 'listReceived';
  let partCost = 'costReceived';
  let partSalePrice = 'saleReceived';
  calculateSuggestedSalePrice(partMSRP, partCost, partSalePrice);
});

document.getElementById('costReceived').addEventListener('input',() => {
  let partMSRP = 'listReceived';
  let partCost = 'costReceived';
  let partSalePrice = 'saleReceived';
   calculateSuggestedSalePrice(partMSRP, partCost, partSalePrice);
 });

async function addPartOrder(){

let partId = document.getElementById('partId').value
let tech = document.getElementById('partTech').value
let data = JSON.parse(document.getElementById('partData').getAttribute('data-part'));

console.log(data);

if(tech === "default"){
toastMessage("Select a Tech",'red')
return
}

let date = await formatDate();

let id = partId ? partId : await generateId();
let orderId = await generateId();
let part = document.getElementById('orderPart').value.toUpperCase()
let qty = document.getElementById('orderQty').value;
let description = document.getElementById('partDescription').value
description = description.charAt(0).toUpperCase() + description.slice(1)
let vendor = document.getElementById('vendor').value;
let currentVendorList = document.getElementById('partVendorList').value;
vendor = currentVendorList.includes(vendor) ? currentVendorList : currentVendorList + ',' + vendor;
let manufacturer = document.getElementById('manufacturer').value;
let location = data.Location;
let orderedDate = date.toString()
let jobId = document.getElementById('partJobId').value
let qtyRemaining = qty;
let qtyReceived = 0;
let listPrice = document.getElementById('listReceived').value;
let cost = document.getElementById('costReceived').value;
let sale = document.getElementById('saleReceived').value;

let addToInventory = document.getElementById('addToInventory').checked
let partReceived = document.getElementById('receivePart').checked

if(partReceived == true){
  qtyReceived = document.getElementById('qtyReceived').value;
  qtyRemaining = qty - qtyReceived;
};

let partData = {
"Part_Number": part,
"Manufacturer": manufacturer,
"Vendor_List": [vendor],
"Description": description,
"Location": location,
"id": id
};

if(addToInventory === true && partId === ''){
  partData.Add_Date = date;
  partData.Qty = 0;
}

if(partReceived === true && (addToInventory === true || partId !== '')){
  partData.Qty = Number(qtyReceived) + Number(data.Qty);
  partData.Cost = cost;
  partData.Sale_Price = sale;
}

let receiveData = {
  "id": orderId,
  "Part_ID": id,
  "Job_ID": jobId,
  "Quantity_Remaining": qtyRemaining,
  "Quantity_Ordered": qty,
  "Quantity_Received": qtyReceived,
  "Vendor": document.getElementById('vendor').value,
  "Description": description,
  "Date": orderedDate,
  "List_Price": listPrice,
  "Location": location,
  "Cost": cost,
  "Sale_Price": sale,
  "Manufacturer": manufacturer,
  "Part_Number": part,
  "Tech": tech
};

await addOrUpdatePart(partData)
await pendingParts(receiveData)

document.getElementById('orderPart').value =''
document.getElementById('orderQty').value=''
document.getElementById('partId').value = '';
document.getElementById('partVendorList').value = '';
document.getElementById('partData').removeAttribute('data-part');
document.getElementById('partDescription').value=''
document.getElementById('addToInventory').checked=false
document.getElementById('receivePart').checked=false
document.getElementById('qtyReceived').value = ''
document.getElementById('partWorkOrder').scrollIntoView()
}

async function addOrUpdatePart(partData) {
  try {
    const partRef = ref(database, `/inventory/${partData.id}`);
    await update(partRef, partData);
    await updateIndexedDB(partData, 'inventoryData');
    updateTimestamps('inventoryData','inventory');
  } catch (error) {
    console.error('Failed to add or update part:', error);
  }
}

async function pendingParts(receiveData) {
  try {
    const partRef = ref(database, `/receiving/${receiveData.id}`);
    await update(partRef, receiveData);
    await updateIndexedDB(receiveData, 'receivingData');
    updateTimestamps('receivingData','receiving');
  } catch (error) {
    console.error('Failed to add or update part:', error);
  }
}

async function updateIndexedDB(data, storeName) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

      openRequest.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains(storeName)) {
          db.createObjectStore(storeName, { keyPath: "id" });
        }
      };

      openRequest.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction(storeName, "readwrite");
        const objectStore = transaction.objectStore(storeName);

        const getRequest = objectStore.get(data.id);

        getRequest.onsuccess = function(event) {
          let itemToUpdate = event.target.result;

          if (itemToUpdate) {
            // Update only the fields provided in the data object
            for (let key in data) {
              if (data.hasOwnProperty(key) && key !== 'id') {
                itemToUpdate[key] = data[key];
              }
            }
          } else {
            // If the item doesn't exist, use the new data
            itemToUpdate = data;
          }

          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function() {
            console.log(`Data patched for ID ${data.id} in store ${storeName}`);
            window.dispatchEvent(new CustomEvent('indexedDBUpdated', { detail: { storeName, data } }));
            resolve();
          };

          updateRequest.onerror = function(error) {
            console.error("Error patching data", error);
            reject(error);
          };
        };

        getRequest.onerror = function(error) {
          console.error("Error fetching data", error);
          reject(error);
        };

        transaction.oncomplete = function() {
          db.close();
        };
      };

      openRequest.onerror = function(error) {
        console.error("Error opening database", error);
        reject(error);
      };
    } catch (error) {
      console.log("Error updating IndexedDB", error);
      reject(error);
    }
  });
}

document.getElementById('generalBeam').addEventListener('input',()=>{
storagePrice()
})

document.getElementById('generalLength').addEventListener('input',()=>{
storagePrice()
})

function storagePrice(){
var length = document.getElementById('generalLength').value
var beam = document.getElementById('generalBeam').value
var tax = document.getElementById('tax').value
var environmental = document.getElementById('environmental').value

if(length <= 20){
  var price = Number(Number(length) * Number(beam)) * 13.35
}else if(length > 20 && length <= 26){
  var price = Number(Number(length) * Number(beam)) * 11.35 
}else if(length > 26 && length <= 32){
  var price = Number(Number(length) * Number(beam)) * 10.15 
}else if(length > 32 && length <= 39){
  var price = Number(Number(length) * Number(beam)) * 9.60 
}else if(length > 39 && length <= 50){
  var price = Number(Number(length) * Number(beam)) * 8.65
}else if(length > 50 && length <= 70){
  var price = Number(Number(length) * Number(beam)) * 8.25
}

var num = Number(Number(price) + (Number(price) * tax) + (Number(price) * environmental)).toFixed(2)
document.getElementById('basePrice').innerHTML = Number(price).toLocaleString('en-US') + " (Base Price)"
document.getElementById('storageTax').innerHTML = "+ " + Number(price * tax).toLocaleString('en-US') + " (Tax)"
document.getElementById('storageEnv').innerHTML = "+ " + Number(price * environmental).toLocaleString('en-US') + " (Env. Fee)"
document.getElementById('storagePrice').innerHTML = '$' + Number(num).toLocaleString('en-US') + "(Total)"

}


const Toast = {
init() {
  this.hideTimeout = null;

  this.el = document.createElement("div");
  this.el.className = "toast";
  document.body.appendChild(this.el);
},

show(message, state) {
  clearTimeout(this.hideTimeout);

  this.el.textContent = message;
  this.el.className = "toast toast--visible";

  if (state) {
    this.el.classList.add(`toast--${state}`);
  }

  this.hideTimeout = setTimeout(() => {
    this.el.classList.remove("toast--visible");
  }, 3000);
}
};

function exitWindow(e){

document.getElementById(e).style.display = 'none'
if(usingSchedule === true && e === 'selectOption'){
getTechSchedule()
}
if(e === 'selectOption'){
document.getElementById('showMap').remove()
}
var selected = document.querySelectorAll('.selectedRow')
selected.forEach( row => row.classList.remove('selectedRow'))

}



/* document.addEventListener('click',function(e){

if(e.target.className.includes('tableButtons')){
  var elements = document.getElementsByClassName('tableButtons')
  for(i=0;i<elements.length;i++){
    elements[i].classList.remove('selected')
  }
  e.target.classList.add('selected')
}
}) */


function showSection(sectionId, sectionName, activeId, table) {
  let buttons = document.querySelectorAll('.selectButton');
  buttons.forEach(button => button.classList.remove('selectedButton'));

  let sectionButton = document.getElementById(activeId);
  if (sectionButton) {
      sectionButton.classList.add('selectedButton');
  }

  let sectionsToHide = document.querySelectorAll('.section');
  sectionsToHide.forEach(section => section.style.display = "none");

  let sectionToShow = document.getElementById(sectionId);
  if (sectionToShow) {
      sectionToShow.style.display = "block";
  }

  document.getElementById('tableName').innerHTML = sectionName;
  document.getElementById('active').innerHTML = 'Work Orders: ' + (Number(document.getElementById(table).rows.length) - 1);
}

/* document.getElementById('display-table-button').addEventListener('click', function() {
  showSection('open', 'General Labor', 'display-table-button', 'display-table');
});

document.getElementById('estimates-table-button').addEventListener('click', function() {
  showSection('estimates', 'Estimates', 'estimates-table-button', 'estimates-table');
});

document.getElementById('winter-display-table-button').addEventListener('click', function() {
  showSection('winterTable', 'Winterizing', 'winter-display-table-button', 'winter-display-table');
});

document.getElementById('spring-display-table-button').addEventListener('click', function() {
  showSection('springTable', 'Spring Commissioning', 'spring-display-table-button', 'spring-display-table');
});

document.getElementById('internal-display-table-button').addEventListener('click', function() {
  showSection('internalTable', 'Internal', 'internal-display-table-button', 'internal-display-table');
});

document.getElementById('mooring-display-table-button').addEventListener('click', function() {
  showSection('mooringTable', 'Moorings', 'mooring-display-table-button', 'mooring-display-table');
});

document.getElementById('hold-table-button').addEventListener('click', function() {
  showSection('hold', 'On Hold', 'hold-table-button', 'hold-table');
});

document.getElementById('billing-display-table-button').addEventListener('click', function() {
  showSection('billingTable', 'Billing', 'billing-display-table-button', 'billing-display-table');
});

document.getElementById('estimates-table-button').addEventListener('click', function() {
  showSection('estimates', 'Estimates', 'estimates-table-button', 'estimates-table');
});



function showGeneral(){
let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('display-table-button').classList.add('selectedButton')
document.getElementById('open').style.display="block"
document.getElementById('estimates').style.display="none"
document.getElementById('billingTable').style.display="none"
document.getElementById('internalTable').style.display="none"

 document.getElementById('mooringTable').style.display="none"
 document.getElementById('hold').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('tableName').innerHTML = "General Labor"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('display-table').rows.length)-1)
}

function showInternal(){
let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('internal-display-table-button').classList.add('selectedButton')
document.getElementById('internalTable').style.display="block"
document.getElementById('estimates').style.display="none"
document.getElementById('billingTable').style.display="none"
document.getElementById('open').style.display="none"

 document.getElementById('mooringTable').style.display="none"
 document.getElementById('hold').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('tableName').innerHTML = "Internal"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('internal-display-table').rows.length)-1)
}

function showWinter(){
let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('winter-display-table-button').classList.add('selectedButton')
document.getElementById('winterTable').style.display="block"
document.getElementById('estimates').style.display="none"
document.getElementById('billingTable').style.display="none"
document.getElementById('internalTable').style.display="none"

 document.getElementById('mooringTable').style.display="none"
 document.getElementById('hold').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('tableName').innerHTML = "Winterizing"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('winter-display-table').rows.length)-1)
}

function showSpring(){
  let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('spring-display-table-button').classList.add('selectedButton')
document.getElementById('springTable').style.display="block"
document.getElementById('billingTable').style.display="none"
document.getElementById('estimates').style.display="none"

document.getElementById('internalTable').style.display="none"
document.getElementById('hold').style.display="none"
 document.getElementById('mooringTable').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('tableName').innerHTML = "Spring Commissioning"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('spring-display-table').rows.length)-1)
}

function showMoorings(){
  let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('mooring-display-table-button').classList.add('selectedButton')
document.getElementById('estimates').style.display="none"
document.getElementById('billingTable').style.display="none"
document.getElementById('mooringTable').style.display="block"

document.getElementById('internalTable').style.display="none"
document.getElementById('hold').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('tableName').innerHTML = "Moorings"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('mooring-display-table').rows.length)-1)
}

function showHide(){
  let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('hold-table-button').classList.add('selectedButton')
document.getElementById('hold').style.display="block"
document.getElementById('estimates').style.display="none"
document.getElementById('billingTable').style.display="none"

document.getElementById('internalTable').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('mooringTable').style.display="none"
document.getElementById('tableName').innerHTML = "On Hold"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('hold-table').rows.length)-1)
}

function showBilling(){
  let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('billing-display-table-button').classList.add('selectedButton')
document.getElementById('billingTable').style.display="block"
document.getElementById('hold').style.display="none"
document.getElementById('estimates').style.display="none"

document.getElementById('internalTable').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('mooringTable').style.display="none"
document.getElementById('tableName').innerHTML = "Billing"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('billing-display-table').rows.length)-1)
}

function showEstimates(){
  let buttons = document.querySelectorAll('.selectButton')
buttons.forEach( button => button.classList.remove('selectedButton'))
document.getElementById('estimates-table-button').classList.add('selectedButton')
document.getElementById('estimates').style.display="block"
document.getElementById('billingTable').style.display="none"
document.getElementById('hold').style.display="none"

document.getElementById('internalTable').style.display="none"
document.getElementById('open').style.display="none"
document.getElementById('winterTable').style.display="none"
document.getElementById('springTable').style.display="none"
document.getElementById('mooringTable').style.display="none"
document.getElementById('tableName').innerHTML = "Estimates"
document.getElementById('active').innerHTML= 'Work Orders: ' + (Number(document.getElementById('estimates-table').rows.length)-1)
} */

function collectKits(e){

if(e.parentElement.innerHTML.includes('build')){
document.getElementById('jobIdPartKits').value = ''
document.getElementById('boatIdPartKits').value = ''
document.getElementById('customerIdPartKits').value =  ''
document.getElementById('customerPartKits').value =  ''
document.getElementById('boatPartKits').value =  ''
document.getElementById('shortDescPartKits').value =  ''
document.getElementById('categoryPartKits').value =  ''
document.getElementById('opTypePartKits').value =  ''
document.getElementById('woPartKits').value =  ''
}

var container = document.getElementById('kitBox')
document.getElementById('partsKits').style.display = "flex"
while(container.hasChildNodes()){
container.removeChild(container.firstChild)}

var jobId = document.getElementById('jobIdPartKits').value
var boatId = document.getElementById('boatIdPartKits').value

data = {}
if(boatId === ''){
data.boatId = document.getElementById('currentBoatId').value
document.getElementById('jobIdPartKits').value = document.getElementById('currentJobId').value
document.getElementById('boatIdPartKits').value = document.getElementById('currentBoatId').value
document.getElementById('customerIdPartKits').value = document.getElementById('currentCustomerId').value
document.getElementById('customerPartKits').value = document.getElementById('currentName').value
document.getElementById('boatPartKits').value = document.getElementById('currentBoat').value
document.getElementById('shortDescPartKits').value = document.getElementById('currentShortDesc').value
document.getElementById('categoryPartKits').value = document.getElementById('currentCategory').value
document.getElementById('opTypePartKits').value = document.getElementById('currentOpType').value
document.getElementById('woPartKits').value = document.getElementById('currentWorkOrder').value

}else{data.boatId = boatId}
data.kit = ''

google.script.run.withSuccessHandler(displayKits).getPartsKits(data)
}



function displayKits(data){
globalKit = data[0]
if(data != null){
var data = data[0]
var kits = []
var buttons = []
var container = document.getElementById('kitBox')


for(i=0;i<data.length;i++){
kits.push(data[i][6])
}
var unique = [...new Set(kits)]

for(j=0;j<unique.length;j++){
buttons[j] = document.createElement('button')
buttons[j].innerHTML = unique[j]
buttons[j].value = unique[j]
buttons[j].addEventListener("click",(e) => {
  var kitName = e.target.value
  document.getElementById("currentKit").value = kitName
  partsKitTable(globalKit, kitName)
  })
container.appendChild(buttons[j])
}
}
}

function showKits(data, kit){

var data = {}
var boatId = document.getElementById('boatIdPartKits').value
data.boatId = boatId
data.kit = kit
google.script.run.withSuccessHandler(partsKitTable).getPartsKits(data)
}

function closePartsKit(){
document.getElementById('kitSelect').style.display="flex"
document.getElementById('kitTable').style.display="none"
document.getElementById('currentKit').value=""
}


function partsKitTable(data, currentKit){

document.getElementById('kitSelect').style.display="none"
document.getElementById('kitTable').style.display="flex"

var Parent = document.getElementById('part-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

data.forEach(r => {

  var tbody = document.getElementById("part-body")
      var row = document.createElement("tr")
      if(r[6] === currentKit || currentKit === ''){
        row.className = "showRow"
      }else{row.className = "hideRow"}
      var id = document.createElement("td")
      id.className = "part-data"
      id.textContent = r[0]
      var part = document.createElement("td")
      part.className = "part-data"
      part.setAttribute("contenteditable", "true");
      part.textContent = r[1]
      var description = document.createElement("td")
      description.className = "part-data"
      description.setAttribute("contenteditable", "true");
      description.textContent = r[2]
      var qty = document.createElement('td')
      qty.className = 'part-data'
      qty.setAttribute('contenteditable','true')
      qty.textContent = r[5]
      var vendor = document.createElement("td")
      vendor.className = "part-data noPrint"
      vendor.setAttribute("contenteditable", "true");
      vendor.textContent = r[3]
      var kit = document.createElement('td')
      kit.className="part-data"
      kit.setAttribute('contenteditable','true')
      kit.textContent = r[6]
      var manufacturer = document.createElement('td')
      manufacturer.className = 'part-data noPrint'
      manufacturer.textContent = r[4]
      var omit = document.createElement('td')
      omit.className = 'noPrint'
      omit.innerHTML = "<button onclick='omitRow(this)' class='noPrint omitButton'><i class='material-icons'>clear</i></button>"
      var del = document.createElement('td')
      del.className = "noPrint"
      del.innerHTML = "<button onclick='hideRow(this)' class='noPrint deleteButton'><i class='material-icons'>delete</i></button>"

      row.appendChild(id).style.display = "none"
      row.appendChild(part)
      row.appendChild(description)
      row.appendChild(qty)
      row.appendChild(vendor)
      row.appendChild(kit)
      row.appendChild(manufacturer).style.display="none"
      row.appendChild(omit)
      row.appendChild(del)
      tbody.appendChild(row)   

  })
}

function printOnlyTable() {
  // Clone the table so the original is unaffected
  var cloneTable = document.getElementById('kitTable').cloneNode(true);

  // Get all the noPrint elements within the cloned table
  var noPrintElements = cloneTable.querySelectorAll('.noPrint');

  // Remove each of the noPrint elements from the cloned table
  noPrintElements.forEach(function(element) {
      element.parentNode.removeChild(element);
  });

  // Open a new window and print the modified cloned table
  var printWindow = window.open('', '', 'width=600,height=600');
  printWindow.document.write('<html><head><title>Print</title></head><body>');

  // Inline styles for the table
  printWindow.document.write('<style>');
  printWindow.document.write('table { border-collapse: collapse; }'); // Ensures that cell borders are combined
  printWindow.document.write('th, td { border: 1px solid black; padding: 8px; }'); // Borders and padding for cells
  printWindow.document.write('th { background-color: grey; }'); // Grey background for headers
  printWindow.document.write('tr:nth-child(odd) { background-color: #f2f2f2; }'); // Lighter grey for every other row
  printWindow.document.write('</style>');

  printWindow.document.write(cloneTable.outerHTML);
  printWindow.document.write('</body></html>');
  printWindow.document.close();
  printWindow.print();
}



function hideRow(e){
var row = e.parentElement.parentElement
row.className = "removeRow"
updatePartsKit()
}

function omitRow(e){
var row = e.parentElement.parentElement
row.className = "hideRow"
}


function addTo(){

var tech = prompt("Enter Tech Name")
var collection = []
if(tech != null){

var table = document.getElementById('part-table')
var tbody = document.getElementById('part-body')
for(j=0;j<table.rows.length-1;j++){
var data = []
var tr = tbody.getElementsByTagName("tr")[j]
if(!tr.classList.contains('hideRow') && !tr.classList.contains('removeRow')){
      var cells = tr.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);    
      }

var id = generateId()

var tech = tech
var wo = document.getElementById('woPartKits').value
var description = document.getElementById('descriptionPartKits').value
var category = document.getElementById('categoryPartKits').value
var opType = document.getElementById('opTypePartKits').value
var jobId = document.getElementById('jobIdPartKits').value
var customerId = document.getElementById('customerIdPartKits').value
var boatId = document.getElementById('boatIdPartKits').value
var customer = document.getElementById('customerPartKits').value
var boat = document.getElementById('boatPartKits').value
var shortDesc = document.getElementById('shortDescPartKits').value
var partNumber = data[1] + " (" + data[2] + ")"
var vendor = data[4]
var manufacturer = data[6]
var qty = data[3]
var partId = data[0]
var date = new Date().toLocaleDateString('en-us', {year:"numeric", month:"2-digit", day:"2-digit",timeZone: 'UTC'}) 
var year = date.split('/')[2]
var month = date.split('/')[0]
var day = date.split('/')[1]
var date = year + "-" + month + "-" + day
date.toString()

if(data[5] === document.getElementById('currentKit').value && !tr.classList.contains('removeRow') && !tr.classList.contains('hideRow')){
collection.push([id,tech,customer,boat,wo,partNumber,qty,date,category,opType,jobId,customerId,boatId,shortDesc])
}
}
}

google.script.run.partKitAdd(collection)
}

document.getElementById('jobIdPartKits').value = ''
document.getElementById('boatIdPartKits').value = ''
document.getElementById('customerIdPartKits').value =  ''
document.getElementById('customerPartKits').value =  ''
document.getElementById('boatPartKits').value =  ''
document.getElementById('shortDescPartKits').value =  ''
document.getElementById('categoryPartKits').value =  ''
document.getElementById('opTypePartKits').value =  ''
document.getElementById('woPartKits').value =  ''
}

document.getElementById('jobExit').addEventListener('click',()=>{
  document.getElementById('job').style.display="none"
  const opCode = document.getElementById("opCode").value
  document.getElementById(opCode).classList.remove('hidden')
})


async function addJob(standard,currentWo){

let newData = []
var data = []

var storageCheck = document.getElementById('storageCheck').checked
var storagePrice = document.getElementById('storagePrice').innerHTML
var price = parseFloat(storagePrice.replace(/[$,]/g, ''));
var length = document.getElementById('generalLength').value
var beam = document.getElementById('generalBeam').value
var mooringWeight = document.getElementById('generalMooringWeight').value
var mooring = document.getElementById('generalMooring').value
var combo = document.getElementById('generalCombo').value
var customerId = document.getElementById('customerId').value;
var boat = document.getElementById('boat').value;
var boatId = document.getElementById('boatId').value;
var engineCount = document.getElementById('generalEngineCount').value;
var batteryCount = document.getElementById('generalBatteryCount').value;
var overallCategory = document.getElementById('overallCategory').value
var generalWorkOrder = document.getElementById('generalWorkOrder').value;
var winterWorkOrder = document.getElementById('winterWorkOrder').value;
var springWorkOrder = document.getElementById('springWorkOrder').value;
var email = document.getElementById('email').value;
var name = document.getElementById('name').value;
if(name.includes(',')){
var firstName = name.split(', ')[1]
var lastName = name.split(', ')[0]
}else{
var firstName = ''
var lastName = name
}

if(overallCategory !== ''){
var category = overallCategory
}else{var category = document.getElementById('category').value}

if(currentWo){

var workOrder = currentWo

}else{

if(category === "Winterizing"){

if(document.getElementById('winterWorkOrder').value === ""){
toastMessage('No Winter Work Order Number',"red")
return
}
var workOrder = document.getElementById('winterWorkOrder').value
}

if(category === "Spring Commissioning"){

if(document.getElementById('springWorkOrder').value === ""){
toastMessage('No Spring Work Order Number',"red")
return
}
var workOrder = document.getElementById('springWorkOrder').value
}

if(category !== "Winterizing" && category !== "Spring Commissioning"){

if(document.getElementById('generalWorkOrder').value === ""){
toastMessage('No Work Order Number',"red")
return
}
var workOrder = document.getElementById('generalWorkOrder').value
}


if(generalWorkOrder === "" && winterWorkOrder === "" && springWorkOrder === ""){
toastMessage('No Work Order Number',"red")
return
}

}

if(customerId === ""){
toastMessage('No Customer Selected',"red")
return
}

if(boatId === "" && !document.getElementById('description').value.toLowerCase().includes('moor')){
toastMessage('No Boat Selected',"red")
return 
}

if(standard === "bottom painting"){
  var opCode = "SP19"
  var opWeight = "1"
  var category = "Spring Commissioning"
  var opType = "Bottom Paint"
  var dmShortDesc = "BOTTOM PAINT, STORAGE"
  var description = 'BOTTOM PAINT, STORAGE : Paint boat with one (1)  coat of anti-fouling paint. Included with winter storage. (No Additional Charge)' + ' (Paint Type: ' + document.getElementById('generalBottomPaint').value + ' ' + document.getElementById('generalBottomPaintColor').value + ') [' +  Number(((length * beam) * .85)/400).toFixed(1) + ' gallons per coat]'

}else if(standard === "hauling"){
  var opCode = "WS01"
  var opWeight = "0"
  var category = "Winterizing"
  var opType = "Hauling"
  var dmShortDesc = "HAUL BOAT, STORAGE"
  var description = "HAUL BOAT, STORAGE : Haul boat and block for storage. Check for scratches, loose or missing paint, and any other damage.  Included with winter storage (No Additional Charge)"
}else if(standard === "launching"){
  var opCode = "SP37"
  var opWeight = "0"
  var category = "Spring Commissioning"
  var opType = "Hauling"
  var dmShortDesc = "LAUNCH BOAT, STORAGE"
  var description = "LAUNCH BOAT, STORAGE : Launch boat. .  Check for leaks in all readily accessible areas below the waterline.  Included with winter storage. (No Additional Charge)"
}else if(standard === "pressure washing"){
  var opCode = "WS02"
  var opWeight = "0"
  var category = "Winterizing"
  var opType = "Cleaning"
  var dmShortDesc = "PRESSURE WASH, STORAGE"
  var description = "PRESSURE WASH, STORAGE : Pressure wash bottom.  Use cleaner to remove yellowing at waterline. Included with winter storage (No Additional Charge)"
}else{
  var opCode = document.getElementById('opCode').value
  var opWeight = document.getElementById('opWeight').value
  var opType = document.getElementById('opType').value
  var description = document.getElementById('description').value
  var dmShortDesc = document.getElementById('dmShortDesc').value

}

if(document.getElementById('date').value == ''){
var date = new Date()
var date = new Date().toLocaleDateString('en-us', {year:"numeric", month:"2-digit", day:"2-digit",timeZone: 'UTC'}) 
var year = date.split('/')[2]
var month = date.split('/')[0]
var day = date.split('/')[1]
var date = year + "-" + month + "-" + day
date.toString()
}else{var date = document.getElementById('date').value;}

var tech = document.getElementById('tech').value;
var status = document.getElementById('status').value;
var hours = ''
var charge = document.getElementById('estimatedCharge').value
var flatPerFoot = document.getElementById('flatPerFoot').value
var flatLabor = document.getElementById('flatLabor').value
var flatMethod = document.getElementById('flatMethod').value
var notes = document.getElementById('note').value

if(document.getElementById('begin').value == ''){

var begin = date
}else{
var begin = new Date(document.getElementById('begin').value).toLocaleDateString('en-us', {year:"numeric", month:"2-digit", day:"2-digit",timeZone: 'UTC'}) 
var beginYear = begin.split('/')[2]
var beginMonth = begin.split('/')[0]
var beginDay = begin.split('/')[1]
var begin = beginYear + "-" + beginMonth + "-" + beginDay
begin.toString()
}
var launch = document.getElementById('launch').value
var haul = document.getElementById('haul').value

if(category === "Winterizing"){
var shortDesc = "Winterizing"
}else if(category === "Spring Commissioning"){
var shortDesc = "Spring Commissioning"
}else{var shortDesc = document.getElementById('shortDesc').value}

if(status.toString() === ''){
var status = "On Work Schedule"
}else{var status = status}

if(description.toLowerCase().includes('winterize engine') || description.toLowerCase().includes('commission engine')){
var kit = "Needs Kit"
}else{var kit = ''}

if( description.toLowerCase().includes('engine') && Number(flatLabor) > 0 && Number(engineCount) >= 1){
var flatLabor = Number(flatLabor) * engineCount
var description = description + ' ENGINE COUNT (' + engineCount + '), TOTAL FLAT RATE = $' + Number(flatLabor).toFixed(2)
}

if( description.toLowerCase().includes('engine') && Number(flatLabor) > 0 && (Number(engineCount) == 0 || !engineCount) ){
var description = description + " ENGINE COUNT IS UNKNOWN.  NUMBER OF ENGINES REQUIRED FOR FLAT RATE CALCULATION."
}

if( ( description.toLowerCase().includes('battery') || description.toLowerCase().includes('batteries') ) && Number(flatLabor) > 0 && Number(batteryCount) >= 1){
var flatLabor = Number(flatLabor) * batteryCount
var description = description + ' [ BATTERY COUNT (' + batteryCount + ') ]'
}

if((description.toLowerCase().includes('battery') || description.toLowerCase().includes('batteries') && (Number(batteryCount) == 0 || !batteryCount) )){
var description = description + " [ BATTERY COUNT IS UNKNOWN.  NUMBER OF BATTERIES REQUIRED FOR FLAT RATE CALCULATION. ]"
}

try {
let customerDM = await getCustomerRecord(customerId);
var jobId = generateId()
let start
if(category === "Spring Commissioning" && description.toLowerCase().includes('bottom paint')){
year = new Date().getFullYear();
start = year + "-02-01"
}else if(category === "Spring Commissioning" && !description.toLowerCase().includes('bottom paint')){
start = launch
}else if(category === 'Winterizing'){
start = haul
}else{start = date}

var serviceLog = {
  "Billing User": "",
  "Boat_ID": boatId,
  "Boat_Name": boat,
  "Category": category,
  "Charge": charge,
  "Customer_ID": customerId,
  "Customer_Name": name,
  "Date Billed": "",
  "Date_Hauled": "",
  "Date_Launched": "",
  "Description": description,
  "Email": email,
  "Estimated_Hours": hours,
  "Event_Added": "",
  "Event_Time": "",
  "Haul_Date": haul,
  "Hold_Days": "",
  "Job_ID": jobId,
  "Labor Billed": "",
  "Launch_Date": launch,
  "Location_Number": "",
  "Notes": notes,
  "OpCode": opCode,
  "Op_Type": opType,
  "Operation_Dependency": opWeight,
  "Parts Billed": "",
  "Parts Ordered": "",
  "Parts_Kit_Status": "",
  "Priority": "",
  "Ready": "",
  "Request_Date": date,
  "Short_Description": shortDesc,
  "Short_Description_DM": dmShortDesc,
  "Start_Date": start,
  "Status": status,
  "Status_Of_Boat": "",
  "Techs": tech,
  "Total_Hours": 0,
  "Transfer_to_Billing": "",
  "Water": "",
  "Work_Order": workOrder,
  "Work_Order_ID": "",
  "Yard": ""
};

let type
if(category.toLowerCase() === 'internal'){
type = 'I'
}else{type = 'R'}

if(serviceLog.Work_Order === ""){
toastMessage("No Selected Work Order for This Operation",'red')
return
}

document.getElementById('job').style.display='none'

const serviceLogForDM = {
  ...serviceLog,
  "type": type,
  "DMCustomer": customerDM.DM_Customer,
  "clerkId": clerkIdInfo[auth.currentUser.displayName]
};

const serviceLogForIndexed = {
  ...serviceLog,
  "id": serviceLog.Job_ID
};

await addJobFirebase(serviceLog);
await updateOpenDataDB(serviceLogForIndexed);
toastMessage('Work Order Added','green');
mainTable();

const timeout = new Promise((resolve, reject) => {
  setTimeout(() => {
    reject(() => {
      // Perform specific actions or execute additional logic
      console.log('Timeout occurred');
      toastMessage('Please check if the work order is open in Dockmaster', 'orange');
    });
  }, 2000); // Adjust the timeout duration as needed (in milliseconds)
});

await Promise.race([updateWorkOrderDM(serviceLogForDM), timeout]);

} catch (error) {
console.error("An error occurred in the addJob function: ", error);
// Handle any errors, such as showing a message to the user
}

let jobBox = document.createElement('fieldset')
jobBox.setAttribute('data-opCode',opCode)
jobBox.setAttribute('data-workOrder', workOrder)

let boxTitle = document.createElement('legend')
boxTitle.className = 'small-legend'
boxTitle.innerHTML = opCode.toString()

let jobDesc = document.createElement('p')
jobDesc.innerHTML = description
jobDesc.setAttribute('data-opCode', opCode)
jobDesc.setAttribute('data-workOrder', workOrder)

jobBox.appendChild(boxTitle)
jobBox.appendChild(jobDesc)


document.getElementById('opCode').value = ''
document.getElementById("opType").value=""
document.getElementById("description").value=""
document.getElementById("date").value=""
document.getElementById("note").value=""
document.getElementById("tech").value=""
document.getElementById("status").value=""
document.getElementById("begin").value=""


if(!document.getElementById(workOrder)){

let field = document.createElement('fieldset')
field.id = workOrder.toString()
let legend = document.createElement('legend')
if(category === "Winterizing"){
var woType = "(Winterizing)"
}
if(category === "Spring Commissioning"){
var woType = "(Spring Commissioning)"
}
if(woType){
legend.innerHTML = workOrder.toString() + " " + woType
}else{legend.innerHTML = workOrder.toString()}

field.appendChild(legend)
field.appendChild(jobBox)

document.getElementById('activeJobs').appendChild(field)

}else{document.getElementById(workOrder).appendChild(jobBox)}
}

function deleteJob(e){
if(e.innerHTML  === "Delete Job"){
  var job = document.getElementById(e.parentElement.id)
  document.getElementById(e.parentElement.parentElement.style.display='none')
}else{var job = document.getElementById(e.parentElement.getAttribute('data-job'))}
job.classList.remove('active')
job.classList.add('inactive')
const number = job.id.at(-1)

document.getElementById('jobExit' + number).classList.remove('hidden')
document.getElementById('jobMinimize' + number).classList.add('hidden')
document.getElementById('addJobButton' + number).classList.remove('hidden')
document.getElementById('deleteJobButton' + number).classList.add('hidden')
const opCode = document.getElementById("opCode" + number).value
document.getElementById(opCode).classList.remove('hidden')
document.getElementById('opCode' + number).value=''
document.getElementById('category' + number).value=''
document.getElementById('opType' + number).value=''
document.getElementById('date' + number).value=''
document.getElementById('tech' + number).value=''
document.getElementById('begin' + number).value=''
document.getElementById('status' + number).value=''
document.getElementById('button' + number).remove()

}

document.getElementById('selectOpCode').addEventListener('click',()=>{

document.getElementById('opCodeTable').style.display="flex"
document.getElementById('codeSearch').value = ''
document.getElementById('codeSearch').focus()
generateOpTable();
})


function updateOpenDataDB(serviceLog) {
  // Return a new promise
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onerror = function(event) {
      // Reject the promise if we can't open the database
      reject("IndexedDB database error: ", event.target.error);
    };

    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const tx = db.transaction("jobData", "readwrite");
      const jobStore = tx.objectStore("jobData");


      // Attempt to store the object in the IndexedDB
      const request = jobStore.put(serviceLog);

      request.onsuccess = function() {
        console.log("Data saved successfully to IndexedDB");
        // Resolve the promise upon successful addition to the database
        resolve("Data saved successfully to IndexedDB");
      };

      request.onerror = function(event) {
        // Reject the promise if there's an error saving the data
        reject("IndexedDB data save error: ", event.target.error);
      };

      // Handle transaction errors
      tx.oncomplete = function() {
        db.close();
      };

      tx.onerror = function(event) {
        reject("Transaction error: ", event.target.error);
      };
    };
  });
}


function generateOpTable(){

    // Open the IndexedDB database
    const request = indexedDB.open('openDatabase', currentIndexedVersion);

    request.onerror = (event) => {
        console.error('Database error:', event.target.errorCode);
    };

    request.onsuccess = (event) => {
        const db = event.target.result;
        const transaction = db.transaction(['opCodeData'], 'readonly');
        const store = transaction.objectStore('opCodeData');

        // Get all the op codes from IndexedDB
        const getAllRequest = store.getAll();

        getAllRequest.onerror = (event) => {
            console.error('Error fetching opCode data:', event.target.errorCode);
        };

        getAllRequest.onsuccess = (event) => {
            const dataArray = event.target.result;
            processDataArray(dataArray); // Process and display the data
        };
    };
}

function processDataArray(dataArray){
var engineCount = document.getElementById('generalEngineCount').value
var batteryCount = document.getElementById('generalBatteryCount').value

var boatId = document.getElementById('boatId').value

if(batteryCount === ''){
var batteryInfo = " BATTERY COUNT IS UNKNOWN"
}else{var batteryInfo = " BATTERY COUNT (" + batteryCount + ")"}

var Parent = document.getElementById('op-code-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

var length = Number(document.getElementById('generalLength').value)
var beam = Number(document.getElementById('generalBeam').value)

Object.values(dataArray).forEach(function(r){

  if(r.id === 'lastUpdated'){
    return
  }

 if( ( length >= Number(r.Flat_Rate_Length.split(',')[0]) && Number(length <= r.Flat_Rate_Length.split(',')[1])) || r.Flat_Rate_Length === '' || r.Flat_Rate_Length.includes('Engine') || r.Flat_Rate_Length.includes('Mast') || r.Flat_Rate_Length.includes('Generator') || r.Flat_Rate_Length.includes('Battery') || r.Flat_Rate_Length.includes('Storage') || r.Flat_Rate_Length.includes('Shrinkwrap') ){
  var tbody = document.getElementById("op-code-body")
      var row = document.createElement("tr")
      if(currentOpCodes.includes(r.OpCode)){
        row.classList.add('hidden');
      }
      row.id = r.OpCode
      row.addEventListener('click',(e)=>{
       
        var mainCategory = document.getElementById('overallCategory').value
        var data = e.target.parentElement.childNodes
        var info = []
        data.forEach(op => {info.push(op.innerHTML)})
        const searchHide = document.querySelectorAll('.searchHide')
        searchHide.forEach(element => element.classList.remove('searchHide'))
        var storage = document.getElementById('storageStatus').value
       
        document.getElementById('job').style.display="flex"
        document.getElementById('opCodeTable').style.display="none"
        document.getElementById('opCode').value = info[0]

        if(info[5].includes(':')){
           document.getElementById('dmShortDesc').value = info[5].split(':')[0]
        }else{
          document.getElementById('dmShortDesc').value = ''
          document.getElementById('dmShortDesc').focus()
          toastMessage('Add Short Description','red')
          }

        if(document.getElementById('generalMooring').value === '' && info[5].toString().toLowerCase().includes('mooring')){
          var mooring = prompt("Enter Mooring Number (XX-##)").toUpperCase()
            if(mooring !== null) {
                document.getElementById('generalMooring').value = mooring.toUpperCase();
                google.script.run.updateMooringNumber(boatId, mooring.toUpperCase());
            }
        } else {
            var mooring = document.getElementById('generalMooring').value;
        }

        if(document.getElementById('generalMooringWeight').value === "" && info[5].toString().toLowerCase().includes('mooring')){
          var mooringWeight = prompt('Enter Mooring Weight')
              if(mooringWeight !== null) {
                document.getElementById('generalMooringWeight').value = mooringWeight;
                google.script.run.updateMooringWeight(boatId, mooringWeight);
            }
        }else{var mooringWeight = document.getElementById('generalMooringWeight').value}
        

        if(mainCategory === "Spring Commissioning"){
          document.getElementById('category').value = "Spring Commissioning"
        }else if(mainCategory === "Winterizing"){
          document.getElementById('category').value = "Winterizing"
        }else if(storage === "true" && info[1] !== "Winterizing" && info[1] !== "Spring Commissioning" ){
          toastMessage("Category is 'General'. Select correct category to add to Winter or Spring work order!")
          document.getElementById('category').value = info[1]
        }else{document.getElementById('category').value = info[1]}

        document.getElementById('opType').value = info[2]
        document.getElementById('opWeight').value = info[3]
        document.getElementById('estimatedCharge').value = info[8]
        document.getElementById('flatMethod').value = info[9]
        document.getElementById('flatPerFoot').value = info[6]
        document.getElementById('flatLabor').value = info[7]

      if(info[5].toLowerCase().includes('bottom paint') && info[5].toLowerCase().includes('storage')){
        document.getElementById('description').value = info[5] + ' (Paint Type: ' + document.getElementById('generalBottomPaint').value + ' ' + document.getElementById('generalBottomPaintColor').value + ') [' +  Number(((length * beam) * .85)/400).toFixed(1) + ' gallons per coat]'
      }else if( info[5].toLowerCase().includes('bottom paint') && !info[5].toLowerCase().includes('storage') ){
var input = prompt('How Many Coats?');
var coats = parseInt(input)

while (isNaN(coats)) {
input = prompt('Please enter a number.');
coats = parseInt(input)
}
        const numbers = {
            1: 'one',
            2: 'two',
            3: 'three',
            4: 'four',
            5: 'five',
            6: 'six',
            7: 'seven',
            8: 'eight',
            9: 'nine',
            10: 'ten'
          };


        var coatsNumber = coats
        var coatsString = numbers[coats]

        document.getElementById('description').value = info[5] + 'Paint boat with ' + coatsString + ' (' + coatsNumber.toString() + ') coat of anti-fouling paint.' + ' (Paint Type: ' + document.getElementById('generalBottomPaint').value + ' ' + document.getElementById('generalBottomPaintColor').value + ') [' +  Number(((length * beam) * .85)/400).toFixed(1) + ' gallons per coat]'
}else if(info[5].includes('(6800)') || info[5].toLowerCase().includes('replace anode')){
    document.getElementById('description').value = info[5] + '\nAnode List: ' + '\n' + document.getElementById('generalAnodeList').value
        }else if(info[0] === 'DISCNT'){
        var discountInfo = prompt("Enter discount percentage or amount and what to apply it to.")
        document.getElementById('description').value = info[5] = "Discount: " + discountInfo
      }else if(info[5].toLowerCase().includes('remove batter') || info[5].toLowerCase().includes('install batter')){
        document.getElementById('description').value = info[5] + batteryInfo
      }else if(info[5].toLowerCase().includes('general rigging labor') || info[5].toLowerCase().includes('general mechanical labor') || info[5].toLowerCase().includes('general boat maintenance') ){
        document.getElementById('description').value = ''
      }else if( info[5].toLowerCase().includes('mooring') && !info[5].toLowerCase().includes('haul mooring') && !info[5].toLowerCase().includes("mooring placement")  ){
      if(mooring.includes(',')){
          updateDescription(info[5],1)
      }else{document.getElementById('description').value = info + " (MOORING: " + mooring + ")";}
      }else if( info[5].toLowerCase().includes('haul mooring') ){
        if(mooring.includes(',')){
           updateDescription(info[5],2)   
        }else{document.getElementById('description').value = "HAUL MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " @ $0.70 PER LB.";}
      }else if( info[5].toLowerCase().includes('mooring placement') ){
        document.getElementById('description').value = "PLACE MOORING " + mooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " LBS @ $0.70 PER LB."
      }else{document.getElementById('description').value = info[5]}
        e.target.parentElement.classList.add('hidden')
        var elements = document.getElementsByClassName('jobTop')
        for(let element of elements){
          element.scrollIntoView()
        }

      })
      
      var opCode = document.createElement("td")
      opCode.className ='form-data'
      opCode.textContent = r.OpCode
      var category = document.createElement("td")
      category.className="form-data"
      category.textContent = r.Op_Category
      var opType = document.createElement("td")
      opType.className="form-data"
      opType.textContent = r.Op_Type
      var dependency = document.createElement("td")
      dependency.className="form-data"
      dependency.textContent = r.Dependency
      var opLength = document.createElement('td')
      opLength.className = "form-data"
      opLength.textContent = r.Flat_Rate_Length
      var hours = document.createElement("td")
      hours.className="form-data"
      hours.textContent = r.Hours
      var description = document.createElement("td")
      description.className="form-data"
      description.textContent = r.Description
      var perFoot = document.createElement('td')
      perFoot.className = 'form-data'
      perFoot.textContent = r.Flat_Rate_Per_Foot
      var flatLabor = document.createElement('td')
      flatLabor.className = "form-data"
      flatLabor.textContent = r.Flat_Rate_Labor
      var flatMethod = document.createElement('td')
      flatMethod.className = "form-data"
      flatMethod.textContent = r.FlatRatePerFootMethod
      var estimate = document.createElement('td')
      estimate.className = "form-data"
 
      if(opLength.innerHTML.includes(',')){
        estimate.textContent = Number(length) * Number(perFoot.innerHTML)
      }else if(opLength.innerHTML.includes('Engine')){
        estimate.textContent = Number(engineCount) * Number(flatLabor.innerHTML)
      }else if(opLength.innerHTML.includes('Generator')){
        estimate.textContent = Number(flatLabor.innerHTML)
      }else if(opLength.innerHTML.includes('Mast')){
        estimate.textContent = Number(Number(length) * 1.3) * Number(perFoot.innerHTML)
      }else if(opLength.innerHTML.includes('Battery')){
        estimate.textContent = Number(batteryCount) * Number(flatLabor.innerHTML)
      }else if(opLength.innerHTML === '' && flatLabor.innerHTML === '' && hours.innerHTML !== ''){
        estimate.textContent = Number(hours.innerHTML) * Number(document.getElementById('currentLaborRate').value)
      }else{estimate.textContent = ''}
     
      row.appendChild(opCode)
      row.appendChild(category).style.display="none"
      row.appendChild(opType).style.display="none"
      row.appendChild(dependency).style.display="none"
      row.appendChild(hours).style.display="none"
      row.appendChild(description)
      row.appendChild(perFoot).style.display="none"
      row.appendChild(flatLabor).style.display="none"
      row.appendChild(estimate).style.display="none"
      row.appendChild(flatMethod).style.display="none"
      tbody.appendChild(row)
 }
}) 

} 

async function updateDescription(info,select) {

var mooring = document.getElementById('generalMooring').value;
if(document.getElementById('generalMooringWeight').value !== ''){
    var mooringWeight = document.getElementById('generalMooringWeight').value + ' LBS'
}else{var mooringWeight = ''}


   if(select === 1){
    var selectedMooring = await showModalWithMooringOptions(mooring);
    document.getElementById('description').value = info + " (MOORING: " + selectedMooring + ")";
  }else if(select === 2){
    var selectedMooring = await showModalWithMooringOptions(mooring);
    document.getElementById('description').value = "HAUL MOORING " + selectedMooring + " " + new Date().getFullYear() + " SEASON. " + mooringWeight + " @ $0.70 PER LB.";
  }


}


function getOpenWorkOrders(boatId,workOrder){
const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

openRequest.onupgradeneeded = function(event) {
  const db = event.target.result;
  db.createObjectStore("openData", { keyPath: "id" });
};

openRequest.onsuccess = function(event) {
  const db = event.target.result;

  const tableTx = db.transaction("openData", "readonly");
  const tableStore = tableTx.objectStore("openData");
  const tableRequest = tableStore.getAll();

  tableRequest.onsuccess = function(event) {
    const data = event.target.result;
const dataArray = data
.map((wo) => {
  if (typeof wo === "object") {
    return Object.values(wo); // Convert object to array
  }
  return wo;
})

var Parent = document.getElementById("open-wo-body")
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}


const seen = new Set();  // To hold seen values at index 6
const filterUnique = array => {
if (seen.has(array[6]) || array[6] === workOrder) {
  return false; // Skip this array, as we've seen this value at index 6 before
} else {
  seen.add(array[6]); // Remember this value at index 6
  return true;
}
};

const testboat = dataArray.filter(array => array[4] === boatId).filter(filterUnique)

const woListTable = document.getElementById('open-wo-body')

testboat.forEach(r => {
if(r[12] == 'Closed'){
  return
}
var row = document.createElement('tr')
row.addEventListener('click',(e) =>{
moveCheckedOpsToCurrent(e)
})
var jobId = document.createElement('td')
jobId.textContent = r[0]
jobId.className = "form-data"
var customer = document.createElement('td')
customer.textContent = r[1]
customer.className = "form-data"
var customerId = document.createElement('td')
customerId.textContent = r[2]
customerId.className = "form-data"
var boat = document.createElement('td')
boat.textContent = r[3]
boat.className = "form-data"
var boatID = document.createElement('td')
boatID.textContent = r[4]
boatID.className = "form-data"
var wo = document.createElement('td')
wo.textContent = r[6]
wo.className = "form-data hide-mobile"
var category = document.createElement('td')
category.textContent = r[7]
var shortDesc = document.createElement('td')
shortDesc.textContent = r[17]
shortDesc.className = "form-data"
var date = document.createElement('td')
date.textContent = r[10].toString()
date.className = "form-data hide-mobile"

row.appendChild(jobId).style.display="none"
row.appendChild(customer)
row.appendChild(customerId).style.display="none"
row.appendChild(boat)
row.appendChild(boatID).style.display="none"
row.appendChild(wo)
row.appendChild(category).style.display="none"
row.appendChild(shortDesc)
row.appendChild(date)
woListTable.appendChild(row)
})
}
}
document.getElementById('openWOTable').style.display="flex"
}

document.getElementById('getDMCustomerId').addEventListener('click', getDMCustomerId );

document.getElementById('getDMBoatId').addEventListener('click', getDMBoatId );


async function getDMCustomerId() {
  let dataLog = {
    Last_Name: document.getElementById('customerLastName').value,
    First_Name: document.getElementById('customerFirstName').value,
    Street: document.getElementById('customerStreet').value,
    City: document.getElementById('customerCity').value,
    State: document.getElementById('customerState').value,
    Zip: document.getElementById('customerZip').value,
    Phone: document.getElementById('customerPhone').value,
    Email: document.getElementById('customerEmail').value,
  };


  const updateDMCustomer = httpsCallable(functions, 'updateDMCustomer');

  try {
    const result = await updateDMCustomer(dataLog);
    console.log('Customer ID:', result.data.CustomerID);
    document.getElementById('customerDM').value = result.data.CustomerID;
   
  } catch (error) {
    console.error('Error updating customer:', error);
   
  }
}

async function getDMBoatId() {
  let dataLog = {};

dataLog.DM_Customer = document.getElementById('customerDM').value
dataLog.Boat = document.getElementById('customerBoatName').value
dataLog.Length = document.getElementById('customerBoatLength').value
dataLog.Beam = document.getElementById('customerBoatBeam').value
dataLog.Draft = document.getElementById('customerBoatDraft').value
dataLog.Combo = document.getElementById('customerComboKey').value
dataLog.Mooring = document.getElementById('customerMooringNumber').value
dataLog.bottomPaint = document.getElementById('bottomPaintTypeSpring').value + " (" + document.getElementById('bottomPaintColorSpring').value + ")"



  const updateDMBoat = httpsCallable(functions, 'updateDMBoat');

  try {
    const result = await updateDMBoat(dataLog);
    console.log('Boat ID:', result.data.BoatID);
    document.getElementById('boatDM').value = result.data.BoatID;
   
  } catch (error) {
    console.error('Error updating customer:', error);
   
  }
}

function getWorkOrderNumber(type){

if(document.getElementById('customerId').value === ''){
toastMessage("No Customer Selected",'red')
return
};

if(document.getElementById('DMCustomerId').value === ''){
toastMessage("No Dockmaster Customer ID",'red')
return
};

document.getElementById(type + '-work-order-button').style.display="none"
document.getElementById(type + '-loading-icon').style.display="flex"
const functions = getFunctions(app);
const createWorkOrderFunction = httpsCallable(functions, 'createDockmasterWorkOrder');

var data = {
  clerkId: "mjh",
  dmCustomerId: document.getElementById('DMCustomerId').value,
  dmBoatId: document.getElementById('DMBoatId').value,
  type: 'R'
};

createWorkOrderFunction(data)
.then((result) => {

    var workOrderNumber = result.data.workOrderNumber;
    console.log(`Work order created: ${workOrderNumber}`);
    // Do something with workOrderNumber, e.g., display it to the user
  
  document.getElementById(type + 'WorkOrder').value = workOrderNumber
  document.getElementById(type + '-work-order-button').style.display="inline-block"
document.getElementById(type + '-loading-icon').style.display="none"

let field = document.createElement('fieldset')
field.id = workOrderNumber.toString()
let legend = document.createElement('legend')
if(type === "winter"){
var woType = "(Winterizing)"
}
if(type === "spring"){
var woType = "(Spring Commissioning)"
}
if(woType){
legend.innerHTML = wo.toString() + " " + woType
}else{legend.innerHTML = wo.toString()}

field.appendChild(legend)


document.getElementById('activeJobs').appendChild(field)
let storageCheck = document.getElementById('storageCheck').checked

if(type === "winter" && storageCheck === true){
addJob("hauling",wo)
addJob("pressure washing",wo)
}
if(type === "spring" && storageCheck === true){
addJob("launching",wo)
addJob("bottom painting",wo)
} 

}).catch((error) => {
  // Getting the Error details.
  var code = error.code;
  var message = error.message;
  var details = error.details;
  // ...
});
}

function submitOpen(){

var id = document.getElementById('currentJobId').value

updateJobStatus(id, 'Complete')
selectedJobRow.style.backgroundColor = 'rgb(255, 205, 210)';
toastMessage('Marking Job Complete','blue')

const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("jobData")) {
          db.createObjectStore("jobData", { keyPath: "id" });
      }
  };

  openRequest.onsuccess = function(event) {
      const db = event.target.result;

              const tx = db.transaction("jobData", "readwrite");
              const tableStore = tx.objectStore("jobData");

              const getRequest = tableStore.get(id);

              getRequest.onsuccess = function(event) {
                  const record = event.target.result;

                  if (record) {
                      record.Status = "Complete";
                      record.Operation_Dependency = 0;
                      tableStore.put(record);
                  }
              };

              getRequest.onerror = function(event) {
                  console.error("Error fetching record:", event.target.errorCode);
              };

              tx.onerror = function(event) {
                  console.error("Transaction error:", event.target.errorCode);
              };

              tx.oncomplete = function() {
                  console.log("Transaction completed.");
              };

toastMessage("Job Marked Complete and Moved to Billing",'green')
document.getElementById('statusTable').style.display = 'none';
document.getElementById('selectOption').style.display = 'none';
}
}


function holdDB(id){
const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

  openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      if (!db.objectStoreNames.contains("openData")) {
          db.createObjectStore("openData", { keyPath: "id" });
      }
  };

  openRequest.onsuccess = function(event) {
      const db = event.target.result;

              const tx = db.transaction("openData", "readwrite");
              const tableStore = tx.objectStore("openData");

              const getRequest = tableStore.get(id);

              getRequest.onsuccess = function(event) {
                  const record = event.target.result;

                  if (record) {
                      record[12] = "Hold";
                      tableStore.put(record);
                  }
              };

              getRequest.onerror = function(event) {
                  console.error("Error fetching record:", event.target.errorCode);
              };

              tx.onerror = function(event) {
                  console.error("Transaction error:", event.target.errorCode);
              };

              tx.oncomplete = function() {
                  console.log("Transaction completed.");
              };

toastMessage("Job is marked as on hold",'green')

}
}

function partsList(dataList){
var Parent = document.getElementById('parts-list-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

var Parent = document.getElementById('parts-kit-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

Object.values(dataList).forEach(function(r){

      var tbodyMain = document.getElementById("parts-list-body")
      var tbodyKit = document.getElementById('parts-kit-body')
      var tbodyOrder = document.getElementById('parts-order-body')
      var tbodySelect = document.getElementById('parts-select-body')
      var row = document.createElement("tr")
      row.setAttribute('data-info',JSON.stringify(r));
      var partId = document.createElement("td")
      partId.className = "main-data"
      partId.textContent = r.id
      var part = document.createElement("td")
      part.className = "main-data"
      part.textContent = r.Part_Number
      var description = document.createElement("td")
      description.className = "main-data"      
      description.textContent = r.Description
      var location = document.createElement('td')
      location.className = 'main-data'
      location.textContent = r.Location 
             
      row.appendChild(partId).style.display='none'
      row.appendChild(part)
      row.appendChild(description)
      row.appendChild(location)

      row.addEventListener('click', partSelect)

      tbodyMain.appendChild(row)
      var clonedRow = row.cloneNode(true);
      clonedRow.addEventListener('click', partKitSelect)

      tbodyKit.appendChild(clonedRow);
      var clonedRow2 = row.cloneNode(true);
      clonedRow2.addEventListener('click', e => {
        partOrderSelect(e.target.closest('tr').getAttribute('data-info'))
      });

      tbodyOrder.appendChild(clonedRow2);
      var clonedRow3 = row.cloneNode(true);
      clonedRow3.addEventListener('click', partSelectData)

      tbodySelect.appendChild(clonedRow3);
      })
}

document.getElementById('myPartsInput').addEventListener('input', searchInventory);

function searchInventory() {
const searchQuery = document.getElementById('myPartsInput').value.toLowerCase().trim();
const tables = ['partsTable','partsOrderTable'];

const searchTerms = searchQuery.split(' ');

for (let table of tables) {
  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {
      row.style.display = 'none';
    }
  }
}
}

document.getElementById('myPartsOrderInput').addEventListener('input', searchOrderInventory);

function searchOrderInventory() {
const searchQuery = document.getElementById('myPartsOrderInput').value.toLowerCase().trim();
const tables = ['partsOrderTable'];

const searchTerms = searchQuery.split(' ');

for (let table of tables) {
  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {
      row.style.display = 'none';
    }
  }
}
}

function searchGeneralInventory() {
const searchQuery = document.getElementById('myPartsSelectInput').value.toLowerCase().trim();
const tables = ['partsSelectTable'];

const searchTerms = searchQuery.split(' ');

for (let table of tables) {
  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {
      row.style.display = 'none';
    }
  }
}
}

function searchKitInventory() {
const searchQuery = document.getElementById('myPartsKitInput').value.toLowerCase().trim();
const tables = ['partsKitTable'];

const searchTerms = searchQuery.split(' ');

for (let table of tables) {
  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');

  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {
      row.style.display = 'none';
    }
  }
}
}

document.getElementById('customerSearchBar').addEventListener('input',() =>{
  searchCustomers('customerSearchBar');
});

document.getElementById('customer-search').addEventListener('input',() =>{
  searchCustomers('customer-search');
});

document.getElementById('customer-email-search').addEventListener('input',() =>{
  searchCustomers('customer-email-search');
});

function resetSearch(tableId) {
  var tableRows = document.querySelectorAll('#' + tableId + ' tbody tr');

  for (let row of tableRows) {
    row.style.display = 'table-row';
  }
}

function searchCustomers(searchBar) {

const searchQuery = document.getElementById(searchBar).value.toLowerCase().trim();
const table = document.getElementById(searchBar).getAttribute('data-table');
const searchTerms = searchQuery.split(' ');

  var tableRows = document.querySelectorAll('#' + table + ' tbody tr');
  
  for (let row of tableRows) {
    const rowCells = row.querySelectorAll('td');
    let matchFound = true;

    for (const searchTerm of searchTerms) {
      let termMatchFound = false;

      for (const cell of rowCells) {
        const cellText = cell.textContent.toLowerCase();

        if (cellText.includes(searchTerm)) {
          termMatchFound = true;
          break;
        }
      }

      if (!termMatchFound) {
        matchFound = false;
        break;
      }
    }

    if (matchFound) {
      row.style.display = 'table-row';
    } else {

      if(!row.classList.contains('header')){
        row.style.display = 'none';
      }
      
    }
  }
} 



document.addEventListener('DOMContentLoaded', ()=>{
document.getElementById('exitCustomerBoat').addEventListener('click',() => {
  document.getElementById('currentCustomerTable').style.display="none"
})
})

document.getElementById('open-new-customer-form').addEventListener('click', openNewCustomerForm)
document.getElementById('open-new-boat-form').addEventListener('click', openCustomerData)

function openCustomerData(){
document.getElementById('currentCustomerTable').style.display="flex"
document.getElementById('customerPopUp').style.display="none"
}

function openNewCustomerForm(){
document.getElementById('addNewCustomer').style.display="flex"
document.getElementById('customerPopUp').style.display="none"
}

function populateTable(uniqueData) {
var tableBody = document.getElementById('customer-table-body');
// Clear existing rows
tableBody.innerHTML = '';

// Loop through uniqueData array and add rows
for (var i = 0; i < uniqueData.length; i++) {
  var row = document.createElement('tr');
  
  var idCell = document.createElement('td');
  idCell.innerText = uniqueData[i].Customer_ID;
  idCell.style.display = 'none';

  var fullNameCell = document.createElement('td');
  fullNameCell.innerText = uniqueData[i].Last_Name + ', ' + uniqueData[i].First_Name
  
  var lastNameCell = document.createElement('td');
  lastNameCell.innerText = uniqueData[i].Last_Name;
  lastNameCell.style.display = 'none';
  
  var firstNameCell = document.createElement('td');
  firstNameCell.innerText = uniqueData[i].First_Name;
  firstNameCell.style.display = 'none';
  
  var streetCell = document.createElement('td');
  streetCell.innerText = uniqueData[i].Street;
  streetCell.style.display = 'none';
  
  var cityCell = document.createElement('td');
  cityCell.innerText = uniqueData[i].City;
  cityCell.style.display = 'none';
  
  var stateCell = document.createElement('td');
  stateCell.innerText = uniqueData[i].State;
  stateCell.style.display = 'none';
  
  var zipCell = document.createElement('td');
  zipCell.innerText = uniqueData[i].Zip;
  zipCell.style.display = 'none';
  
  var phoneCell = document.createElement('td');
  phoneCell.innerText = uniqueData[i].Phone;
  phoneCell.style.display = 'none';
  
  var emailCell = document.createElement('td');
  emailCell.innerText = uniqueData[i].Email;
  emailCell.style.display = 'none';

  var customerDM = document.createElement('td');
  customerDM.innerText = uniqueData[i].DM_Customer;
  customerDM.style.display = 'none';

  // Append all cells to the row
  row.append(idCell, fullNameCell, lastNameCell, firstNameCell, streetCell, cityCell, stateCell, zipCell, phoneCell, emailCell, customerDM);

  // Create a scoped function to attach the click event
  (function() {
    var rowData = {
      Customer_ID: uniqueData[i].Customer_ID,
      Last_Name: uniqueData[i].Last_Name,
      First_Name: uniqueData[i].First_Name,
      Street: uniqueData[i].Street,
      City: uniqueData[i].City,
      State: uniqueData[i].State,
      Zip: uniqueData[i].Zip,
      Phone: uniqueData[i].Phone,
      Email: uniqueData[i].Email,
      DM_Customer: uniqueData[i].DM_Customer
    };
    
    row.addEventListener('click', function() {
      console.log(rowData);
      document.getElementById('customerFormId').value = rowData.Customer_ID
      document.getElementById('customerLastName').value = rowData.Last_Name;
      document.getElementById('customerFirstName').value = rowData.First_Name;
      document.getElementById('customerStreet').value = rowData.Street;
      document.getElementById('customerCity').value = rowData.City;
      document.getElementById('customerState').value = rowData.State;
      document.getElementById('customerZip').value = rowData.Zip;
      document.getElementById('customerPhone').value = rowData.Phone;
      document.getElementById('customerEmail').value = rowData.Email;
      document.getElementById('customerDM').value = rowData.DM_Customer;

      document.getElementById('addNewCustomer').style.display="flex"
      document.getElementById('currentCustomerTable').style.display="none"
      
    });
  })();

  // Append the row to the table body
  tableBody.appendChild(row);
}
}

document.getElementById('general-work-form').addEventListener('click', generalWorkForm)
document.getElementById('storage-work-form').addEventListener('click', storageWorkForm)

document.getElementById('myPartsSelectInput').addEventListener('input', searchGeneralInventory)

function storageWorkForm(){
document.getElementById('entryTitle').innerHTML = "Storage Contract Form"
document.getElementById('generalWorkOrderForm').style.display="none"
document.getElementById('winterWorkOrderForm').style.display="block"
document.getElementById('springWorkOrderForm').style.display="block"
document.getElementById('general-work-form').style.display="block"
document.getElementById('storage-work-form').style.display="none"
document.getElementById('storageStatus').value = "true"
}

function generalWorkForm(){
document.getElementById('entryTitle').innerHTML = "Work Order Form"
document.getElementById('generalWorkOrderForm').style.display="block"
document.getElementById('winterWorkOrderForm').style.display="none"
document.getElementById('springWorkOrderForm').style.display="none"
document.getElementById('general-work-form').style.display="none"
document.getElementById('storage-work-form').style.display="block"
document.getElementById('storageStatus').value = ""
}

function partKitSelect(){

var partTbody = document.getElementById("parts-kit-body")

partTbody.onclick = function (e) {
  e = e || window.event;
  var data = [];
  var target = e.srcElement || e.target;
  while (target && target.nodeName !== "TR") {
      target = target.parentNode;
  }
  if (target) {
      var cells = target.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);
     }
  }

var qtyPrompt = window.prompt("Enter Quantity")
if(qtyPrompt == null){
return
}

if(document.getElementById('currentKit').value === ''){
var kit = prompt ("Enter Kit Name")
}else{var kit = document.getElementById('currentKit').value}

var dataLog = {}
dataLog.id = data[0]
dataLog.part = data[2]
dataLog.description = data[3]
dataLog.qty = qtyPrompt
dataLog.vendor = data[4]
dataLog.kit = kit
document.getElementById("partSelectModal").style.display='none'
addPartKit(dataLog)
}
}

function addPartKit(dataLog){


var table = document.getElementById('part-body')
var row = table.insertRow(table.rows.length)
if(dataLog.kit !== document.getElementById('currentKit').value){
row.style.display="none"
row.setAttribute('data-add','none')
}
var id = row.insertCell(0)
var part = row.insertCell(1)
var description = row.insertCell(2)
var qty = row.insertCell(3)
var vendor = row.insertCell(4)
var kit = row.insertCell(5)

id.innerHTML = dataLog.id
id.style.display="none"
part.innerHTML = dataLog.part
description.innerHTML= dataLog.description
qty.innerHTML= dataLog.qty
vendor.innerHTML= dataLog.vendor
kit.innerHTML= dataLog.kit
var omit = row.insertCell(6)
omit.innerHTML = "<button onclick='omitRow(this)' class='omitButton'><i class='material-icons'>clear</i></button>"
var del = row.insertCell(7)
del.innerHTML = "<button onclick='hideRow(this)' class='deleteButton'><i class='material-icons'>delete</i></button>"


updatePartsKit()
}

function updatePartsKit(){
var boatId = document.getElementById('currentBoatId').value
var table = document.getElementById('part-table')
var tbody = document.getElementById('part-body')
var check = []
for(j=0;j<table.rows.length-1;j++){
var data = []
var tr = tbody.getElementsByTagName("tr")[j]
if(tr.className != 'removeRow'){
      var cells = tr.getElementsByTagName("td");
      for (var i = 0; i < cells.length; i++) {
          data.push(cells[i].innerHTML);    
      }
}    
if(data.length != 0){ 
var collection = '{"partId":' + '"' + data[0] + '"' + ',' + '"qty":' + '"' + data[3] + '"' + ',' + '"kit":' + '"' + data[5] + '"' + '}'
check.push(collection)
}
}

google.script.run.updatePartsKit(boatId,check)
}

function addPart(){

var dataLog = {}

dataLog.partNumber = document.getElementById('partNumber').value
dataLog.description = document.getElementById('partDesc').value
dataLog.manufacturer = document.getElementById('partManufacturer').value
dataLog.vendor = document.getElementById('partVendor').value
dataLog.partId = document.getElementById('partId').value

google.script.run.inventoryUpdate(dataLog)
}

async function renderLaborData() {// Reference to your Firebase database


  if(!navigator.onLine){
    console.log('User is offline. Skipping function.');
    return;
  }

  try{
  var laborRef = ref(database, 'quickSelect/labor'); // Adjust this path if your labor data is stored elsewhere in your Firebase database
  
  // Fetch labor data once from Firebase Realtime Database
  get(laborRef).then((snapshot) => {
      const data = snapshot.val(); // This is your labor data
      var container = document.getElementById("laborQS");
  
      // Assuming data is an object with keys as categories and values as arrays of tasks
      Object.keys(data).forEach((category) => {
          var header = document.createElement("h2");
          header.className = "form-header2";
          header.innerText = category;
          container.appendChild(header);
          
          var ul = document.createElement("ul");
          ul.className = 'ul-item';
          
          // Handling both arrays and objects for nested data
          const items = data[category];
          if (Array.isArray(items)) {
              items.forEach(item => appendListItem(ul, item, "Labor"));
          } else { // If it's an object with further categorization
              Object.keys(items).forEach(subCategory => {
                  var subHeader = document.createElement("h3");
                  subHeader.className = "form-subheader";
                  subHeader.innerText = subCategory;
                  ul.appendChild(subHeader);
  
                  items[subCategory].forEach(item => appendListItem(ul, item, 'Labor'));
              });
          }
          
          container.appendChild(ul);
      });
  })
}catch(error){
      console.error("Error fetching labor data:", error);
  };
}  

function appendListItem(ul, text, type) {
  if (text === '') {
    return;
  }
  var li = document.createElement("li");
  li.className = "list-item";
  li.innerText = text;
  li.addEventListener('click', (e) => {
    if (type === "Labor") {
      const labor = e.target.innerHTML;
      const laborEntryElement = document.getElementById('labor-entry-description');
      const currentLabor = laborEntryElement.value;

      if (e.target.classList.contains('highlighted')) {
        // Remove highlight and remove from labor entry
        e.target.classList.remove('highlighted');
        const laborArray = currentLabor.split('  ');
        const updatedLabor = laborArray.filter(item => item !== labor).join('  ');
        laborEntryElement.value = updatedLabor;
      } else {
        // Add highlight and add to labor entry
        e.target.classList.add('highlighted');
        if (currentLabor === '') {
          laborEntryElement.value = labor;
        } else {
          laborEntryElement.value = currentLabor + '  ' + labor;
        }
      }
    } else if (type === "Parts") {
      addParts(e.target.innerHTML);
    } else {
      return;
    }
  });
  ul.appendChild(li);
}



function writtenNumber(number) {
if (number == 1) {
  return 'one (1)';
} else if (number == 2) {
  return 'two (2)';
} else if (number == 3) {
  return 'three (3)';
} else if (number == 4) {
  return 'four (4)';
} else if (number == 5) {
  return 'five (5)';
} else if (number == 6) {
  return 'six (6)';
} else if (number == 7) {
  return 'seven (7)';
} else if (number == 8) {
  return 'eight (8)';
} else if (number == 9) {
  return 'nine (9)';
} else if (number == 10) {
  return 'ten (10)';
} else {
  return 'Invalid input'; // or handle invalid input as needed
}
}


async function renderPartsData() {

  if(!navigator.onLine){
    console.log('User is offline. Skipping function.');
    return;
  }

  try{
  var partsRef = ref(database, 'quickSelect/parts'); // Adjust this path if your parts data is stored elsewhere in your Firebase database

  // Fetch parts data once from Firebase Realtime Database
  get(partsRef).then((snapshot) => {
      const data = snapshot.val(); // This is your parts data
      var container = document.getElementById("partsQS");

      // Assuming data is an object with keys as categories and values as arrays of tasks
      Object.keys(data).forEach((category) => {
          var header = document.createElement("h2");
          header.className = "form-header2";
          header.innerText = category;
          container.appendChild(header);
          
          var ul = document.createElement("ul");
          ul.className = 'ul-item';
          
          // Handling both arrays and objects for nested data
          const items = data[category];
          if (Array.isArray(items)) {
              items.forEach(item => appendListItem(ul, item, "Parts"));
          } else { // If it's an object with further categorization
              Object.keys(items).forEach(subCategory => {
                  var subHeader = document.createElement("h3");
                  subHeader.className = "form-subheader";
                  subHeader.innerText = subCategory;
                  ul.appendChild(subHeader);

                  items[subCategory].forEach(item => appendListItem(ul, item, "Parts"));
              });
          }
          
          container.appendChild(ul);
      });
  })
  }catch(error){
    console.error("Error fetching parts data:", error);
  }
}

document.getElementById('searchBarQS').addEventListener('input', filterDataQS);

function filterDataQS() {
  var input = document.getElementById("searchBarQS");
  var filter = input.value.trim().toLowerCase(); // Convert filter to lowercase
  var headers = document.getElementsByClassName("form-header2");
  
  for (var i = 0; i < headers.length; i++) {
      var header = headers[i];
      var ul = header.nextElementSibling;

      // Assume no matches initially
      var hasMatchingItem = false;

      if (ul && ul.tagName.toLowerCase() === "ul") {
          var listItems = ul.getElementsByTagName("li");

          for (var j = 0; j < listItems.length; j++) {
              var listItemText = listItems[j].textContent || listItems[j].innerText;
              
              // If a list item matches the filter, set hasMatchingItem to true
              if (listItemText.toLowerCase().indexOf(filter) !== -1) {
                  hasMatchingItem = true;
                  listItems[j].style.display = "block";  // Show the matching list item
              } else {
                  listItems[j].style.display = "none";   // Hide non-matching list items
              }
          }
      }

      // Show or hide the header and ul based on the hasMatchingItem flag
      if (hasMatchingItem) {
          header.style.display = "block";
          ul.style.display = "block";
      } else {
          header.style.display = "none";
          if (ul) {
              ul.style.display = "none";
          }
      }
  }
}


function showModalWithMooringOptions(mooring) {
return new Promise((resolve) => {
  var mooringArray = mooring.split(',');
  var mooringModal = document.getElementById('mooringModal-mooring');
  var mooringButtonsContainer = document.getElementById('mooringButtons-mooring');

  // Clear previous buttons
  mooringButtonsContainer.innerHTML = '';

  // Create a button for each mooring option
  mooringArray.forEach(function(mooringOption) {
    var button = document.createElement('button');
    button.textContent = mooringOption.trim();
    button.addEventListener('click', function() {
      // Resolve the promise with the selected mooring option
      resolve(mooringOption.trim());
      mooringModal.style.display = 'none';
    });
    mooringButtonsContainer.appendChild(button);
  });

  // Show the modal
  mooringModal.style.display = 'block';

  // Close button
  var closeButton = document.querySelector('.close-mooring');
  closeButton.onclick = function() {
    mooringModal.style.display = 'none';
  };

  // Close the modal when clicking outside of it
  window.onclick = function(event) {
    if (event.target === mooringModal) {
      mooringModal.style.display = 'none';
    }
  };
});
}



document.getElementById('openLaborQS').addEventListener('click',()=>{
document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
document.getElementById('laborQS').style.display = "flex";
document.getElementById('partsQS').style.display = "none";
document.getElementById('searchBarQS').focus()
})

document.getElementById('openPartsQS').addEventListener('click',()=>{
document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
document.getElementById('laborQS').style.display = "none";
document.getElementById('partsQS').style.display = "flex";
document.getElementById('searchBarQS').focus()
})

document.getElementById('qs-return').addEventListener('click',()=>{
document.getElementById('laborAndPartsQS').classList.toggle('qs-open');
document.getElementById('searchBarQS').value = '';
document.querySelectorAll('.ul-item .list-item').forEach(item => item.classList.remove('highlighted'));
});


function openShrinkMenu(status){

if(status != "On Work Schedule"){

if(status == "Framed"){
document.getElementById('framed').classList += ' hide'
}

if(status == "Vacuumed Bilge"){
document.getElementById('framed').classList += ' hide'
document.getElementById('vacuumed').classList += ' hide'
}

if(status == "Covered"){
document.getElementById('framed').classList += ' hide'
document.getElementById('vacuumed').classList += ' hide'
document.getElementById('covered').classList += ' hide' 
}

if(status == "Wrap Tightened"){
document.getElementById('framed').classList += ' hide'
document.getElementById('vacuumed').classList += ' hide'
document.getElementById('covered').classList += ' hide'
document.getElementById('tightened').classList += ' hide'
}

}

document.getElementById('shrinkPopUp').style.display='flex'

}

function shrinkSubmit(){

document.getElementById('processing').style.display="flex"

var jobId = document.getElementById('shrinkJobId').value
var checks = document.getElementsByClassName('shrinkCheck')
var desc = []
var hours = []

for(i=0;i<checks.length;i++){
 if(checks[i].checked == true){
var status = checks[i].getAttribute('data-status')
desc.push(checks[i].getAttribute('data-description') + ' ')
hours.push(checks[i].getAttribute('data-hours'))
}
}
if(localStorage.getItem("currentTech")){
var tech = localStorage.getItem('currentTech')
}else{var tech = toProperCase(prompt('Enter Tech Name'))}
var dataLog = {}
dataLog.tech = tech
dataLog.name = globalShrink[1]
dataLog.boat = globalShrink[3]
dataLog.workOrder = globalShrink[6]
dataLog.desc = desc.flat().toString()
dataLog.hours = hours.reduce( (a,b) => {return Number(a) + Number(b)},0)
dataLog.category = globalShrink[7]
dataLog.opType  = globalShrink[8]
dataLog.jobId = globalShrink[0]
dataLog.customerId = globalShrink[2]
dataLog.boatId = globalShrink[4]
dataLog.shortDesc = globalShrink[17]

google.script.run.withSuccessHandler(()=>{
  document.getElementById('shrinkPopUp').style.display="none"
  var Parent = document.getElementById('shrink-part-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

  document.getElementById('shrinkParts').style.display="flex"

  google.script.run.withSuccessHandler( (parts) => {

document.getElementById('shrinkPartsUsed').style.display="flex"

    parts.forEach( part =>{
      var tbody = document.getElementById('shrink-part-body')
      var row = document.createElement('tr')
      var partNumber = document.createElement('td')
      partNumber.textContent = part[0]
      var qty = document.createElement('td')
      qty.textContent = part[1]

      row.appendChild(partNumber)
      row.appendChild(qty)
      tbody.appendChild(row)
    })
  }).getShrinkPartsUsed(dataLog.jobId)

  document.getElementById('processing').style.display="none"
}).updateShrinkStatus(dataLog.jobId,status,dataLog)

}

function toProperCase(str) {
  return str.toLowerCase().replace(/(^|\s)\S/g, function(t) {
      return t.toUpperCase();
  });
}

function shrinkPartsSubmit(){
document.getElementById('processing').style.display = "flex";
var parts = document.querySelectorAll(".shrinkPart");
var total = [];

if(localStorage.getItem("currentTech")){
var tech = localStorage.getItem('currentTech')
}else{var tech = toProperCase(prompt('Enter Tech Name'))}

parts.forEach(part => {
  if(part.value !== ''){
    var partNumber = part.nextElementSibling.innerHTML;
    var partQty = part.value;
    var dataLog = {};
    dataLog.tech = tech
    dataLog.name = globalShrink[1];
    dataLog.boat = globalShrink[3];
    dataLog.workOrder = globalShrink[6];
    dataLog.part = partNumber;
    dataLog.qty = partQty;
    dataLog.category = globalShrink[7];
    dataLog.opType  = globalShrink[8];
    dataLog.jobId = globalShrink[0];
    dataLog.customerId = globalShrink[2];
    dataLog.boatId = globalShrink[4];
    dataLog.shortDesc = globalShrink[17];
    
    total.push(dataLog); // push the object directly
  }
});

google.script.run.withSuccessHandler(() => {
  document.getElementById('shrinkParts').style.display = "none";
  document.getElementById('processing').style.display = "none";
}).updateShrinkParts(total);

clearShrinkParts();
}


function clearShrinkParts(){
document.getElementById('shrinkParts').style.display="none"
var parts = document.querySelectorAll(".shrinkPart")

parts.forEach( part => {
  part.value=''
})
}

function openShrinkParts() {
  document.getElementById('shrinkPopUp').style.display = "none"
  document.getElementById('shrink-table').style.display = "none"
  document.getElementById('shrinkPartCheck').style.display="flex"
  var Parent = document.getElementById('shrink-part-body');
  while (Parent.hasChildNodes()) {
      Parent.removeChild(Parent.firstChild);
  }
  document.getElementById('shrinkParts').style.display = "flex";

  google.script.run.withSuccessHandler((parts) => {
      document.getElementById('shrinkPartsUsed').style.display = "flex";

      // Process parts to aggregate quantities for same part number
      let aggregatedParts = {};
      parts.forEach(part => {
          if (aggregatedParts[part[0]]) {
              aggregatedParts[part[0]] += Number(part[1]);
          } else {
              aggregatedParts[part[0]] = Number(part[1]);
          }

      });

      for (let partNumberText in aggregatedParts) {
          var tbody = document.getElementById('shrink-part-body');
          var row = document.createElement('tr');
          var partNumber = document.createElement('td');
          partNumber.textContent = partNumberText;
          var qty = document.createElement('td');
          qty.textContent = aggregatedParts[partNumberText];

          row.appendChild(partNumber);
          row.appendChild(qty);
          tbody.appendChild(row);
      }
      document.getElementById('shrinkPartCheck').style.display="none"
      document.getElementById('shrink-table').style.display = "table"
  }).getShrinkPartsUsed(globalShrink[0]);
}

// Get the modal
/* var modal = document.getElementById("myModal");

// Get the button that opens the modal
var btn = document.getElementById("selectTable");

// When the user clicks the button, toggle the modal 
btn.onclick = function() {
if (modal.style.display === "block") {
  modal.style.display = "none";
} else {
  modal.style.display = "block";
}
}

// Optional: Hide the modal when clicking outside of it
window.onclick = function(event) {
if (event.target !== modal && event.target !== btn) {
  modal.style.display = "none";
}
} */

function receiveBox() {

var id = document.getElementById('currentJobId').value

document.getElementById('part-order-table').style.display='flex'
var rows = document.getElementById('part-table-body').rows;


for (var i = 0; i < rows.length; i++) {

  var jobId = rows[i].cells[13].textContent;
  
  // If the jobId does not match the idToMatch, hide the row
  if (jobId !== id) {
    rows[i].style.display = 'none';
  } else {
    // If it matches, ensure the row is visible (in case it was previously hidden)
    rows[i].style.display = '';
  }
}
}

async function getFormattedDate(date) {
  return new Promise((resolve) => {
    const dateObj = date ? new Date(date) : new Date();
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;
    resolve(formattedDate);
  });
}

function receiveParts(e) {

  var rowData = e.closest('tr'); // This is a single <tr> element

  // Assuming you want to work with each cell in this row
  var cells = rowData.querySelectorAll('td');
  var data = [];
  

  cells.forEach( cell => {
      data.push(cell.textContent || cell.innerText);
  });

  var received = parseInt(prompt("Enter Quantity Received"), 10);

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;

  var dataLog = {
    id: data[0],
    tech: data[1],
    wo: data[2],
    part: data[3],
    qty: parseInt(data[4], 10) - received,
    description: data[5],
    vendor: data[6],
    manufacturer: data[7],
    totalReceived: received,
    date: formattedDate,
    jobId: data[13],
    originalQty: data[20]
  };

  if (received === parseInt(data[4], 10)) {
    dataLog.received = "All parts received";

    var partsLog = {
      techName: data[1],
      customerName: data[16],
      boatName: data[17],
      workOrder: data[2],
      partNumber: data[3] + "(" + data[5] + ")",
      quantity: data[4],
      category: data[11],
      opType: data[12],
      jobId: data[13],
      customerId: data[14],
      boatId: data[15],
      shortDesc: data[18],
      originalQty:data[20]
    };


    if (data[2] !== "Stock Order" && data[2] !== "stock" && data[2] !== "Stock") {
      google.script.run
        .withSuccessHandler(function () {

        })
        .partsOrderClicked(partsLog);
    }

    document.getElementById('partNumber').value = data[3];
    document.getElementById('partDesc').value = data[5];
    document.getElementById('partManufacturer').value = data[7];
    document.getElementById('partVendor').value = data[6];
    document.getElementById('partId').value = data[23];
    

    if(document.getElementById('addToInventory').checked === true){
        addPart();
    }


  } else {
    dataLog.received = 'Received (' + Number(received) + ')' + '\n' + 'Awaiting (' + (parseInt(data[4], 10) - received) + ')';
  }

  google.script.run
    .withSuccessHandler(function () {
      format(tableMaker);
    })
    .updatePartOrder(dataLog);
   
};


var dropdowns = document.getElementsByClassName("dropbtn");

// Loop through the buttons and add an onclick listener
for (var i = 0; i < dropdowns.length; i++) {
dropdowns[i].addEventListener("click", function() {
  this.classList.toggle("active");
  var content = this.nextElementSibling;
  if (content.style.display === "block") {
    content.style.display = "none";
  } else {
    content.style.display = "block";
  }
});
}



function partReceiveTable(dataArray){
var Parent = document.getElementById('part-table-body')
while(Parent.hasChildNodes()){
Parent.removeChild(Parent.firstChild)}

dataArray.forEach(function(r){

  var tbody = document.getElementById("part-table-body")
      var row = document.createElement("tr")
      var orderId = document.createElement("td")
      orderId.className = "main-data"
      orderId.textContent = r[0]
      var tech = document.createElement("td")
      tech.className = "main-data"
      tech.textContent = r[1]
      var wo = document.createElement("td")
      wo.className = "main-data"
      wo.textContent = r[2]
      var part = document.createElement("td")
      part.className = "main-data"
      part.setAttribute("contenteditable", "true");
      part.textContent = r[3]
      var qty = document.createElement("td")
      qty.className = "main-data"
      qty.textContent = r[4]
      var description = document.createElement("td")
      description.className = "main-data"
      description.textContent = r[5]
      description.setAttribute("contenteditable", "true");
      var vendor = document.createElement("td")
      vendor.className = "main-data"
      vendor.textContent = r[6]
      vendor.setAttribute("contenteditable", "true");
      var manufacturer = document.createElement("td")
      manufacturer.className = "main-data"
      manufacturer.textContent=r[8]
      manufacturer.setAttribute("contenteditable", "true");
      var ordered = document.createElement('td')
      ordered.className = 'main-data'
      ordered.textContent=r[9]
      var backOrder = document.createElement('td')
      backOrder.className = 'main-data'
      backOrder.textContent=r[10]
      var received = document.createElement('td')
      received.className = 'main-data'
      received.textContent=r[11]
      var notes = document.createElement('td')
      notes.className = 'main-data'
      notes.textContent=r[20]
      var category = document.createElement('td')
      category.className = 'main-data'
      category.textContent=r[12]
      var opType = document.createElement('td')
      opType.className = 'main-data'
      opType.textContent=r[13]
      var jobId = document.createElement('td')
      jobId.className = 'main-data'
      jobId.textContent=r[14]
      var customerId = document.createElement('td')
      customerId.className = 'main-data'
      customerId.textContent=r[15]
      var boatId = document.createElement('td')
      boatId.className = 'main-data'
      boatId.textContent=r[16]
      var customer = document.createElement("td")
      customer.className = "main-data"
      customer.textContent = r[17]
      var boat = document.createElement("td")
      boat.className = "main-data"
      boat.textContent = r[18]
      var shortDesc = document.createElement("td")
      shortDesc.className = "main-data"
      shortDesc.textContent = r[19]
      var originalQty = document.createElement('td')
      originalQty.className='main-data'
      originalQty.textContent = r[21]
      var add = document.createElement("td")
      add.innerHTML = "<button onclick='receiveParts(this)' class='addOpButton'><i class='avoid material-icons'>fact_check</i></button>"
      var del = document.createElement("td")
      del.innerHTML = "<button onclick='deleteParts(this)' class='deleteOpButton'><i class='avoid material-icons'>clear</i></button>"
      var updateQty = document.createElement("td")
      updateQty.className = "main-data"
      updateQty.textContent = r[22]
      var partId = document.createElement('td')
      partId.className = "main-data"
      partId.textContent = r[23]
      
     
      row.appendChild(orderId).style.display="none"
      row.appendChild(tech)
      row.appendChild(wo)
      row.appendChild(part)
      row.appendChild(qty)
      row.appendChild(description)
      row.appendChild(vendor).style.display="none"
      row.appendChild(manufacturer).style.display="none"
      row.appendChild(ordered)
      row.appendChild(backOrder).style.display="none"
      row.appendChild(received).style.display="none"
      row.appendChild(category).style.display="none"
      row.appendChild(opType).style.display="none"
      row.appendChild(jobId).style.display="none"
      row.appendChild(customerId).style.display="none"
      row.appendChild(boatId).style.display="none"
      row.appendChild(customer).style.display="none"
      row.appendChild(boat).style.display="none"
      row.appendChild(shortDesc).style.display="none"
      row.appendChild(notes).style.display="none"
      row.appendChild(originalQty).style.display="none"
      row.appendChild(add)
      row.appendChild(del)
      row.appendChild(updateQty).style.display="none"
      row.appendChild(partId).style.display="none"
      tbody.appendChild(row)
})
document.getElementById('receiveButton').disabled = false;
}

function closePartTable(){
document.getElementById("part-order-table").style.display='none'
}

document.getElementById('receivePart').addEventListener('click',()=>{
if(document.getElementById('receivePart').checked === true){
  document.getElementById('listReceived').value = '';
  document.getElementById('costReceived').value = '';
  document.getElementById('saleReceived').value = '';
  document.getElementById('listReceived').focus();
  
  var qty = prompt('Enter Quantity Received')
  document.getElementById('qtyReceived').value = qty
}else{document.getElementById('qtyReceived').value = ''}

})

function clearPartOrder(){

document.getElementById('orderPart').value =''
document.getElementById('orderQty').value=''
document.getElementById('partDescription').value=''
document.getElementById('vendor').value=''
document.getElementById('vendorId').value=''
document.getElementById('manufacturer').value=''
document.getElementById('newPart').value = "Yes"
document.getElementById('orderedPart').checked=false
document.getElementById('addToInventory').checked=false
document.getElementById('receivePart').checked=false
document.getElementById('qtyReceived').value = ''
document.getElementById('partWorkOrder').scrollIntoView()

}

function closePartOrderBox(){
document.getElementById('partsModal').style.display='none'
document.getElementById('orderPart').value =''
document.getElementById('partTech').value = ''
document.getElementById('orderQty').value=''
document.getElementById('partDescription').value=''
document.getElementById('vendor').value=''
document.getElementById('vendorId').value=''
document.getElementById('manufacturer').value=''
document.getElementById('newPart').value = "Yes"
document.getElementById('addToInventory').checked=false
document.getElementById('receivePart').checked=false
document.getElementById('qtyReceived').value = ''
document.getElementById('partWorkOrder').scrollIntoView()

}



function discount() {
var list = document.getElementById('listPrice').value
var discountGiven = Number(document.getElementById('receivedPercent').value)/100

var cost = document.getElementById('costPrice').value

if(list == ''){
document.getElementById('listPrice').value = Number(Number(cost) / (1 - discountGiven)).toFixed(2)
var list = document.getElementById('listPrice').value
}

var percent = Math.round( ( ( Number(list) - Number(cost) ) / Number(list) )*100)

if(percent <= 15){
  var sale = list
  var discount = "No Discount"
}else if(percent > 15 && percent <= 25){
  var discount = "10%"
  var sale = Number(list) - (Number(list)*.10)
}else if(percent > 25 && percent <= 35){
  var discount = "10%"
  var sale = Number(list) - (Number(list)*.10)
}else if(percent > 35 && percent <= 40){
  var sale = Number(list) - (Number(list)*.15)
  var discount = "15%"
}else if(percent > 40){
  var sale = Number(list) - (Number(list)*.15)
  var discount = "15%"
}

if(document.getElementById('receivedPercent').value == ''){
document.getElementById('receivedPercent').value = percent + '%'
}
document.getElementById('discountPercent').value = discount
document.getElementById('salePrice').value = '$' + Number(sale).toFixed(2).toLocaleString()
}

function closeCalc(){
document.getElementById('calcBody').style.display='none'
document.getElementById('listPrice').value = ''
document.getElementById('costPrice').value = ''
document.getElementById('receivedPercent').value = ''
document.getElementById('discountPercent').value = ''
document.getElementById('salePrice').value = ''
}

function passId(id, index, value) {
const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

openRequest.onupgradeneeded = function(event) {
  const db = event.target.result;
  db.createObjectStore("openData", { keyPath: "id" });
};

openRequest.onsuccess = function(event) {
  const db = event.target.result;
  const tableTx = db.transaction("openData", "readwrite"); // Changed to readwrite
  const tableStore = tableTx.objectStore("openData");
  const tableRequest = tableStore.getAll();

  tableRequest.onsuccess = function(event) {
    const data = event.target.result;

    // Find the item to update
    const itemToUpdate = data.find(boat => boat.id === id); // Assuming each item has an 'id' field

    if (itemToUpdate) {
      // Update the value
      if (itemToUpdate[index] !== undefined) {
        itemToUpdate[index] = value; // Updated the value directly
      }

      // Put the updated item back into the store
      const updateRequest = tableStore.put(itemToUpdate);

      updateRequest.onsuccess = function() {
        console.log(`Data updated for id ${id}`);
      };

      updateRequest.onerror = function() {
        console.error("Error updating data");
      };
    }
  };

  tableRequest.onerror = function() {
    console.error("Error fetching data");
  };
};

openRequest.onerror = function() {
  console.error("Error opening database");
};
}


function getCustomerRecord(customerId) {
return new Promise((resolve, reject) => {
  // Open a connection to the IndexedDB database
  const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion); // Adjust the version number as needed

  openRequest.onerror = function(event) {
    reject("IndexedDB error: " + openRequest.error);
  };

  openRequest.onsuccess = function(event) {
    const db = event.target.result;
    const transaction = db.transaction("customerData", "readonly");
    const objectStore = transaction.objectStore("customerData");
    const request = objectStore.get(customerId);

    request.onsuccess = function(event) {
      // Check if we got something back
      if (request.result) {
        resolve(request.result); // Return the found customer record
      } else {
        resolve(null); // No customer found
      }
    };

    request.onerror = function(event) {
      reject("Could not retrieve customer data: " + request.error);
    };
  };
});
}

/* function openButtons() {
let buttons = document.querySelectorAll('.tideButton');
buttons.forEach(button => {
  button.classList.toggle('hidden');
});
document.getElementById('btnBox').classList.toggle('hidden')
}

const closerButtons = document.querySelectorAll('.closer')

closerButtons.forEach( button => {
button.addEventListener('click', openButtons)
}) */


async function updateJobDB(jobId, index, value) {

  try{
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };
  
    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");
      
      // Use the get method to fetch the individual item using jobId
      const getRequest = objectStore.get(jobId);
      console.log(getRequest)
      getRequest.onsuccess = function(event) {
        const itemToUpdate = event.target.result;
        
        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;
          
          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);
  
          updateRequest.onsuccess = function() {
            console.log(`Data updated for Job_ID ${jobId}`);
          };
  
          updateRequest.onerror = function() {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };
  
      getRequest.onerror = function() {
        console.error("Error fetching data");
      };
  
      // Handle the transaction completion
      transaction.oncomplete = function() {
        db.close(); // It's important to close the database when you're done
      };
    };
  
    openRequest.onerror = function() {
      console.error("Error opening database");
    };
  }catch(error){
    console.log("Error updating IndexedDB", error);
  };
}

async function updateCustomerDB(customerId, index, value) {

  try{
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };
  
    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");
      
      // Use the get method to fetch the individual item using jobId
      const getRequest = objectStore.get(customerId);
      console.log(getRequest)
      getRequest.onsuccess = function(event) {
        const itemToUpdate = event.target.result;
        
        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;
          
          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);
  
          updateRequest.onsuccess = function() {
            console.log(`Data updated for Job_ID ${customerId}`);
          };
  
          updateRequest.onerror = function() {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${customerId}`);
        }
      };
  
      getRequest.onerror = function() {
        console.error("Error fetching data");
      };
  
      // Handle the transaction completion
      transaction.oncomplete = function() {
        db.close(); // It's important to close the database when you're done
      };
    };
  
    openRequest.onerror = function() {
      console.error("Error opening database");
    };
  }catch(error){
    console.log("Error updating IndexedDB", error);
  };
}

async function getJobDataDB(jobId) {
  return new Promise((resolve, reject) => {
    try {
      const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

      openRequest.onupgradeneeded = function(event) {
        const db = event.target.result;
        if (!db.objectStoreNames.contains("jobData")) {
          db.createObjectStore("jobData", { keyPath: "id" });
        }
      };

      openRequest.onsuccess = function(event) {
        const db = event.target.result;
        const transaction = db.transaction("jobData", "readonly");
        const objectStore = transaction.objectStore("jobData");

        const getRequest = objectStore.get(jobId);

        getRequest.onsuccess = function(event) {
          const jobData = event.target.result;
          if (jobData) {
            resolve(jobData);
          } else {
            console.error(`No item found with Job_ID ${jobId}`);
            resolve(null);
          }
        };

        getRequest.onerror = function() {
          console.error("Error fetching data");
          reject(new Error("Error fetching data"));
        };

        transaction.oncomplete = function() {
          db.close();
        };
      };

      openRequest.onerror = function() {
        console.error("Error opening database");
        reject(new Error("Error opening database"));
      };
    } catch (error) {
      console.log("Error retrieving data from IndexedDB", error);
      reject(error);
    }
  });
}


function getTechId(name){
let techs = {
"Alex": "56",
"Antony": "43",
"Chelsea": "60",
"Conrad": "66",
"Darlin": "68",
"Eduardo": "58",
"Elvin": "70",
"Henry": "57",
"Jorge": "55",
"Karen": "3",
"Kyle": "36",
"Sophie": "61",
"Liam": "67",
"Mac": "33"
}
return techs[name]
}


async function addJobToTech(row, tech) {

  console.log(row)

  let jobId = row.Job_ID;

  try {
   
    // Update the Techs value in /data/jobID
    const dataJobRef = ref(database, `data/${jobId}`);
    await update(dataJobRef, { 
      Techs: tech,
    });
    await updateJobDB(jobId, "Techs", tech);
    console.log("Techs value updated successfully");
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }
}

async function removeTech(jobId) {

  try {
   
    // Update the Techs value in /data/jobID
    const dataJobRef = ref(database, `data/${jobId}`);
    await update(dataJobRef, { 
      Techs: '',
    });
    await updateJobDB(jobId, "Techs", '');
    console.log("Techs value updated successfully");
  } catch (e) {
    console.error("Error adding job to tech: " + e.toString());
  }
}


async function addJobFirebase(serviceLog) {

let start
if(serviceLog.Category === "Spring Commissioning" && serviceLog.Description.toLowerCase().includes('bottom paint')){
let year = new Date().getFullYear();
start = year + "-02-01"
}else if(serviceLog.Category === "Spring Commissioning" && !serviceLog.Description.toLowerCase().includes('bottom paint')){
start = serviceLog.Launch_Date
}else if(serviceLog.Category === 'Winterizing'){
start = serviceLog.Haul_Date
}else{start = serviceLog.Request_Date}

let data = {
'Billing User': "",
'Boat_ID': serviceLog.Boat_ID,
'Boat_Name': serviceLog.Boat_Name,
'Category': serviceLog.Category,
'Charge': "",
'Customer_ID': serviceLog.Customer_ID,
'Customer_Name': serviceLog.Customer_Name,
'Date Billed': "",
'Date_Hauled': "",
'Date_Launched': "",
'Description': serviceLog.Description,
'Email': serviceLog.Email,
'Estimated_Hours': serviceLog.Estimated_Hours,
'Event_Added': "",
'Event_Time': "",
'Haul_Date': serviceLog.Haul_Date,
'Hold_Days': "",
'Job_ID': serviceLog.Job_ID,
'Labor Billed': "",
'Launch_Date': serviceLog.Launch_Date,
'Location_Number': "",
'Notes': serviceLog.Notes,
'OpCode': serviceLog.OpCode,
'Op_Type': serviceLog.Op_Type,
'Operation_Dependency': serviceLog.Operation_Dependency,
'Parts Billed': "",
'Parts Ordered': "",
'Parts_Kit_Status': "",
'Priority': "",
'Ready': "",
'Request_Date': serviceLog.Request_Date,
'Short_Description': serviceLog.Short_Description,
'Short_Description_DM': serviceLog.Short_Description_DM,
'Start_Date': start,
'Status': serviceLog.Status,
'Status_Of_Boat': "",
'Techs': serviceLog.Techs,
'Transfer_to_Billing': "",
'Water': "",
'Work_Order': serviceLog.Work_Order,
'Work_Order_ID': "",
'Yard': ""
};


let jobId = data.Job_ID;
const idToken = await fetchIdToken();
const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/";
const path = `data/${jobId}.json?auth=${idToken}`;
const timestampPath = `data/lastUpdated.json?auth=${idToken}`;

try {
  const response = await fetch(firebaseUrl + path, {
    method: 'PUT', 
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data)
  });
  const result = await response.json();
  console.log("Job added successfully", result);
} catch (e) {
  console.error("Error adding job to tech: " + e.toString());
}

try {
  const timestampResponse = await fetch(firebaseUrl + timestampPath, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({".sv": "timestamp"})
  });
  const timestampResult = await timestampResponse.json();
  console.log("Timestamp updated successfully", timestampResult);
} catch (e) {
  console.error("Error updating timestamp: " + e.toString());
}

}


document.addEventListener('DOMContentLoaded', () => {
  const buttons = document.querySelectorAll('.exitButton');
  buttons.forEach(button => {
    button.addEventListener('click', e => {
      // Get the button element, whether clicked directly or on a child element
      const targetButton = e.target.closest('button');

      if (targetButton) {
        console.log('Clicked element:', e.target.tagName);
        console.log('Target button:', targetButton.tagName);

        if (targetButton.hasAttribute('data-close')) {
          const elementToClose = document.getElementById(targetButton.getAttribute('data-close'));
          if (elementToClose) {
            elementToClose.style.display = 'none';
          }
        }

        if (targetButton.hasAttribute('data-slide')) {
          const elementToSlide = document.getElementById(targetButton.getAttribute('data-slide'));
          if (elementToSlide) {
            elementToSlide.classList.remove('active');
          }
        }
      }
    });
  });
});


document.addEventListener("DOMContentLoaded", () => {

const buttons = document.querySelectorAll('.tideButton')
buttons.forEach(button => {
  button.addEventListener('click',(e) => {
    const elements = document.querySelectorAll('.selected-tide-button');
    elements.forEach(element => element.classList.remove('selected-tide-button'));
    e.target.classList.add('selected-tide-button');
  })
  
})

})

async function modifyTechJobs(techID, jobID, desc, notes, techName) {
  if(!techID || techID.trim() === ''){
    return;
  }

  try {
    const idToken = await fetchIdToken(); 
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/scheduleDatabase/${techID}/jobs/${jobID}.json?auth=${idToken}`;

    // Construct the body to only include the fields being updated
    const partialUpdate = {
      Description: desc,
      Notes: notes
    };

    // Set up the fetch options for a PATCH request
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate), // Convert the partial update object to a JSON string
    };

    // Make the fetch call to update the job
    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error('HTTP error! status: ' + response.status);
    const result = await response.json();
    console.log("Operation successful", result);

    updateJobDB(jobID, 'Techs', techName); 
  } catch (error) {
    console.error("Error modifying tech jobs:", error);
  }
}



async function createEmailTemplate() {
  const templateId = generateId();
  const subject = document.getElementById('email-subject').value;
  const body = document.getElementById('email-message').value;

  try {
    let templateRef;
    if (templateId) {
      templateRef = ref(database, `emailTemplates/${templateId}`);
    } else {
      templateRef = push(ref(database, "emailTemplates"));
    }

    const template = {
      subject: subject,
      body: body
    };

    await set(templateRef, template);
    console.log("Email template created successfully");

    return templateRef.key;
  } catch (error) {
    console.error("Error creating email template:", error);
    return null;
  }
}


async function updateJobStatusTech(jobID, tech, status) {
  if (!tech) {
    return;
  }

  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    const techID = getTechId(tech); // Assuming getTechId is a synchronous function that returns techID
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/scheduleDatabase/${techID}/jobs/${jobID}.json?auth=${idToken}`;

    const partialUpdate = { "Status": status};

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);

    updateJobDB(jobID, 'Status', status);
  } catch (error) {
    console.error("Error modifying tech jobs:", error);
  }
}


async function updateJobStatus(jobID, status) {
  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/data/${jobID}.json?auth=${idToken}`;

    const partialUpdate = { "Status": status, "Operation_Dependency": 0 };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);

    updateJobDB(jobID, 'Status', status); //Updates job status
    if(status === 'Complete'){
      updateJobDB(jobID, 'Operation_Dependency', 0); //Updates job dependency
    }
  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}


async function updateJobInfo(jobID, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/data/${jobID}.json?auth=${idToken}`;
    
    const partialUpdate = { [index]: value };
    
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateJobDB(jobID, index, value);
    updateTimestamps('jobData','data');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

document.getElementById('note-text').addEventListener('keydown', async (e) => {
  if (e.key !== 'Enter') { return; }
  e.preventDefault(); // Prevent default Enter behavior

  const jobId = document.getElementById('note-job-id').value;
  const note = document.getElementById('note-text').value;
  const tech = await getCurrentUserData();
  const newId = generateId();
  const noteDate = await getFormattedDate();
  const noteTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

  let newNote = {
    Note: note,
    Tech: tech.displayName,
    Date: noteDate,
    Time: noteTime,
    Job_ID: jobId,
    id: newId
  } 

  // Fetch the current job data
  const currentJobData = await getJobData(jobId);

  // If Notes doesn't exist, create it
  if (!currentJobData.Notes) {
    currentJobData.Notes = {};
  }

  // Add the new note to the Notes object
  currentJobData.Notes[newId] = newNote;

  // Update the job with the new Notes object
  await updateJobNotes(jobId, 'Notes', currentJobData.Notes);
  document.getElementById('note-text').value = '';
  
  const noteArea = document.getElementById('note-area');
  const noteContainer = document.createElement('div');
  noteContainer.className = 'note-pair';
  noteContainer.id = newId;

  const noteInfo = document.createElement('div');
  noteInfo.className = 'note-info';
              
  const noteText = document.createElement('p');
  noteText.textContent = note;

  const techInfo = document.createElement('div');
  techInfo.className = 'note-info-tech';

  techInfo.textContent = `${tech.displayName} | ${noteDate}, ${noteTime}`;

  const deleteButton = document.createElement('button');
  deleteButton.textContent = 'Delete';
  deleteButton.className = 'delete-note';
  deleteButton.addEventListener('click', async () => {
    await deleteNotes(jobId, newId);
    noteContainer.remove();
  });

  noteInfo.appendChild(noteText);
  noteInfo.appendChild(techInfo); 
  noteInfo.appendChild(deleteButton);
  noteContainer.appendChild(noteInfo);
  
  noteArea.appendChild(noteContainer);
});


document.getElementById('note-submit').addEventListener('click', async () => {
  const jobId = document.getElementById('note-job-id').value;
  const note = document.getElementById('note-text').value;
  const tech = await getCurrentUserData();
  const newId = generateId();
  const noteDate = await getFormattedDate();
  const noteTime = new Date().toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });

  let newNote = {
    Note: note,
    Tech: tech.displayName,
    Date: noteDate,
    Time: noteTime,
    Job_ID: jobId,
    id: newId
  } 

  // Fetch the current job data
  const currentJobData = await getJobData(jobId);

  // If Notes doesn't exist, create it
  if (!currentJobData.Notes) {
    currentJobData.Notes = {};
  }

  // Add the new note to the Notes object
  currentJobData.Notes[newId] = newNote;

  // Update the job with the new Notes object
  await updateJobNotes(jobId, 'Notes', currentJobData.Notes);
  document.getElementById('note-text').value = '';
  
  const noteArea = document.getElementById('note-area');
  const noteContainer = document.createElement('div');
  noteContainer.className = 'note-pair';
  noteContainer.id = newId;

  const noteInfo = document.createElement('div');
  noteInfo.className = 'note-info';
              
  const noteText = document.createElement('p');
  noteText.textContent = note;

  const techInfo = document.createElement('div');
  techInfo.className = 'note-info-tech';

  techInfo.textContent = `${tech.displayName} | ${noteDate}, ${noteTime}`;

  const deleteButton = document.createElement('button');
  deleteButton.textContent = 'Delete';
  deleteButton.className = 'delete-note';
  deleteButton.addEventListener('click', async () => {
    await deleteNotes(jobId, newId);
    noteContainer.remove();
  });

  noteInfo.appendChild(noteText);
  noteInfo.appendChild(techInfo); 
  noteInfo.appendChild(deleteButton);
  noteContainer.appendChild(noteInfo);
  
  noteArea.appendChild(noteContainer);
});


async function updateJobNotes(jobId, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/data/${jobId}.json?auth=${idToken}`;
    
    const partialUpdate = { [index]: value };
    
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateJobNotesDB(jobId, index, value);
    updateTimestamps('jobData','data');
    console.log("IndexedDB update successful");
  } catch (error) {
    console.error("Error updating job info:", error);
  }
}

async function updateJobNotesDB(jobId, index, value) {

  try{
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function(event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("jobData")) {
        db.createObjectStore("jobData", { keyPath: "id" }); // Assuming 'Job_ID' is the unique identifier
      }
    };
  
    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");
      
      // Use the get method to fetch the individual item using jobId
      const getRequest = objectStore.get(jobId);
      console.log(getRequest)
      getRequest.onsuccess = function(event) {
        const itemToUpdate = event.target.result;
        
        if (itemToUpdate) {
          // Update the value
          itemToUpdate[index] = value;
          
          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);
  
          updateRequest.onsuccess = function() {
            console.log(`Data updated for Job_ID ${jobId}`);
          };
  
          updateRequest.onerror = function() {
            console.error("Error updating data");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };
  
      getRequest.onerror = function() {
        console.error("Error fetching data");
      };
  
      // Handle the transaction completion
      transaction.oncomplete = function() {
        db.close(); // It's important to close the database when you're done
      };
    };
  
    openRequest.onerror = function() {
      console.error("Error opening database");
    };
  }catch(error){
    console.log("Error updating IndexedDB", error);
  };
}

async function deleteNotes(jobId, noteId) {
  try {
    // Delete from Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/data/${jobId}/Notes/${noteId}.json?auth=${idToken}`;
    
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    console.log("Firebase delete successful");

    // Delete from IndexedDB
    await deleteNotesDB(jobId, noteId);
    updateTimestamps('jobData','data');
    console.log("IndexedDB delete successful");
  } catch (error) {
    console.error("Error deleting note:", error);
  }
}

async function deleteNotesDB(jobId, noteId) {
  console.log(jobId, noteId);
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onsuccess = function(event) {
      const db = event.target.result;
      const transaction = db.transaction("jobData", "readwrite");
      const objectStore = transaction.objectStore("jobData");
      
      const getRequest = objectStore.get(jobId);

      getRequest.onsuccess = function(event) {
        const itemToUpdate = event.target.result;
        
        if (itemToUpdate) {
          // Remove the note
          delete itemToUpdate.Notes[noteId];
          
          // Put the updated item back into the store
          const updateRequest = objectStore.put(itemToUpdate);

          updateRequest.onsuccess = function() {
            console.log(`Note deleted for Job_ID ${jobId}`);
          };

          updateRequest.onerror = function() {
            console.error("Error deleting note");
          };
        } else {
          console.error(`No item found with Job_ID ${jobId}`);
        }
      };

      getRequest.onerror = function() {
        console.error("Error fetching data");
      };

      transaction.oncomplete = function() {
        db.close();
      };
    };

    openRequest.onerror = function() {
      console.error("Error opening database");
    };
  } catch(error) {
    console.log("Error updating IndexedDB", error);
  }
}

async function updateCustomerInfo(customerId, index, value) {
  try {
    // Update Firebase Realtime Database
    const idToken = await fetchIdToken();
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/customers/${customerId}.json?auth=${idToken}`;
    
    const partialUpdate = { [index]: value };
    
    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Firebase update successful", result);

    // Update IndexedDB
    await updateCustomerDB(customerId, index, value);
    updateTimestamps('customerData','customers');
    console.log("IndexedDB update successful");

  } catch (error) {
    console.error("Error updating job info:", error);
  }
}



async function removeJobFromTech(jobId, tech, changed) {
  const techId = getTechId(tech);
  const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/";
  const schedulePath = `scheduleDatabase/${techId}/jobs/${jobId}.json`;
  const dataPath = `data/${jobId}.json`;

  try {
    // Remove the job from the old technician's schedule
    await fetch(firebaseUrl + schedulePath, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      }
    });
    console.log("Job removed successfully from old tech");

    // Update the job's Techs field in the /data node
    const updateResponse = await fetch(firebaseUrl + dataPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Techs: "" }) // Set Techs to an empty string
    });
    
    if (updateResponse.ok) {
      console.log(`Techs cleared for job ${jobId}`);
    } else {
      console.error(`Failed to clear Techs for job ${jobId}`);
    }
  } catch (e) {
    console.error("Error removing job from old tech or clearing Techs: " + e.toString());
  }
  if(!changed){
    await updateJobDB(jobId, 'Techs', '');
  }
}


async function getTechSchedule() {
 document.getElementById('techField').innerHTML = ''
 document.getElementById('workList').innerHTML = ''

 const currentUser = auth.currentUser;
 const hasAccess = await getUserRole(currentUser.uid)
 const idToken = await fetchIdToken();

  const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/scheduleDatabase.json?auth=" + idToken;
  try {
      const response = await fetch(firebaseUrl);
      const scheduleData = await response.json();
      const currentUser = auth.currentUser;
      const hasAccess = await getUserRole(currentUser.uid);
      let totalHoursForAllTechs = [];
      let techSort = []

      console.log(scheduleData);

      Object.keys(scheduleData).forEach(async techId =>{
        techSort.push(scheduleData[techId])
      })

      techSort.sort((a, b) => {
        if (a.name == null && b.name == null) return 0;
        if (a.name == null) return 1;
        if (b.name == null) return -1;
        return a.name.localeCompare(b.name);
      });

        techSort.forEach(tech => {

          if(!tech.name){ return }

          const techName = tech.name;
          const techDiv = document.createElement('div');
          techDiv.className = 'tech-div'
          const techP = document.createElement('p')
          const techPHours = document.createElement('p')
          techP.innerHTML = techName;
          const techId = tech.id;
          const techJobs = Object.values(scheduleData[techId].jobs || {});
          const techHours = tech.hours;
          

          // Sort jobs by date as in your original code
          techJobs.sort((a, b) => {
              const dateA = new Date(a.date).getTime(); // Assuming `date` is directly under job
              const dateB = new Date(b.date).getTime();
              return dateA - dateB;
          });

          // Follow similar steps to create and append job elements to the techTableDiv
          const techTableDiv = document.querySelector('#workList');
          const techNameContainer = document.createElement('fieldset');
          const label = document.createElement('legend');
          

      let totalHoursTech = 0

      techJobs.forEach(job => {
      if(!job){
        return;
      }
      var jobDiv = document.createElement('div');
      jobDiv.className = 'job-item'; 
      jobDiv.setAttribute('data-id',job.Job_ID);
      jobDiv.setAttribute('data-tech',tech.name);
      jobDiv.setAttribute('data-techId',tech.id);
      jobDiv.setAttribute('data-jobInfo',JSON.stringify(job));
      jobDiv.setAttribute('data-status', job.Status);
      jobDiv.addEventListener('click', (e) => {
        opCheck(e);
      });
      /* jobDiv.addEventListener('click', (e) => {
        e.stopPropagation(); // Prevent the click from bubbling up
        const jobId = e.target.closest('.job-item').getAttribute('data-id');
        fetchAndLogRecord(jobId)
          .then(record => {
            console.log('Fetched job record:', record);
          })
          .catch(error => {
            console.error('Failed to fetch job record:', error);
          });
      }); */

        const statusDiv = document.createElement('div');
        statusDiv.className = 'status-info';
        if(job.Status === 'In Progress'){
          statusDiv.style.backgroundColor = 'yellow';
        }else if(job.Status === 'Hold'){
          statusDiv.style.backgroundColor = 'gray'
        }else{statusDiv.style.backgroundColor = 'green'};
        statusDiv.innerHTML = ' ';

      const infoDiv = document.createElement('div');
      infoDiv.className = 'info-div-tech';
      
      const customerDiv = document.createElement('div');
      customerDiv.className = 'customer-boat-info';

      const customerNameP = document.createElement('p');
      customerNameP.style.fontWeight = 'bold';
      customerNameP.style.fontSize = '1rem';
      customerNameP.innerHTML = job.Customer_Name;

      const boatNameP = document.createElement('p');
      boatNameP.style.color = 'gray';
      boatNameP.innerHTML = job.Boat_Name;

      customerDiv.appendChild(customerNameP);
      customerDiv.appendChild(boatNameP);

      const descriptionDiv = document.createElement('div');
      descriptionDiv.className = 'description-info';
      const jobDescriptionP = document.createElement('p');

      let jobDescription;

      if(job.Description.toLowerCase().includes('bottom paint')){
        const originalData = job.Description;
        const startIndex = originalData.indexOf('(Paint Type:');
        const endIndex = originalData.indexOf(']', startIndex) + 1;

        if (startIndex !== -1 && endIndex !== -1) {
        const extractedSubstring = originalData.substring(startIndex, endIndex);

        if(job.Description.split(':')[0].includes(',')){
          jobDescription = `${job.Description.split(':')[0].split(',')[0]} : ${extractedSubstring}`
        }else{jobDescription = job.Description.split(':')[0] + ": " + extractedSubstring};
      }
    }else if(job.Description.toLowerCase().includes('replace anodes')){
      const originalData = job.Description;
      const startIndex = originalData.indexOf('(Anode List:');
      if (startIndex !== -1) {
        const extractedSubstring = originalData.substring(startIndex);
        if(job.Description.split(':')[0].includes(',')){
          jobDescription = `${job.Description.split(':')[0].split(',')[0]} : ${extractedSubstring}`
        }
        jobDescription = job.Description.split(':')[0] + ": " + extractedSubstring;
      }
    }else{

       if(job.Description.split(':')[0].includes(',')){
          jobDescription = job.Description.split(':')[0].split(',')[0]
        }else{jobDescription = job.Description.split(':')[0]};
      };

      jobDescriptionP.innerHTML = jobDescription;
      descriptionDiv.appendChild(jobDescriptionP);

      infoDiv.appendChild(customerDiv);
      infoDiv.appendChild(descriptionDiv);
      
      jobDiv.appendChild(statusDiv);
      jobDiv.appendChild(infoDiv);

      // Create a div to hold status and hours information
      const statusHoursDiv = document.createElement('div');
      statusHoursDiv.className = 'status-hours-info';
      statusHoursDiv.style.display = "none";

      const statusP = document.createElement('p');
      statusP.innerHTML = `<strong>Status:</strong> ${job.Status}`;
      statusHoursDiv.appendChild(statusP);

      const estimatedHoursP = document.createElement('p');
      estimatedHoursP.innerHTML = `<strong>Estimated Hours:</strong> ${job.Estimated_Hours}`;
      statusHoursDiv.appendChild(estimatedHoursP);

      totalHoursTech += Number(job.Estimated_Hours)

      let total = 0

      if(jobHours[job.Job_ID]){
        total = jobHours[job.Job_ID]
      }

      const totalHoursP = document.createElement('p');
      totalHoursP.innerHTML = `<strong>Total Hours:</strong> ${total}`;
      statusHoursDiv.appendChild(totalHoursP);

      const noteDiv = document.createElement('div');
      noteDiv.className = 'description-info';
      const jobNotesP = document.createElement('p');
      jobNotesP.innerHTML = `<strong>Notes</strong><br> ${job.Notes}`;
      noteDiv.appendChild(jobNotesP);

      if(job.notes){
        jobDiv.appendChild(noteDiv);
      }

      jobDiv.appendChild(statusHoursDiv);

/*       if (hasAccess === 'admin') {
          console.log('Has Access: ' + hasAccess);
              const moveJobButton = document.createElement('button');
              moveJobButton.innerHTML = '<i class="material-icons">menu</i>';
              moveJobButton.className = 'move-job-button';
              moveJobButton.addEventListener('click', async (e) => {
                e.stopPropagation();
                const techNames = ["Alex", "Antony", "Conrad", "Darlin", "Eduardo", "Elvin", "Henry", "Jorge", "Kyle", "Liam", "Mac"];
                const currentTechName = e.target.closest('.job-item').getAttribute('data-tech');
                const jobId = e.target.closest('.job-item').getAttribute('data-id');
                const currentTechId = e.target.closest('.job-item').getAttribute('data-techId');
                const jobInfo = e.target.closest('.job-item').getAttribute('data-jobInfo');
                const popup = createTechPopup(techNames, currentTechName, jobId, currentTechId, jobInfo);

                // Position the popup near the button
                const rect = e.target.getBoundingClientRect();
                popup.style.top = `${rect.bottom + window.scrollY}px`;
                popup.style.left = `${rect.left + window.scrollX}px`;
              });

              const removeJobButton = document.createElement('button');
              removeJobButton.innerHTML = '<i class="material-icons">close</i>';
              removeJobButton.className = 'remove-job-button';
              removeJobButton.addEventListener('click', async (e) => {
                e.stopPropagation();
                await removeJobFromTech(job.Job_ID, techName);
              });

              jobDiv.appendChild(moveJobButton);
              jobDiv.appendChild(removeJobButton);
            
      } */

      techNameContainer.appendChild(jobDiv);
    });

    let availHours = Number(techHours) - Number(totalHoursTech)
    label.textContent = techName + " (Available: " + availHours + " Hours)" || techId; // Fallback to techId if name is not found
      techNameContainer.appendChild(label);
      techTableDiv.appendChild(techNameContainer);
      techPHours.innerHTML = totalHoursTech
      techDiv.appendChild(techP)
      techDiv.appendChild(techPHours)
      document.getElementById('techField').appendChild(techDiv);

    });

  } catch (error) {
      console.error("Error fetching schedule data:", error);
  }
}


function createTechPopup(techNames, currentTechName, jobId, currentTechId,jobInfo) {
  // Remove existing popup if any
  const existingPopup = document.getElementById('tech-popup');
  if (existingPopup) {
      existingPopup.remove();
  }

  // Create the popup container
  const popup = document.createElement('div');
  popup.id = 'tech-popup';
  popup.style.position = 'absolute';
  popup.style.zIndex = '100';
  popup.style.backgroundColor = '#fff';
  popup.style.border = '1px solid #ddd';
  popup.style.padding = '10px';
  popup.style.borderRadius = '5px';
  popup.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';
  
  // Ensure the popup is placed correctly
  document.body.appendChild(popup);
  
  // Populate the popup with tech names
  techNames.forEach(techName => {
      if (techName !== currentTechName) { // Exclude the current technician
          const techOption = document.createElement('div');
          techOption.textContent = techName;
          techOption.style.padding = '5px';
          techOption.style.cursor = 'pointer';
          techOption.addEventListener('click', () => {

            addJobFromSchedule(techName,jobId, jobInfo)
            removeJobFromTech(jobId, currentTechName)
              
              popup.remove(); // Close the popup

          });
          popup.appendChild(techOption);
      }
  });
  const handleOutsideClick = (event) => {
    if (!popup.contains(event.target) && !jobDiv.contains(event.target)) {
      popup.remove();
      document.removeEventListener('click', handleOutsideClick);
    }
  };
  document.addEventListener('click', handleOutsideClick);
  return popup;
}

function jobListPopup(techNames, jobId, jobInfo, jobDiv, currentTech) {
  // Remove existing popup if any

  const existingPopup = document.getElementById('tech-popup');
  if (existingPopup) {
      existingPopup.remove();
  }

  // Create the popup container
  const popup = document.createElement('div');
  popup.id = 'tech-popup';
  popup.style.position = 'absolute';
  popup.style.zIndex = '100';
  popup.style.backgroundColor = '#fff';
  popup.style.border = '1px solid #ddd';
  popup.style.padding = '10px';
  popup.style.borderRadius = '5px';
  popup.style.boxShadow = '0 2px 5px rgba(0,0,0,0.2)';

  const deleteOption = document.createElement('div');
  deleteOption.textContent = 'Delete';
  deleteOption.style.padding = '5px';
  deleteOption.style.cursor = 'pointer';
  deleteOption.addEventListener('click', async (e) => {
    e.stopPropagation();
    try {
      await removeTech(jobId)
      await updateJobDB(jobId, 'Techs', '');
      populateJobsForCurrentTech();
      updateWeekView();
      popup.remove();
      // Update the tech name in the HTML
      if (jobDiv && jobDiv.querySelector) {
        console.log(jobDiv);
          const techInfoElement = jobDiv
          if (techInfoElement) {
              techInfoElement.innerHTML = `<strong>Tech: </strong>`;
          } else {
              console.error('Could not find .tech-info element');
          }
      } else {
          console.error('updateSection is not a valid HTML element');
      }
    } catch (error) {
      console.error('Error updating job:', error);
    }
  });
  popup.appendChild(deleteOption);

  // Ensure the popup is placed correctly
  document.body.appendChild(popup);

  // Populate the popup with tech names
  techNames.forEach(techName => {
      if (techName !== currentTech) { // Exclude the current technician
          const techOption = document.createElement('div');
          techOption.textContent = techName;
          techOption.style.padding = '5px';
          techOption.style.cursor = 'pointer';
          techOption.addEventListener('click', async (e) => {
            
              e.stopPropagation();
              try {

                      await addJobFromJobList(techName, jobId, jobInfo);
                      await updateJobDB(jobId, 'Techs', techName);
                      populateJobsForCurrentTech();
                  
                  // Update the tech name in the HTML
                  if (jobDiv && jobDiv.querySelector) {
                    console.log(jobDiv);
                      const techInfoElement = jobDiv
                      if (techInfoElement) {
                          techInfoElement.innerHTML = `<strong>Tech: </strong>${techName}`;
                      } else {
                          console.error('Could not find .tech-info element');
                      }
                  } else {
                      console.error('updateSection is not a valid HTML element');
                  }
                  
                  popup.remove();
              } catch (error) {
                  console.error('Error updating job:', error);
              }
          });
          popup.appendChild(techOption);
      }
  });

  const handleOutsideClick = (event) => {
      if (!popup.contains(event.target) && !jobDiv.contains(event.target)) {
          popup.remove();
          document.removeEventListener('click', handleOutsideClick);
      }
  };
  document.addEventListener('click', handleOutsideClick);

  return popup;
}

async function addJobFromSchedule(techName, jobId, data) {
const techId = getTechId(techName);
const idToken = await fetchIdToken();
const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/";
const path = `scheduleDatabase/${techId}/jobs/${jobId}.json?auth=${idToken}`;

var dataObj;
if (typeof data === 'string') {
  try {
    dataObj = JSON.parse(data);
  } catch (e) {
    console.error("Error parsing data string into JSON:", e);
    return; 
  }
} else {
  dataObj = data; 
}

try {
  const response = await fetch(firebaseUrl + path, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(dataObj)
  });
  const result = await response.json();
  console.log("Job added successfully", result);
} catch (e) {
  console.error("Error adding job to tech: " + e.toString());
}
}

document.addEventListener('DOMContentLoaded', function() {
  // Create tooltip element and add it to the body
  var tooltip = document.createElement('div');
  tooltip.className = 'tooltip';
  document.body.appendChild(tooltip);
  tooltip.style.display='block'
  // Function to show the tooltip
  function showTooltip(e) {
    var tooltipText = this.getAttribute('data-tooltip');
    if (!tooltipText) return;

    tooltip.innerHTML = tooltipText;
    tooltip.style.display = 'block';
    tooltip.style.position = 'absolute'
    tooltip.style.right = '100px';
    tooltip.style.top = '50px';
  }

  // Function to hide the tooltip
  function hideTooltip() {
    tooltip.style.display = 'none';
  }

  // Attach event listeners to buttons with data-tooltip attribute
  var buttons = document.querySelectorAll('[data-tooltip]');
  buttons.forEach(function(button) {
    button.addEventListener('mousemove', showTooltip);
    button.addEventListener('mouseleave', hideTooltip);
  });
});



async function addJobFromJobList(techName, jobId, data) {

  const techId = getTechId(techName);
  const idToken = await fetchIdToken();
  const firebaseUrl = "https://marine-center-database-default-rtdb.firebaseio.com/";
  const schedulePath = `scheduleDatabase/${techId}/jobs/${jobId}.json?auth=${idToken}`;
  const dataPath = `data/${jobId}.json?auth=${idToken}`;

  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1
  const day = String(currentDate.getDate()).padStart(2, '0');
  const formattedDate = `${year}-${month}-${day}`;


  let dataObj;
  if (typeof data === 'string') {
    try {
      dataObj = JSON.parse(data);
    } catch (e) {
      console.error("Error parsing data string into JSON:", e);
      return; 
    }
  } else {
    dataObj = data; 
  }

  let dataUpload = {
    Boat_ID: dataObj.Boat_ID,
    Boat_Name: dataObj.Boat_Name,
    Category: dataObj.Category,
    Customer_Name: dataObj.Customer_Name,
    Customer_ID: dataObj.Customer_ID,
    Start_Date: dataObj.Start_Date,
    Description: dataObj.Description,
    Email: dataObj.Email,
    Haul_Date: dataObj.Haul_Date,
    Estimated_Hours: dataObj.Estimated_Hours,
    Job_ID: dataObj.Job_ID,
    Launch_Date: dataObj.Launch_Date,
    Notes: dataObj.Notes,
    OpType: dataObj.Op_Type,
    Status: dataObj.Status,
    Techs: techName,
    Work_Order: dataObj.Work_Order,
    Schedule_Date: formattedDate
  };

  try {
    // Update the job under the technician's schedule
    const scheduleResponse = await fetch(firebaseUrl + schedulePath, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(dataUpload)
    });
    const scheduleResult = await scheduleResponse.json();
    console.log("Job added to tech schedule successfully", scheduleResult);

    // Update the Techs field for the job in /data
    const dataResponse = await fetch(firebaseUrl + dataPath, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Techs: techName,
       }) // This updates just the Techs and Schedule Date fields
    });
    const dataResult = await dataResponse.json();
    
    updateJobDB(jobId, 'Techs', techName);
    console.log("Techs field updated in /data successfully", dataResult);
  } catch (e) {
    console.error("Error updating job data: " + e.toString());
  }
}





  let isUpdating = false;

  function listenForUpdates() {

    if(!navigator.onLine){
      console.log('User is offline. Skipping function.');
      return;
    }
      
      const scheduleRef = ref(database, '/scheduleDatabase');
      onValue(scheduleRef, async (snapshot) => {
          if (!isUpdating) {
              isUpdating = true; // Indicate an update is in progress
              document.getElementById('workList').innerHTML = ''; // Clear the content
              isUpdating = false; // Reset the flag once update is complete
              
              
          }
      });
  }
  
  listenForUpdates();
  


document.getElementById('job-search').addEventListener('keyup', filterJobs)

document.getElementById('schedule-search').addEventListener('keyup', filterSchedule)


function filterTechs() {
  var searchQuery = document.getElementById('searchFieldTechs').value.toLowerCase();

  var fieldsets = document.querySelectorAll('#workList fieldset');

  fieldsets.forEach(function(fieldset) {
      var jobs = fieldset.querySelectorAll('.job-item');
      var legendText = fieldset.querySelector('legend') ? fieldset.querySelector('legend').textContent.toLowerCase() : '';
      var jobMatchFound = false;

      jobs.forEach(function(job) {
          var jobText = job.textContent.toLowerCase();
          if (jobText.includes(searchQuery) || legendText.includes(searchQuery)) {
              job.style.display = '';
              jobMatchFound = true;
          } else {
              job.style.display = 'none';
          }
      });

      fieldset.style.display = jobMatchFound ? '' : 'none';
  });
}

var selectedButtons = {};

function filterJobs() {
  var searchQuery = document.getElementById('job-search').value.toLowerCase();
  var allJobs = document.querySelectorAll('#jobList .job-item');

  allJobs.forEach(function(job) {
    var jobData = JSON.parse(job.getAttribute('data-jobinfo'));
    delete jobData.Notes;
    var jobMatchFound = false;

    // Check if the job matches any of the selected buttons
    for (var button in selectedButtons) {
      if (selectedButtons[button]) {
        var buttonText = button.toLowerCase();

        for (var key in jobData) {
          if (jobData.hasOwnProperty(key)) {
            var value = jobData[key].toString().toLowerCase();
            if (value.includes(buttonText)) {
              jobMatchFound = true;
              break;
            }
          }
        }

        if (jobMatchFound) {
          break;
        }
      }
    }


    if (Object.values(selectedButtons).every(state => !state)) {
      jobMatchFound = true;
    }

    
    if (jobMatchFound && searchQuery) {
      jobMatchFound = false;
      for (var key in jobData) {
        if (jobData.hasOwnProperty(key)) {
          var value = jobData[key].toString().toLowerCase();
          if (value.includes(searchQuery)) {
            jobMatchFound = true;
            break;
          }
        }
      }
    }

    job.style.display = jobMatchFound ? '' : 'none';
    
  });
  if (searchQuery.length == 0 && Object.values(selectedButtons).every(state => !state)) {
  allJobs.forEach(function(job) {
    job.style.display = 'flex';
  });
}
}

function filterSchedule() {
  var searchQuery = document.getElementById('schedule-search').value.toLowerCase();
  var allJobs = document.querySelectorAll('#schedule-list .job-item');

  allJobs.forEach(function(job) {
    var jobData = JSON.parse(job.getAttribute('data-jobinfo'));
    delete jobData.Notes;
    var jobMatchFound = false;

    // Check if the job matches any of the selected buttons
    for (var button in selectedButtons) {
      if (selectedButtons[button]) {
        var buttonText = button.toLowerCase();

        for (var key in jobData) {
          if (jobData.hasOwnProperty(key)) {
            var value = jobData[key].toString().toLowerCase();
            if (value.includes(buttonText)) {
              jobMatchFound = true;
              break;
            }
          }
        }

        if (jobMatchFound) {
          break;
        }
      }
    }


    if (Object.values(selectedButtons).every(state => !state)) {
      jobMatchFound = true;
    }

    
    if (jobMatchFound && searchQuery) {
      jobMatchFound = false;
      for (var key in jobData) {
        if (jobData.hasOwnProperty(key)) {
          var value = jobData[key].toString().toLowerCase();
          if (value.includes(searchQuery)) {
            jobMatchFound = true;
            break;
          }
        }
      }
    }

    job.style.display = jobMatchFound ? '' : 'none';
    
  });
  if (searchQuery.length == 0 && Object.values(selectedButtons).every(state => !state)) {
  allJobs.forEach(function(job) {
    job.style.display = 'flex';
  });
}
}

// Toggle the selected state of a button
function toggleButton(button) {
  selectedButtons[button.textContent] = !selectedButtons[button.textContent];
  button.classList.toggle('selected');
  const selected = document.querySelectorAll('.selected');
  filterJobs();
}

// Attach click event listeners to the buttons
document.addEventListener('DOMContentLoaded',()=>{
  const buttons = document.querySelectorAll('.filter-button');
    buttons.forEach(function(button) {
      button.addEventListener('click', function() {
        toggleButton(this);
      });
  });
});


// Attach input event listener to the search field
var searchField = document.getElementById('job-search');
searchField.addEventListener('input', filterJobs);

let usingSchedule = false



function fetchAndLogRecord(jobId) {
  return new Promise((resolve, reject) => {
    
    const laborRef = ref(database, 'data/' + jobId);

    get(laborRef).then((snapshot) => {
      const record = snapshot.val();
      if (record) {
        resolve(record);
        opCheckSchedule(record);
        usingSchedule = true;
        document.getElementById('tech-data').style.display = "none";
      } else {
        console.log('No record found with jobId:', jobId);
        reject('No record found');
      }
    }).catch((error) => {
      console.error('Error fetching record:', error);
      reject(error);
    });
  });
}




function changeJobTech(){
const oldTech = this.getAttribute('data-selected-tech'); // Get the old technician
  const newTech = this.value; // Get the new technician
  this.setAttribute('data-selected-tech', newTech); // Update the selected technician

  const id = this.closest('tr').children[0].innerHTML; // Assuming this is the job ID
  const data = this.closest('tr').children;

  // Step 1: Remove the job from the old technician's list
  if (oldTech) {
      removeJobFromTech(id, oldTech); // You'll need to implement this
  }

  // Step 2: Add the job to the new technician's list
  addJobToTech(data, newTech);

}

async function generateLaborTable(id) {
  try {
    const laborContainer = document.getElementById('labor-table-body-current');
    laborContainer.innerHTML = '';

    const headerDiv = document.createElement('div');
    headerDiv.className = 'labor-entry header';
    headerDiv.innerHTML = `
      <p class="labor-tech hide-mobile">Tech</p>
      <p class="labor-data description">Description</p>
      <p class="labor-data hours">Hours</p>
      <p class="labor-data date hide-mobile">Date</p>
    `;
    laborContainer.appendChild(headerDiv);

    const request = indexedDB.open('openDatabase');
  
    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');
  
      const getRequest = objectStore.get(id);
  
      getRequest.onsuccess = (event) => {
        const job = event.target.result;
        if (job && job.Labor) {
          const laborEntries = Object.values(job.Labor);
          
          // Sort labor entries by Time_Submitted
          laborEntries.sort((a, b) => new Date(a.Time_Submitted) - new Date(b.Time_Submitted));
          
          laborEntries.forEach(laborEntry => {
            const laborDiv = document.createElement('div');
            laborDiv.className = 'labor-entry';
            appendLaborDataToDiv(laborDiv, laborEntry);
            laborContainer.appendChild(laborDiv);
          });
          
          document.getElementById('labor-header').innerHTML = 'Labor' + ' (' + laborEntries.length + ')';
        } else {
          console.log("No matching labor data found.");
        }
      };
  
      getRequest.onerror = (event) => {
        console.error("Error fetching labor data: ", event.target.error);
      };
    };
  
    request.onerror = (event) => {
      console.error("Error opening IndexedDB: ", event.target.error);
    };
  } catch(error) {
    console.error("Error fetching labor data: ", error);
  }
}

function appendLaborDataToDiv(laborDiv, laborEntry) {
  laborDiv.className = 'labor-entry';
  laborDiv.setAttribute('data-id', laborEntry.ID);
  
  const techDiv = document.createElement('div');
  techDiv.className = 'labor-tech hide-mobile';
  techDiv.innerHTML = `
    <p class="labor-data" contenteditable="true">${laborEntry.Tech || ''}</p>
  `;
  
  const descriptionP = document.createElement('p');
  descriptionP.className = 'labor-data description';
  descriptionP.contentEditable = true;
  descriptionP.textContent = laborEntry.Description || '';
  
  const hoursP = document.createElement('p');
  hoursP.className = 'labor-data hours';
  hoursP.contentEditable = true;
  hoursP.textContent = laborEntry.Hours || '';
  
  const dateP = document.createElement('p');
  dateP.className = 'labor-data date';
  dateP.classList.add('hide-mobile');
  dateP.textContent = laborEntry.Time_Submitted || '';
  
  const deleteDiv = document.createElement('div');
  deleteDiv.className = 'labor-part-delete-div';
  const deleteButton = document.createElement('button');
  deleteButton.className = 'labor-data-delete-button table-button';
  deleteButton.innerHTML = "<i class='material-icons'>clear</i>";
  deleteButton.addEventListener('click', deleteLabor);

  deleteDiv.appendChild(deleteButton);
  
  laborDiv.appendChild(techDiv);
  laborDiv.appendChild(descriptionP);
  laborDiv.appendChild(hoursP);
  laborDiv.appendChild(dateP);
  laborDiv.appendChild(deleteDiv);

  let holdTimer;
  let holdDelayType = 3000;
    
  laborDiv.addEventListener('input', (e) => {
    clearTimeout(holdTimer);
  });
    
  laborDiv.addEventListener('keyup', (e) => {
    clearTimeout(holdTimer);
    holdTimer = setTimeout(() => {
      const laborData = {
        'Tech': techDiv.querySelector('.labor-data').textContent,
        'Description': descriptionP.textContent,
        'Hours': hoursP.textContent,
        'Category': document.getElementById('currentCategory').value,
        'Op_Type': document.getElementById('currentOpType').value,
        'Job_ID': document.getElementById('currentJobId').value,
        'ID': laborEntry.ID,
        'Boat_Name': document.getElementById('currentBoat').value,
        'Customer_Name': document.getElementById('currentName').value,
        'Time_Submitted': getTodaysDate(),
        'Work_Order': document.getElementById('currentWorkOrder').value
      };

      addLaborFirebase(laborData);
    }, holdDelayType);
  });
}

async function generatePartsTable(id) {
  try {
    const partsContainer = document.getElementById('parts-table-body-current');
    partsContainer.innerHTML = '';

    const headerDiv = document.createElement('div');
    headerDiv.className = 'part-entry header';
    headerDiv.innerHTML = `
      <p class="part-tech hide-mobile">Tech</p>
      <p class="part-number">Part Number</p>
      <p class="part-data quantity">Qty</p>
      <p class="part-data date hide-mobile">Date</p>
    `;
    partsContainer.appendChild(headerDiv);

    const request = indexedDB.open('openDatabase');
  
    request.onsuccess = (event) => {
      const db = event.target.result;
      const transaction = db.transaction(['jobData'], 'readonly');
      const objectStore = transaction.objectStore('jobData');
  
      const getRequest = objectStore.get(id);
  
      getRequest.onsuccess = (event) => {
        const job = event.target.result;
        if (job && job.Parts) {
          const partEntries = Object.values(job.Parts);
          
          // Sort part entries by Time_Submitted
          partEntries.sort((a, b) => new Date(a.Time_Submitted) - new Date(b.Time_Submitted));
          
          partEntries.forEach(partEntry => {
            const partDiv = document.createElement('div');
            partDiv.setAttribute('data-id', partEntry.ID);
            partDiv.className = 'part-entry';
            appendPartsDataToDiv(partDiv, partEntry);
            partsContainer.appendChild(partDiv);
          });
          
          document.getElementById('parts-header').innerHTML = 'Parts' + ' (' + partEntries.length + ')';
        } else {
          console.log("No matching parts data found.");
        }
      };
  
      getRequest.onerror = (event) => {
        console.error("Error fetching parts data: ", event.target.error);
      };
    };
  
    request.onerror = (event) => {
      console.error("Error opening IndexedDB: ", event.target.error);
    };
  } catch(error) {
    console.error("Error fetching parts data: ", error);
  }
}

function appendPartsDataToDiv(partDiv, partEntry) {
  partDiv.className = 'part-entry';

  console.log(partEntry);
  const techDiv = document.createElement('div');
  techDiv.className = 'part-tech hide-mobile';
  techDiv.innerHTML = `
    <p class="part-data" contenteditable="true">${partEntry.Tech || ''}</p>
  `;
  
  const partNumberDiv = document.createElement('div');
  partNumberDiv.className = 'part-table-part-number';

  const partNumberP = document.createElement('p');
  partNumberP.className = 'part-table-part-data';
  partNumberP.contentEditable = true;
  partNumberP.textContent = partEntry.Part_Number || '';
  partNumberDiv.appendChild(partNumberP);

  const descriptionP = document.createElement('p');
  descriptionP.className = 'part-data part-description';
  descriptionP.textContent = partEntry.Description || '';
  descriptionP.style.color = 'gray';

  partNumberDiv.appendChild(descriptionP);

  const quantityP = document.createElement('p');
  quantityP.className = 'part-data quantity';
  quantityP.contentEditable = true;
  quantityP.textContent = partEntry.Quantity || '';
  
  const dateP = document.createElement('p');
  dateP.className = 'part-data date';
  dateP.classList.add('hide-mobile');
  dateP.textContent = partEntry.Time_Submitted || '';
  
  const deleteDiv = document.createElement('div');
  deleteDiv.className = 'labor-part-delete-div';
  const deleteButton = document.createElement('button');
  deleteButton.className = 'parts-data-delete-button table-button';
  deleteButton.innerHTML = "<i class='material-icons'>clear</i>";
  deleteButton.addEventListener('click', deleteParts);

  deleteDiv.appendChild(deleteButton);
  
  partDiv.appendChild(techDiv);
  partDiv.appendChild(partNumberDiv);
  partDiv.appendChild(quantityP);
  partDiv.appendChild(dateP);
  partDiv.appendChild(deleteDiv);

  let holdTimer;
  let holdDelayType = 3000;
    
  partDiv.addEventListener('input', (e) => {
    clearTimeout(holdTimer);
  });
    
  partDiv.addEventListener('keyup', (e) => {
    clearTimeout(holdTimer);
    holdTimer = setTimeout(() => {
      const partNumber = partNumberDiv.querySelector('.part-data').textContent;
      const jobId = document.getElementById('currentJobId').value;
      const partData = {
        'Part_Number': partNumber,
        'Quantity': quantityP.textContent,
        'Category': document.getElementById('currentCategory').value,
        'Op_Type': document.getElementById('currentOpType').value,
        'Job_ID': jobId,
        'ID': partEntry.ID, // Assuming ID is still needed
        'Boat_Name': document.getElementById('currentBoat').value,
        'Customer_Name': document.getElementById('currentName').value,
        'Time_Submitted': getTodaysDate(),
        'Work_Order': document.getElementById('currentWorkOrder').value,
        'Tech': document.getElementById('currentTech').value, // Assuming Tech is still needed
        'Description': partNumberDiv.querySelector('.part-description').textContent
      };

      addPartsFirebase(partData);
      updatePartsIndex(jobId, partData);
      updateTimestamps('jobData', 'data');
    }, holdDelayType);
  });
}

document.getElementById('labor-header').addEventListener('click', () => {
  const laborHeader = document.getElementById('labor-header');
  const laborTable = document.getElementById('labor-table-body-current');
  laborHeader.classList.toggle('closed-header');
  laborTable.classList.toggle('closed');
})

document.getElementById('parts-header').addEventListener('click', () => {
  const laborHeader = document.getElementById('parts-header');
  const laborTable = document.getElementById('parts-table-body-current');
  laborHeader.classList.toggle('closed-header');
  laborTable.classList.toggle('closed');
})

document.addEventListener('DOMContentLoaded', function() {
  document.querySelectorAll('svg').forEach(function(svg) {
    svg.addEventListener('click', function() {
      this.classList.toggle('toggle');
    });
  });
});


document.getElementById('launch').addEventListener('change',() => {
  const customerId = document.getElementById('customerId').value;
  const boatId = document.getElementById('boatId').value;
  const index = 'Launch_Date';
  const date = document.getElementById('launch').value;
  addHaulLaunchDates(customerId, boatId, index, date)
})

document.getElementById('haul').addEventListener('change',() => {
  const customerId = document.getElementById('customerId').value;
  const boatId = document.getElementById('boatId').value;
  const index = 'Haul_Date';
  const date = document.getElementById('haul').value;
  addHaulLaunchDates(customerId, boatId, index, date)
})

async function addHaulLaunchDates(customerId, boatId,index,date) {

  let updateDate
  if(index == 'Launch_Date'){
    updateDate = 'Updated_Launch_Date';
  }else if(index == 'Haul_Date'){
    updateDate = 'Updated_Haul_Date';
  }

  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token

    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/customers/${customerId}/Boats/${boatId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: date, [updateDate]:'' };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);
    addHaulLaunchDB(customerId, boatId, index, date, updateDate)
  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}

async function addHaulLaunchDB(customerId, boatId, index, value, updateDate) {
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'Customer_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      // Use the get method to fetch the individual item using customerId
      const getRequest = objectStore.get(customerId);

      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;
        if (itemToUpdate) {
          // Check if the Boats object exists in the customer data
          if (itemToUpdate.Boats && itemToUpdate.Boats[boatId]) {
            // Update the value for the specific boat
            itemToUpdate.Boats[boatId][index] = value;
            if(updateDate){
              itemToUpdate.Boats[boatId][updateDate] = '';
            }
            

            // Put the updated item back into the store
            const updateRequest = objectStore.put(itemToUpdate);

            updateRequest.onsuccess = function () {
              console.log(`Data updated for Customer ${customerId} and Boat ${boatId}`);
            };

            updateRequest.onerror = function () {
              console.error("Error updating data");
            };
          } else {
            console.error(`No boat found with ID ${boatId} for Customer ${customerId}`);
          }
        } else {
          console.error(`No item found with Customer ${customerId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  }
}

/* document.getElementById('launch').addEventListener('change',() => {
  const customerId = document.getElementById('customerId').value;
  const boatId = document.getElementById('boatId').value;
  const index = 'Launch_Date';
  const date = document.getElementById('launch').value;
  updateDates(customerId, boatId, index, date)
}) */

async function updateDates(customerId, boatId,index,date) {
  
  try {
    const idToken = await fetchIdToken(); // Assume this fetches the ID token
    console.log(idToken);
    const firebaseUrl = `https://marine-center-database-default-rtdb.firebaseio.com/customers/${customerId}/Boats/${boatId}.json?auth=${idToken}`;

    const partialUpdate = { [index]: date };

    const options = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(partialUpdate),
    };

    const response = await fetch(firebaseUrl, options);
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
    const result = await response.json();
    console.log("Operation successful", result);
    updateBoatDB(customerId, boatId, index, date)
  } catch (error) {
    console.error("Error modifying job status:", error);
  }
}

async function updateBoatDB(customerId, boatId, index, value) {
  try {
    const openRequest = window.indexedDB.open("openDatabase", currentIndexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      // Ensure that the object store is created only if it doesn't exist
      if (!db.objectStoreNames.contains("customerData")) {
        db.createObjectStore("customerData", { keyPath: "id" }); // Assuming 'Customer_ID' is the unique identifier
      }
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const transaction = db.transaction("customerData", "readwrite");
      const objectStore = transaction.objectStore("customerData");

      // Use the get method to fetch the individual item using customerId
      const getRequest = objectStore.get(customerId);

      getRequest.onsuccess = function (event) {
        const itemToUpdate = event.target.result;
        if (itemToUpdate) {
          // Check if the Boats object exists in the customer data
          if (itemToUpdate.Boats && itemToUpdate.Boats[boatId]) {
            // Update the value for the specific boat
            itemToUpdate.Boats[boatId][index] = value;

            // Put the updated item back into the store
            const updateRequest = objectStore.put(itemToUpdate);

            updateRequest.onsuccess = function () {
              console.log(`Data updated for Customer ${customerId} and Boat ${boatId}`);
            };

            updateRequest.onerror = function () {
              console.error("Error updating data");
            };
          } else {
            console.error(`No boat found with ID ${boatId} for Customer ${customerId}`);
          }
        } else {
          console.error(`No item found with Customer ${customerId}`);
        }
      };

      getRequest.onerror = function () {
        console.error("Error fetching data");
      };

      // Handle the transaction completion
      transaction.oncomplete = function () {
        db.close(); // It's important to close the database when you're done
      };
    };

    openRequest.onerror = function () {
      console.error("Error opening database");
    };
  } catch (error) {
    console.log("Error updating IndexedDB", error);
  }
}

async function fetchDataFromStore(storeName) {
  return new Promise((resolve, reject) => {
      const request = indexedDB.open('openDatabase', indexedVersion);

      request.onerror = (event) => {
          console.error('Database error:', event.target.errorCode);
          reject(event.target.errorCode);
      };

      request.onsuccess = (event) => {
          const db = event.target.result;
          const transaction = db.transaction([storeName], 'readonly');
          const objectStore = transaction.objectStore(storeName);
          const getRequest = objectStore.getAll();

          getRequest.onsuccess = () => {
              const data = getRequest.result;
              resolve(data);
          };

          getRequest.onerror = () => {
              reject(getRequest.error);
          };

          transaction.oncomplete = () => {
              db.close();
          };
      };
  });
}

async function populateVendorLists() {
  const data = await fetchDataFromStore('vendorData');
  const vendorList = Object.values(data).map(vendor => vendor.Vendor_Name);

  const vendorInputs = document.querySelectorAll('.vendor-select');

  vendorInputs.forEach(input => {
    // Create a wrapper div for positioning context
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative';
    input.parentNode.insertBefore(wrapper, input);
    wrapper.appendChild(input);

    const suggestionContainer = document.createElement('div');
    suggestionContainer.classList.add('suggestion-list');
    suggestionContainer.style.display = 'none';
    suggestionContainer.style.position = 'absolute';
    suggestionContainer.style.width = '100%';
    suggestionContainer.style.top = '100%';
    suggestionContainer.style.left = '0';
    wrapper.appendChild(suggestionContainer);

    input.addEventListener('input', async function() {
      const query = this.value.toLowerCase().trim();
      suggestionContainer.innerHTML = ''; // Clear previous suggestions

      const filteredVendors = vendorList.filter(vendor => 
        vendor.toLowerCase().includes(query)
      );

      filteredVendors.forEach(vendor => {
        const suggestionItem = document.createElement('div');
        suggestionItem.classList.add('suggestion-item');
        suggestionItem.textContent = vendor;
        suggestionItem.addEventListener('click', () => {
          input.value = vendor;
          suggestionContainer.style.display = 'none';
        });
        suggestionContainer.appendChild(suggestionItem);
      });

      suggestionContainer.style.display = filteredVendors.length ? 'block' : 'none';
    });

    // Hide suggestions when clicking outside
    document.addEventListener('click', function(e) {
      if (e.target !== input && !suggestionContainer.contains(e.target)) {
        suggestionContainer.style.display = 'none';
      }
    });
  });
}

function callOpenCustomerData(customerId) {

  const customersIframe = document.getElementById('customers').querySelector('iframe');
  if (customersIframe) {
    customersIframe.contentWindow.postMessage({
      type: 'customerLookup',
      customerId: customerId,
      return: 'open-work'
    }, '*');
  }

  const elements = document.querySelectorAll('.page-view')
  elements.forEach( page => {
    page.style.display = 'none';
    page.style.visibility = 'hidden';
  });

  document.getElementById('customers').style.display = 'flex';
  document.getElementById('customers').style.visibility = 'visible';
  document.getElementById('current-page').innerHTML = "Customer Database";
}

window.addEventListener('message', function(event) {
  const { type, from } = event.data;
  
  if (from === 'maps') {
      console.log('Data received from maps iframe:', event.data);
  } else if (from === 'spring') {
    if(type === 'opCheck'){
      const jobData = event.data.info;
      opCheck('', jobData);
     }
  } else if (from === 'customers') {
      console.log('Data received from customers iframe:', event.data);
      console.log(event);
      
      // Check if the message is about a new customer being added
      if (type === 'customerAdded') {
          console.log('Sending message to rentalDatabase iframe');
          // Forward the message to the rentalDatabase iframe
          const rentalDatabaseIframe = document.getElementById('rentalDatabase');
          rentalDatabaseIframe.contentWindow.postMessage({
              type: 'generateCustomerTable',
              from: 'main'
          }, '*');
          
          // Update customer list in the main app
          fetchCustomerDataAndGenerateTable();
      }
      
      // Test if the messages are working
      if (type === 'returnView') {
          const elements = document.querySelectorAll('.page-view')
          elements.forEach( page => {
              page.style.display = 'none';
              page.style.visibility = 'hidden';
          });
          
          document.getElementById('open-work').style.display = 'flex';
          document.getElementById('open-work').style.visibility = 'visible';
          document.getElementById('work-order-view').style.display = 'flex';
          document.getElementById('current-page').innerHTML = "Work Orders";
          
          const buttonCheck = document.getElementById('customerRecordMobile');
          const opView = window.getComputedStyle(buttonCheck).getPropertyValue('display');
          console.log(opView);

          if(opView == 'flex'){
            console.log('Customer Record Mobile is open')
              document.getElementById('Update').style.display = 'flex';
          }
      }
  }else if (from === 'hauling'){

    if(type === 'opCheck'){
     const jobData = event.data.info;
     opCheck('', jobData);
    }
  }
});


const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];


/*
function numPad0(str) {
    var cStr = str.toString();
    if (cStr.length < 2) str = 0 + cStr;
    return str;
}

 function time() {
    var currDate = new Date();
    var currSec = currDate.getSeconds();
    var currMin = currDate.getMinutes();
    var curr24Hr = currDate.getHours();
    var ampm = curr24Hr >= 12 ? 'pm' : 'am';
    let currHr = curr24Hr % 12;
    currHr = currHr ? currHr : 12;
    var sttime = currHr + ':' + numPad0(currMin);
    var ssec = numPad0(currSec);
    document.getElementById('time').innerHTML = sttime;
    document.getElementById('ampm').innerHTML = ampm;
    if(curr24Hr>=5 && curr24Hr<=17)document.getElementById('dn').innerHTML = "🌞";
    else document.getElementById('dn').innerHTML = "🌜";
} 

var t;

function startClock() {
    time(); // Run once immediately
    t = setInterval(time, 50);
}

function stopClock() {
    clearInterval(t);
} */

  async function setupLocation() {
    let latitude = localStorage.getItem('latitude');
    let longitude = localStorage.getItem('longitude');
  
    if (!latitude || !longitude) {
      const city = prompt("Please enter your city:");
      const state = prompt("Please enter your state abbreviation:");
  
      if (!city || !state) {
        console.error("City and state are required.");
        return null;
      }
  
      try {
        const response = await fetch(`https://api.openweathermap.org/geo/1.0/direct?q=${city},${state},US&limit=1&appid=5d8cd967e127c059292f51ac548a5e2b`);
        const data = await response.json();
  
        if (data.length > 0) {
          latitude = data[0].lat;
          longitude = data[0].lon;
          localStorage.setItem('latitude', latitude);
          localStorage.setItem('longitude', longitude);
          console.log(`Location set to: ${city}, ${state}`);
        } else {
          console.error("Location not found.");
          return null;
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
        return null;
      }
    }
  
    return { latitude, longitude };
  }

  async function fetchWeatherInfo(dateString) {
    if (!navigator.onLine) {
      console.log('User is offline. Skipping weather info fetch.');
      return null;
    }
  
    const location = await setupLocation();
    if (!location) {
      console.error("Unable to get location. Weather info cannot be fetched.");
      return null;
    }
  
    const { latitude, longitude } = location;
    const apiKey = '5d8cd967e127c059292f51ac548a5e2b';
    
    // Use UTC dates for comparison
    const targetDate = new Date(dateString + 'T00:00:00Z');
    const today = new Date();
    const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate()));
  
    // Compare dates using UTC
    const isToday = targetDate.getTime() === todayUTC.getTime();
    const daysDifference = Math.floor((targetDate - todayUTC) / (1000 * 60 * 60 * 24));
  
    try {
      if (isToday || daysDifference === 0) {
        // Fetch current weather for today
        const currentWeatherEndpoint = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=imperial`;
        const response = await fetch(currentWeatherEndpoint);
        const weatherData = await response.json();
        return {
          temperature: Math.round(weatherData.main.temp_max),
          description: weatherData.weather[0].description,
          windSpeed: Math.round(weatherData.wind.speed),
          windDirection: getWindDirection(weatherData.wind.deg),
        };
      } else if (daysDifference > 0 && daysDifference <= 5) {
        // Fetch forecast for next 5 days
        const forecastEndpoint = `https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${apiKey}&units=imperial`;
        const response = await fetch(forecastEndpoint);
        const data = await response.json();
  
        // Find the forecast for the specified date
        const forecast = data.list.find(item => {
          const itemDate = new Date(item.dt * 1000);
          return itemDate.toDateString() === targetDate.toDateString();
        });
  
        if (forecast) {
          return {
            temperature: Math.round(forecast.main.temp),
            description: forecast.weather[0].description,
            windSpeed: Math.round(forecast.wind.speed),
            windDirection: getWindDirection(forecast.wind.deg),
          };
        }
      }
  
      // For dates beyond 5 days, return null
      console.log('No forecast available for:', dateString);
      return null;
    } catch (error) {
      console.error('Failed to fetch weather data:', error);
      return null;
    }
  }

  function getWeatherEmoji(description) {
    const lowerDesc = description.toLowerCase();
    if (lowerDesc.includes('clear')) return '☀️';
    if (lowerDesc.includes('cloud')) return '☁️';
    if (lowerDesc.includes('rain')) return '🌧️';
    if (lowerDesc.includes('snow')) return '❄️';
    if (lowerDesc.includes('thunderstorm')) return '⛈️';
    if (lowerDesc.includes('mist') || lowerDesc.includes('fog')) return '🌫️';
    return '🌤️'; // default to partly cloudy
  }
  
  function getWindDirection(degrees) {
    const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
    return directions[Math.round(degrees / 45) % 8];
  }

  let currentWeekStart;

  function initializeWeekStart() {
    currentWeekStart = new Date();
    currentWeekStart.setDate(currentWeekStart.getDate() - (currentWeekStart.getDay() + 6) % 7);
    currentWeekStart.setHours(0, 0, 0, 0);
    console.log("Initialized currentWeekStart:", currentWeekStart.toDateString());
  }

  initializeWeekStart();
  
  function navigateWeek(direction) {
    currentWeekStart.setDate(currentWeekStart.getDate() + (direction * 7));
    console.log("Navigated to week starting:", currentWeekStart.toDateString());
    updateWeekView();
  }

  async function updateWeekView() {
    console.log("Starting updateWeekView for week:", currentWeekStart.toDateString());
    
    const weekContainer = document.getElementById('week-container');
    if (!weekContainer) {
      console.error("Week container not found");
      return;
    }
    weekContainer.innerHTML = '';
  
    if (!globalTechList || !Array.isArray(globalTechList) || globalTechList.length === 0) {
      console.error("globalTechList is not properly defined");
      return;
    }
  
    let jobs;
    try {
      jobs = await getJobDataIndex(currentWeekStart);
      if (!Array.isArray(jobs)) {
        console.error("getJobDataIndex did not return an array");
        return;
      }
    } catch (error) {
      console.error("Error fetching job data:", error);
      return;
    }
  
    const techStats = initializeTechStats(globalTechList);
  
    try {
      const includeSaturday = isSaturdayIncluded(jobs);
      const { weekStart, weekEnd } = getCurrentWeekRange(includeSaturday);
      console.log("Current Week Range:", { weekStart: weekStart.toDateString(), weekEnd: weekEnd.toDateString() });
  
      await createHeaderRow(weekStart, weekEnd);
      
      jobs = processJobs(jobs, getTodayString());
      await distributeAndSortJobs(jobs, techStats, weekStart, weekEnd);
  
      const updatedTechList = Object.keys(techStats);
      for (const tech of updatedTechList) {
        if (tech && tech !== '') {
          await createTechRow(tech, techStats, weekContainer, weekStart);
        }
      }
  
      adjustColumnWidths(weekContainer);
      addDragAndDropListeners();
      updateWeekLabel(weekStart, weekEnd);
  
    } catch (e) {
      console.error("Error in updateWeekView:", e);
      console.error("Stack trace:", e.stack);
    }
  }

  function getCurrentWeekRange() {
    const weekStart = new Date(currentWeekStart);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekStart.getDate() + 4);
    return { weekStart, weekEnd };
  }

function initializeTechStats(techList) {
    const techStats = {};
    techList.forEach(tech => {
        techStats[tech] = { totalHours: 0, dailyStats: {} };
    });
    return techStats;
}

function getTodayString() {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today.toISOString().split('T')[0];
}

async function createHeaderRow(weekStart, weekEnd) {

  document.getElementById('weekdays').innerHTML = '';
  
  const headerRow = document.createElement('div');
  headerRow.className = 'header-row';

  const techHeader = document.createElement('div');
  techHeader.className = 'tech-header';
  techHeader.textContent = 'TECH';
  headerRow.appendChild(techHeader);

  let numberOfDays = 6; // Default to 5 days

/*   if (isSaturdayIncluded(jobs)) {
    console.log("Saturday is included in the jobs list");
      numberOfDays = 6; // Extend to include Saturday
  } */

    for (let i = 0; i < numberOfDays; i++) {
      const date = new Date(weekStart);
      date.setDate(date.getDate() + i);
      const dateString = date.toISOString().split('T')[0];

      let tideInfo, weatherInfo;
      try {
          [tideInfo, weatherInfo] = await Promise.all([
              fetchTideInfo(dateString),
              fetchWeatherInfo(dateString)
          ]);
      } catch (error) {
          console.warn(`Error fetching tide or weather info for ${dateString}:`, error);
      }

      const dateHeader = document.createElement('div');
      dateHeader.className = 'date-header';
      dateHeader.setAttribute('data-date', dateString);
      dateHeader.innerHTML = `
          ${weekday[date.getDay()].slice(0, 3).toUpperCase()}<br>
          ${formatDateWithOrdinal(date)}<br>
          ${weatherInfo ? getWeatherEmoji(weatherInfo.description) : ''}
      `;
      headerRow.appendChild(dateHeader);
  }

  document.getElementById('weekdays').appendChild(headerRow);
}

function isSaturdayIncluded(jobs) {
  return jobs.some(job => {
      const jobDate = new Date(job.Schedule_Date);
      return jobDate.getUTCDay() === 6; // Saturday
  });
}

function processJobs(jobs, todayString) {
  return jobs
    .filter(job => (job.Status === "On Work Schedule" || job.Status === "In Progress") && job.Techs != '')
    .sort((a, b) => new Date(a.Request_Date) - new Date(b.Request_Date))
    .map(job => {
      if (new Date(job.Schedule_Date) < new Date(todayString)) {
        job.Schedule_Date = todayString;
        job.Sort_Date = todayString;
      }else{
        job.Sort_Date = job.Schedule_Date;
      }
      return job;
    });
}

  async function distributeAndSortJobs(jobs, techStats, weekStart, weekEnd) {
    const startOfWeek = new Date(weekStart);
    const endOfWeek = new Date(weekEnd);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
  
    console.log("Start of Week:", startOfWeek);
    console.log("End of Week:", endOfWeek);
    console.log("Today:", today);

  let allTechs = {};

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  // Initialize allTechs and techStats
  for (const job of jobs) {
    const techNames = job.Techs.split(',').map(tech => tech.trim());
    techNames.forEach(tech => {
      if (!allTechs[tech]) {
        const scheduleDate = new Date(job.Schedule_Date);
        allTechs[tech] = {
          totalHours: 0,
          currentDate: isValidDate(scheduleDate) ? new Date(Math.max(today, scheduleDate)) : new Date(today)
        };
      }
      if (!techStats[tech]) {
        techStats[tech] = { totalHours: 0, dailyStats: {} };
      }
    });
  }

  for (const job of jobs) {
    const techNames = job.Techs.split(',').map(tech => tech.trim());
    let remainingHours = job.Estimated_Hours ? parseFloat(job.Estimated_Hours) : 0;
    let jobScheduleDate = new Date(job.Schedule_Date);
    let jobStartDate = isValidDate(jobScheduleDate) ? new Date(Math.max(today, jobScheduleDate)) : new Date(today);
    
    while (remainingHours > 0) {
      let earliestDate = new Date(Math.max(jobStartDate, ...techNames.map(tech => allTechs[tech].currentDate)));
      let availableHours = Math.min(8, ...techNames.map(tech => 8 - allTechs[tech].totalHours));
      let hoursToAdd = Math.min(remainingHours, availableHours);

      try {
        const dateString = earliestDate.toISOString().split('T')[0];

        // Only process if the date is within the current week
        if (earliestDate >= startOfWeek && earliestDate <= endOfWeek) {
          techNames.forEach(tech => {
            if (!techStats[tech].dailyStats[dateString]) {
              techStats[tech].dailyStats[dateString] = { hours: 0, jobCount: 0, jobs: [] };
            }

            techStats[tech].dailyStats[dateString].hours += hoursToAdd;
            techStats[tech].dailyStats[dateString].jobCount++;
            techStats[tech].dailyStats[dateString].jobs.push({
              ...job,
              Estimated_Hours: hoursToAdd,
              Sort_Date: dateString
            });
            techStats[tech].totalHours += hoursToAdd;
          });
        }

        techNames.forEach(tech => {
          allTechs[tech].totalHours += hoursToAdd;
          if (allTechs[tech].totalHours >= 8) {
            allTechs[tech].totalHours -= 8;
            do {
              allTechs[tech].currentDate.setDate(allTechs[tech].currentDate.getDate() + 1);
            } while (allTechs[tech].currentDate.getDay() === 0 || allTechs[tech].currentDate.getDay() === 6);
          }
        });

        remainingHours -= hoursToAdd;

        if (remainingHours > 0) {
          earliestDate.setDate(earliestDate.getDate() + 1);
          while (earliestDate.getDay() === 0 || earliestDate.getDay() === 6) {
            earliestDate.setDate(earliestDate.getDate() + 1);
          }
          jobStartDate = new Date(earliestDate);
        }
      } catch (error) {
        console.error("Error processing job:", job);
        console.error("Error details:", error);
        break; // Skip to the next job if there's an error
      }
    }
  }

  return techStats;
}

async function createTechRow(tech, techStats, weekContainer, weekStart) {

  const techRow = document.createElement('div');
  techRow.className = 'tech-row';

  const techNameColumn = document.createElement('div');
  techNameColumn.className = 'tech-stats';

  const techName = document.createElement('div');
  techName.className = 'tech-name';
  techName.textContent = tech;

  techNameColumn.appendChild(techName);

  const progressBarContainer = document.createElement('div');
  progressBarContainer.className = 'progress-bar-container';

  const progressWrapper = document.createElement('div');
  progressWrapper.className = 'progress-wrapper';

  const progressBar = document.createElement('div');
  progressBar.className = 'progress-bar';
  progressBar.id = `${tech}-progress-bar`;

  progressWrapper.appendChild(progressBar);

  const techHours = document.createElement('div');
  techHours.className = 'tech-hours';
  techHours.id = `${tech}-hours`;

  progressBarContainer.appendChild(progressWrapper);
  progressBarContainer.appendChild(techHours);
  techNameColumn.appendChild(progressBarContainer);
  techRow.appendChild(techNameColumn);

  const numberOfDays = 6;
  let numberOfHours = 40;
  let totalRowHours = 0;

  for (let i = 0; i < numberOfDays; i++) {
    const date = new Date(weekStart);
    date.setDate(date.getDate() + i);

      const dateString = await getFormattedDate(date); // Use the provided async function for formatted date
      
      const dayColumn = document.createElement('div');
      dayColumn.className = 'day-column';
      dayColumn.setAttribute('data-date', dateString);
      dayColumn.setAttribute('data-tech', tech);

      const eventContainer = document.createElement('div');
      eventContainer.className = 'event-container';

      const dayStats = techStats[tech].dailyStats[dateString] || { hours: 0, jobCount: 0, jobs: [] };

      dayStats.jobs.forEach(job => {
          try {

            if(new Date(job.Schedule_Date).getDay() == 6 && numberOfHours == 40){
              console.log(numberOfHours);
              numberOfHours = 48;
            }

              const eventElement = createEventElement(job);
              eventContainer.appendChild(eventElement);
          } catch (error) {
              console.error(`Error creating event element for job:`, job, error);
          }
      });

      const countElement = document.createElement('div');
      countElement.className = 'count-element';
      if(dayStats.hours == 1 && dayStats.jobCount == 1){
        countElement.textContent = `${dayStats.jobCount} JOB / ${dayStats.hours.toFixed(1)} HR`;
      }else if(dayStats.hours == 1 && dayStats.jobCount != 1){
      countElement.textContent = `${dayStats.jobCount} JOBS / ${dayStats.hours.toFixed(1)} HR`;
      }else if(dayStats.hours != 1 && dayStats.jobCount == 1){
        countElement.textContent = `${dayStats.jobCount} JOB / ${dayStats.hours.toFixed(1)} HRS`;
      }else{
        countElement.textContent = `${dayStats.jobCount} JOBS / ${dayStats.hours.toFixed(1)} HRS`;
      }

      totalRowHours += dayStats.hours;

      dayColumn.appendChild(eventContainer);
      dayColumn.appendChild(countElement);
      techRow.appendChild(dayColumn);
  }

  weekContainer.appendChild(techRow);

// Calculate daysPassed based on the current date relative to the currentWeekStart
let currentDate = new Date();
let currentWeekStartDate = new Date(currentWeekStart);
let remainingHours;

// Check if the current date is within the same week as currentWeekStart
if (currentDate >= currentWeekStartDate && currentDate < new Date(currentWeekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000)) {
    let daysPassed = currentDate.getDay() - currentWeekStartDate.getDay();

    if (currentDate.getDay() === 0) {  // Sunday case
        daysPassed = 6 * 8; // Consider the whole week passed
    } else if (currentDate.getDay() === 1) { // Monday case
        daysPassed = 0; // No hours have passed yet
    } else {
        daysPassed = (currentDate.getDay() - 1) * 8;
    }

    remainingHours = Math.max(0, numberOfHours - daysPassed);
} else {
    // If the current date is not within the current week, assume the full week is remaining
    remainingHours = numberOfHours;
}

  

  const totalHours = totalRowHours;
  console.log(totalHours, remainingHours);
  const progressBarWidth = (totalHours / remainingHours) * 100;

  const progressBarElement = document.getElementById(`${tech}-progress-bar`);
  if (progressBarElement) {
      progressBarElement.style.width = `${progressBarWidth}%`;
  } else {
      console.warn(`Progress bar element not found for tech: ${tech}`);
  }

  const techHoursElement = document.getElementById(`${tech}-hours`);
  if (techHoursElement) {
    if(totalHours == 1){
      techHoursElement.textContent = `${totalHours.toFixed(1)} HOUR`;
    }else{
      techHoursElement.textContent = `${totalHours.toFixed(1)} HOURS`;
    }
  } else {
      console.warn(`Tech hours element not found for tech: ${tech}`);
  }
}

function adjustColumnWidths(weekContainer) {
    const allDayColumns = weekContainer.querySelectorAll('.day-column');
    const headerColumns = weekContainer.querySelectorAll('.date-header');
    if (headerColumns.length > 0) {
        const columnWidth = headerColumns[0].offsetWidth;
        allDayColumns.forEach(column => {
            column.style.width = `${columnWidth}px`;
        });
    } else {
        console.warn("No header columns found");
    }
}
  
  function createEventElement(event) {
    const eventElement = document.createElement('div');
    eventElement.className = 'event';
    eventElement.draggable = true;
    eventElement.setAttribute('data-event-id', event.Job_ID || 'unknown');
  
    const customerDescriptionDiv = document.createElement('div');
    customerDescriptionDiv.className = 'customer-description';
  
    const customer = document.createElement('div');
    customer.className = 'schedule-customer';
    customer.textContent = `${event.Customer_Name.split(',')[0] || 'Unknown'} / ${event.Boat_Name || 'Unknown'}`;
  
    const description = document.createElement('div');
    description.className = 'schedule-description';
    description.textContent = event.Description || 'No description';
  
    const hours = document.createElement('div');
    hours.className = 'schedule-hours';
    const estimatedHours = parseFloat(event.Estimated_Hours) || 0;
    hours.textContent = estimatedHours === 1 ? "1 HR" : `${estimatedHours} HRS`;
  
    customerDescriptionDiv.appendChild(description);
    customerDescriptionDiv.appendChild(customer);
    eventElement.appendChild(customerDescriptionDiv);
  
    if (estimatedHours > 0) {
      eventElement.appendChild(hours);
    }else{
      eventElement.style.display = 'none';
    }
    
    return eventElement;
  }
  
  function getOrdinalSuffix(day) {
    if (day > 3 && day < 21) return day + 'th'; // Covers 11th through 20th
    switch (day % 10) {
        case 1: return day + 'st';
        case 2: return day + 'nd';
        case 3: return day + 'rd';
        default: return day + 'th';
    }
}

function formatDateWithOrdinal(date) {
    const day = date.getDate();
    return `${getOrdinalSuffix(day)}`;
}

function addEvent(date, description) {
  events.push({ date, description });
  updateWeekView();
}

async function load() {
  try{
    updateWeekView();


    
    document.getElementById('prev-week').addEventListener('click', () => navigateWeek(-1));
    document.getElementById('next-week').addEventListener('click', () => navigateWeek(1));


    if (document.getElementById('wrap')) {
        var sort = new Sort("wrap");
        sort.init();
    } else {
        console.warn("Element with id 'wrap' not found. Sort initialization skipped.");
    }
  }catch(e){
    console.log(e)
  }
}

function addDragAndDropListeners() {

  const events = document.querySelectorAll('.event');
  const dayColumns = document.querySelectorAll('.day-column');

  events.forEach(event => {
    event.addEventListener('dragstart', dragStart);
    event.addEventListener('dragend', dragEnd);
  });

  dayColumns.forEach(column => {
    column.addEventListener('dragover', dragOver);
    column.addEventListener('dragenter', dragEnter);
    column.addEventListener('dragleave', dragLeave);
    column.addEventListener('drop', drop);
  });
}

function dragStart(e) {
  e.dataTransfer.setData('text/plain', e.target.getAttribute('data-event-id'));
  setTimeout(() => e.target.style.opacity = '0.5', 0); // Adding opacity for visual feedback
}

function dragEnd(e) {
  e.target.style.opacity = '1'; // Reset opacity after drag ends
}

function dragOver(e) {
  e.preventDefault(); // Ensure the drop is allowed
  e.dataTransfer.dropEffect = 'move'; // Indicates a move operation
}

function dragEnter(e) {
  e.preventDefault(); // Ensure the drop is allowed
  if (e.target.classList.contains('day-column') || e.target.classList.contains('event-container')) {
    e.target.classList.add('drag-over');
  }
}

function dragLeave(e) {
  if (e.target.classList.contains('day-column') || e.target.classList.contains('event-container')) {
    e.target.classList.remove('drag-over');
  }
}

function drop(e) {
  e.preventDefault();

  const eventId = e.dataTransfer.getData('text');
  const draggedElement = document.querySelector(`[data-event-id="${eventId}"]`);
  const dropZone = e.target.closest('.day-column');
  const newTech = dropZone.getAttribute('data-tech');
  const newDate = dropZone.getAttribute('data-date');

  console.log(e.target);

  console.log('Dropped event:', eventId, 'New tech:', newTech, 'New date:', newDate);

  if (!draggedElement || !dropZone || !newTech || !newDate) {
    console.error('Drag and drop failed due to missing elements or attributes.');
    return;
  }

  // Append the dragged event to the new event container
  dropZone.querySelector('.event-container').appendChild(draggedElement);

  // Update the event's schedule date and tech in the database
  updateEventDate(eventId, newDate, newTech)
    .then(() => {
      // Recalculate hours for the affected days after the update
      updateDayStats(newTech, newDate);

      // Update the stats of the original day column (from where the event was dragged)
      const originalTech = draggedElement.closest('.day-column').getAttribute('data-tech');
      const originalDate = draggedElement.closest('.day-column').getAttribute('data-date');
      updateDayStats(originalTech, originalDate);

      // Update the entire week view to reflect changes
      updateWeekView();
    })
    .catch(error => {
      console.error('Failed to update event date and tech:', error);
    });
}

function updateDayStats(tech, date) {
  const dayColumn = document.querySelector(`.day-column[data-tech="${tech}"][data-date="${date}"]`);
  const events = dayColumn.querySelectorAll('.event');
  let totalHours = 0;
  events.forEach(event => {
    totalHours += parseFloat(event.textContent.split(' - ')[1]);
  });
  
  const countElement = dayColumn.querySelector('.count-element');
  countElement.textContent = `${events.length} JOBS / ${totalHours.toFixed(1)} HRS`;
}

function updateWeekLabel(weekStart) {
  const weekEnd = new Date(weekStart);
  weekEnd.setDate(weekStart.getDate() + 5); // Assuming 5-day work week
  const startStr = weekStart.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  const endStr = weekEnd.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  document.getElementById('current-week').textContent = `${startStr} - ${endStr}`;
}

async function updateEventDate(eventId, newDate, tech) {

  try{
    await updateJobInfo(eventId, "Schedule_Date", newDate);
    await updateJobInfo(eventId, "Techs", tech);
  }catch(error){
    console.log('Error updating event data', error);
  };

}

// Sort functionality
var Sort = function(target_id) {
  let _mobile = mobileCheck();
  let wrap;
  let list;
  let draggable;
  let dragging;
  let drIdx;
  let tarIdx;
  let moveable = false;
  let listname = "list";
  let dr_listname = "draggable";
  let _scroll = document.body;

  var handle = {
      up: (_mobile) ? "touchend" : "mouseup",
      move: (_mobile) ? "touchmove" : "mousemove",
      down: (_mobile) ? "touchstart" : "mousedown"
  };

    this.setScroll = function(obj) {
        _scroll = obj;
    }

    this.setListname = function(str) {
        listname = str;
    }

    this.setdrListname = function(str) {
        dr_listname = str;
    }

    function set_e() {
      wrap = document.getElementById(target_id);
      if (!wrap) {
          console.error(`Element with id "${target_id}" not found`);
          return;
      }
      list = wrap.getElementsByClassName(listname);
      draggable = wrap.getElementsByClassName(dr_listname);
      _mobile = mobileCheck();
      drIdx = null;

      wrap.setAttribute("sorting", "false");

      for (var i = 0; i < draggable.length; i++) {
          (function(i) {
              list[i].setAttribute("dragging", "false");
              list[i].setAttribute("drag_after", "false");
              draggable[i].addEventListener(handle.down, function(e) {
                  // ... (rest of the event listener code)
              });
          })(i);
      }
  }

    
    function init() {
      if (document.getElementById(target_id)) {
          set_e();
          moveable = true;
      } else {
          console.error(`Element with id "${target_id}" not found. Sort initialization failed.`);
      }
  }
  this.init = init;

    function set_y(elem, y) {
        elem.style.transform = "translate3d(0, " + y + "px, 0)";
        elem.style.webkitTransform = "translate3d(0, " + y + "px, 0)";
    }

    function remove_y(elem) {
        elem.style.transform = "";
        elem.style.webkitTransform = "";
    }

  function mobileCheck() {
    var check = false;
    (function(a) {
      if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) check = true
    })(navigator.userAgent || navigator.vendor || window.opera);
    return check;
  }
}

function setupFilterListeners() {
  const filterView = document.getElementById('filter-view');
  const checkboxes = filterView.querySelectorAll('input[type="checkbox"]');
  
  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', applyFilters);
  });
}

document.addEventListener('DOMContentLoaded', () => {
  const progressBar = document.getElementById('progress-bar');
  let width = 0;
  const intervalId = setInterval(() => {
      if (width >= 100) {
          clearInterval(intervalId);
      } else {
          width++;
          progressBar.style.width = width + '%';
      }
  }, 20);
});

window.addEventListener('load', () => {
  document.getElementById('progress-bar').style.width = '100%';
  document.getElementById('progress-bar').style.display = 'none';
});

document.getElementById('DOMContentLoaded', setupFilterListeners);

async function applyFilters(shouldUpdate = true) {
  try{
    const filterView = document.getElementById('filter-view');
    const checkboxes = filterView.querySelectorAll('input[type="checkbox"]:checked');
    const tableItems = document.querySelectorAll('.table-item');
    const jobItems = document.querySelectorAll('.job-item');
  
    const selectedFilters = {
      'WO Category': [],
      'Priority': [],
      'Status': [],
      'Op Type': []
    };
  
    // Gather all selected filters
    checkboxes.forEach(checkbox => {
      const filterType = checkbox.closest('ul').closest('li').firstChild.textContent.trim();
      selectedFilters[filterType].push(checkbox.value);
    });
  
    const hasActiveFilters = Object.values(selectedFilters).some(filters => filters.length > 0);
  
    function filterItem(item) {
      if (!hasActiveFilters) {
        item.classList.remove('filtered');
        item.style.display = '';
        return;
      }
  
      const data = JSON.parse(item.getAttribute('data-jobinfo'));
      let shouldShow = true;
  
      for (const [filterType, filterValues] of Object.entries(selectedFilters)) {
        if (filterValues.length > 0) {
          switch (filterType) {
            case 'WO Category':
              if (!filterValues.includes(data.Category)) shouldShow = false;
              break;
            case 'Priority':
              if (!filterValues.includes(data.Priority)) shouldShow = false;
              break;
            case 'Status':
              if (!filterValues.includes(data.Status)) shouldShow = false;
              break;
            case 'Op Type':
              if (!filterValues.includes(data.Op_Type)) shouldShow = false;
              break;
          }
        }
        if (!shouldShow) break;
      }
  
      if (shouldShow) {
        item.classList.remove('filtered');
        item.style.display = '';
      } else {
        item.classList.add('filtered');
        item.style.display = 'none';
      }
    }
  
    tableItems.forEach(filterItem);
    jobItems.forEach(filterItem);
  
    if (shouldUpdate) {
      updateTable();
    }
  }catch{error}{
    console.log(error)
  }
  
}

function setupSortListeners() {
  const sortView = document.getElementById('sort-view');
  const sortOptions = sortView.querySelectorAll('li');
  
  sortOptions.forEach(option => {
    // Remove any existing listeners before adding a new one
    option.removeEventListener('click', applySorting);
    option.addEventListener('click', applySorting);
  });
}

let currentSortType = '';
let isAscending = true;

function applySorting(eventOrSortType, shouldUpdate = true) {
  let sortType;
  let targetElement;

  // Check if the first argument is an event or a string
  if (typeof eventOrSortType === 'object' && eventOrSortType.target) {
    // It's an event
    sortType = eventOrSortType.target.textContent.trim();
    targetElement = eventOrSortType.target;
  } else {
    // It's a sort type string
    sortType = eventOrSortType;
    // Find the element with matching text content
    targetElement = Array.from(document.querySelectorAll('#sort-view li'))
      .find(el => el.textContent.trim() === sortType);
  }

  // Toggle sort direction if the same sort type is clicked
  if (sortType === currentSortType) {
    isAscending = !isAscending;
  } else {
    currentSortType = sortType;
    isAscending = true;
  }

  console.log(`Sorting by ${currentSortType}, isAscending: ${isAscending}`);

  performSort();

  // Clear current sort indication
  const sortView = document.getElementById('sort-view');
  sortView.querySelectorAll('li').forEach(li => {
    li.classList.remove('active-sort', 'sort-asc', 'sort-desc');
  });
  
  // Add indication to current sort type
  if (targetElement) {
    targetElement.classList.add('active-sort', isAscending ? 'sort-asc' : 'sort-desc');
  }

  if (shouldUpdate) {
    updateTable(false);  // Pass false to prevent re-sorting
  }
}

function performSort() {
  const tableContent = document.getElementById('table-content');
  const jobList = document.getElementById('jobList');
  const tableItems = Array.from(tableContent.querySelectorAll('.table-item:not(.filtered)'));
  const jobItems = Array.from(jobList.querySelectorAll('.job-item:not(.filtered)'));

  function sortItems(items) {
    return items.sort((a, b) => {
      const dataA = JSON.parse(a.getAttribute('data-jobinfo'));
      const dataB = JSON.parse(b.getAttribute('data-jobinfo'));
      let comparison = 0;

      switch (currentSortType) {
        case 'Haul Date':
          comparison = new Date(dataA.Haul_Date || 0) - new Date(dataB.Haul_Date || 0);
          break;
        case 'Request Date':
          comparison = new Date(dataA.Request_Date || 0) - new Date(dataB.Request_Date || 0);
          break;
        case 'Scheduled Date':
          comparison = new Date(dataA.Schedule_Date || 0) - new Date(dataB.Schedule_Date || 0);
          break;
        case 'Customer [Last Name]':
          comparison = (dataA.Customer_Name || '').localeCompare(dataB.Customer_Name || '');
          break;
        case 'Work Order #':
          comparison = (dataA.Work_Order || '').localeCompare(dataB.Work_Order || '');
          break;
        case 'Status':
          comparison = (dataA.Status || '').localeCompare(dataB.Status || '');
          break;
        case 'Start Date':
          comparison = new Date(dataA.Start_Date ? dataA.Start_Date : dataA.Request_Date || 0) -
            new Date(dataB.Start_Date ? dataB.Start_Date : dataB.Request_Date || 0);
          break;
        default:
          console.warn('Unknown sort type:', currentSortType);
          return 0;
      }

      return isAscending ? comparison : -comparison;
    });
  }

  const sortedTableItems = sortItems(tableItems);
  const sortedJobItems = sortItems(jobItems);

  // Re-append sorted items to the table and job list
  sortedTableItems.forEach(item => tableContent.appendChild(item));
  sortedJobItems.forEach(item => jobList.appendChild(item));
}

function updateTable(shouldApplySort = true) {
  const dataSection = document.getElementById('table-content');
  const jobSection = document.getElementById('jobList');
  const tableItems = Array.from(dataSection.querySelectorAll('.table-item'));
  const jobItems = Array.from(jobSection.querySelectorAll('.job-item'));

  // Re-append filtered items to the table and job list
  tableItems.forEach(item => {
    if (!item.classList.contains('filtered')) {
      dataSection.appendChild(item);
    }
  });

  jobItems.forEach(item => {
    if (!item.classList.contains('filtered')) {
      jobSection.appendChild(item);
    }
  });

  if (shouldApplySort) {
    if (!currentSortType) {
      // If no sort is applied, sort by the default method (Start Date)
      currentSortType = 'Start Date';
      isAscending = true;
    }
    performSort();
  }

  // Apply search after sorting and filtering
  searchCall();

  // Handle empty table and job list
 /*  handleEmptyTable();
  handleEmptyJobList(); */
}

document.getElementById('clear-filters').addEventListener('click', clearFilters);

async function clearFilters(){
  try{
    const selectedFilters = document.querySelectorAll('#filter-view input[type="checkbox"]:checked');
    selectedFilters.forEach(filter => {
      filter.checked = false;
    });
    await applyFilters();
    updateTable()
  }catch(error){
    console.log('Error removing filters', error);
  }
}


document.addEventListener('DOMContentLoaded', () => {
  setupFilterListeners();
  setupSortListeners();

  const filterViewButton = document.getElementById('filter-view-button');
  const sortViewButton = document.getElementById('sort-view-button');

  filterViewButton.addEventListener('click', () => {
    document.getElementById('filter-view').classList.toggle('hideBox');
  });

  sortViewButton.addEventListener('click', () => {
    document.getElementById('sort-view').classList.toggle('hideBox');
  });

  const searchInput = document.getElementById('search');
  searchInput.addEventListener('input', updateTable);

  // Add event listeners for filter checkboxes
  const filterCheckboxes = document.querySelectorAll('#filter-view input[type="checkbox"]');
  filterCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('change', updateTable);
  });

  // Note: We don't need to add sort listeners here because setupSortListeners() already does this
});

function fillInCustomerData() {
  const customerData = document.getElementById('customer-data-template');
  const laborData = document.getElementById('labor-customer-data');
  const partsData = document.getElementById('parts-customer-data');

  laborData.innerHTML = '';
  partsData.innerHTML = '';

  if (customerData && laborData && partsData) {
    const laborDataClone = customerData.cloneNode(true);
    const partsDataClone = customerData.cloneNode(true);
    
    laborData.appendChild(laborDataClone);
    partsData.appendChild(partsDataClone);
  } else {
    console.error('One or more required elements are missing');
  }
}


async function getJobDataIndex() {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", indexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore("jobData", { keyPath: "id" });
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("jobData", "readonly");
      const store = tx.objectStore("jobData");
      const request = store.getAll();

      request.onsuccess = function (event) {
        const data = event.target.result;
        resolve(data);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    };

    openRequest.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

async function getCustomerDataIndex() {
  return new Promise((resolve, reject) => {
    const openRequest = window.indexedDB.open("openDatabase", indexedVersion);

    openRequest.onupgradeneeded = function (event) {
      const db = event.target.result;
      db.createObjectStore("customerData", { keyPath: "id" });
    };

    openRequest.onsuccess = function (event) {
      const db = event.target.result;
      const tx = db.transaction("customerData", "readonly");
      const store = tx.objectStore("customerData");
      const request = store.getAll();

      request.onsuccess = function (event) {
        const data = event.target.result;
        resolve(data);
      };

      request.onerror = function (event) {
        reject(event.target.error);
      };
    };

    openRequest.onerror = function (event) {
      reject(event.target.error);
    };
  });
}

const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
recognition.lang = 'es-ES';
recognition.continuous = true;
recognition.interimResults = true;

let finalTranscript = '';

recognition.onresult = (event) => {
  let interimTranscript = '';
  for (let i = event.resultIndex; i < event.results.length; ++i) {
    if (event.results[i].isFinal) {
      finalTranscript += event.results[i][0].transcript;
    } else {
      interimTranscript += event.results[i][0].transcript;
    }
  }
  
  document.getElementById('interim').innerHTML = interimTranscript;
  
  if (event.results[event.resultIndex].isFinal) {
    document.getElementById('final').innerHTML = finalTranscript;
    translateText(finalTranscript);
  }
};

document.getElementById('startButton').onclick = () => {
  recognition.start();
};

document.getElementById('stopButton').onclick = () => {
  recognition.stop();
};

async function translateText(text) {
  const apiKey = 'AIzaSyBgl19jEdx85UhgD5vrAXii8-o99xdST2U'; // Your API key
  const url = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        q: text,
        source: 'es',
        target: 'en',
        format: 'text'
      })
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    const translatedText = result.data.translations[0].translatedText;
    document.getElementById('translation').innerHTML = translatedText;
  } catch (error) {
    console.error("Translation error:", error);
    document.getElementById('translation').innerHTML = "Translation failed. Please try again.";
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('.update-form');
  let debounceTimer;

  elements.forEach(element => {
    // Create the icon element
    const iconElement = document.createElement('span');
    iconElement.className = 'update-icon';
    
    // Insert the icon after the element
    element.parentNode.insertBefore(iconElement, element.nextSibling);

    element.addEventListener('input', (e) => {
      clearTimeout(debounceTimer);
      iconElement.textContent = '🔄';
      iconElement.classList.add('updating');
      iconElement.classList.remove('updated');

      debounceTimer = setTimeout(async () => {
        const jobId = document.getElementById('currentJobId').value;
        const customerId = document.getElementById('updateCustomerId').value;
        const jobIndex = e.target.getAttribute('data-update');
        const customerIndex = e.target.getAttribute('data-update-customer');
        const value = e.target.value;

        try {
          if (jobIndex) {
            await updateJobInfo(jobId, jobIndex, value);
            console.log(`Updated job ${jobIndex} to ${value} for job ${jobId}`);
          } else if (customerIndex) {
            await updateCustomerInfo(customerId, customerIndex, value);
            console.log(`Updated customer ${customerIndex} to ${value} for customer ${customerId}`);
          }

          iconElement.textContent = '✅';
          iconElement.classList.remove('updating');
          iconElement.classList.add('updated');
          
          // Remove the 'updated' class and clear the icon after 1 second
          setTimeout(() => {
            iconElement.classList.remove('updated');
            iconElement.textContent = '';
          }, 1000);
        } catch (error) {
          console.error('Error updating info:', error);
          iconElement.classList.remove('updating');
          iconElement.textContent = '❌';
          toastMessage('Failed to update info. Please try again.', 'red');
        }
      }, 2000);
    });
  });
});

class CustomTechSelect extends HTMLElement {
  constructor() {
    super();
    this.techList = ["Alex", "Antony", "Conrad", "Darlin", "Eduardo", "Elvin", "Henry", "Jorge", "Kyle", "Liam", "Mac"];
    this.selectedTechs = new Set();
  }

  connectedCallback() {
    this.render();
    this.addEventListeners();
  }

  render() {
    const classes = this.getAttribute('class') || '';
    const id = this.getAttribute('id') || '';

    this.innerHTML = `
      <div class="custom-select ${classes}" id="${id}">
        <div class="selected-techs">Select Techs</div>
        <div class="tech-list"></div>
      </div>
    `;
    this.updateTechList();
  }

  addEventListeners() {
    const selectedTechs = this.querySelector('.selected-techs');
    const techList = this.querySelector('.tech-list');

    if (selectedTechs) {
      selectedTechs.addEventListener('click', () => {
        techList.classList.toggle('open');
        selectedTechs.classList.toggle('open');
      });
    }

    if (techList) {
      techList.addEventListener('change', (e) => this.handleTechSelection(e));
    }
  }

  updateTechList() {
    const container = this.querySelector('.tech-list');
    if (!container) return;

    container.innerHTML = this.techList.map(tech => `
      <div class="tech-item">
        <input type="checkbox" id="${tech}" value="${tech}">
        <label for="${tech}">${tech}</label>
      </div>
    `).join('');
  }

  handleTechSelection(event) {
    if (event.target.type === 'checkbox') {
      const tech = event.target.value;
      if (event.target.checked) {
        this.selectedTechs.add(tech);
      } else {
        this.selectedTechs.delete(tech);
      }
      this.updateSelectedTechs();
      this.dispatchEvent(new Event('change'));
    }
  }

  updateSelectedTechs() {
    const selectedTechs = Array.from(this.selectedTechs).join(', ');
    this.querySelector('.selected-techs').textContent = selectedTechs || 'Select Techs';
  }

  get value() {
    return Array.from(this.selectedTechs).join(', ');
  }

  set value(techs) {
    this.selectedTechs = new Set(techs.split(', ').map(tech => tech.trim()));
    this.updateSelectedTechs();
  }
}

customElements.define('custom-tech-select', CustomTechSelect);


async function checkIndexedDBUsage() {
  if (navigator.storage && navigator.storage.estimate) {
    try {
      const estimate = await navigator.storage.estimate();
      const totalBytes = estimate.quota;
      const usedBytes = estimate.usage;
      const usedMB = Math.round(usedBytes / (1024 * 1024));
      const totalMB = Math.round(totalBytes / (1024 * 1024));
      const percentageUsed = ((usedBytes / totalBytes) * 100).toFixed(2);

      console.log(`Storage Usage:
        Used: ${usedMB} MB
        Total: ${totalMB} MB
        Percentage Used: ${percentageUsed}%`);

      // Optionally, you can check usage for specific storage types
      if (estimate.usageDetails) {
        const indexedDBUsage = estimate.usageDetails.indexedDB;
        const indexedDBUsageMB = Math.round(indexedDBUsage / (1024 * 1024));
        console.log(`IndexedDB Usage: ${indexedDBUsageMB} MB`);
      }
    } catch (error) {
      console.error('Error checking storage estimate:', error);
    }
  } else {
    console.log('Storage estimation API is not available');
  }
}